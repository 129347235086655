import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from "react-select";

function Designation() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    name: "",
    description: "",
  };
  const [desig, setDesig] = useState(initValue);
  const [desigs, setDesigs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  const [desigUpdate, setDesigUpdate] = useState(false);
  const [error, setError] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');

  const setDesigDetail = ({ key, value }) => {
    let detail = { ...desig };
    detail[key] = value.toUpperCase();
    setDesig(detail);
    
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const saveDesig = () => {
    let temp = { ...desig };
    
    if (temp._id) {
      customAxios.post("/designation/update", { temp }).then((data) => {
        
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/designation";
      customAxios
        .post(url, desig)
        .then((resp) => {
          
          if (resp.data.error) {
            
            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setDesig(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchDesig = () => {
    let url = "/designation/search";
    customAxios.post(url, { name: searchValue }).then((charges) => {
      setDesigs(charges.data.data);
    });
  };

  const deleteDesig = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios
        .delete("/designation", { data: { userId: id } })
        .then((data) => {
          if (data.data.error) {
            setError(data.message);
          } else {
            
            window.alert("Designation deleted succesfully!");
            let temp = [...desigs];
            temp.splice(index, 1);
            setDesigs(temp);
          }
        });
    }
  };

  const editDesig = (index, id) => {
    let temp = [...desigs];
    setDesig({ ...temp[index] });
    setShowList(false);
    setDesigUpdate(true);
  };

  useEffect(() => {
    let url = "/designation";
    customAxios.get(url).then((desigs) => {
      setDesigs(desigs.data.data);
      setSearchList(desigs.data.data);
    });

    let desigurl = "/designation";
    customAxios.get(desigurl).then((desigs) => {
      let temp = [...desigs.data.data];
      let designations = temp.map((data)=>{
        return{label: data.name, value: data.name}
      })
      setDesignationList([...designations]);
    });
  }, [showList]);

  const handleSearch = (option) => {
    
    if (option == null) {
      let url = "/designation";
      customAxios.get(url).then((desigs) => {
        setDesigs(desigs.data.data);
        setSearchList(desigs.data.data);
        setSelectedVal(null);
     
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setDesigs(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Designation</h5>
        <section className={classes.title}>Dashboard / designation</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Designation Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    // onClick={searchCustomer}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                     options={designationList}
                    value={selectedVal}
                    onChange={handleSearch} 
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setDesig(initValue);
                      setDesigUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Designation Name</th>
                    <th scope="col" style={theadStyle}>Description</th>

                    {/* {isSuperuser &&  */}<th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {desigs.length
                    ? desigs.map((desig, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td style={{textTransform: 'uppercase'}}>{desig.name}</td>
                            <td>{desig.description}</td>
                            {/* {isSuperuser && ( */}
                              <td>
                                {/* <a href='#'> */}
                                <img
                                  src={EditIcon}
                                  alt="edit_icon"
                                  onClick={() => editDesig(index, desig._id)}
                                />
                                {/* </a>{' '} */}
                                &nbsp;&nbsp; {/* <a href='#'> */}
                                <img
                                  src={DeleteIcon}
                                  alt="delete_Icon"
                                  onClick={() => deleteDesig(index, desig._id)}
                                />
                                {/* </a> */}
                              </td>
                            {/* )} */}
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              {desigs.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Designation</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Designation Name</p>
                <input
                style={{textTransform: 'uppercase'}}
                  type="text"
                  className={classes.inputText}
                  value={desig.name}
                  onChange={(e) =>
                    setDesigDetail({ key: "name", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Designation Description</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={desig.description}
                  onChange={(e) =>
                    setDesigDetail({
                      key: "description",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveDesig}>
                {desigUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setDesig(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Designation;

import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { LogoText, NotificationIcon, ProfileIcon } from '../Images';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import classes from './AdminDashboard.module.css';
import Footer from './Master/Footer';
import React, { useEffect, useState } from 'react';
//sds fsadfsdfads
function AdminDashboard() {
  let navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('roll');
    localStorage.removeItem('role');
    navigate('/admin/login');
  };

  const [path, setPath] = useState('home');
  // const isSuperuser = localStorage.getItem('roll') == 'Super Admin' ? true : false;

  useEffect(() => {
    setPath(window.location.pathname.slice(17));

  }, [window.location.pathname]);

  const loginRole = localStorage.getItem('role');


  return (
    <div>
      <header>
        <section className={classes.section}>
          <img className={classes.LogoText} src={LogoText} alt='Logo' style={{ height: '100%' }} />
        </section>
        <section className={classes.section}>
          <input
            className={classes.searchBox}
            type='text'
            placeholder='Search..'
          />
          <div className={classes.vl}></div>
          <img
            className={classes.NotifIcon}
            src={NotificationIcon}
            alt='notifications'
          />
          <div className={classes.vl}></div>
          <img
            className={classes.ProfileIcon}
            src={ProfileIcon}
            onClick={logout}
            alt='Profile'
          />
        </section>
      </header>

      <Container fluid className={classes.dashboardContainer}>
        <Row className={classes.row}>
          <Col xl={2} lg={2} sm={2} md={2} className={classes.NavBar}>
            <ProSidebar
              breakPoint='md'
              width='100%'
              popperArrow={true}
              // collapsed={true}
              collapsedWidth='50%'
            >
              <SidebarHeader>
                <div
                  style={{
                    backgroundColor: '#0292d1',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '15px 20px',
                    letterSpacing: '1px',
                    border: '0px',
                  }}
                >
                  WELCOME ADMIN
                </div>
              </SidebarHeader>

              <SidebarContent className={classes.SidebarContent}>
                <Menu iconShape='square'>
                  {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'A&E' ?
                    <SubMenu
                      title='Master'
                      className={
                        window.location.pathname.includes(
                          '/admin/dashboard/master'
                        )
                          ? classes.subMenuActive
                          : classes.subMenu
                      }
                    >

                      <MenuItem
                        onClick={() => navigate('/admin/dashboard/master/home')}
                        className={
                          path === 'master/home' ? classes.menuItemActive : ''
                        }
                      >
                        Home
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/customer')
                        }
                        className={
                          path === 'master/customer' ? classes.menuItemActive : ''
                        }
                      >
                        Customer
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/charges')
                        }
                        className={
                          path === 'master/charges' ? classes.menuItemActive : ''
                        }
                      >
                        Charges
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/currency')
                        }
                        className={
                          path === 'master/currency' ? classes.menuItemActive : ''
                        }
                      >
                        Currency
                      </MenuItem>

                      <MenuItem
                        onClick={() => navigate('/admin/dashboard/master/vessel')}
                        className={
                          path === 'master/vessel' ? classes.menuItemActive : ''
                        }
                      >
                        Vessel
                      </MenuItem>

                      <MenuItem
                        onClick={() => navigate('/admin/dashboard/master/port')}
                        className={
                          path === 'master/port' ? classes.menuItemActive : ''
                        }
                      >
                        Port
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/container')
                        }
                        className={
                          path === 'master/container'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Container
                      </MenuItem>

                      <MenuItem
                        onClick={() => navigate('/admin/dashboard/master/cargo')}
                        className={
                          path === 'master/cargo' ? classes.menuItemActive : ''
                        }
                      >
                        Cargo
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/package')
                        }
                        className={
                          path === 'master/package' ? classes.menuItemActive : ''
                        }
                      >
                        Package
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/company')
                        }
                        className={
                          path === 'master/company' ? classes.menuItemActive : ''
                        }
                      >
                        Company
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/employee')
                        }
                        className={
                          path === 'master/employee' ? classes.menuItemActive : ''
                        }
                      >
                        Employee
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/department')
                        }
                        className={
                          path === 'master/department'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Department
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/designation')
                        }
                        className={
                          path === 'master/designation'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Designation
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/instructions')
                        }
                        className={
                          path === 'master/instructions'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Export Remarks
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/bankAccDetails')
                        }
                        className={
                          path === 'master/bankAccDetails'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Bank A/C Details
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/master/invoiceEOE')
                        }
                        className={
                          path === 'master/invoiceEOE'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        E & O E
                      </MenuItem>
                    </SubMenu> : null}

                  {loginRole == 'A&E' ?
                    (<>
                      <SubMenu
                        title='Exports'
                        className={
                          window.location.pathname.includes(
                            '/admin/dashboard/exports'
                          )
                            ? classes.subMenuActive
                            : classes.subMenu
                        }
                      >

                        {loginRole != 'D' ?

                          <SubMenu
                            title='Sales'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/exports/sales'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/sales/buyer')
                              }
                              className={
                                path === 'exports/sales/buyer'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Buying
                            </MenuItem>

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/sales/quotation')
                              }
                              className={
                                path === 'exports/sales/quotation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Selling (Quotation)
                            </MenuItem>

                            {loginRole == 'C' || loginRole == 'A' || loginRole == 'A&E' ? <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/sales/approval')
                              }
                              className={
                                path === 'exports/sales/approval'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Approval
                            </MenuItem> : null}

                            {/* loginRole == 'C' || loginRole == 'A' ||  */loginRole == 'A&E' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/sales/approved')
                                }
                                className={
                                  path === 'exports/sales/approved'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Approved
                              </MenuItem> : null}

                            {loginRole == 'B' || loginRole == 'A' || loginRole == 'A&E' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/sales/booking')
                                }
                                className={
                                  path === 'exports/sales/booking'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Booking
                              </MenuItem> : null}

                          </SubMenu> : null}

                        {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'A&E' ?
                          <SubMenu
                            title='Shipping Instruction'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/exports/shipping'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/shipping/creation')
                              }
                              className={
                                path === 'exports/shipping/creation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              S/I Creation
                            </MenuItem>

                            {/* <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/shipping/test')
                        }
                        className={
                          path === 'exports/shipping/test'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Test
                      </MenuItem> */}
                          </SubMenu> : null}

                        {/* <SubMenu
                      title='ExpAttachment'
                      className={
                        window.location.pathname.includes(
                          '/admin/dashboard/exports/expAttachment'
                        )
                          ? classes.subMenuActive
                          : classes.subMenu
                      }
                    >
                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/expAttachment/upload_View')
                        }
                        className={
                          path === 'exports/expAttachment/upload_View'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Upload & View
                      </MenuItem>
                    </SubMenu> */}


                        <SubMenu
                          title='Invoice'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/exports/invoice'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Creation'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/proformaInvoice')
                                }
                                className={
                                  path === 'exports/Invoice/proformaInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ProfomaCNOPS')
                                }
                                className={
                                  path === 'exports/Invoice/ProfomaCNOPS'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approval'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ProfomaApproval')
                                }
                                className={
                                  path === 'exports/Invoice/ProfomaApproval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprovalCN')
                                }
                                className={
                                  path === 'exports/Invoice/ApprovalCN'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>
                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approved'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprvdInvoice')
                                }
                                className={
                                  path === 'exports/Invoice/ApprvdInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprvdCnOps')
                                }
                                className={
                                  path === 'exports/Invoice/ApprvdCnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                          {/* <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/Invoice/ProfomaApprovedList')
                        }
                        className={
                          path === 'exports/Invoice/ProfomaApprovedList'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Approved List
                      </MenuItem> */}

                        </SubMenu>

                        <SubMenu
                          title='Accounts'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/exports/Accounts'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >
                          {loginRole != 'D' ?

                            <SubMenu
                              title='Voucher'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/Accounts/Voucher'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <SubMenu
                                title='Creation'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/exports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/ProfomaCreditNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/ProfomaCreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/ProfomaDebitNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/ProfomaDebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>
                              {loginRole == 'C' || loginRole == 'A' || loginRole == 'A&E' ?
                                <SubMenu
                                  title='Approval'
                                  className={
                                    window.location.pathname.includes(
                                      '/admin/dashboard/exports/Accounts/Voucher'
                                    )
                                      ? classes.subMenuActive
                                      : classes.subMenu
                                  }
                                >
                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/exports/Accounts/ProCreditApproval')
                                    }
                                    className={
                                      path === 'exports/Accounts/ProCreditApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    CreditNote
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/exports/Accounts/ProDebitApproval')
                                    }
                                    className={
                                      path === 'exports/Accounts/ProDebitApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    DebitNote
                                  </MenuItem>
                                </SubMenu> : null}

                              <SubMenu
                                title='Approved'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/exports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/CreditNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/CreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/DebitNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/DebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>

                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Receipts'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/Accounts/Receipts'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/Invoice')
                                }
                                className={
                                  path === 'exports/Accounts/Invoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/CnOps')
                                }
                                className={
                                  path === 'exports/Accounts/CnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/ReceiptProfoCreditNt')
                                }
                                className={
                                  path === 'exports/Accounts/ReceiptProfoCreditNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Credit Note
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/ReceiptProfoDebitNt')
                                }
                                className={
                                  path === 'exports/Accounts/ReceiptProfoDebitNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Debit Note
                              </MenuItem>
                            </SubMenu> : null}

                        </SubMenu>

                        {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/exports/Jobclose')
                            }
                            className={
                              path === 'exports/Jobclose'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Close
                          </MenuItem> : null}

                        {loginRole == 'A' || loginRole == 'A&E' ?
                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/exports/JobEnquiry')
                            }
                            className={
                              path === 'exports/JobEnquiry'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Enquiry
                          </MenuItem>
                          : null}
                      </SubMenu>

                      <SubMenu
                        title='Imports'
                        className={classes.subMenu}
                      >
                        {loginRole != 'H' ? <SubMenu
                          title='Sales'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/exports/sales'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/sales/buyer')
                            }
                            className={
                              path === 'Imports/sales/buyer'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Buying
                          </MenuItem>

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/sales/quotation')
                            }
                            className={
                              path === 'Imports/sales/quotation'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Selling (Quotation)
                          </MenuItem>

                          {loginRole == 'E' || loginRole == 'G' || loginRole == 'A&E' ?

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/sales/approval')
                              }
                              className={
                                path === 'Imports/sales/approval'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Approval
                            </MenuItem> : null}

                          {loginRole == 'E' || loginRole == 'G' || loginRole == 'A&E' ?

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/sales/approved')
                              }
                              className={
                                path === 'Imports/sales/approved'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Approved
                            </MenuItem> : null}

                          {loginRole == 'E' || loginRole == 'F' || loginRole == 'A&E' ? <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/sales/booking')
                            }
                            className={
                              path === 'Imports/sales/booking'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Booking
                          </MenuItem> : null}

                        </SubMenu> : null}

                        {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'A&E' ?

                          <SubMenu
                            title='Shipping Instruction'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/Imports/shipping'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/shipping/creation')
                              }
                              className={
                                path === 'Imports/shipping/creation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              S/I Creation
                            </MenuItem>

                          </SubMenu> : null}

                        <SubMenu
                          title='Invoice'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/Imports/invoice'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Creation'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/proformaInvoice')
                                }
                                className={
                                  path === 'Imports/Invoice/proformaInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ProfomaCNOPS')
                                }
                                className={
                                  path === 'Imports/Invoice/ProfomaCNOPS'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CNOPS
                              </MenuItem>
                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approval'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ProfomaApproval')
                                }
                                className={
                                  path === 'Imports/Invoice/ProfomaApproval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprovalCN')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprovalCN'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>
                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?
                            <SubMenu
                              title='Approved'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprvdInv')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprvdInv'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprvdCno')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprvdCno'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                        </SubMenu>

                        <SubMenu
                          title='Accounts'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/Imports/Accounts'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole != 'H' ?

                            <SubMenu
                              title='Voucher'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/Accounts/Voucher'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <SubMenu
                                title='Creation'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/Imports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/ProfomaCreditNt')
                                  }
                                  className={
                                    path === 'Imports/Accounts/ProfomaCreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/ProfomaDebitNt')
                                  }
                                  className={
                                    path === 'Imports/Accounts/ProfomaDebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>

                              {loginRole == 'E' || loginRole == 'G' || loginRole == 'A&E' ?

                                <SubMenu
                                  title='Approval'
                                  className={
                                    window.location.pathname.includes(
                                      '/admin/dashboard/Imports/Accounts/Voucher'
                                    )
                                      ? classes.subMenuActive
                                      : classes.subMenu
                                  }
                                >

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/Imports/Accounts/ProCreditApproval')
                                    }
                                    className={
                                      path === 'Imports/Accounts/ProCreditApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    CreditNote
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/Imports/Accounts/ProDebitApproval')
                                    }
                                    className={
                                      path === 'Imports/Accounts/ProDebitApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    DebitNote
                                  </MenuItem>

                                </SubMenu> : null}

                              <SubMenu
                                title='Approved'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/Imports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >
                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/CreditNote')
                                  }
                                  className={
                                    path === 'Imports/Accounts/CreditNote'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/DebitNote')
                                  }
                                  className={
                                    path === 'Imports/Accounts/DebitNote'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>

                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Receipts'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/Accounts/Receipts'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/Invoice')
                                }
                                className={
                                  path === 'Imports/Accounts/Invoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/CnOps')
                                }
                                className={
                                  path === 'Imports/Accounts/CnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/ReceiptProfoCreditNt')
                                }
                                className={
                                  path === 'Imports/Accounts/ReceiptProfoCreditNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Credit Note
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/ProDebitNTReceipt')
                                }
                                className={
                                  path === 'Imports/Accounts/ProDebitNTReceipt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Debit Note
                              </MenuItem>

                            </SubMenu> : null}

                        </SubMenu>

                        {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/Jobclose')
                            }
                            className={
                              path === 'Imports/Jobclose'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Close
                          </MenuItem> : null}

                        {loginRole == 'E' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/JobEnquiry')
                            }
                            className={
                              path === 'Imports/JobEnquiry'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Enquiry
                          </MenuItem> : null}

                      </SubMenu>
                    </>)
                    :
                    (loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' ? (

                      <SubMenu
                        title='Exports'
                        className={
                          window.location.pathname.includes(
                            '/admin/dashboard/exports'
                          )
                            ? classes.subMenuActive
                            : classes.subMenu
                        }
                      >

                        {loginRole != 'D' ?

                          <SubMenu
                            title='Sales'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/exports/sales'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/sales/buyer')
                              }
                              className={
                                path === 'exports/sales/buyer'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Buying
                            </MenuItem>

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/sales/quotation')
                              }
                              className={
                                path === 'exports/sales/quotation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Selling (Quotation)
                            </MenuItem>

                            {loginRole == 'C' || loginRole == 'A' || loginRole == 'A&E' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/sales/approval')
                                }
                                className={
                                  path === 'exports/sales/approval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Approval
                              </MenuItem> : null}

                            {loginRole == 'B' || loginRole == 'A' || loginRole == 'A&E' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/sales/booking')
                                }
                                className={
                                  path === 'exports/sales/booking'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Booking
                              </MenuItem> : null}

                          </SubMenu> : null}

                        {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'A&E' ?

                          <SubMenu
                            title='Shipping Instruction'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/exports/shipping'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/exports/shipping/creation')
                              }
                              className={
                                path === 'exports/shipping/creation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              S/I Creation

                            </MenuItem>
                            {/* <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/shipping/test')
                        }
                        className={
                          path === 'exports/shipping/test'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Test
                      </MenuItem> */}
                          </SubMenu> : null}

                        {/* <SubMenu
                      title='ExpAttachment'
                      className={
                        window.location.pathname.includes(
                          '/admin/dashboard/exports/expAttachment'
                        )
                          ? classes.subMenuActive
                          : classes.subMenu
                      }
                    >
                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/expAttachment/upload_View')
                        }
                        className={
                          path === 'exports/expAttachment/upload_View'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Upload & View
                      </MenuItem>
                    </SubMenu> */}

                        <SubMenu
                          title='Invoice'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/exports/invoice'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Creation'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/proformaInvoice')
                                }
                                className={
                                  path === 'exports/Invoice/proformaInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ProfomaCNOPS')
                                }
                                className={
                                  path === 'exports/Invoice/ProfomaCNOPS'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approval'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ProfomaApproval')
                                }
                                className={
                                  path === 'exports/Invoice/ProfomaApproval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprovalCN')
                                }
                                className={
                                  path === 'exports/Invoice/ApprovalCN'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approved'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >


                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprvdInvoice')
                                }
                                className={
                                  path === 'exports/Invoice/ApprvdInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Invoice/ApprvdCnOps')
                                }
                                className={
                                  path === 'exports/Invoice/ApprvdCnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}
                          {/* <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/exports/Invoice/ProfomaApprovedList')
                        }
                        className={
                          path === 'exports/Invoice/ProfomaApprovedList'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Approved List
                      </MenuItem> */}

                        </SubMenu>

                        <SubMenu
                          title='Accounts'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/exports/Accounts'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole != 'D' ?

                            <SubMenu
                              title='Voucher'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/Accounts/Voucher'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >


                              <SubMenu
                                title='Creation'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/exports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/ProfomaCreditNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/ProfomaCreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/ProfomaDebitNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/ProfomaDebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>

                              {loginRole == 'C' || loginRole == 'A' || loginRole == 'A&E' ?

                                <SubMenu
                                  title='Approval'
                                  className={
                                    window.location.pathname.includes(
                                      '/admin/dashboard/exports/Accounts/Voucher'
                                    )
                                      ? classes.subMenuActive
                                      : classes.subMenu
                                  }
                                >

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/exports/Accounts/ProCreditApproval')
                                    }
                                    className={
                                      path === 'exports/Accounts/ProCreditApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    CreditNote
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/exports/Accounts/ProDebitApproval')
                                    }
                                    className={
                                      path === 'exports/Accounts/ProDebitApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    DebitNote
                                  </MenuItem>

                                </SubMenu> : null}


                              <SubMenu
                                title='Approved'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/exports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/CreditNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/CreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/exports/Accounts/DebitNt')
                                  }
                                  className={
                                    path === 'exports/Accounts/DebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>
                              </SubMenu>
                            </SubMenu> : null}

                          {loginRole == 'A' || loginRole == 'D' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Receipts'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/exports/Accounts/Receipts'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/Invoice')
                                }
                                className={
                                  path === 'exports/Accounts/Invoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/CnOps')
                                }
                                className={
                                  path === 'exports/Accounts/CnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/ReceiptProfoCreditNt')
                                }
                                className={
                                  path === 'exports/Accounts/ReceiptProfoCreditNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Credit Note
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/exports/Accounts/ReceiptProfoDebitNt')
                                }
                                className={
                                  path === 'exports/Accounts/ReceiptProfoDebitNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Debit Note
                              </MenuItem>
                            </SubMenu> : null}

                        </SubMenu>

                        {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/exports/Jobclose')
                            }
                            className={
                              path === 'exports/Jobclose'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Close
                          </MenuItem> : null}

                        {loginRole == 'A' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/exports/JobEnquiry')
                            }
                            className={
                              path === 'exports/JobEnquiry'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Enquiry
                          </MenuItem> : null}
                      </SubMenu>)
                      :
                      (<SubMenu
                        title='Imports'
                        className={classes.subMenu}
                      >
                        {loginRole != 'H' ?
                          <SubMenu
                            title='Sales'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/exports/sales'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/sales/buyer')
                              }
                              className={
                                path === 'Imports/sales/buyer'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Buying
                            </MenuItem>

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/sales/quotation')
                              }
                              className={
                                path === 'Imports/sales/quotation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              Selling (Quotation)
                            </MenuItem>

                            {loginRole == 'E' || loginRole == 'G' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/sales/approval')
                                }
                                className={
                                  path === 'Imports/sales/approval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Approval
                              </MenuItem> : null}

                            {loginRole == 'E' || loginRole == 'F' ?

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/sales/booking')
                                }
                                className={
                                  path === 'Imports/sales/booking'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Booking
                              </MenuItem> : null}
                          </SubMenu> : null}

                        {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'A&E' ?

                          <SubMenu
                            title='Shipping Instruction'
                            className={
                              window.location.pathname.includes(
                                '/admin/dashboard/Imports/shipping'
                              )
                                ? classes.subMenuActive
                                : classes.subMenu
                            }
                          >

                            <MenuItem
                              onClick={() =>
                                navigate('/admin/dashboard/Imports/shipping/creation')
                              }
                              className={
                                path === 'Imports/shipping/creation'
                                  ? classes.menuItemActive
                                  : ''
                              }
                            >
                              S/I Creation
                            </MenuItem>

                          </SubMenu> : null}

                        <SubMenu
                          title='Invoice'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/Imports/invoice'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Creation'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/proformaInvoice')
                                }
                                className={
                                  path === 'Imports/Invoice/proformaInvoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ProfomaCNOPS')
                                }
                                className={
                                  path === 'Imports/Invoice/ProfomaCNOPS'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CNOPS
                              </MenuItem>

                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Approval'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ProfomaApproval')
                                }
                                className={
                                  path === 'Imports/Invoice/ProfomaApproval'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprovalCN')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprovalCN'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?
                            <SubMenu
                              title='Approved'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/invoice'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprvdInv')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprvdInv'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Invoice/ApprvdCno')
                                }
                                className={
                                  path === 'Imports/Invoice/ApprvdCno'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                            </SubMenu> : null}
                        </SubMenu>

                        <SubMenu
                          title='Accounts'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/Imports/Accounts'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >
                          {loginRole != 'H' ?

                            <SubMenu
                              title='Voucher'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/Accounts/Voucher'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >

                              <SubMenu
                                title='Creation'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/Imports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >
                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/ProfomaCreditNt')
                                  }
                                  className={
                                    path === 'Imports/Accounts/ProfomaCreditNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/ProfomaDebitNt')
                                  }
                                  className={
                                    path === 'Imports/Accounts/ProfomaDebitNt'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>

                              {loginRole == 'E' || loginRole == 'G' || loginRole == 'A&E' ?
                                <SubMenu
                                  title='Approval'
                                  className={
                                    window.location.pathname.includes(
                                      '/admin/dashboard/Imports/Accounts/Voucher'
                                    )
                                      ? classes.subMenuActive
                                      : classes.subMenu
                                  }
                                >
                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/Imports/Accounts/ProCreditApproval')
                                    }
                                    className={
                                      path === 'Imports/Accounts/ProCreditApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    CreditNote
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() =>
                                      navigate('/admin/dashboard/Imports/Accounts/ProDebitApproval')
                                    }
                                    className={
                                      path === 'Imports/Accounts/ProDebitApproval'
                                        ? classes.menuItemActive
                                        : ''
                                    }
                                  >
                                    DebitNote
                                  </MenuItem>

                                </SubMenu> : null}

                              <SubMenu
                                title='Approved'
                                className={
                                  window.location.pathname.includes(
                                    '/admin/dashboard/Imports/Accounts/Voucher'
                                  )
                                    ? classes.subMenuActive
                                    : classes.subMenu
                                }
                              >
                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/CreditNote')
                                  }
                                  className={
                                    path === 'Imports/Accounts/CreditNote'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  CreditNote
                                </MenuItem>

                                <MenuItem
                                  onClick={() =>
                                    navigate('/admin/dashboard/Imports/Accounts/DebitNote')
                                  }
                                  className={
                                    path === 'Imports/Accounts/DebitNote'
                                      ? classes.menuItemActive
                                      : ''
                                  }
                                >
                                  DebitNote
                                </MenuItem>

                              </SubMenu>
                            </SubMenu> : null}

                          {loginRole == 'E' || loginRole == 'H' || loginRole == 'A&E' ?

                            <SubMenu
                              title='Receipts'
                              className={
                                window.location.pathname.includes(
                                  '/admin/dashboard/Imports/Accounts/Receipts'
                                )
                                  ? classes.subMenuActive
                                  : classes.subMenu
                              }
                            >
                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/Invoice')
                                }
                                className={
                                  path === 'Imports/Accounts/Invoice'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Invoice
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/CnOps')
                                }
                                className={
                                  path === 'Imports/Accounts/CnOps'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                CN-OPS
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/ReceiptProfoCreditNt')
                                }
                                className={
                                  path === 'Imports/Accounts/ReceiptProfoCreditNt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Credit Note
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate('/admin/dashboard/Imports/Accounts/ProDebitNTReceipt')
                                }
                                className={
                                  path === 'Imports/Accounts/ProDebitNTReceipt'
                                    ? classes.menuItemActive
                                    : ''
                                }
                              >
                                Debit Note
                              </MenuItem>

                            </SubMenu> : null}
                        </SubMenu>

                        {loginRole == 'E' || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/Jobclose')
                            }
                            className={
                              path === 'Imports/Jobclose'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Close
                          </MenuItem> : null}

                        {loginRole == 'E' || loginRole == 'A&E' ?

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/Imports/JobEnquiry')
                            }
                            className={
                              path === 'Imports/JobEnquiry'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Enquiry
                          </MenuItem> : null}
                      </SubMenu>))}

                  {loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' || loginRole == 'E'
                    || loginRole == 'F' || loginRole == 'G' || loginRole == 'H' || loginRole == 'A&E' ?

                    <SubMenu
                      title='Doc Attachment'
                      className={classes.subMenu}
                    >
                      {/* <SubMenu
                      title='Sales'
                      className={
                        window.location.pathname.includes(
                          '/admin/dashboard/exports/sales'
                        )
                          ? classes.subMenuActive
                          : classes.subMenu
                      }
                    > */}
                      {loginRole == 'A&E' ? (
                        <>

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/DocAttachment/ExpUpload')
                            }
                            className={
                              path === 'DocAttachment/ExpUpload'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Exp Upload
                          </MenuItem>

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/DocAttachment/ImpUpload')
                            }
                            className={
                              path === 'DocAttachment/ImpUpload'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Imp Upload
                          </MenuItem>
                        </>
                      ) :

                        (loginRole == 'A' || loginRole == 'B' || loginRole == 'C' || loginRole == 'D' ?
                          (<MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/DocAttachment/ExpUpload')
                            }
                            className={
                              path === 'DocAttachment/ExpUpload'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Exp Upload
                          </MenuItem>)
                          :
                          (<MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/DocAttachment/ImpUpload')
                            }
                            className={
                              path === 'DocAttachment/ImpUpload'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Imp Upload
                          </MenuItem>)
                        )}

                      {/* </SubMenu> */}


                    </SubMenu> : null}

                  {loginRole == 'A' || loginRole == 'E' || loginRole == 'A&E' ?

                    <SubMenu
                      title='Accounts'
                      className={classes.subMenu}
                    ></SubMenu>
                    : null}

                  {loginRole == 'A&E' ?

                    <SubMenu
                      title='Reports'
                      className={
                        window.location.pathname.includes(
                          '/admin/dashboard/reports'
                        )
                          ? classes.subMenuActive
                          : classes.subMenu
                      }
                    >
                      <SubMenu
                        title='Exports'
                        className={
                          window.location.pathname.includes(
                            '/admin/dashboard/reports/exports'
                          )
                            ? classes.subMenuActive
                            : classes.subMenu
                        }
                      >

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/buyingreport')
                          }
                          className={
                            path === 'reports/exports/buyingreport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Buying Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/Sellingreport')
                          }
                          className={
                            path === 'reports/exports/Sellingreport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Selling Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/BookingReport')
                          }
                          className={
                            path === 'reports/exports/BookingReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Booking Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/InvoiceReport')
                          }
                          className={
                            path === 'reports/exports/InvoiceReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Invoice Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/CnopsReport')
                          }
                          className={
                            path === 'reports/exports/CnopsReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Cnops Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/CreditReport')
                          }
                          className={
                            path === 'reports/exports/CreditReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Credit Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/exports/DebitReport')
                          }
                          className={
                            path === 'reports/exports/DebitReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Debit Report
                        </MenuItem>

                      </SubMenu>

                      <SubMenu
                        title='Imports'
                        className={
                          window.location.pathname.includes(
                            '/admin/dashboard/reports/Imports'
                          )
                            ? classes.subMenuActive
                            : classes.subMenu
                        }
                      >

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/buyerreport')
                          }
                          className={
                            path === 'reports/Imports/buyerreport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Buying Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/Sellingreport')
                          }
                          className={
                            path === 'reports/Imports/Sellingreport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Selling Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/BookingReport')
                          }
                          className={
                            path === 'reports/Imports/BookingReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Booking Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/InvoiceReport')
                          }
                          className={
                            path === 'reports/Imports/InvoiceReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Invoice Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/CnopsReport')
                          }
                          className={
                            path === 'reports/Imports/CnopsReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Cnops Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/CreditReport')
                          }
                          className={
                            path === 'reports/Imports/CreditReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Credit Report
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            navigate('/admin/dashboard/reports/Imports/DebitReport')
                          }
                          className={
                            path === 'reports/Imports/DebitReport'
                              ? classes.menuItemActive
                              : ''
                          }
                        >
                          Debit Report
                        </MenuItem>

                      </SubMenu>

                      <SubMenu
                        title='PL'
                        className={
                          window.location.pathname.includes(
                            '/admin/dashboard/reports/P&L'
                          )
                            ? classes.subMenuActive
                            : classes.subMenu
                        }
                      >
                        <SubMenu
                          title='Exports'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/reports/P&L/Exports'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/reports/P&L/Exports/JobcloseReport')
                            }
                            className={
                              path === 'reports/P&L/Exports/JobcloseReport'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Jobclose Report
                          </MenuItem>

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/reports/P&L/Exports/JobUncloseReport')
                            }
                            className={
                              path === 'reports/P&L/Exports/JobUncloseReport'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Unclose Report
                          </MenuItem>

                        </SubMenu>

                        <SubMenu
                          title='Imports'
                          className={
                            window.location.pathname.includes(
                              '/admin/dashboard/reports/P&L/Imports'
                            )
                              ? classes.subMenuActive
                              : classes.subMenu
                          }
                        >

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/reports/P&L/Imports/JobcloseReport')
                            }
                            className={
                              path === 'reports/P&L/Imports/JobcloseReport'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Jobclose Report
                          </MenuItem>

                          <MenuItem
                            onClick={() =>
                              navigate('/admin/dashboard/reports/P&L/Imports/JobUncloseReport')
                            }
                            className={
                              path === 'reports/P&L/Imports/JobUncloseReport'
                                ? classes.menuItemActive
                                : ''
                            }
                          >
                            Job Unclose Report
                          </MenuItem>

                        </SubMenu>

                      </SubMenu>
                    </SubMenu> : null}

                  {loginRole == 'A' || loginRole == 'E' || loginRole == 'A&E' ?
                    <SubMenu
                      title='Maintenance'
                      className={classes.subMenu}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate('/admin/dashboard/Maintenance/Addusers')
                        }
                        className={
                          path === 'Maintenance/Addusers'
                            ? classes.menuItemActive
                            : ''
                        }
                      >
                        Add Users
                      </MenuItem>
                    </SubMenu> : null}

                </Menu>
              </SidebarContent>

            </ProSidebar>
            {/* </ul> */}
          </Col>
          <Col
            xl={10}
            lg={10}
            sm={12}
            md={10}
            className={classes.dashboardContent}
          >
            <div className={classes.mainContent}>
              <Outlet />
            </div>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AdminDashboard;
import React from 'react';
import classes from './Footer.module.css';

function Footer() {
  return (
    <>
      <footer className={classes.footer}>
        <p>Copyright c 2022 REUDAN. All rights reserved.</p>
        <div className={classes.footerContent}>
          <p>About</p>
          <p>Support</p>
          <p>Contact Us</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;

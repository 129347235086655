import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './profoapproval.module.css';
import './invoice.module.css';
import CustomTable from '../../customTable';

export default function ProfomaApproval() {

  const initProdetails = {
    booking_number: '',
    carrier_booking_no: '',
    bill_type: '',
    to_customer_name: '',
    current_date: '',
    Address: '',
    currency: '',
    shipper: '',
    agent: '',
    mlo: '',
    notify_party: '',
    cnf: '',
    remarks: '',
    invoice_charges: '',
  }

  const [tableLists, setTableLists] = useState([]);
  const [showList, setShowList] = useState(true);
  const [tableListNo, setTableListNo] = useState('');
  const [profoDetails, setProfoDetails] = useState(initProdetails);
  const [profoList, setProfoList] = useState('');
  const [isApproved, setIsApproved] = useState(0);
  const [isApprovedId, setIsApprovedId] = useState('');
  const [constno, setconstno] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [autogenInvNo, setAutogenInvNo] = useState("");
  const [refnumber, setRefnumber] = useState([]);
  const [searchList, setSearchList] = useState('');
  const [selectedVal, setSelectedVal] = useState('');

  



  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };



  const editprofolist = (index, refId) => {
    setShowList(false);
    setTableListNo(refId);

    customAxios.post('/profomainvoice/getById', { refId: refId }).then((result) => {
      if (!result.data.error && result.data.data) {

        let {
          _id,
          booking_number,
          carrier_booking_no,
          bill_type,
          to_customer_name,
          current_date,
          Address,
          currency,
          shipper,
          agent,
          mlo,
          notify_party,
          cnf,
          remarks,
          invoice_charges,
          destination,
          consignee,
          ref_id,
          currency_value,
          container_no,
          container_count,
          totalamt,
        } = result.data.data

        console.log(result.data.data)



        setProfoDetails({
          _id: _id,
          booking_number: booking_number,
          carrier_booking_no: carrier_booking_no,
          bill_type: bill_type,
          to_customer_name: to_customer_name,
          current_date: current_date,
          Address: Address,
          currency: currency,
          shipper: shipper,
          agent: agent,
          mlo: mlo,
          notify_party: notify_party,
          cnf: cnf,
          remarks: remarks,
          invoice_charges: invoice_charges,
          isApproved: 0,
          destination: destination,
          consignee: consignee,
          ref_id: ref_id,
          currency_value: currency_value,
          container_no: container_no,
          container_count: container_count,
          totalamt: totalamt,
        })


        setProfoList(invoice_charges);
        setIsApproved(result.data.isApproved ? result.data.isApproved : 0);
        setIsApprovedId(result.data.data._id);

      }
      else {
        window.alert('No data found');
      }
    })
  }

  const deleteCharge = (index, _id) => {
    setDeleteId(_id);
    if (window.confirm('Are you sure to delete?')) {
      customAxios.post('/invoice/bkginv/delete', { _id: _id }).then((result) => {
        if (result.data.data) {

          let temp = [...tableLists];
          temp.splice(index, 1);
          setTableLists([...temp]);


        }
        let url = '/profomainvoice';
        customAxios.get(url).then((tableLists) => {

          setTableLists(tableLists.data.data);



        });

        let refNumUrl = '/profomainvoice';
        customAxios.get(refNumUrl).then((refNos) => {
          let temp = [...refNos.data.data];
          let refno = temp.map((data) => {
            return { label: data.ref_id, value: data.ref_id };
          })
          setRefnumber([...refno]);

        });
        window.location.reload();
      })
    }
    else {
      let url = '/profomainvoice';
      customAxios.get(url).then((tableLists) => {

        setTableLists(tableLists.data.data);


      });
    }
  };

  const ApprovedId = () => {


    /* customAxios
            .post('/profomainvoice/updateapproved', {
              "temp": {
               "_id": isApprovedId,
              "is_approved": 1
              }
          }) */
    customAxios
      .post('/invoice/approved/autogen', {
        "temp": {
          "_id": isApprovedId,
          "is_approved": 1
        }
      })
      .then((data) => {
        if (data.data.error) window.alert('Unable to update the details!!');
        else {
          window.alert('Approved successfully');
          setIsApprovedId(1)

        }
      });

  }

  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
    setconstno(profomainvoiceAutogen)


    let url = '/profomainvoice';
    customAxios.get(url).then((tableLists) => {

      setTableLists(tableLists.data.data);
      setSearchList(tableLists.data.data);
      console.log(tableLists.data.data);

    });

    let refNumUrl = '/profomainvoice';
    customAxios.get(refNumUrl).then((refNos) => {
      let temp = [...refNos.data.data];
      let refno = temp.map((data) => {
        return { label: data.ref_id, value: data.ref_id };
      })
      setRefnumber([...refno]);

    })
  }, [showList]);

  const handleSearch = (option) => {
    if (option == null) {
      let url = '/profomainvoice';
      customAxios.get(url).then((tableLists) => {

        setTableLists(tableLists.data.data);
        setSearchList(tableLists.data.data);
        setSelectedVal(null);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.ref_id === (option ? option.value : ' '));
      setTableLists(filterdata);
    }
  }

  const columns = [

    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "BKG.NO",
      accessor: "booking_number",

    },
    {
      Header: "REF#",
      accessor: "ref_id",
    },
    {
      Header: "To",
      accessor: "to_customer_name",
    },
    {
      Header: "Curr",
      accessor: "currency",
    },
    {
      Header: "Total Amount",
      accessor: "totalamt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const DATA = tableLists.filter(item => item.is_approved === 0).map((item, index) => {
    const refNo = item.ref_no;
    // { console.log(DATA, refNo); }

    return {
      id: index + 1,
      booking_number: 'RUKEX' + item.booking_number,
      ref_id: 'RILWEM/FE/' + item.ref_id,
      to_customer_name: item.to_customer_name,
      currency: item.currency,
      totalamt: item.totalamt.toFixed(2),
      action: (
        <div style={{ textAlign: 'center' }}>
          {console.log(refNo)}
          <div style={{ textAlign: 'center' }}>

            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, item.ref_id)} />
            &nbsp;&nbsp;
            <span><img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, item._id)} /></span>
          </div>
        </div>
      )
    };

  });

  if(DATA.length === 0 || null){
    DATA.push({
      id: '',
      booking_number: '',
      ref_id: '',
      to_customer_name: 'No Data Available',
      currency: '',
      totalamt: '',
      action: ''
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Invoice <span className={classes.title}>Export / Approval</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <table>
              <tr>
                <h6>Proforma Invoice Listing</h6>
                <td colSpan={1} style={{ width: '10px', paddingLeft: '642px' }}>Search</td>
                <td colSpan={4} style={{ width: '175px' }}>
                  {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={refnumber}
                    // value={cnNo.value}
                    /* value={invnumber.filter(
                        (option) =>{ 
                            return option.value === tableListNo
                        }
                    )} */
                    value={selectedVal}
                    onChange={handleSearch}
                  // selectedValue={selectedValue}
                  /* onChange={(selected) => {
                      editprofolist(selected ? selected.value:'');
                    }}  */

                  />

                </td>
              </tr>
            </table>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>

              <CustomTable columns={columns} data={DATA} />


              {/* <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col' style={{ 'text-align': 'center ' }}>#</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>BKG.NO</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>REF#</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>To</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Curr</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Total Amount</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {tableLists.length
                    ? tableLists.map((tableList, index) => {
                     
                      return (
                        <>
                        {tableList.is_approved == 0 ? 
                        <tr key={index}>
                          <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                          <td style={{ 'text-align': 'center ' }}>RUKEX{tableList.booking_number}</td>
                          <td style={{ 'text-align': 'center ' }}>RILWEM/ FE/{tableList.ref_id}</td>
                          <td style={{ 'text-align': 'center ' }}>{tableList.to_customer_name}</td>
                          <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                          <td style={{ 'text-align': 'center ' }}>{(tableList.totalamt).toFixed(2)}</td>
                          <td style={{ 'text-align': 'center ' }}>
                            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, tableList.ref_id)} />
                            &nbsp;&nbsp;<span><img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, tableList._id)} /></span>
                          </td>
                        </tr>
                        : null}
                        </>
                      );
                    
                    })
                    : ''}
                </tbody>
              </table> */}
            </div>
          </>
        ) : (
          <>
            <table style={{ width: '100%' }}>
              <tbody><tr>
                <td colSpan={1}><span>BKG.No#*</span></td>
                <td colSpan={2} style={{ width: '100px' }}>
                  <section>
                    <div style={{ position: 'relative' }}>
                      <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 5px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                      <input
                        type='text'
                        className={classes.inputText}
                        style={{ paddingLeft: '58px' }}
                        value={profoDetails ? profoDetails.booking_number : ''}
                        // onChange={(e) => setBookingNo(e.target.value)}
                        // onKeyUp={(e) => { handleShippingNo(e ? e : 'edfc') }}
                        disabled={true} />
                    </div>
                  </section>
                </td>

                <td colSpan={1} style={{ width: '10px' }}><span>BL#*</span></td>
                <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.carrier_booking_no : ''} disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'carrier_no', value: e.target.checked }); }} */ type="text" /></td>
                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                <td colSpan={1}><input className={classes.inputText} type="text"
                  value={profoDetails ? profoDetails.ref_id : ''}
                  // value={refNo}
                  // onChange={(evt) => handlerefNo(evt)}
                  // onChange={(e) => { handleshippingDetails({ key: 'refNo', value: e.target.checked }); 
                  // onKeyUp={(evt) => getinvoiceDetail(evt)} 
                  disabled={true} />
                </td>

              </tr>
                <tr>
                  <td>To<span>*</span></td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.to_customer_name : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_name',
                                    value: evt.target.value,
                                })} */
                    type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '35px' }}>Date</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.current_date : ''} type="text" disabled={true} /></td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.Address : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>
                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                  <td colSpan={1} ><input className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    // options={currencies}
                    type="text"
                    value={profoDetails ? profoDetails.currency : ''}
                    // value={currencies.filter(
                    //     (option) => option.value === selectedValue
                    // )}
                    // selectedValue={selectedValue}
                    // onChange={(e) => {
                    //     if (e) handleSelectChange(e);
                    // }}
                    disabled={true} >
                  </input>
                  </td>
                  <td colSpan={1}><input className={classes.inputText} value={profoDetails ? profoDetails.currency_value : ''} /* onChange={(e) => { handleProfoInvInp(e) }} */ type="text" disabled={true} /></td>
                </tr>
                <tr>

                  <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.destination : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>

                </tr>
                <tr>
                  <td colSpan={1}>Shipper</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.shipper : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'shipper_name',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.agent : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container</td>
                  <td colSpan={2} rowSpan={2}><textarea style={{ height: '100px', marginTop: '-18px' }} value={profoDetails ? profoDetails.container_count : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} */ disabled={true} /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Cosingnee</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.consignee : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'consignee_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.mlo : ''} /* onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} */ type="text" disabled={true} /></td>

                </tr>
                <tr>
                  <td colSpan={1}>Notify Party</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.notify_party : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.cnf : ''} type="text" disabled={true} /* onChange={(e) => { handleBookingDetails({ key: 'CNF', value: e.target.checked });  */ /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Remarks</td>
                  <td colSpan={5}><textarea style={{ height: '100px' }} value={profoDetails ? profoDetails.remarks : ''} disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'remarks', value: e.target.checked });  }} */ /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container No</td>
                  <td colSpan={2}><textarea style={{ height: '118px', marginTop: '-20px' }} value={profoDetails ? profoDetails.container_no : ''} disabled={true} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} */  /></td>
                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tr>
              </tbody>
            </table>
            <div className={classes.footer}>
              {isApprovedId == 1 ? <button className={classes.footerButton} onClick={ApprovedId} disabled={true}>
                Approved
              </button> : <button className={classes.footerButton} onClick={ApprovedId}>
                Approve
              </button>}
              <button
                className={classes.footerButton}
                onClick={() => { setShowList(true) }}
              >
                View List
              </button>
            </div>
            <Row className={classes.row}>
              <Col className={classes.input} md='5'>
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    padding: '10px 0px',
                  }}
                >
                  Invoices
                </p>
              </Col>
            </Row>

            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className={classes.theadDark}>
                  <tr>
                    <th>charges</th>
                    <th>Curr</th>
                    <th>Rate</th>
                    <th>Base</th>
                    <th>Exchange Rate</th>
                    <th>No of Containers</th>
                    <th>Amount</th>
                    {/* <th>Action</th> */}


                  </tr>
                </thead>

                <tbody>
                  {profoList ? <>
                    {profoList.map((data, index) => (
                      <tr>
                        <td>{data.charge}</td>
                        <td>{data.currency}</td>
                        <td>{parseFloat(data.rate).toFixed(2)}</td>
                        <td>{data.base}</td>
                        <td>{data.exchangeRate}</td>
                        <td>{data.quantity}</td>
                        <td>{data.total == null || data.total == 0 ? '0' : parseFloat(data.total).toFixed(2)}</td>
                        {/* {data.total > 0 ? <td>{data.total}</td> : '0'}  */}

                        {/*                                         {
                                            data.currency == 'USD' ? (
                                            <td>{data.rate * profoInvInp}</td>
                                            ): <td>{data.rate * details.containerlength }</td>
                                            } */}
                      </tr>
                    )
                    )} </>
                    : null}
                </tbody>

              </table>

            </div>
            <div>
              <table>
                <tr>
                  <td colSpan={5} style={{ width: '50%' }}></td>
                  <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                  <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoDetails ? (parseFloat(profoDetails.totalamt).toFixed(2)) : ''} type="text" disabled={true} ></input></td>

                </tr>
              </table>
            </div>
          </>
        )}
      </Container>
    </>
  );

}


import React, { useEffect } from "react";
import {useState, useRef} from "react";
import classes from './docAttach.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../Axios';
import { icon_download, upload, DeleteIcon } from '../../Images';
import { Link } from "react-router-dom";
import Select from 'react-select';

export default function ImpUpload() {
const [filename, setFilename] = useState('');
const [bkngNofl, setBkngNofl] = useState('');
const [splitFlNam, setSplitFlNam] = useState([]);
const fileInputRef = useRef(null);
const [filenms, setfilenms] = useState([]);
const [selectedFile, setSelectedFile] = useState('')



const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const deleteCharge = (index, data) => {
    if (window.confirm('Are you sure to delete?')) {
      customAxios.post('/imp/deletefile', { 'Foldername': selectedFile, 'Filename': data }).then((result) => {
        if (result.data.data) {

          let temp = [...splitFlNam];
          temp.splice(index, 1);
          setSplitFlNam([...temp]);
        }
        let bkgfile_name;
        customAxios
          .post("/getimpfiles", { 'Foldername': selectedFile }).then((respon) => {
          
            bkgfile_name = respon.data.Files;
            
            setSplitFlNam(bkgfile_name);
          })
      })
    }
    else {
      let bkgfile_name;
      customAxios.post("/getimpfiles", { 'Foldername': selectedFile }).then((respon) => {
       
        bkgfile_name = respon.data.Files;
        
        setSplitFlNam(bkgfile_name);
      })
    }
  };

  const downloadFile = async (folderName, fileName) => {
    let response = await customAxios.get('/import/download/' + folderName + '/' + fileName,
      { responseType: 'blob' } // Important
    );

    let fileURL = window.URL.createObjectURL(response.data);
    let tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();

  }

useEffect(()=>{
    // customAxios.get('/getimpfiles/dropdown').then((ids) => {

    //     let temp = ([...ids.data.Files]);
    //     let details = temp.map((data) => {
    //       return { label: 'RUKIM'+data, value: data };
    //     });
    //     setfilenms([...details]);
    //     /* setfilenms(ids.data);
    //      */
    //   });

      customAxios.get('/getimpfiles/dropdown').then((ids) => {
        if (ids.data.Files && Array.isArray(ids.data.Files)) {
          let temp = [...ids.data.Files];
          let details = temp.map((data) => {
            return { label: 'RUKIM' + data, value: data };
          });
          setfilenms([...details]);
        } else {
          console.error('Invalid or non-iterable data received');
        }
      });
      
},[])

const handleClick = (filenmss) => {
    let bkgfile_name;
    

    if(selectedFile != ""){
    //getting booking number 
    customAxios
    .post("/getimpfiles",
        // 
        {
            'Foldername':  selectedFile
}) .then((respon)=>{

bkgfile_name = respon.data.Files;

setSplitFlNam(bkgfile_name);
//splitting only the file name
/* let value= bkgfile_name;
const myArr = String(value || '').split("/");
var fl = myArr[myArr.length-1];
setSplitFlNam([fl]); */
/*  */

if(bkgfile_name){
setBkngNofl(bkgfile_name);
}

/* else if (!bkgfile_name) {
            alert('File not found!!');
          } */
else if(!bkgfile_name){
    alert('Please choose a file');
}
})
}
else{
  alert('Please choose a file');
}
};



const handleChange=(e)=>{
// e.preventDefault();

setSelectedFile(e ? e.value : ' ');

// setFilename(e.target.value);    

}

/* const handlefldrnm=(e)=>{
  
  setFilename(e.target.value);
} */

function handleBtnClick() {
  if(filename){
    fileInputRef.current.click();
  }
  else{
    window.alert('Please fill out the FolderName');
  }
  }

  function handleFileUpload(event) {
    const file = event.target.files[0];

    let form = new FormData()
      
    form.append("name", filename);
    for(let file of event.target.files){
      form.append("documents", file);
    }
    customAxios.post('/imp/upload', form).then((data) => {
      
      // fileInputRef.current.value = null
        window.alert('File Uploaded  Successfully');
     
    }).catch((err)=>{
      window.alert('Something went wrong');
    })

    customAxios.get('/getimpfiles/dropdown').then((ids) => {
     
              let temp = ([...ids.data.Files]);
              let details = temp.map((data) => {
                return { label: data, value: data };
              });
              setfilenms([...details]);
              /* setfilenms(ids.data);
               */
            });
 
// window.location.reload(); 
  }

  

    return (
<>
<div className={classes.container}>
                <h5>Import Attachment <span className={classes.title}>Import \ Attachment</span></h5>
            </div>
            <Container className={classes.inputsContainer}>
            
            <table style={{ width: '100%' }}>
                    {/* <tbody> */}
                      <tr>
                    <td><span>Folder Name#</span></td>
                        <td><input className={classes.inputText} style={{width: '200px'}} value={filename}  onChange={e => setFilename(e.target.value)}   type="text" /></td>
                    
                        <td>
            <img src={upload} alt="Upload PDF" style={{ borderRadius: '50%', height: '50px', paddingRight: '650px' }} onClick={handleBtnClick} />
            <input type="file" accept="jpeg|pdf|jpg" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} multiple={true}/>
          </td>
                    
                        
                       </tr>
                       {/* </tbody> */}
                        </table>

                        <br/>

                        <table>
                       <tr>
                       <td><span>File Name#</span></td>
                       &nbsp; &nbsp; &nbsp;
                        <td style={{ width: '200px', position: 'relative', left: '6px' }}>
                     {/* <input className={classes.inputText} style={{width: '200px'}} value={filename}  onChange={(e) => {handleChange(e)}}   type="text" /> */}
                       
                     <Select
                      components={{ IndicatorSeparator, Placeholder }}
                      styles={style}
                      style={{width: '200px'}}
                      isClearable={true}
                      isSearchable={true}
                      options={filenms}
                      value={filenms.find(option => option.value === selectedFile)}
                      onChange={(e)=>{handleChange(e)}}
                    //   onChange={(selectedOption) => setOpenSelectedBkgNo(selectedOption && selectedOption.value)}
                    />
                       
                       
                        </td>
          &nbsp; &nbsp;
                       <td>
                        {/* {selectedFile != ' '  && selectedFile ? */}
                       <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                      {/* : */}
                      {/* <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick} disabled={true}>Get Files</button> */}
                      {/* } */}
                       </td>
                        </tr>   
                        </table>

                            <br/>
                        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className={classes.theadDark}>
                  <tr>
                    <th>S.NO</th>
                    <th>Booking Files</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                   { splitFlNam && splitFlNam.length  ? splitFlNam.map((data, index)=>{
                    return(
                      <>
                      <tr key = {index}>
                    <td>{index+1}</td>
                    <td>{data}</td>

                    <td>
                    <img src={icon_download} onClick={() => { downloadFile(selectedFile, data) }} />
                      &nbsp;
                        <img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, data)} />
                      </td>
                    
                    </tr>
                      </>
                    )
                   })
                  
                    : ' '}
                    

                </tbody>

              </table>

            </div>
                        

                       

                
            </Container>
</>
    );
}
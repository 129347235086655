import React, { useEffect, useState } from 'react';
import customAxios from '../../../Axios';
import { Logo } from '../../../Images';
import { Loader } from '../../../Images';
// import './PdfTemplate.css';
import Select from 'react-select';
// import classes from './Buyer.module.css';

export default function Template({
  sellingDetails,
  buyingDetails,
  bookingDetails,
  bookingNo,
  charges_inc,
  details,
  allCharges,
  customerdetails,
  remarks,
  cargo,
  shippingDetails,
  selectedValue,
  amtinwords,
  profoDetails,
  profoList,
  
  
  // formInput
}) {

  const style = {
    control: (base) => ({
      ...base,
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const username = localStorage.getItem('name');
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth();
  // const day = date.getDate();
  // const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


  let data = {
    customer: 'Test',
    bookingNo: bookingNo,
    ...sellingDetails,
    ...buyingDetails,
    ...bookingDetails,
    ...details,
    ...allCharges,
    ...customerdetails,
    ...shippingDetails,
    ...profoDetails,
    ...profoList,
    remarks,
    cargo,
    date,
    selectedValue,
    amtinwords,
    // ...formInput,

    username: username,
    charges_inc: charges_inc,
  };

  console.log(data);
  let count = 0;
  //if (data.container_detail.length) {
  //  let arr = bookingDetails.container_detail.split(',');
  //  for (let str of arr) {
  //    count = count + parseInt(str);
  //  }
  //}

  count = parseInt(data.quantity_20) + parseInt(data.quantity_40);

  //const [noContainers, setNoContainers] = useState(count);
  //const [containers, setContainers] = useState('');
  //console.log(data);
  //const [details, setDetails] = useState(data);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [instructions, setInstructions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  let containers = '';
  if (count > 0) {
    if (data.quantity_20 > 0) {
      containers = containers + data.quantity_20 + ' x 20FT ';
    }

    if (data.quantity_40 > 0) {
      containers = containers + data.quantity_40 + ' x 40FT ';
    }
  }

  const styles = `
  @media print{
    @page { 
      // margin: 0 !important;
      
     }
   
    //  html, body {
    //   padding:0;
    //   margin:0;
    //  }
    
  

    #toPdf{
      padding: 1px;
      border-spacing: 0px;
      // margin: 2%;
      
   }
   .pageHeader{
    // padding: 1px;
      border-spacing: 0px;
    // margin: 2%;
   }
    th .headerLeft{
      text-align: left !important;
   }
  
   .tableContainer {
      display: flex;
    //   margin: 20px 10px;
    //   border-width: 5px;
      margin-top:10px; 
      bordercolor: 'white';
   }
   .tablepdf tr {
    //   border: 2px solid !important ;
   }
    .tablepdf th {
      //   border: 2px solid !important ;
    }
    .tablepdf td {
      //   border: 2px solid !important ;
    }
    .bankdetails{
    //  padding:100px !important;
      text-decoration:underline !important ;
      text-decroration-thickness:5px !important;
    }

    .nextpage{
    page-break-before:always;
    }
  }
  `;

  const [isLoading, setIsLoading] = useState(false);

  const print = () => {
    let html = document.getElementById('toPdf');
    console.log(data);
    let style = styles;
    let content;
    if (html.outerHTML) content = html.outerHTML;
    else if (XMLSerializer)
      content = new XMLSerializer().serializeToString(html);
    //console.log('inside', content);
    setIsLoading(true);
    customAxios
      .post('/booking/pdf', {
        template: content,
        style: style,
        bookingRefNo: data.bookingNo,
        logo: Logo,
      })
      .then((resp) => {
        if (resp.status == 200) {
          customAxios
            .get('/booking/getPdf', { responseType: 'blob' })
            .then((resp) => {
              //  console.log('pdf resp', resp);
              const blob = new Blob([resp.data], { type: 'application/pdf', mode: 'no-cors' });
              //  const url = URL.createObjectURL(blob);
              //  console.log('blob file', blob);
              //  window.open(url);

              // blob ready, download it
              let link = document.createElement('a');
              link.download = 'Proforma Approval' + data.invoice_no.toString().padStart(5,'0') + '.pdf';
              link.href = URL.createObjectURL(blob);
              link.click();
              // delete the internal blob reference, to let the browser clear memory from it
              URL.revokeObjectURL(link.href);
              console.log(data.invoice_no.toString().padStart(5,'0'))
              setInvoiceNo(data.invoice_no.toString().padStart(5,'0'));
              setIsLoading(false);
            })
            .catch((err) => console.log(err.message || 'Some error'));
        }
      });
  };

  // useEffect(() => {
  //   customAxios.get('/instruction').then((resp) => {
  //     console.log(resp);
  //     if (resp && resp.data && resp.data.data)
  //       setInstructions(resp.data.data);
  //   });
  // }, []);

  useEffect(() => {
    customAxios.post('/customer/byName', { name: data.customer }).then((resp) => {
      if (resp && resp.data && resp.data.data)
        setCustomerDetails(resp.data.data);
      console.log(resp.data.data);
      console.log(data.sailing_schedule);

    });
  }, [data.customer]);

  return (
    <>
      <div id='toPdf' style={{ padding: '0px;', margin: '0px;' }}>

        <div id='pageHeader'>
          <div className='title' style={{ display: '-webkit-flex', margin: '0px 0px', marginBottom: '10px' }}>
          <img src={Logo} alt='Logo' className='img' style={{ width: '70px', height: '60px' }} />
            <div className='nameAddress' style={{ marginLeft: '15px' }}>
            <p className='name'  id='heading'  style={{ fontFamily: 'Times New Roman', fontSize: '12px', fontWeight: 'bold',marginBottom:'0%' }}  >REUDAN INTERNATIONAL LTD</p>
              <p className='address' style={{ fontSize: '7px', fontFamily: 'Courier New' }}>
                OFFICE 102, 15 MAIN DRIVE, EAST LANE BUSINESS PARK, WEMBLEY - HA9
                7NA
              </p>
              <p className='phone' style={{ fontSize: '7px', marginBottom: '2px', fontFamily: 'Courier New' }}>
                Phone : +44 (0) 208 904 9616
              </p>
            </div>
          </div>
          
        </div>

        <div id='pageContent' style={{ marginTop: '5px' }} >

          <div style={{ textAlign: 'end', fontSize: '8px', fontFamily: 'Courier New' }}>REF # :{`${data.ref_id}`}</div>
          <div className='header' style={{ fontSize: '10px', fontWeight: 'bold', textTransform: 'uppercase', borderTop: '1px solid black', padding: '5px', marginBottom: '5px', marginTop: '5px', textAlign: 'center', borderBottom: '1px solid black', margin: '10px 5px 5px', fontFamily: 'Courier New' }}>I N V O I C E</div>
          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`JOB REF NO `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.booking_number}`}</div>
            </div>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '20%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase',fontFamily: 'Helvetica' }}>{`INVOICE NO. `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${"RILWEM/ FE/" + " " + year +"  "+ String(data.invoice_no).padStart(5,'0')}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex', paddingBottom:'4px' }}>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`VESSEL`}</div>
              <div className='value' style={{ width: '20%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.mlo}`}</div>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Helvetica' }}>{`VOYAGE`}</div>
              <div className='value' style={{ width: '24%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.voyage}`}</div>
            </div>
            <div className='keyValue' style={{ width: '40%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '25%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase',fontFamily: 'Helvetica' }}>{` DATE `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${date}`}</div>
            </div>
          </div>

          <div className='tableContainer ' style={{ display: 'flex', margin: '5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <table className='tablepdf' cellspacing='0' style={{ width: '100%', borderSpacing: '0px', fontSize: '0.9rem', borderCollapse: 'collapse' }}>

              <tr>
                <td className='headerLeft p-2' colspan="2" style={{ width: '20%', textAlign: 'left', marginBottom: '10px' ,fontSize: '7px', fontFamily: 'Helvetica'}}><b>TO</b></td>
                <td style={{ width: '30%', padding: '1px' }}></td>

                <td className='headerLeft' style={{ width: '10%', textAlign: 'left' ,fontSize: '7px', fontFamily: 'Helvetica'}}> <b>BOOKING NO</b></td>
                <td style={{ width: '30%', padding: '1px' ,fontSize: '7px', fontFamily: 'Courier New'}}><b>:</b>{`${data.booking_number}`}</td>
              </tr>

              <tr>
                <td rowspan="5" colSpan='3' style={{ borderRight: 'none',fontSize: '7px', fontFamily: 'Courier New' }}>{`${data.to_customer_name}`}</td>

                <td className='headerLeft' style={{ width: '10%', textAlign: 'left',fontSize: '7px', fontFamily: 'Helvetica'}}><b>BL</b></td>
                <td style={{ width: '30%' ,fontSize: '7px', fontFamily: 'Courier New'}}><b>:</b>{`${data.carrier_booking_no}`}</td>

              </tr>

              <tr>
                <td className='headerLeft' style={{ width: '10%', textAlign: 'left',fontSize: '7px', fontFamily: 'Helvetica' }}><b>POL/ETD</b></td>
                <td style={{ width: '30%',fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.pol +' ' + '&' +' '+ data.etd}`}</td>
              </tr>

              <tr>
                <td className='headerLeft' style={{ width: '10%', textAlign: 'left',fontSize: '7px', fontFamily: 'Helvetica' }}><b>POD/ETA</b> </td>
                <td style={{ width: '30%' ,fontSize: '7px', fontFamily: 'Courier New'}}><b>:</b>{`${data.pod + ' ' + '&' + ' ' + data.eta}`}</td>
              </tr>

              <tr>

                <td className='headerLeft' style={{ width: '10%', textAlign: 'left' ,fontSize: '7px', fontFamily: 'Helvetica'}}><b>CARGO</b></td>
                <td style={{ width: '30%' ,fontSize: '7px', fontFamily: 'Courier New'}}><b>:</b>{`${data.cnf}`}</td>
              </tr>

              <tr>
                <td className='headerLeft' style={{ width: '10%', textAlign: 'left',paddingBottom:'4px',fontSize: '7px', fontFamily: 'Helvetica' }}><b>VOLUME</b></td>
                <td style={{ width: '30%',fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.container_count}`}</td>
              </tr>
            </table>
          </div>


          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`SHIPPER`}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.shipper}`}</div>
            </div>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '19%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase', fontFamily: 'Helvetica' }}>{` REMARKS `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.remarks}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`CONSIGNEE `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b> {`${data.consignee}`}</div>   
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex', paddingBottom: '4px' }}>
              <div className='key' style={{ width: '25%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`NOTIFY PARTY `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.notify_party}`}</div>
            </div>
          </div>

          <div className='tableContainer  ' style={{ display: 'flex', margin: '5px' }}>
            <table className='tablepdf' cellspacing='0' style={{ width: '100%', borderSpacing: '0px', fontSize: '0.9rem', borderCollapse: 'collapse' }}>

              <tr style={{ borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
                <th colspan='2' style={{ borderRight: 'none', textAlign: 'center', fontSize: '7px', fontFamily: 'Helvetica' }}>CHARGES</th>
                <th style={{ borderRight: 'none' , fontSize: '7px', fontFamily: 'Helvetica'}}>CURR</th>
                <th style={{ borderRight: 'none' , fontSize: '7px', fontFamily: 'Helvetica'}}>RATE</th>
                <th style={{ borderRight: 'none' , fontSize: '7px', fontFamily: 'Helvetica' }}>BASE</th>
                <th style={{ borderRight: 'none' , fontSize: '7px', fontFamily: 'Helvetica'}}>Amount</th>

              </tr>
              {profoList ? <>
                                {profoList.map((data, index) => (
              <tr>
                <td colspan='2' style={{ borderRight: 'none', textAlign: 'left' , fontSize: '7px', fontFamily: 'Courier New'}}>{`${data.charge}`}</td>
                <td style={{ borderRight: 'none', textAlign: 'center', fontSize: '7px', fontFamily: 'Courier New' }}>{`${data.currency}`}</td>
                <td style={{ borderRight: 'none', textAlign: 'center', fontSize: '7px', fontFamily: 'Courier New'}}>{`${data.rate}`}</td>
                <td style={{ borderRight: 'none', textAlign: 'center', fontSize: '7px' , fontFamily: 'Courier New'}}>{`${data.base}`}</td>
                <td style={{ borderRight: 'none', textAlign: 'center', fontSize: '7px', fontFamily: 'Courier New'}}>{`${data.total}`}</td>
              </tr>
)
)} </>
: null}
            </table>
          </div>

          <div className='container' style={{ margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%' }}>
              <div className='key' style={{ width: '33%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`CONTAINER DETAILS`}</div>
              <div className='value' style={{ width: '100%', fontSize: '7px', fontFamily: 'Courier New' }}>{`${data.container_no}`}</div>
            </div>
          </div>

          <div className='container' style={{ margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <div className='keyValue' style={{ width: '50%', paddingBottom: '4px' }}>
              <div className='key' style={{ width: '33%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`LOADING POINT`}</div>
              <div className='value' style={{ width: '1%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}>{`${data.yard_detail}`}</div>
            </div>
          </div>


          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <div className='keyValue' style={{ width: '215%', display: '-webkit-flex', padding: '4px', borderTop: '1px solid black' }}>
              <div className='key' style={{ width: '70%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', textAlign: 'right', fontFamily: 'Helvetica' }}><b>{`TOTAL :`}</b></div>
              <div className='value' style={{ width: '5%', fontSize: '7px', textAlign: 'right', fontFamily: 'Courier New' }}><b>{`${profoDetails ? profoDetails.totalamt: 0}`}</b></div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <p className='name' style={{ fontSize: '7px', fontFamily: 'Courier New' }}><span>{`${data.currency}`}</span> &nbsp; <span>{`${data.amtinwords}`}</span></p>
          </div>

          <div className='container' style={{ margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <p className='name' style={{ fontSize: '20px', fontWeight: 'bold',fontSize: '7px', fontFamily: 'Courier New' }}>E & O E</p>
            <p className='points' style={{ fontSize: '7px', fontFamily: 'Courier New' }}> » If there is any discrepancy, kindly contact our accounts department within 3 days in writing from this invoice date otherwise all charges are deemed to be correct</p>
            <p className='points' style={{ fontSize: '7px', fontFamily: 'Courier New' }}> » Penalties for late payment as applicable</p>
            <p className='points' style={{ fontSize: '7px', fontFamily: 'Courier New' }}> » All cargoes must be insured until its final destination, we as carrier will not be responsible for any damage/claim during cargo in transit</p>
          </div>


          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%' }}>
              <div className='key bankdetails' style={{ width: '50%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', textDecoration: 'underline', textDecorationThickness: '2px', fontFamily: 'Helvetica' }}>{`BANK A/C DETAILS :`}</div>
            </div>
          </div>
          <br></br>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '31%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`BANK `}</div>
              <div className='value' style={{ width: '1%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}><b>:</b>{`${data.customer}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '31%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`BRANCH `}</div>
              <div className='value' style={{ width: '1%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}><b>:</b>{`${data.customer}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '31%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`ADDRESS `}</div>
              <div className='value' style={{ width: '10%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}><b>:</b>{`${data.cus_address}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '31%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`USD A/C DETAILS `}</div>
              <div className='value' style={{ width: '1%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}><b>:</b>{`${data.customer}`}</div>
            </div>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '22%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase', textAlign: 'center', fontFamily: 'Helvetica' }}>{` SORT CODE `}</div>
              <div className='value' style={{ width: '54%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.bookingNo}`}</div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px' }}>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '31%', textTransform: 'uppercase', fontSize: '7px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>{`IBAN`}</div>
              <div className='value' style={{ width: '1%', fontSize: '7px', textAlign: 'left', fontFamily: 'Courier New' }}><b>:</b>{`${data.customer}`}</div>
            </div>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '22%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase', textAlign: 'center', fontFamily: 'Helvetica' }}>{` SWIFT BIC `}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}><b>:</b>{`${data.bookingNo}`}</div>
            </div>
          </div>
          <br></br>
          <div className='container' style={{ display: '-webkit-flex', margin: '0 5px', borderBottom: '1px solid black', margin: '10px 5px 5px' }}>
            <div className='name' style={{ fontSize: '8px', fontFamily: 'Courier New' }}>THIS INVOICE IS COMPUTER GENERATED. NO SIGNATURE IS REQUIRED</div>
            <div className='keyValue' style={{ width: '50%', display: '-webkit-flex' }}>
              <div className='key' style={{ width: '190%', fontWeight: 'bold', fontSize: '7px', textTransform: 'uppercase', textAlign: 'right', fontFamily: 'Helvetica' }}>{` PREPARED BY:`}</div>
              <div className='value' style={{ width: '50%', fontSize: '7px', fontFamily: 'Courier New' }}>{`${data.bookingNo}`}</div>
            </div>
          </div>


          <div className='container' style={{ margin: '0 5px' }}>
            <p className='name' style={{ fontSize: '5px', fontFamily: 'Courier New' }}>ALL BUSINESS ARE UNDERTAKEN SUBJECT TO THE STANDARD TRADING CONDITIONS OF BRITISH INTERNATIONAL FREIGHT ASSOCIATION. A COPY OF WHICH CAN BE OBTAINED
              FROM HTTP://WWW.BIFA.ORG/CONTENT/TRADING.ASPx</p>
          </div>

        </div>

      </div>

      <div onClick={print}>
       {isLoading ?  
      <img src={Loader} width="25px" alt="" />:  'Download PDF'
         }
      </div>
    </>
  );
}
import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from "react-select";

function Employee() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    code: "",
    name: "",
    designation: "",
    department: "",
    education: "",
    experience: "",
  };
  const [employee, setEmployee] = useState(initValue);
  const [employees, setEmployees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  const [employUpdate, setEmployUpdate] = useState(false);
  const [error, setError] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');

  const setEmployeeDetail = ({ key, value }) => {
    let detail = { ...employee }; 
    detail[key] = value.toUpperCase();
    setEmployee(detail);
    
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const saveEmployee = () => {
    let temp = { ...employee };
    
    if (temp._id) {
      customAxios.post("/employee/update", { temp }).then((data) => {
        
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/employee";
      customAxios
        .post(url, employee)
        .then((resp) => {
          
          if (resp.data.error) {
            
            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setEmployee(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchEmployee = () => {
    let url = "/employee/search";
    customAxios.post(url, { name: searchValue }).then((charges) => {
      setEmployees(charges.data.data);
    });
  };

  const deleteEmployee = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios.delete("/employee", { data: { userId: id } }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Employee deleted succesfully!");
          let temp = [...employees];
          temp.splice(index, 1);
          setEmployees(temp);
        }
      });
    }
  };

  const editEmployee = (index, id) => {
    let temp = [...employees];
    setEmployee({ ...temp[index] });
    setShowList(false);
    setEmployUpdate(true);
  };

  useEffect(() => {
    let url = "/employee";
    customAxios.get(url).then((employees) => {
      setEmployees(employees.data.data);
      setSearchList(employees.data.data);
    });

    let empurl = "/employee";
    customAxios.get(empurl).then((employees) => {
      let temp = [...employees.data.data];
      let employ = temp.map((data)=>{
        return{label: data.name, value: data.name}
      })
      setEmployeeList([...employ]);
    });
  }, [showList]);

  const handleSearch = (option) => {
    
    if (option == null) {
      let url = "/employee";
      customAxios.get(url).then((employees) => {
        setEmployees(employees.data.data);
        setSearchList(employees.data.data);
        setSelectedVal(null);
     
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setEmployees(filterdata);
    }
  }


  return (
    <>
      <div className={classes.container}>
        <h5>Employee</h5>
        <section className={classes.title}>Dashboard / employee</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Employees Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    // onClick={searchCustomer}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                     options={employeeList}
                    value={selectedVal}
                    onChange={handleSearch} 
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setEmployee(initValue);
                      setEmployUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Emp Code</th>
                    <th scope="col" style={theadStyle}>Name</th>
                    <th scope="col" style={theadStyle}>Designation</th>
                    <th scope="col" style={theadStyle}>Department</th>
                    <th scope="col" style={theadStyle}>Education</th>
                    <th scope="col" style={theadStyle}>Experience</th>

                    {/* {isSuperuser && */} <th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {employees.length
                    ? employees.map((employee, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{employee.code}</td>
                            <td style={{textTransform: 'uppercase'}}>{employee.name}</td>
                            <td>{employee.designation}</td>
                            <td>{employee.department}</td>
                            <td>{employee.education}</td>
                            <td>{employee.experience}</td>
                            {/* {isSuperuser && ( */}
                              <td>
                                {/* <a href='#'> */}
                                <img
                                  src={EditIcon}
                                  alt="edit_icon"
                                  onClick={() =>
                                    editEmployee(index, employee._id)
                                  }
                                />
                                {/* </a>{' '} */}
                                &nbsp;&nbsp; {/* <a href='#'> */}
                                <img
                                  src={DeleteIcon}
                                  alt="delete_Icon"
                                  onClick={() =>
                                    deleteEmployee(index, employee._id)
                                  }
                                />
                                {/* </a> */}
                              </td>
                            {/* )} */}
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              {employees.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Employee</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Employee Name</p>
                <input
                style={{textTransform: 'uppercase'}}
                  type="text"
                  className={classes.inputText}
                  value={employee.name}
                  onChange={(e) =>
                    setEmployeeDetail({ key: "name", value: e.target.value })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Employee Code</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={employee.code}
                  onChange={(e) =>
                    setEmployeeDetail({ key: "code", value: e.target.value })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Designation</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={employee.designation}
                  onChange={(e) =>
                    setEmployeeDetail({
                      key: "designation",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Department</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={employee.department}
                  onChange={(e) =>
                    setEmployeeDetail({
                      key: "department",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Education</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={employee.education}
                  onChange={(e) =>
                    setEmployeeDetail({
                      key: "education",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Experience</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={employee.experience}
                  onChange={(e) =>
                    setEmployeeDetail({
                      key: "experience",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveEmployee}>
                {employUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setEmployee(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Employee;

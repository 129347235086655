import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './Buyer.module.css';
import CustomTable from '../../customTable';
import Approval from '../../Exports/Sales/Approval';

export default function ImpApproval() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const username = localStorage.getItem('name');
  const userId = localStorage.getItem('userId');

  let initBuyValue = {
    carrier: '',
    source: '',
    loading_port: '',
    discharge_port: '',
    destination: '',
    cargo: '',
    freight: '',
    shipment: '',
    charges: [],
  };

  let initSellValue = {
    created_by: username,
    customer: '',
    date: '',
    valid_till: '',
    remarks: '',
    selling_rates: [],
    status: '',
  };

  let initCharge = {
    charge: '',
    currency: '',
    rate: '',
    base: '',
  };

  const freightOptions = [
    { value: 'Prepaid', label: 'Prepaid' },
    { value: 'Collect', label: 'Collect' },
  ];
  const shipmentOptions = [
    { value: 'FCL', label: 'FCL' },
    { value: 'LCL', label: 'LCL' },
  ];

  const style = {
    control: (base) => ({
      ...base,
      height: '40px',
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const approveSelling = () => {
    if (sellingNo) {
      customAxios
        .post('/import/selling/update', {
          sellingNo: sellingNo,
          data: {
            status: 'Approved',
            approved_by: userId,
          },
        })
        .then((data) => {
         
          if (data.data.error) {
            setError(data.message);
          } else {
            console.log(data.data);
            setIsApproved(data.data.message);
            window.alert('Approved succesfully!');
          }
          console.log(data);
        });
    }
  }

  const saveDetail = () => {
    let {
      source,
      loading_port,
      discharge_port,
      destination,
      cargo,
      freight,
      shipment,
      charges,
      carrier,
    } = buyingDetails;
    let { created_by, customer, date, valid_till, remarks, selling_rates } = {
      ...sellingDetails,
    };
    console.log(selling_rates);
  //  selling_rates = selling_rates.map((data) => {
  //    return { charge: data.charge, currency: data.currency, base: data.base, rate: data.rate };
  //  });
    if (
      source &&
      loading_port &&
      discharge_port &&
      destination &&
      cargo &&
      freight &&
      shipment &&
      created_by &&
      customer &&
      date &&
      valid_till
    ) {
      console.log('save details');
      let temp = {
        created_by: userId,
        customer,
        date,
        valid_till,
        source,
        loading_port,
        discharge_port,
        destination,
        cargo,
        freight,
        shipment,
        remarks,
        selling_rates,
        carrier,
      };
      if (sellingNo) {
        console.log(temp);
        customAxios
          .post('/imports/selling/update', {
            sellingNo: sellingNo,
            data: {
              ...temp,
              buying_id: buyingNumber,
              buying_rates: buyingDetails.charges,
            },
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Detail updated succesfully!');
            }
            console.log(data);
          });
      } else
        customAxios
          .post('/imports/selling', {
            ...temp,
            buying_id: buyingNumber,
            buying_rates: buyingDetails.charges,
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Entry added succesfully!');
              setSellingNo(data.data.count);
            }
            console.log(data);
          });
    } else {
      setError('Please fill all required fields');
    }
  };

  const [buyingNumber, setBuyingNumber] = useState('');
  const [sellingNo, setSellingNo] = useState('');
  const [buyingDetails, setBuyingDetails] = useState(initBuyValue);
  const [sellingDetails, setSellingDetails] = useState(initSellValue);
  const [buyingNumbers, setBuyingNumbers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [charges, setCharges] = useState([]);
  const [newCharge, setNewCharge] = useState(initCharge);
  const [allCharges, setAllcharges] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState('');
  const [showList, setShowList] = useState(true);
  const [sellings, setSellings] = useState(true);
  const [isApproved,setIsApproved] = useState('');

  let buttonEnabled = !!(
    newCharge.charge &&
    newCharge.currency &&
    newCharge.rate &&
    newCharge.base
  );

  useEffect(() => {
    customAxios.get('/buyer/rateIds').then((ids) => {
      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: data.rate_id, value: data.rate_id };
      });
      setBuyingNumbers([...details]);
    });

    customAxios.get('/customer').then((customers) => {
      let temp = [...customers.data.data];
      let customersDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCustomers([...customersDetails]);
    });

    customAxios.get('/container').then((containers) => {
      let temp = [...containers.data.data];
      let containersDetails = temp.map((data) => {
        return { label: data.type, value: data.type };
      });
      setContainers([...containersDetails]);
    });

    customAxios.get('/charges').then((charges) => {
      let temp = [...charges.data.data];
      let chargesDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCharges([...chargesDetails]);
    });

    customAxios.get('/currency').then((currencies) => {
      let temp = [...currencies.data.data];
      let currenciesDetails = temp.map((data) => {
        return { label: data.code, value: data.code };
      });
      setCurrencies([...currenciesDetails]);
    });
  }, []);

  useEffect(() => {
    // console.log('buyingDetails', buyingDetails);
    console.log('sellingDetails', sellingDetails);
    console.log('new charge', newCharge);
  }, [newCharge, sellingDetails]);

  const updateSellingDetail = ({ key, value }) => {
    let temp = { ...sellingDetails };
    temp[key] = value;
    setSellingDetails({ ...temp });
  };

  const saveCharge = ({ key, evt }) => {
    let temp = { ...newCharge };
    temp[key] = evt && evt.value ? evt.value : '';
    setNewCharge(temp);
  };

  const addCharge = () => {
    console.log('newww', newCharge);
    let temp = [...allCharges, newCharge];
    setAllcharges(temp);
    setSellingDetails({ ...sellingDetails, selling_rates: temp });
    setNewCharge(initCharge);
  };

  const editCharge = (index) => {
    let temp = [...allCharges];
    setNewCharge({ ...temp[index] });
    temp.splice(index, 1);
    setAllcharges([...temp]);
  };

  const deleteCharge = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      let temp = [...allCharges];
      temp.splice(index, 1);
      setAllcharges([...temp]);
      setSellingDetails({ ...sellingDetails, selling_rates: temp });
    }
  };

  const getBuyingDetails = (selected) => {
    setBuyingNumber(selected ? selected.value : '');
    if (selected && selected.value) {
      customAxios
        .post('/buyer/getById', { id: selected.value })
        .then((data) => {
          if (!data.error) {
            let temp = data.data.data;
            console.log(temp)
          //  const temp_charge = temp.charges;
            let temp_charge = temp.charges.map((data) => {
              return { charge: data.charge, currency: data.currency, buyingRate: data.rate, base: data.base, rate: '' };
            });
            setAllcharges(temp_charge);
            console.log(allCharges);
            setBuyingDetails({ ...temp });
          }
        });
    } else {
      setBuyingDetails(initBuyValue);
    }
  };

  const cancelDetails = () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      confirmCancel();
    }
  };

  const confirmCancel = () => {
    setAllcharges([]);
    setNewCharge(initCharge);
    setBuyingDetails(initBuyValue);
    setSellingDetails(initSellValue);
    setError('');
    setBuyingNumber('');
    setSellingNo('');
  };

  const handleSellingNo = (evt) => {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && sellingNo) {
      customAxios.post('/selling/getById', { id: sellingNo }).then((result) => {
        console.log('result data', result.data.data);
        if (!result.data.error && result.data.data) {
          let {
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            created_by,
            customer,
            date,
            valid_till,
            remarks,
            selling_rates,
            carrier,
            buying_rates,
            buying_id,
            status,
          } = result.data.data;
          setBuyingDetails({
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            charges: buying_rates,
            carrier,
          });
          setSellingDetails({
            created_by: created_by,
            customer,
            date: date.substring(0, 10),
            valid_till: valid_till.substring(0, 10),
            remarks,
            selling_rates,
            carrier,
            status,
          });
          setBuyingNumber(buying_id);
          setAllcharges(selling_rates);
        } else {
          window.alert('No data found');
          confirmCancel();
        }
      });
    } else if (evt.key === 'Enter') {
      confirmCancel();
    }
  };

  const editSellings = (index,id) => {
    setShowList(false);
    setSellingNo(id);
    customAxios.post('/import/selling/getById', { id: id }).then((result) => {
      console.log('result data', result.data.data);
      if (!result.data.error && result.data.data) {
        let {
          source,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          created_by,
          customer,
          date,
          valid_till,
          remarks,
          selling_rates,
          carrier,
          buying_rates,
          buying_id,
          status,
        } = result.data.data;
        setBuyingDetails({
          source,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          charges: buying_rates,
          carrier,
        });
        setSellingDetails({
          created_by: created_by,
          customer,
          date: date.substring(0, 10),
          valid_till: valid_till.substring(0, 10),
          remarks,
          selling_rates,
          carrier,
          status,
        });
        setBuyingNumber(buying_id);
        setAllcharges(selling_rates);
      } else {
        window.alert('No data found');
        confirmCancel();
      }
  });
  };

  useEffect(() => {
    let url = '/import/selling';
    customAxios.get(url).then((sellings) => {
      setSellings(sellings.data.data);
      console.log('dataaa', sellings.data.data);
    });
  }, [showList]);

  const Approvalcolumns = [
    {
      Header: "#",
      accessor: "id"
    },
    {
      Header: "Buying",
      accessor: "buying_id"
    },
    {
      Header: "Quotation",
      accessor: "selling_id"
    },
    {
      Header: "Customer Name",
      accessor: "customer"
    },
    {
      Header: "Valid Till",
      accessor: "valid_till"
    },
    {
      Header: "Loading Port",
      accessor: "loading_port"
    },
    {
      Header: "Discharge Port",
      accessor: "discharge_port"
    },
    {
      Header: "Destination",
      accessor: "destination"
    },
    {
      Header: "Action",
      accessor: "action"
    }
  ];

  const ApprovalDatas = sellings.length ? sellings.filter(item => item.status !== 'Approved').map((item, index) => {
return{
  id: index+1,
  buying_id: item.buying_id,
  selling_id: item.selling_id,
  customer: item.customer,
  valid_till: item.valid_till,
  loading_port: item.loading_port,
  discharge_port: item.discharge_port,
  destination: item.destination,
  action:(
    <div style={{textAlign: 'center'}}>
      <img src={EditIcon} alt='edit_icon' onClick={() => editSellings(index,item.selling_id)}/>
    </div>
  )

}
  }) : [];

  if(ApprovalDatas && ApprovalDatas.length === 0 || null){
    ApprovalDatas.push({
      id: '',
      buying_id: '',
      selling_id: '',
      customer: '',
      valid_till: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
      action: ''
        })
  }

  return (
    <>
      <div className={classes.container}>
                <h5>Quotation <span className={classes.title}> Import / Sales Approval</span></h5>
            </div>
      <Container className={classes.inputsContainer}>
      {showList ? (
        <>
          <h6>Selling/Quotation Listing</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <CustomTable columns={Approvalcolumns} data={ApprovalDatas}/>
            
              {/* <table class='table'>
                <thead className={classes.theadDark} style={{position: 'sticky', zIndex: '1'}}>
                  <tr>
                    <th scope='col' style={theadStyle}>#</th>
                    <th scope='col' style={theadStyle}>Buying</th>
                    <th scope='col' style={theadStyle}>Quotation</th>
                    <th scope='col' style={theadStyle}>Customer Name</th>
                    <th scope='col' style={theadStyle}>Valid till</th>
                    <th scope='col' style={theadStyle}>Loading port</th>
                    <th scope='col' style={theadStyle}>Discharge port</th>
                    <th scope='col' style={theadStyle}>Destination</th>
                    <th scope='col' style={theadStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sellings.length
                    ? sellings.filter(sellings=>sellings.status !== 'Approved').map((selling, index) => {
                        return (
                          <tr key={index}>
                            <th scope='row'>{index + 1}</th>
                            <td>{selling.buying_id}</td>
                            <td>{selling.selling_id}</td>
                            <td>{selling.customer}</td>
                            <td>{selling.valid_till}</td>
                            <td>{selling.loading_port}</td>
                            <td>{selling.discharge_port}</td>
                            <td>{selling.destination}</td>
                            <td style={{ 'text-align': 'center '}}>
                              <img src={EditIcon} alt='edit_icon' onClick={() => editSellings(index,selling.selling_id)}/>
                            </td>
                          </tr>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              {sellings.length ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )} */}
            </div>
        </>
        ) : (
        <>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <span>Quotation#</span>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={sellingNo}
                disabled={true}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Date</p>
            </section>
            <input
              type='date'
              className={classes.inputText}
              value={sellingDetails.date}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Valid Till</p>
              <span>*</span>
            </section>
            <input
              type='date'
              min={sellingDetails.date}
              className={classes.inputText}
              value={sellingDetails.valid_till}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Customer</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.customer}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <span>Buying#</span>
            <input
              type='text'
              className={classes.inputText}
              value={buyingNumber}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Status</p>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.status}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Carrier</p>
            </section>
            <input
              type='text'
              value={buyingDetails.carrier}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Cargo</p>
            </section>
            <input
              type='text'
              value={buyingDetails.cargo}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>Place of Receipt</p>
            </section>
            <input
              type='text'
              value={buyingDetails.source}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Port of Loading</p>
            </section>
            <input
              type='text'
              value={buyingDetails.loading_port}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Port of Discharge</p>
            </section>
            <input
              type='text'
              value={buyingDetails.discharge_port}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Final Destination</p>
            </section>
            <input
              type='text'
              value={buyingDetails.destination}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Freight</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.freight}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Shipment</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.shipment}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Remarks</p>
            </section>
            <input
              type='text'
              value={sellingDetails.remarks}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>By</p>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.created_by}
              disabled={true}
            />
          </Col>
        </Row>
        <div style={{ height: '30px' }}>
          <span>{error}</span>
        </div>
        <div className={classes.footer}>
          {isApproved == 'Updated!!' ? <button className={classes.footerButton} onClick={approveSelling} disabled={true}>
            Approved
          </button> : <button className={classes.footerButton} onClick={approveSelling}>
            Approve
          </button>}
        
          
          <button
                className={classes.footerButton}
                onClick={() => {setShowList(true)}}
              >
                View List
              </button>
        </div>
        <Row className={classes.row}>
          <Col className={classes.input} md='5'>
            <p
              style={{
                color: 'red',
                fontWeight: 'normal',
                padding: '20px 0px',
              }}
            >
            Rates
            </p>
          </Col>
        </Row>
        <div
          style={{
            height: '200px',
            overflowY: 'scroll',
            backgroundColor: '#eff0f1',
          }}
        >
          <table class='table'>
            <thead className={classes.theadDark} style={{position: 'sticky', zIndex: 1}}>
              <tr>
                <th scope='col' style={theadStyle}>#</th>
                <th scope='col' style={theadStyle}>
                  Charges
                </th>
                <th scope='col' style={theadStyle}>Curr</th>
                <th scope='col' style={theadStyle}>Base</th>
                <th scope='col' style={theadStyle}>Buying Rates</th>
                <th scope='col' style={theadStyle}>Selling Rate</th>
                <th scope='col' style={theadStyle}>Diff</th>
              </tr>
            </thead>
            <tbody>
              {allCharges.length
                ? allCharges.map((charge, index) => {
                    return (
                      <tr key={index} style={{ backgroundColor: 'lightgray' }}>
                        <th scope='row'>{index + 1}</th>
                        <td>{charge.charge}</td>
                        <td>{charge.currency}</td>
                        <td>{charge.base}</td>
                        <td>{parseFloat(charge.buyingRate).toFixed(2)}</td>
                        <td>{charge.rate ? parseFloat(charge.rate).toFixed(2) : 0}</td>
                        <td>{charge.rate - (charge.buyingRate ? charge.buyingRate : 0)}</td>
                      </tr>
                    );
                  })
                : ''}
            </tbody>
          </table>
        </div>
        </>)}
      </Container>
    </>
  );
}

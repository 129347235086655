exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer_footer__1m44D {\r\n    background-color: white;\r\n    color: gray !important;\r\n    margin-top: 60px;\r\n    margin-bottom: 0;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 15px 30px;\r\n    position: fixed;\r\n    bottom: 0;\r\n    width: 100%;\r\n}\r\n\r\n.Footer_footer__1m44D:hover {\r\n    cursor: default;\r\n}\r\n\r\n.Footer_footerContent__3a-G4 {\r\n    display: flex;\r\n    grid-gap: 20px;\r\n    gap: 20px;\r\n}", ""]);

// exports
exports.locals = {
	"footer": "Footer_footer__1m44D",
	"footerContent": "Footer_footerContent__3a-G4"
};
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import classes from './Style.module.css';
import CustomTable from '../../customTable';
import ExcelJS from 'exceljs';

export default function Buyingreport() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const username = localStorage.getItem('name');
  const [buyingDetails, setBuyingDetails] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [allCharges, setAllCharges] = useState([]);
  const [rateId, setRateId] = useState('');
  const [showList, setShowList] = useState(true);
  const [buyingList, setBuyingList] = useState([]);
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [excelData, setExcelData] = useState([]);

  const theadStyle = {
    backgroundColor: '#3d4d76',
  };

  const showBuyReport = (index, rate_id) => {
    setShowList(false);
    customAxios.post('/buyer/getById', { id: rate_id }).then((result) => {
      if (!result.data.error && result.data.data) {
        setRateId(rateId);
        const {
          carrier,
          source,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          valid_from,
          valid_to,
          remarks,
          charges,
          rate_id,
        } = result.data.data;

        setBuyingDetails({
          rate_id,
          carrier,
          source,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          valid_from: valid_from.substring(0, 10),
          valid_to: valid_to.substring(0, 10),
          remarks,
          charges,
        });

        setAllCharges(charges);
      } else {
        window.alert('No data found');
      }
    });
  }

  const handleClick = () => {
    if (fromdate && todate) {
      const payload = {
        fromdate: fromdate,
        todate: todate
      };

      customAxios.post("/buyer/getbydate", payload)
        .then((response) => {
          const responseData = response.data;

          if (responseData && responseData.length > 0) {
            const files = responseData;
            console.log(files);
            setBuyingList(files);
          } else {
            setBuyingList([]);
            alert('No files found for the selected date range.');
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert('An error occurred while fetching files.');
        });

    } else {
      alert('Please choose both From Date and To Date');
    }
  };


  // Extracting Data For Excel
  useEffect(() => {
    const extractData = buyingList && buyingList.flatMap((buyingLists, index) => {
      const formattedDate1 = buyingLists.valid_from;
      const formattedDate2 = buyingLists.valid_to;

      const validFrom = formattedDate1.split('T')[0];
      const validTill = formattedDate2.split('T')[0];

      const rowData = {
        ID: index + 1,
        BuyNo: buyingLists.rate_id,
        Carrier: buyingLists.carrier,
        POR: buyingLists.source,
        POD: buyingLists.loading_port,
        DischargePort: buyingLists.discharge_port,
        DEL: buyingLists.destination,
        cargo: buyingLists.cargo,
        Freight: buyingLists.freight,
        shipment: buyingLists.shipment,
        valid_from: validFrom,
        valid_to: validTill,
        Remarks: buyingLists.remarks,
      };

      rowData.charges = buyingLists.charges.map((charge) => ({
        charge: charge.charge,
        currency: charge.currency,
        rate: charge.rate,
        base: charge.base,
      }));

      return rowData;
    });

    setExcelData(extractData);
  }, [buyingList]);

  const convertToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    const mainHeaderRow = sheet.addRow([
      '#',
      'BuyNo',
      'Carrier',
      'POR',
      'POD',
      'Discharge Port',
      'DEL',
      'Cargo',
      'Freight',
      'Shipment',
      'Valid From',
      'Valid To',
      'Remarks',
    ]);

    //Main Row 
    mainHeaderRow.font = { bold: true };

    excelData.forEach((row) => {
      sheet.addRow([
        row.ID,
        row.BuyNo,
        row.Carrier,
        row.POR,
        row.POD,
        row.DischargePort,
        row.DEL,
        row.cargo,
        row.Freight,
        row.shipment,
        row.valid_from,
        row.valid_to,
        row.Remarks,
      ]);

      sheet.addRow([]); // Add blank row as separator

      const chargesHeaderRow = sheet.addRow(['', '', 'Charge', 'Currency', 'Rate', 'Base']);
      chargesHeaderRow.font = { bold: true };

      if (row.charges && Array.isArray(row.charges)) {
        row.charges.forEach((charge) => {
          sheet.addRow(['', '', charge.charge, charge.currency, charge.rate, charge.base]);
        });
      }

      sheet.addRow([]); // Add blank row as separator
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'Buying Report.xlsx');
    });
  };

  const saveAs = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    let url = '/buyer/getall';
    customAxios.get(url).then((buying) => {
      setBuyingList(buying.data.data);
    });
  }, [showList]);

  const buyingColumns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Buy No',
      accessor: 'rate_id',
    },
    // {
    //   Header: "Customer",
    //   accessor: "customer",
    // },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
    {
      Header: "Cargo",
      accessor: "cargo",
    },
    {
      Header: "Action",
      accessor: "action",
    }
  ];

  const buyingDatas = buyingList && buyingList.length
    ? buyingList.map((item, index) => {
      return {
        id: index + 1,
        rate_id: item.rate_id,
        carrier: item.carrier,
        source: item.source,
        loading_port: item.loading_port,
        discharge_port: item.discharge_port,
        destination: item.destination,
        cargo: item.cargo,
        action: (
          <div style={{ textAlign: 'center' }}>
            <img src={icon_view} alt='icon_view' onClick={() => showBuyReport(index, item.rate_id)} />
          </div>
        ),
      };
    })
    : [];

  if (!buyingDatas || buyingDatas.length === 0) {
    buyingDatas.push({
      id: '',
      rate_id: '',
      // customer: '',
      valid_till: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
      cargo: '',
    });
  }

  return (
    <>
      <div className={classes.container}>
        <h5>
          Buying Report <span className={classes.title}>Export / BuyingReport</span>
        </h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Buying Report Listing</h6>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div style={{ display: 'inline-flex', padding: '1%', width: '100%' }}>
                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>From Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={fromdate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>To Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={todate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%', width: '100%' }}>
                  <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  &nbsp;
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <button className={classes.footerButton} style={{ float: 'right' }} onClick={convertToExcel}>
                    Download
                  </button>
                </Col>

              </div>
              <CustomTable columns={buyingColumns} data={buyingDatas} />
            </div>
          </>
        ) : (
          <>
            {/* Add more components for the detailed view */}
            <Container className={classes.inputsContainer}>
              <Row className={classes.row}>
                <Col className={classes.input}>
                  <section>
                    <p style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={setIsOpen}>Buy no</p>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.rate_id : ''} disabled={true} onChange={(evt) => setRateId(evt.target.value)}
                  />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Valid From</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.valid_from : ''} disabled={true}
                  />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Valid Till</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.valid_to : ''}
                    disabled={true}
                  />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Freight</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.freight : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Shipment</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.shipment : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>POR</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={buyingDetails ? buyingDetails.source : ''}
                      disabled={true} />
                  </div>
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>POL</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={buyingDetails ? buyingDetails.loading_port : ''}
                      disabled={true} />
                  </div>
                </Col>
              </Row>
              <Row className={classes.row}>
                <Col className={classes.input}>
                  <section>
                    <p>POD</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.discharge_port : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>DEL</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.destination : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Cargo</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.cargo : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <section>
                    <p>Carrier</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.carrier : ''}
                    disabled={true} />
                </Col>
                <Col className={classes.input}>
                  <p>Remarks</p>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails ? buyingDetails.remarks : ''} disabled={true}
                  />
                </Col>
              </Row>
              &nbsp;&nbsp;
              <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead className={classes.theadDark}>
                    <tr>
                      <th scope='col' style={theadStyle}>
                        Charges
                      </th>
                      <th scope='col' style={theadStyle}>
                        Curr
                      </th>
                      <th scope='col' style={theadStyle}>
                        Rate
                      </th>
                      <th scope='col' style={theadStyle}>
                        Base
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCharges.length
                      ? allCharges.map((charge, index) => {
                        return (
                          <tr key={index} style={{ borderBottomWidth: 'none' }}>
                            <td>{charge.charge}</td>
                            <td>{charge.currency}</td>
                            <td>{parseFloat(charge.rate).toFixed(2)}</td>
                            <td>{charge.base}</td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </Container>
            <div className={classes.footer} style={{ display: 'flex', justifyContent: 'end', gridGap: '20px', padding: '10px', gap: '20px' }}>
              <button className={classes.footerButton} style={{ margin: '1%' }} onClick={() => setShowList(true)}>
                View List
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './accountsCssCls.module.css';
import CustomTable from '../../customTable';



export default function ProCreditApproval() {

    const initProdetails = {
        bkg_no: '',

        bill_type: '',
        to_address: '',
        current_date: '',
        to_address: '',
        currency: '',
        shipper: '',
        agent: '',
        mlo: '',
        notify_party: '',
        cnf: '',
        remarks: '',
        creditnote_charges: '',
    }

    const [tableLists, setTableLists] = useState([]);
    const [showList, setShowList] = useState(true);
    const [tableListNo, setTableListNo] = useState('');
    const [prodebitDetails, setprodebitDetails] = useState(initProdetails);
    const [prodebitlist, setprodebitlist] = useState('');
    const [isApproved, setIsApproved] = useState(0);
    const [isApprovedId, setIsApprovedId] = useState('');
    const [constno, setconstno] = useState('');
    const [vendor, setvendor] = useState();
    const [creditdays, setcreditdays] = useState();
    const [debitnote, setdebitnote] = useState();
    const [cn_no, setcn_no] = useState();
    const [deleteId, setDeleteId] = useState('');
    const [refnumber, setRefnumber] = useState([]);
    const [searchList, setSearchList] = useState('');
    const [selectedVal, setSelectedVal] = useState('');





    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };



    const editprodebitlist = (index, ref_no) => {
        setShowList(false);
        // setTableListNo(ref_no);

        customAxios.post('/voucher/proformacreditnote/getbyID', { ref_no: ref_no }).then((result) => {
            if (!result.data.error && result.data.data) {
                let {
                    _id,
                    credit_no,
                    bkg_no,
                    vendor_ref,
                    creditdays,
                    bill_type,
                    current_date,
                    to_address,
                    Address,
                    currency,
                    shipper,
                    agent,
                    mlo,
                    notify_party,
                    cnf,
                    remarks,
                    creditnote_charges,
                    destination,
                    consignee,
                    ref_no,
                    curr_value,
                    contatainer_no,
                    container_count,
                    total_amt,
                } = result.data.data



                setprodebitDetails({
                    _id: _id,
                    bkg_no: bkg_no,
                    bill_type: bill_type,
                    current_date: current_date,
                    to_address: to_address,
                    Address: Address,
                    currency: currency,
                    shipper: shipper,
                    agent: agent,
                    mlo: mlo,
                    notify_party: notify_party,
                    cnf: cnf,
                    remarks: remarks,
                    creditnote_charges: creditnote_charges,
                    isApproved: 0,
                    destination: destination,
                    consignee: consignee,
                    ref_no: ref_no,
                    curr_value: curr_value,
                    contatainer_no: contatainer_no,
                    container_count: container_count,
                    credit_no: credit_no,
                    vendor_ref: vendor_ref,
                    creditdays: creditdays,
                    total_amt: total_amt,
                })

                setprodebitlist(creditnote_charges);
                setIsApproved(result.data.isApproved ? result.data.isApproved : 0);
                setIsApprovedId(result.data.data._id);



            }
            else {
                window.alert('No data found');
            }
        })
    }

    const ApprovedId = () => {


        customAxios
            .post('/voucher/proformacreditnote/approved', {
                "temp": {
                    "_id": isApprovedId,
                    "is_approved": 1
                }
            })
            .then((data) => {

                if (data.data.error) window.alert('Unable to update the details!!');
                else {
                    window.alert('Approved successfully');
                    setIsApprovedId(1);
                    setcn_no(data.data.cn_no);

                }
            });
    }

    useEffect(() => {
        let year = new Date().getFullYear();
        const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
        setconstno(profomainvoiceAutogen)


        let url = '/voucher/proformacreditnote/list';
        customAxios.get(url).then((tableLists) => {

            setTableLists(tableLists.data.data);
            setSearchList(tableLists.data.data);

            // 
        });

        let refNumUrl = '/voucher/proformacreditnote/list';
        customAxios.get(refNumUrl).then((refNos) => {
            let temp = [...refNos.data.data];
            let refno = temp.map((data) => {
                return { label: data.ref_no, value: data.ref_no };
            })
            setRefnumber([...refno]);

        })
    }, [showList]);


    const deleteCharge = (index, _id) => {
        setDeleteId(_id);
        if (window.confirm('Are you sure to delete?')) {
            customAxios.post('/voucher/proformacreditnote/delete', { _id: _id }).then((result) => {
                if (result.data.data) {

                    let temp = [...tableLists];
                    temp.splice(index, 1);
                    setTableLists([...temp]);


                }
                let url = '/voucher/proformacreditnote/list';
                customAxios.get(url).then((tableLists) => {

                    setTableLists(tableLists.data.data);



                });
            })
        }
        else {
            let url = '/voucher/proformacreditnote/list';
            customAxios.get(url).then((tableLists) => {

                setTableLists(tableLists.data.data);


            });

        }
    };

    const handleSearch = (option) => {
        if (option == null) {
            let url = '/voucher/proformacreditnote/list';
            customAxios.get(url).then((tableLists) => {

                setTableLists(tableLists.data.data);
                setSearchList(tableLists.data.data);
                setSelectedVal(null);
            });
        }
        else {
            setSelectedVal(option);
            const filterdata = searchList.filter(item => item.ref_no === (option ? option.value : ' '));
            setTableLists(filterdata);
        }
    }

    const columns = [

        {
            Header: "#",
            accessor: "id",
        },
        {
            Header: "BKG.NO",
            accessor: "bkg_no",

        },
        {
            Header: "REF#",
            accessor: "ref_no",
        },
        {
            Header: "To",
            accessor: "to_address",
        },
        {
            Header: "Curr",
            accessor: "currency",
        },
        {
            Header: "Total Amount",
            accessor: "total_amt",
        },
        {
            Header: "Action",
            accessor: "action",
        },
    ];

    { console.log(tableLists); }

    const DATA = tableLists.filter(item => item.is_approved === 0).map((item, index) => {
        const refNo = item.ref_no;
        // { console.log(DATA, refNo); }

        return {

            id: index + 1,
            bkg_no: 'RUKEX' + item.bkg_no,
            ref_no: 'RILWEM/FE/' + item.ref_no,
            to_address: item.to_address,
            currency: item.currency,
            total_amt: item.total_amt.toFixed(2),
            action: (
                <div style={{ textAlign: 'center' }}>
                    {console.log(refNo)}
                    <div style={{ textAlign: 'center' }}>

                        <img src={EditIcon} alt='edit_icon' onClick={() => editprodebitlist(index, item.ref_no)} />
                        &nbsp;&nbsp;
                        <span><img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, item._id)} /></span>
                    </div>

                    {console.log(item.refNo)}
                </div>
            )
        };

    });

    if (DATA.length === 0 || null) {
        DATA.push({
            id: '',
            bkg_no: '',
            ref_no: '',
            to_address: 'No Data Available',
            currency: '',
            total_amt: '',
            action: ''

        })
    }

    return (
        <>
            <div className={classes.container}>
                <h5>Proforma CreditNote Approval <span className={classes.title}>Export / proforma CreditNote Approval</span></h5>
            </div>
            <Container className={classes.inputsContainer}>
                {showList ? (
                    <>
                        <table>
                            <tr>
                                <h6 style={{ width: '300px' }}>Proforma CreditNote Approval</h6>
                                <td colSpan={1} style={{ width: '10px', paddingLeft: '557px' }}>Search</td>
                                <td colSpan={4} style={{ width: '175px' }}>
                                    {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={refnumber}
                                        // value={cnNo.value}
                                        /* value={invnumber.filter(
                                            (option) =>{ 
                                                return option.value === tableListNo
                                            }
                                        )} */
                                        value={selectedVal}
                                        onChange={handleSearch}
                                    // selectedValue={selectedValue}
                                    /* onChange={(selected) => {
                                        editprofolist(selected ? selected.value:'');
                                      }}  */

                                    />

                                </td>
                            </tr>
                        </table>
                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <CustomTable columns={columns} data={DATA} />

                            {/* <table class='table'>
                                <thead className={classes.theadDark}>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'>BKG.NO</th>
                                        <th scope='col'>REF#</th>
                                        <th scope='col'>To</th>
                                        <th scope='col'>Total Amount</th>
                                        <th scope='col'>CURR</th>
                                        
                                        <th scope='col'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableLists.length
                                        ? tableLists.map((tableList, index) => {

                                            return (
                                                <>
                                                    {tableList.is_approved == 0 ? 
                                                    <tr key={index}>
                                                        <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                                                        <td style={{ 'text-align': 'center ' }}>{tableList.bkg_no}</td>
                                                        <td style={{ 'text-align': 'center ' }}>{tableList.ref_no}</td>
                                                        <td style={{ 'text-align': 'center ' }}>{tableList.to_address}</td>
                                                        <td style={{ 'text-align': 'center ' }}>{tableList.total_amt.toFixed(2)}</td>
                                                        <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                                                        
                                                        <td style={{ 'text-align': 'center ' }}>
                                                            <img src={EditIcon} alt='edit_icon' onClick={() => editprodebitlist(index, tableList.ref_no)} />
                                                            &nbsp;&nbsp;<span><img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, tableList._id)}/></span>
                                                        </td>
                                                    </tr>
                                                     : null} 
                                                </>
                                            );

                                        })
                                        : ''}
                                </tbody>
                            </table> */}
                        </div>
                    </>
                ) : (
                    <>
                        <table style={{ width: '100%' }}>
                            <tbody><tr>
                                <td colSpan={1} style={{ width: '10px' }}><span>CN#*</span></td>
                                <td colSpan={2} style={{ width: '100px' }}>
                                    <input className={classes.inputText} value={cn_no} disabled={true} type="text" />
                                </td>
                                <td colSpan={1} style={{ paddingLeft: '97px' }}><span >BKG.No#*</span></td>


                                <td colSpan={2} style={{ width: '100px' }}>
                                    <section>
                                        <div style={{ position: 'relative' }}>
                                            <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                                            <input
                                                type='text'
                                                className={classes.inputText}
                                                style={{ paddingLeft: '60px' }}
                                                value={prodebitDetails ? prodebitDetails.bkg_no : ''}
                                                disabled={true} />
                                        </div>
                                    </section>
                                </td>

                                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                                <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                                <td colSpan={1}><input className={classes.inputText} type="text" value={prodebitDetails ? prodebitDetails.ref_no : ''}
                                    disabled={true} /></td>

                            </tr>
                                <tr>
                                    <td>To<span>*</span></td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.to_address : ''}
                                        disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '35px' }}>Date</td>
                                    <td colSpan={2}><input className={classes.inputText} value={date} type="text" disabled={true} /></td>
                                </tr>

                                <tr>

                                    <td style={{ paddingLeft: '2px' }} colSpan={1} >Address</td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.Address : ''}
                                        disabled={true} type="text" /></td>


                                    <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                                    <td colSpan={1} ><input className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        type="text" value={prodebitDetails ? prodebitDetails.currency : ''}
                                        disabled={true} >
                                    </input>
                                    </td>
                                    <td colSpan={1}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.curr_value : ''} disabled={true} type="text" /></td>
                                </tr>

                                <tr></tr>
                                <tr>

                                    <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.destination : ''}
                                        disabled={true} type="text" /></td>


                                    <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Vendor Ref#</span></td>
                                    <td colSpan={5}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={prodebitDetails ? prodebitDetails.vendor_ref : ''}
                                        disabled={true} /></td>
                                    {/* <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={prodebitDetails ? prodebitDetails.creditdays : ''}
                                        disabled={true} /></td> */}
                                    {/* <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td> */}
                                    {/* <td colSpan={1} style={{ paddingLeft: '42px' }}>Vendo Ref #</td> */}
                                    {/* <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Credit Days</span></td> */}


                                </tr>
                                <tr >

                                    <td colSpan={1}>Shipper</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.shipper : ''} disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.agent : ''} disabled={true} type="text" /></td>


                                    <td colSpan={1} style={{ paddingLeft: '35px', }}>Container</td>
                                    <td colSpan={4} rowSpan={2}><textarea style={{ height: '83px', marginTop: '-18px', marginBottom: '0px' }} value={prodebitDetails ? prodebitDetails.container_count : ''}
                                        disabled={true} /></td>


                                </tr>
                                <tr >

                                    <td colSpan={1} style={{ paddingRight: '50px', paddingBottom: '22px' }}  >Cosingnee</td>
                                    <td colSpan={2} style={{ paddingBottom: '22px' }}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.consignee : ''} disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px', paddingBottom: '22px' }} colSpan={1} >MLO</td>
                                    <td colSpan={2} style={{ paddingBottom: '22px' }}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.mlo : ''} disabled={true} type="text" /></td>


                                </tr>
                                <tr></tr>
                                <tr>

                                    <td colSpan={1} >Notify Party</td>
                                    <td colSpan={2} ><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.notify_party : ''}
                                        disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px', }} colSpan={1}>CNF</td>
                                    <td colSpan={2} ><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.cnf : ''}
                                        disabled={true} type="text" /></td>


                                    {/* <td colSpan={1} style={{ paddingLeft: '35px', }}></td> */}
                                    <td colSpan={1}  style={{ paddingLeft: '35px'/* , paddingBottom: '87px' */ }}> Container No.</td>
                                    <td colSpan={3} rowSpan={2} style={{ /* paddingBottom: '64px' */verticalAlign: 'top' }}><textarea style={{ height: '145px'/* , marginTop: '-20px' */ }} value={prodebitDetails ? prodebitDetails.contatainer_no : ''}
                                        disabled={true} /></td>

                                </tr>

                                <tr>
                                    <td colSpan={1} style={{ paddingBottom: '32px' }}>Remarks</td>
                                    <td colSpan={5} style={{ paddingTop: '15px', paddingBottom: '46px' }}><textarea style={{ height: '90px' }} value={prodebitDetails ? prodebitDetails.remarks : ''} disabled={true} /></td>

                                    
                                </tr>
                                <tr><td colSpan="9">
                                    <hr></hr>
                                </td></tr>


                            </tbody>
                        </table>
                        <div className={classes.footer}>
                            {isApprovedId == 1 ? <button className={classes.footerButton} onClick={ApprovedId} disabled={true}>
                                Approved
                            </button> : <button className={classes.footerButton} onClick={ApprovedId}>
                                Approve
                            </button>}
                            <button
                                className={classes.footerButton}
                                onClick={() => { setShowList(true) }}
                            >
                                View List
                            </button>
                        </div>
                        <Row className={classes.row}>
                            <Col className={classes.input} md='5'>
                                <p
                                    style={{
                                        color: 'red',
                                        fontWeight: 'normal',
                                        padding: '10px 0px',
                                    }}
                                >
                                    Invoices
                                </p>
                            </Col>
                        </Row>

                        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                            <table style={{ width: '100%' }}>
                                <thead className={classes.theadDark}>
                                    <tr>
                                        <th style={{ 'text-align': 'left' }}>charges</th>
                                        <th style={{ 'text-align': 'left' }}>Curr</th>
                                        <th style={{ 'text-align': 'left' }}>Rate</th>
                                        <th style={{ 'text-align': 'left' }}>Base</th>
                                        <th style={{ 'text-align': 'left' }}>Exchange Rate</th>
                                        <th style={{ 'text-align': 'left' }}>No of Containers</th>
                                        <th style={{ 'text-align': 'left' }}>Amount</th>
                                        {/* <th>Action</th> */}


                                    </tr>
                                </thead>

                                <tbody>
                                    {prodebitlist ? <>
                                        {prodebitlist.map((data, index) => (
                                            <tr>
                                                <td>{data.charge}</td>
                                                <td>{data.currency}</td>
                                                <td>{parseFloat(data.rate).toFixed(2)}</td>
                                                <td>{data.base}</td>
                                                <td>{data.exchangeRate}</td>
                                                <td>{data.quantity}</td>
                                                <td>{parseFloat(data.total).toFixed(2)}</td>
                                                {/* {data.total > 0 ? <td>{data.total}</td> : '0'}  */}

                                                {/*                                         {
                                            data.currency == 'USD' ? (
                                            <td>{data.rate * profoInvInp}</td>
                                            ): <td>{data.rate * prodebitDetails.containerlength }</td>
                                            } */}
                                            </tr>
                                        )
                                        )} </>
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table>
                                <tr>
                                    <td colSpan={5} style={{ width: '50%' }}></td>
                                    <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                                    <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={prodebitDetails ? (prodebitDetails.total_amt == null ? 0 : parseFloat(prodebitDetails.total_amt).toFixed(2)) : ' '} type="text" disabled={true} ></input></td>

                                </tr>
                            </table>
                        </div>
                    </>
                )}
            </Container>
        </>
    );

}


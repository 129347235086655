import React, { useEffect, useState, useRef } from 'react';
import customAxios from '../../../Axios';
import { Logo } from '../../../Images';
import { Loader, HeaderLogo } from '../../../Images';
import './PdfTemplate.css';
import Select from 'react-select';



export default function Template({
  sellingDetails,
  buyingDetails,
  bookingDetails,
  bookingNo,
  charges_inc,
  allHaulage
}) {


  /* const fntStyl = { 
  
    head :{
       fontFamily: 'times new roman',
       fontSize: '9.5px',
       fontWeight: 'bold',
    },
    subHead: {
      fontFamily: 'Courier New',
      fontSize: '9.5px',
    },
    subHeading: {
      fontFamily: 'Helvetica',
      fontSize: '9.5px',
    }
  } */



  const style = {
    control: (base) => ({
      ...base,
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const username = localStorage.getItem('name');

  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth();
  // const day = date.getDate();
  // const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const htmlContentRef = useRef(null);


  let data = {
    customer: 'Test',
    bookingNo: bookingNo,
    ...sellingDetails,
    ...buyingDetails,
    ...bookingDetails,
    ...allHaulage,
    // today: today,
    username: username,
    charges_inc: charges_inc,
  };

  console.log(data);



  let count = 0;
  //if (data.container_detail.length) {
  //  let arr = bookingDetails.container_detail.split(',');
  //  for (let str of arr) {
  //    count = count + parseInt(str);
  //  }
  //}

  count = parseInt(data.quantity_20) + parseInt(data.quantity_40);

  //const [noContainers, setNoContainers] = useState(count);
  //const [containers, setContainers] = useState('');

  //const [details, setDetails] = useState(data);
  const [instructions, setInstructions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  let containers = '';
  if (count > 0) {
    if (data.quantity_20 > 0) {
      containers = containers + data.quantity_20 + ' x 20FT';
    }
    if (data.quantity_40 > 0) {
      containers = (containers ? (containers + ', ') : ' ') + data.quantity_40 + ' x 40FT ';
    }
  }

  console.log(containers);

  const styles = `
  @media print{
    @page { 
      // margin: 0 !important;  
     }
     table tbody tr td:before,
    table tbody tr td:after {
        content: "";
        // height: 4px;
        display: block;
    }

    table{
      border-collapse:collapse;
  }
  table{
    border-collapse:collapse;
    
}

    //  html, body {
    //   padding:0;
    //   margin:0;
    //  }  
  }

    #toPdf{
      padding: 1px;
      border-spacing: 0px;
  }
  .pageHeader{
    // padding: 1px;
      border-spacing: 0px;
    // margin: 2%;
    
   }
    
    th .headerLeft{
      text-align: left !important;
  }

  
  .tableContainer {
      // display: flex;
      // margin: 20px 10px;
      // border-width: 1px;
      // margin-top:10px; 
  }
  .tablepdf tr {
      // border: 1px solid !important ;
  }
  .tablepdf th {
    // border: 0.2px solid !important ;
    // padding: 10px !important;
}
  .tablepdf td {
      // border: 1px solid !important ;
      // padding: 5px !important;
  
  }
  .nextpage{
    page-break-before:always;
  }

  #toPdf{
    font-family : Arial;
    letter-spacing: 0.5px;
    
   }

   #parent > td, #child > td{
    border : 1px solid #C3C3C3;
   }

   .tab {
    display: inline-block;
    margin-left: 4em;
}

}
  `;
  const [isLoading, setIsLoading] = useState(false);


  var userMailId = localStorage.getItem('userMail');
  const print = () => {
    let html = document.getElementById('toPdf');

    let style = styles;
    let content;
    if (html.outerHTML) content = html.outerHTML;
    else if (XMLSerializer)
      content = new XMLSerializer().serializeToString(html);


    let headerhtml = document.getElementById('pageHeader');
    // let style = styles;
    let headercontent;
    if (headerhtml.outerHTML) headercontent = headerhtml.outerHTML;

    else if (XMLSerializer)
      headercontent = new XMLSerializer().serializeToString(headerhtml);
    console.log(headercontent);


    let headerLine = document.getElementById('pageHeaderwithLine');
    // let style = styles;
    let headercontentwithLine;
    if (headerLine.outerHTML) headercontentwithLine = headerLine.outerHTML;

    else if (XMLSerializer)
      headercontentwithLine = new XMLSerializer().serializeToString(headerLine);
    console.log(headercontentwithLine);


    /* let footer = document.getElementById('footerhtml');
    let footerContent;
    if (footer.outerHTML) footerContent = footer.outerHTML;
    else if (XMLSerializer)
      footerContent = new XMLSerializer().serializeToString(footer);
    console.log(footerContent); */
    setIsLoading(true);
    customAxios
      .post('/booking/pdf', {
        header: headercontent,
        template: content,
        headerwithline: headercontentwithLine,
        // footer: footerContent,
        style: style,
        bookingRefNo: data.bookingNo,
        logo: Logo,
      })
      .then((resp) => {
        if (resp.status == 200) {
          customAxios
            .get('/booking/getPdf', { responseType: 'blob' })
            .then((resp) => {
              const blob = new Blob([resp.data], {
                type: 'application/pdf',
                mode: 'no-cors',
              });

              // const timestamp = Date.now();
              const fileName = `Booking-${data.bookingNo}.pdf`;

              let link = document.createElement('a');
              link.download = `Booking-${bookingNo}.pdf`;
              link.href = URL.createObjectURL(blob);
              link.click();
              URL.revokeObjectURL(link.href);
              setIsLoading(false);

              customAxios
                .post('/booking/getById', {
                  id: bookingNo
                })
                .then((data) => {
                  console.log(data);
                  if (data.data.data.booking_id) {
                    const formData = new FormData();
                    formData.append('name', data.data.data.booking_id);
                    formData.append('images', blob, fileName);

                    customAxios
                      .post('/exp/upload', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      })
                      .then((uploadResponse) => {
                        console.log('File uploaded successfully!');
                        console.log('Response:', uploadResponse.data);
                      })
                      .catch((err) => console.log(err.message || 'Some error'));
                  } else {
                    console.log('PDF upload not allowed.');
                  }
                })
                .catch((err) => console.log(err.message || 'Some error'));
            })
            .catch((err) => console.log(err.message || 'Some error'));
        }
      });
  }



  useEffect(() => {

    customAxios.get('/instruction').then((resp) => {

      if (resp && resp.data && resp.data.data)
        setInstructions(resp.data.data);
    });
  }, []);

  useEffect(() => {
    customAxios.post('/customer/byName', { name: data.customer }).then((resp) => {
      if (resp && resp.data && resp.data.data)
        setCustomerDetails(resp.data.data);
      console.log(resp.data.data);


    });


  }, [data.customer]);

  const [base64Image, setBase64Image] = useState('');
  useEffect(() => {
    // Load the image and convert it to base64
    const loadImageAsBase64 = async () => {
      const response = await fetch(HeaderLogo); // Replace with your image URL
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(blob);
    };

    loadImageAsBase64();
  }, []);

  return (
    <>
      <div id="pageHeader" style={{ padding: "5px 20px 5px 20px", display: 'none' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <td colspan="4" style={{ textAlign: 'left' }}><img src={base64Image} alt="Image" style={{ height: '15%', width: '55%', verticalAlign: 'top' }} /></td>
              <td colspan="3" style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center', textAlign: 'justify', fontFamily: 'Arimo' }}>BOOKING CONFIRMATION</td>
            </tr>
          </thead>
        </table>
      </div>

      <div id="pageHeaderwithLine" style={{ padding: "5px 20px 5px 20px", display: 'none' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <td colspan="4" style={{ textAlign: 'left', borderBottom: '1px solid #C3C3C3', padding: '3px' }}><img src={base64Image} alt="Image" style={{ height: '15%', width: '55%', verticalAlign: 'top' }} /></td>
              <td colspan="3" style={{ borderBottom: '1px solid #C3C3C3', padding: '3px', fontSize: '15px', fontWeight: 'bold', textAlign: 'center', textAlign: 'justify', fontFamily: 'Arimo' }}>BOOKING CONFIRMATION</td>
            </tr>
          </thead>
        </table>
      </div>

      <div id="toPdf" style={{ padding: "5px 20px 5px 20px", display: 'none' }}>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            <tr>

              <td colspan="5" style={{ fontSize: '7px'/* , width: '50%' */, fontFamily: 'Arimo' }}>CUSTOMER&nbsp;:</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>BOOKING REF#</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>RUKEX{`${data.bookingNo}`}</td>
            </tr>
            <tr >
              <td colspan="5" rowspan="3" style={{ fontSize: '7px'/* , width: '50%' */, fontFamily: 'Arimo' }}>{`${data.customer}`}<br />{/* {`${customerDetails.address}`} */}
                {customerDetails && customerDetails.address && customerDetails.address.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>DATE</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${date}`}</td>
            </tr>

            <tr>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>FROM</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>{`${data.username}`}</td>
            </tr>
            <tr>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '26%' */, fontFamily: 'Arimo' }}>EMAIL</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${userMailId}`}</td>
            </tr>


            <tr>
              <td colspan="1" style={{ fontSize: '7px'/* , width: '30%' */, borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan="4" style={{ fontSize: '7px'/* , width: '20%' */, borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>


            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>EMAIL</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${customerDetails.mailId}`}</td>
              <td colspan="1" style={{ border: 'none', fontSize: '7px', borderTop: 'none', width: '26%', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan="1" style={{ border: 'none', borderLeft: 'none', fontSize: '7px', borderTop: 'none', width: '24%', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>
            <tr >
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>CARRIER</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.carrier}`}</td>
              <td colspan="1" style={{ border: 'none', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan="1" style={{ border: 'none', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', width: '20%', fontFamily: 'Arimo' }}>CARRIER REF#</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.liner_ref_number}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: '1px solid #C3C3C3', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>PORT OF LOADING</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.loading_port}`}</td>
            </tr>
            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>CARGO DETAILS</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.cargo}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>PORT OF DISCHARGE</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.discharge_port}`}</td>
            </tr>
            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>NO.OF.CONTAINERS</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${containers}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>FINAL PLACE OF DELIVERY</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.destination}`}</td>
            </tr>
            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>LOAD POINT</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.yard_detail}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>APPRX.WEIGHT</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${parseInt(data.weight_in_kgs_20) + parseInt(data.weight_in_kgs_40)}`}</td>
            </tr>



            <tr>
              <td colSpan={7}>&nbsp;</td>
            </tr>
          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            {charges_inc ?
              <tr style={{ border: '1px solid #C3C3C3', backgroundColor: '#F2F2F2', fontSize: '7px' }} id="parent">
                <td style={{ width: '50%', textAlign: 'center', fontFamily: 'Arimo' }}>CHARGES</td>
                <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>CURR</td>
                <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>RATE</td>
                <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>BASE</td>

              </tr>
              : ' '}


            {charges_inc ?
              <div>
                {sellingDetails.selling_rates.map((charge) => {
                  if (charge.rate) {

                    return (
                      <tr style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px' }} id="child">
                        <td style={{ width: '50%', textAlign: 'Left', fontFamily: 'Arimo' }}>{charge.charge}</td>
                        <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{charge.currency}</td>
                        <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{charge.rate}</td>
                        <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{charge.base}</td>

                      </tr>
                    );
                  }
                })}

              </div>
              : ' '}

            <tr style={{ fontSize: '7px', borderBottom: 'none' }} id="child">
              <td style={{ width: '50%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              {/* <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}>&nbsp;</td> */}
            </tr>
          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>

            <tr style={{ border: '1px solid #C3C3C3', backgroundColor: '#F2F2F2', fontSize: '7px' }} id="parent">
              <td style={{ width: '50%', textAlign: 'center', fontFamily: 'Arimo' }}>VESSEL & VOY</td>
              <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>ETD</td>
              <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>ETA</td>
              <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>VESSEL CUTOFF</td>
              {/* <td style={{ width: '10%', textAlign: 'center' }}>EX RATE</td>
              <td style={{ width: '10%', textAlign: 'center' }}>QUANTITY</td>
              <td style={{ width: '10%', textAlign: 'center', borderRight: '1px solid #C3C3C3' }}>AMOUNT({`${data.selectedValue}`})</td> */}
            </tr>


            {[data.sailing_schedule].length
              ? [data.sailing_schedule].map((sch, index) => {
                return (
                  <tr style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px' }} id="child">
                    <td style={{ width: '50%', textAlign: 'center', fontFamily: 'Arimo' }}>{sch.vessel} - {sch.voyage}</td>
                    <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{new Date((sch.etd)).toLocaleDateString('en-GB')}</td>
                    <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{new Date((sch.eta)).toLocaleDateString('en-GB')}</td>
                    <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{new Date((bookingDetails.vessel_cutoff)).toLocaleDateString('en-GB') + ' ' + ',' + ' ' + 'Time:' + ' ' + new Date((bookingDetails.vessel_cutoff)).toLocaleTimeString('en-US', { hour12: false, timeStyle: 'medium' })}</td>

                  </tr>
                );
              })
              : ''}

            <tr style={{ fontSize: '7px', borderBottom: 'none' }} id="child">
              <td style={{ width: '50%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              {/* <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}>&nbsp;</td> */}
            </tr>
          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>

            <tr style={{ border: '1px solid #C3C3C3', backgroundColor: '#F2F2F2', fontSize: '7px' }} id="parent">
              <td colspan={1} style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>S.NO</td>
              <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>LOAD POINT</td>
              <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>LOAD DATE</td>
              <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>LOAD REF</td>
              {/* <td style={{ width: '10%', textAlign: 'center' }}>EX RATE</td>
              <td style={{ width: '10%', textAlign: 'center' }}>QUANTITY</td>
              <td style={{ width: '10%', textAlign: 'center', borderRight: '1px solid #C3C3C3' }}>AMOUNT({`${data.selectedValue}`})</td> */}
            </tr>


            {allHaulage.length > 0
              ? allHaulage.map((haul, index) => {
                return (
                  <tr style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px' }} id="child">
                    <td colspan={1} style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>{index + 1}</td>
                    <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>{haul.point}</td>
                    <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>{new Date((haul.date)).toLocaleDateString('en-GB') + ' ' + ',' + ' ' + 'Time:' + ' ' + new Date((haul.date)).toLocaleString('en-US', { hour12: false, timeStyle: 'medium' })}</td>
                    <td colspan={2} style={{ width: '35%', textAlign: 'center', fontFamily: 'Arimo' }}>{haul.ref}</td>

                  </tr>
                );
              })
              : ''}


          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            <tr style={{ fontSize: '7px' }} id="child">
              <td colspan={2} style={{ width: '30%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={2} style={{ width: '30%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={2} style={{ width: '20%', textAlign: 'left', fontWeight: 'bold', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'left', borderRight: '1px solid #C3C3C3', fontWeight: 'bold', textAlign: 'center', borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>

            <tr style={{ fontSize: '7px' }} id="child">
              <td colspan={1} style={{ width: '11%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>S/I CUTOFF</td>
              <td colspan={1} style={{ width: '2%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>:</td>
              <td colspan={2} style={{ width: '30%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>{`${new Date((data.si_cutoff ? data.si_cutoff : '')).toLocaleDateString('en-GB') + ' ' + ',' + ' ' + 'Time:' + ' ' + new Date((data.si_cutoff ? data.si_cutoff : '')).toLocaleString('en-US', { hour12: false, timeStyle: 'medium' })}`}</td>
              <td colspan={1} style={{ width: '10%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>VGM CUTOFF</td>
              <td colspan={1} style={{ width: '2%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>:</td>
              <td style={{ width: '37%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>{`${new Date((data.vgm_cutoff ? data.vgm_cutoff : '')).toLocaleDateString('en-GB') + ' ' + ',' + ' ' + 'Time:' + ' ' + new Date((data.vgm_cutoff ? data.vgm_cutoff : '')).toLocaleString('en-US', { hour12: false, timeStyle: 'medium' })}`}</td>
            </tr>

            <tr style={{ fontSize: '7px' }} id="child">
              <td colspan={1} style={{ width: '11%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={1} style={{ width: '2%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={2} style={{ width: '30%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={1} style={{ width: '10%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={1} style={{ width: '2%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '37%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>

            <tr style={{ fontSize: '7px' }} id="child">
              <td colspan={1} style={{ width: '10%', textAlign: 'left', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>REMARKS</td>
              <td colspan={1} style={{ width: '0%', textAlign: 'left', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>:</td>
              <td colspan={1} style={{ width: '30%', textAlign: 'left', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>{data.remarks}</td>
              <td colspan={2} style={{ width: '14%', textAlign: 'left', fontWeight: 'bold', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '0%', textAlign: 'left', fontWeight: 'bold', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>

            <tr>
              <td colspan={6}>&nbsp;</td>
            </tr>

          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            <tr>
              <td colspan={7} style={{ borderTop: '1px solid #C3C3C3' }}></td>
            </tr>
            <tr>
              <td colspan={7}>&nbsp;</td>
            </tr>

            <tr>
              <td colspan={7} style={{ fontSize: '7px', fontFamily: 'Arimo' }}>
                <span style={{ fontWeight: 'bold', fontSize: '7px', fontFamily: 'Arimo' }}>PLEASE NOTE&nbsp;:</span><br></br>
                <ol>
                  {instructions.map((data, index) => {
                    return (
                      <li style={{ fontFamily: 'Arimo' }}>{data.instruction}</li>

                    );
                  })}
                </ol>
              </td>
            </tr>

          </tbody>

        </table>

      </div>

      <div onClick={print}>
        {isLoading ?
          <img src={Loader} width="25px" alt="" /> : 'Download PDF'
        }
      </div>
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
// import classes from './invoice.module.css';
import classes from './accountsCssCls.module.css';
import customAxios from '../../../Axios';
import Customer from '../../Master/Customer';
import { DeleteIcon, EditIcon } from '../../../Images';
import Template from './ProCreditPdf';

export default function ProfomaCreditNt() {

    const [tableData, setTableData] = useState('');
    const [formInput, setFormInput] = useState(
        {
            charge: '',
            currency: '',
            rate: '',
            amount: '',
            base: '',
            exchangeRate: ''
        }
    );

    const initDetails = {
        booking_number: '',
        //liner_ref_number: '',
        //carrier_bl: '',
        issued_at: '',
        issued_on: '',
        bl_type: '',
        //bl_by: '',
        shipper_name: '',
        shipper_address: '',
        consignee_name: '',
        consignee_address: '',
        notify_party_name: '',
        notify_party_address: '',
        also_notify_party_name: '',
        also_notify_party_address: '',
        forwarding_agent: '',
        mark_and_no: '',
        mark_description: '',
        container_details: [],
    };

    const initBooking = {
        buying_id: '',
        selling_id: '',
        liner_ref_number: '',
        quantity_20: '',
        quantity_40: '',
        house_bl: false,
        vessel: '',
        voyage: '',
        pol: '',
        etd: '',
        pod: '',
        etd: '',

    };

    const initContDetail = {
        container_no: '',
        seal_no: '',
        package_no: '',
        package_type: '',
        gross_wt: '',
        net_wt: '',
        measurement: '',
    };

    const initBuying = {
        freight: '',
        source: '',
        destination: '',
        loading_port: '',
        discharge_port: '',
        carrier: '',
        _id: '',
    };
    const handleChange = (evnt) => {
        const newInput = (data) => ({ ...data, [evnt.target.name]: evnt.target.value })
        setFormInput(newInput)
    }

    // calculation when data is added to table 
    const handleSubmit = (evnt) => {
        evnt.preventDefault();
        const checkEmptyInput = !Object.values(formInput).every(res => res === "")

        if (checkEmptyInput) {

            var data = allCharges;
            // setAllcharges([])
            var dat = { charge: newCharge, currency: newCurrency, rate: formInput.rate, base: newBase, exchangeRate: formInput.exchangeRate }
            data.push(dat);
            setAllcharges(data);



            var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
            var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;



            var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
            var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;

            allCharges.total = 0;
            {
                allCharges.forEach((data, index) => {

                    if (selectedValue === 'USD') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                    else if (selectedValue === 'GBP') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }
                    if (data.currency == 'EUR') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = data.rate * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                        else /* (data.base == '20GP' || data.base == '40GP' || data.base == '40HC' || data.base == '20OT' || data.base == '40OT') */ {
                            allCharges[index].total = data.rate * (data.base == '20GP' ? q20 : q40) * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                    }
                })
                setTotalInWords(numberToWords(allCharges.total) + ' Only');
            }

        }
        var data = [...allCharges];
        data.total = allCharges.total;
        setAllcharges(data);
        allCharges.total = data.total;
    }

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };

    let initBookingValue = {
        shipping_id: "", //shippingNo
        carrier_no: "",
        customer_name: '',
        Address: '',
        remarks: '',
        currency: '',
        Destination: '',
        shipper: '',
        Agent: 0,
        container: 0,
        cosingnee: 21000,
        MLO: 28000,
        Notify_party: '',
        CNF: '',
        total: '',
    };

    // set the data to bind the value for ref id  
    const [shippingDetails, setshippingDetails] = useState(initBookingValue);

    const handleshippingDetails = ({ key, value }) => {

        handleProfoInvInp(value)

        let tempshipping = { ...shippingDetails };
        tempshipping[key] = value;
        setshippingDetails({ ...tempshipping });
    };

    const handleBookingDetails = ({ key, value }) => {
        // 
        let temp = { ...customerdetails };

        temp[key] = value;
        setcustomerdetails({ ...temp });
    };

    const updateCustomerAddress = (val) => {
        customAxios
            .post('/customer/byName', { name: val })
            .then((resp) => {
                let res = resp.data.data;
                setcustomerdetails({ ...customerdetails, cus_name: val, cus_address: res ? res.address : '' });
            });
    }

    const handleDetails = ({ key, value }) => {
        // 
        let tempdetails = { ...details };
        console.log(tempdetails);
        tempdetails[key] = value;
        setDetails({ ...tempdetails });
    };

    const handlebookingDetails = ({ key, value }) => {
        // 
        let tempbooking = { ...bookingDetails };
        tempbooking[key] = value;
        setBookingDetails({ ...tempbooking });
    };

    const [remarks, setRemarks] = useState('');
    const [sellingNo, setSellingNo] = useState('');
    const [bookingNumbers, setBookingNumbers] = useState([]);
    const [bookingNo, setBookingNo] = useState('');
    const [constno, setconstno] = useState('');
    const [containerno, setcontainerno] = useState('');
    const [buyingDetails, setBuyingDetails] = useState(initBuying);
    const [bookingDetails, setBookingDetails] = useState(initBooking);
    const [details, setDetails] = useState(initDetails);
    const [customerdetails, setcustomerdetails] = useState([]);
    const [contDetail, setContDetail] = useState(initContDetail);
    const [shippingNo, setShippingNo] = useState('');
    const [houseBL, setHouseBL] = useState(false);
    const [cargo, setcargo] = useState();
    const [allCharges, setAllcharges] = useState([]);
    const [refNo, setrefNo] = useState();
    const [profoInvInp, setProfoInvInp] = useState(0);
    const [Total, setTotal] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [selectedValue, setSelectedValue] = useState("USD");
    const [bookingid, setBookingid] = useState('')
    const [editFunc, setEditFunc] = useState(false);
    const [amtinwords, setAmtinwords] = useState('');
    const [charges, setCharges] = useState([]);
    const [containers, setContainers] = useState([]);
    const [newCurrency, setNewCurrency] = useState();
    const [newBase, setNewBase] = useState();
    const [newCharge, setNewCharge] = useState();
    const [vendorRef, setVendorRef] = useState();
    const [creditDays, setCreditDays] = useState();
    const [creditNo, setCreditNo] = useState();
    const [cnNo, setCnNo] = useState([]);
    const [ref_no, setref_no] = useState();
    const [creditNum, setCreditNum] = useState();
    const [inputdisable, setinputdisable] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [totalInWords, setTotalInWords] = useState('');
    const fileInputRef = useRef(null);


    // calculation based on currency value and currency type
    const handleProfoInvInp = (event) => {

        setProfoInvInp(event.target ? event.target.value : event)

        let a = 0;
        var temp = allCharges;

        var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
        var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;



        // var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
        var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : 0;
        var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;
        // ;
        allCharges.total = 0;
        {
            allCharges.forEach((data, index) => {

                if (selectedValue === 'USD') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }

                else if (selectedValue === 'GBP') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }
            })



            // setAllcharges(allCharges);
        }
    }

    //Converting total amount in to a words
    const numberToWords = (number) => {
        // Define arrays for the words
        const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
        const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
        const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
        const thousands = ["", "thousand", "million", "billion"];

        // Function to convert a two-digit number to words
        const twoDigitToWords = (num) => {
            if (num === 0) return "";
            else if (num < 10) return units[num];
            else if (num < 20) return teens[num - 10];
            else {
                const ten = Math.floor(num / 10);
                const unit = num % 10;
                return tens[ten] + (unit > 0 ? ` ${units[unit]}` : "");
            }
        };

        // Function to convert a three-digit number to words
        const threeDigitToWords = (num) => {

            const hundred = Math.floor(num / 100);
            const remainder = num % 100;
            if (hundred > 0 && remainder > 0) {

                return `${units[hundred]} hundred and ${twoDigitToWords(remainder)}`;
            } else if (hundred > 0) {
                return `${units[hundred]} hundred`;
            } else {
                return twoDigitToWords(remainder);
            }
        };

        // Function to convert a number to words
        const convert = (num) => {
            if (num === 0) return "zero";
            let result = "";
            for (let i = 0; num > 0; i++) {
                const chunk = num % 1000;
                if (chunk > 0) {
                    const chunkWords = threeDigitToWords(chunk);
                    result = chunkWords + (i > 0 ? ` ${thousands[i]}` : "") + (result ? ` ${result} ` : "");
                }
                num = Math.floor(num / 1000);
            }
            return result;
        };

        return convert(number);
    };


    const editCharge = (index, value) => {
        setEditFunc(true);
        let temp = [...allCharges];
        var total = allCharges.total - allCharges[index].total;
        temp.total = total;

        setFormInput({ ...temp[index] });
        setNewCharge(value.charge);
        setNewBase(value.base);
        setNewCurrency(value.currency);
        temp.splice(index, 1);
        setAllcharges([...temp]);

        allCharges.total = temp.total
        setTotalInWords(numberToWords(allCharges.total) + ' Only');

        allCharges.splice(index, 1);
        setAllcharges(allCharges);

    };

    const deleteCharge = (index) => {
        if (window.confirm('Are you sure to delete?')) {
            let temp = [...allCharges];

            var total = allCharges.total - allCharges[index].total;
            temp.total = total;
            setFormInput({ ...temp[index] });

            temp.splice(index, 1);
            setAllcharges([...temp]);

            allCharges.total = temp.total;
            setTotalInWords(numberToWords(allCharges.total) + ' Only');

            allCharges.splice(index, 1);
            setAllcharges(allCharges);
        }
    };

    const saveCharge = ({ key, evt }) => {


        if (key == 'charge') {
            setNewCharge(evt && evt.value ? evt.value : '');
        }
        else if (key == 'base') {
            setNewBase(evt && evt.value ? evt.value : '');
        }
        else if (key == 'currency') {
            setNewCurrency(evt && evt.value ? evt.value : '');
        }
    };

    let initCharge = {
        charge: '',
        currency: '',
        rate: '',
        base: '',
    };

    const reload = () => {
        window.location.reload();
    };

    useEffect(() => {
        customAxios.get('/booking/ids').then((ids) => {
            let temp = [...ids.data.data];
            let details = temp.filter(data => data.is_closed === 0).map((data) => {
                return { label: 'RUKEX' + ' ' + data.booking_id, value: data.booking_id };
            });
            setBookingNumbers([...details]);

        });

        let currencyUrl = '/currency';
        customAxios.get(currencyUrl).then((currencies) => {
            let temp = [...currencies.data.data];
            let currenciesDetails = temp.map((data) => {
                return { label: data.code, value: data.code };
            });

            setCurrencies([...currenciesDetails]);


        });

        let creditnumberUrl = '/voucher/proformacreditnote/ids';
        customAxios.get(creditnumberUrl).then((creditNumbers) => {
            let temp = [...creditNumbers.data.data];
            let creditNos = temp.map((data) => {
                return { label: data.credit_no, value: data.credit_no };
            })
            setCnNo([...creditNos]);

        })

        customAxios.get('/container').then((containers) => {
            let temp = [...containers.data.data];
            let containersDetails = temp.map((data) => {
                return { label: data.type, value: data.type };
            });
            setContainers([...containersDetails]);
        });

        customAxios.get('/charges').then((charges) => {
            let temp = [...charges.data.data];
            let chargesDetails = temp.map((data) => {
                return { label: data.name, value: data.name };
            });
            setCharges([...chargesDetails]);
        });

        let customerurl = "/customer";
        customAxios.get(customerurl).then((customers) => {
            let temp = [...customers.data.data];
            let custo = temp.map((data) => {
                return { label: data.name, value: data.name };
            })
            setCustomerList([...custo]);
        });

    }, []);

    console.log('5656', allCharges);

    for (const iterator of allCharges) {
        console.log('777', iterator);
        iterator.exchangeRate = iterator.currency === "EUR" ? iterator.exchangeRate : iterator.currency == selectedValue ? '1' : profoInvInp;
        iterator.quantity = ((iterator.base === "20GP" || iterator.base == '20OT') ? bookingDetails.quantity_20 : bookingDetails.quantity_40);

    }


    // save and update 
    const savedata = async () => {
        if (!ref_no) {
        let temp = {
            bkg_no: bookingid,
            // to_customer_name: customerdetails.cus_name,
            current_date: date,
            to_address: customerdetails.cus_name,
            currency: selectedValue,
            curr_value: profoInvInp,
            shipper: details.shipper_name,
            destination: details.place_of_delivery,
            agent: details.forwarding_agent,
            consignee: details.consignee_name,
            mlo: bookingDetails.vessel,
            notify_party: details.notify_party_name,
            cnf: cargo,
            contatainer_no: details.container_details,
            container_count: (containerno || bookingDetails.qty_Conc),
            vendor_ref: vendorRef,
            creditdays: creditDays,
            liner_ref_number:bookingDetails.liner_ref_number,
            loading_port:bookingDetails.loading_port,
            discharge_port:bookingDetails.discharge_port,
            source: bookingDetails.source,
            carrier: bookingDetails.carrier
        }
        console.log('Temp Object:', temp);
        if (Object.values(temp).includes('')) {
            window.alert('Please fill all the mandatory fields');
        }
        
        else  {

            customAxios
                .post("/voucher/proformacreditnote/save",
                    // 
                    {
                        "bkg_no": bookingid,
                        "to_address": customerdetails.cus_name,
                        "current_date": date,
                        "Address": customerdetails.cus_address,
                        "currency": selectedValue,
                        "curr_value": profoInvInp,
                        "shipper": details.shipper_name,
                        /*  "agent": details.forwarding_agent, */
                        "destination": details.place_of_delivery,
                        "agent": details.forwarding_agent,
                        "consignee": details.consignee_name,
                        "mlo": bookingDetails.vessel,
                        "notify_party": details.notify_party_name,
                        "cnf": cargo,
                        "contatainer_no": details.container_details,
                        "container_count": containerno, // details.containerlength, // bookingDetails.qty_Conc,
                        "remarks": remarks,
                        "creditnote_charges": allCharges,
                        "total_amt": allCharges.total,
                        "is_approved": 0,
                        "vendor_ref": vendorRef,
                        "creditdays": creditDays,
                        "credit_no": creditNo,
                        "ref_no": ref_no,
                        "liner_ref_number":bookingDetails.liner_ref_number,
                        "loading_port":bookingDetails.loading_port,
                        "discharge_port":bookingDetails.discharge_port,
                        "source": bookingDetails.source,
                        "carrier": bookingDetails.carrier
                    }).then((res) => {
                        // 
                        if (res.data.error) window.alert('Unable to save the details!!');
                        else {
                            window.alert('Detail saved successfully');
                            let year = new Date().getFullYear();
                            const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
                            setconstno(profomainvoiceAutogen)

                            setref_no(res.data.count)
                            setAmtinwords(res.data.amt_in_words)

                        }
                        // if (!res.data.totalamt) window.alert('Total amount is required');
                    })
                .catch((err) =>
                    window.alert(err.message || 'Some internal error occured')
                );

            if (fileInputRef.current.files.length > 0) {
                const file = fileInputRef.current.files[0];
                const selectedBookingNo = bookingid;

                const folderName = `${selectedBookingNo}`;

                try {
                    const formData = new FormData();
                    formData.append('name', folderName);
                    formData.append('images', file);

                    const uploadResponse = await customAxios.post('/exp/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    console.log('File uploaded successfully!');
                    console.log('Response:', uploadResponse.data);
                } catch (error) {
                    console.error('Error occurred while uploading the file:', error);
                }
            }
        }

        } else {


            customAxios
                .post('voucher/proformacreditnote/update', {
                    "ref_no": ref_no,
                    // shippingDetails: temp,

                    "bkg_no": bookingid,
                    "to_customer_name": customerdetails.cus_name,
                    "current_date": date,
                    "to_address": customerdetails.cus_name,
                    "Address": customerdetails.cus_address,
                    "currency": selectedValue,
                    "curr_value": profoInvInp,
                    "destination": details.place_of_delivery,
                    "shipper": details.shipper_name,
                    "agent": details.forwarding_agent,
                    "consignee": details.consignee_name,
                    "mlo": bookingDetails.vessel,
                    "notify_party": details.notify_party_name,
                    "cnf": cargo,
                    "contatainer_no": details.container_details,
                    "container_count": bookingDetails.qty_Conc,
                    "remarks": remarks,
                    "creditnote_charges": allCharges,
                    "total_amt": allCharges.total,
                    "vendor_ref": vendorRef,
                    "creditdays": creditDays,
                    "credit_no": creditNo,
                    "amt_in_words": totalInWords
                })
                .then((data) => {


                    if (data.data.error) {
                        window.alert('Unable to update the details!!');
                        setTotalInWords(totalInWords);
                    }
                    else {
                        window.alert('Detail updated successfully');
                    }
                });
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        const selectedBookingNo = bookingid;

        const folderName = `${selectedBookingNo}`;
        window.alert('File inserted successfully');


        // try {
        //     const formData = new FormData();
        //     formData.append('name', folderName);
        //     formData.append('images', file);

        //     const uploadResponse = await customAxios.post('/exp/upload', formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     });

        //     alert('File Uploaded Successfully!');
        //     console.log('Response:', uploadResponse.data);
        // } catch (error) {
        //     alert('Error occurred while uploading the file:', error);
        // }
    };

    // Getting data by bookingno (using dropdown)
    const handleShippingNo = (selected) => {

        setBookingNo(selected ? selected.value : '');
        if (selected && selected.value) {
            handleShippingNoasync(selected.value)
            if (!selected.myValue)
                setBookingDetails({
                    ...bookingDetails,
                    booking_number: bookingNo ? bookingNo : selected.myValue,
                });
        } else {
            setBookingDetails(initBooking)
        }
    };
    async function handleShippingNoasync(val, refIdCal = 0) {

        setBookingid(val)


        if (val) {
            let name;
            let cus;
            let valueee;

            let bookingById = await customAxios
                .post('/booking/getById', { id: val })
                .then((resp) => {
                    let res = resp.data.data;
                    console.log(res);
                    setHouseBL(res.house_bl);
                    var qty = (res.quantity_20 !== 0 ? (("20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '))) : (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '));
                    setcontainerno(qty)

                    name = res;
                    if (res.buying_id) {
                        setBookingDetails({
                            port_of_discharge: res.port_of_discharge,
                            buying_id: res.buying_id,
                            selling_id: res.selling_id,
                            liner_ref_number: res.liner_ref_number,
                            quantity_20: res.quantity_20,
                            quantity_40: res.quantity_40,
                            qty_Conc: qty,
                            house_bl: res.house_bl,
                            vessel: res.sailing_schedule.vessel,
                            voyage: res.sailing_schedule.voyage,
                            pol: res.sailing_schedule.pol,
                            eta: res.sailing_schedule.eta,
                            pod: res.sailing_schedule.pod,
                            etd: res.sailing_schedule.etd,
                            remarks: res.remarks,
                            yard_detail: res.yard_detail,
                            liner_ref_number: res.liner_ref_number,
                            loading_port: res.loading_port,
                            discharge_port: res.discharge_port,
                            source: res.source,
                            carrier:res.carrier,
                        });
                        console.log(bookingDetails)
                    }

                    else {
                        setBookingDetails(initBooking);
                    }
                });

            await customAxios
                .post('/selling/getById', { id: name.selling_id }).then((ress) => {
                    setcargo(ress.data.data.cargo)
                    cus = ress.data.data.customer;

                });

            await customAxios
                .post('/customer/byName', { name: cus }).then((respon) => {

                    valueee = respon.data.data;

                    if (valueee == null) {
                        window.alert('BKG ID not found');
                        return;
                    }
                    else if (valueee) {
                        setcustomerdetails({
                            cus_name: valueee.name,
                            cus_address: valueee.address,
                        });

                    } else {
                        setcustomerdetails({
                            cus_name: '',
                            cus_address: '',

                        });
                    }
                });

            await customAxios
                .post('/shipping/getById', { id: val })
                .then((resp) => {
                    let res = resp.data.data;

                    let a = "";
                    if (res.container_details && res.container_details.length > 0) {
                        for (let i = 0; i < res.container_details.length; i++) {
                            a += i == 0 ? "" + res.container_details[i].container_no : " , " + res.container_details[i].container_no
                        }
                    }

                    if (res.shipping_id) {
                        setShippingNo(res.shipping_id);
                        setDetails({
                            booking_number: res.booking_number,

                            bl_type: res.bl_type,
                            // bl_by: res.bl_by,
                            shipper_name: res.shipper_name,
                            shipper_address: res.shipper_address,
                            consignee_name: res.consignee_name,
                            consignee_address: res.consignee_address,
                            notify_party_name: res.notify_party_name,
                            notify_party_address: res.notify_party_address,
                            also_notify_party_name: res.also_notify_party_name,
                            also_notify_party_address: res.also_notify_party_address,
                            forwarding_agent: res.forwarding_agent,
                            mark_and_no: res.mark_and_no,
                            mark_description: res.mark_description,
                            container_details: a,
                            containerlength: res.container_details.length,
                            place_of_delivery: res.place_of_delivery
                        });
                    }

                    else {
                        setShippingNo('');
                        setDetails({
                            booking_number: " ",
                            //liner_ref_number: '',
                            //carrier_bl: '',
                            issued_at: " ",
                            issued_on: "",
                            bl_type: "",
                            // bl_by: res.bl_by,
                            shipper_name: "",
                            shipper_address: "",
                            consignee_name: "",
                            consignee_address: "",
                            notify_party_name: "",
                            notify_party_address: "",
                            also_notify_party_name: "",
                            also_notify_party_address: "",
                            forwarding_agent: "",
                            mark_and_no: "",
                            mark_description: "",
                            container_details: '',
                            containerlength: ""
                        });
                    }
                });

            if (refIdCal === 0) {
                await customAxios
                    .post('/selling/getById', { id: name.selling_id }).then((resstt) => {

                        //while choosing a booking number first which means before saving, this part will be disable
                        // .
                        // .
                        // setAllcharges(resstt.data.data.buying_rates)
                        // .
                        // .

                    });
            }
            // await customAxios
            //     .post('/booking/getById', { id: val })
            //     .then((resp) => {
            //         let res = resp.data.data;

            //         setHouseBL(res.house_bl);
            //         var qty = (res.quantity_20 !== 0 ? (("20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '))) : (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '));
            //         setcontainerno(qty)
            //         if (res.buying_id) {
            //             setBookingDetails({
            //                 port_of_discharge: res.port_of_discharge,
            //                 buying_id: res.buying_id,
            //                 selling_id: res.selling_id,
            //                 liner_ref_number: res.liner_ref_number,
            //                 quantity_20: res.quantity_20,
            //                 quantity_40: res.quantity_40,
            //                 qty_Conc: qty,
            //                 house_bl: res.house_bl,
            //                 vessel: res.sailing_schedule.vessel,
            //                 voyage: res.sailing_schedule.voyage,
            //                 pol: res.sailing_schedule.pol,
            //                 eta: res.sailing_schedule.eta,
            //                 pod: res.sailing_schedule.pod,
            //                 etd: res.sailing_schedule.etd,
            //                 remarks: res.remarks,
            //                 yard_detail: res.yard_detail,
            //             });

            //         } else {
            //             setBookingDetails(initBooking);
            //         }
            //     });

            setContDetail(initContDetail);

        } else {
            setShippingNo('');
            setBookingDetails(initBooking);
            setDetails(initDetails);
            setContDetail(initContDetail);
            setHouseBL(false);
        }
    };

    
    useEffect(() => {
        console.log('bookig details', bookingDetails);
    }, [bookingDetails]);

    const handleSelectChange = (event) => {

        if (event != null) {
            setSelectedValue(event.value ? event.value : event)


            var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
            var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;



            // var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
            var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : 0;
            var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;
            // ;
            allCharges.total = 0;
            {
                allCharges.forEach((data, index) => {

                    if (event.value === 'USD') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                    else if (event.value === 'GBP') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                })
            }
        }
        else {
            setSelectedValue(null);
        }

    };


    const handleref_no = (evt) => {
        setref_no(evt.target.value);
        let year = new Date().getFullYear();
        const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
        // 
        setconstno(profomainvoiceAutogen)
    };


    // Getting data by credit no 
    async function getcreditDetail(creditNum) {
        setCreditNum(creditNum);
        let getbkngid;
        let creditIds =

            await customAxios.post('/voucher/proformacreditnoteid/getbyID', { credit_no: creditNum }).then((data) => {

                let result = data.data;
                console.log(result);

                setBookingNo(result.data.bkg_no);
                setProfoInvInp(result.data.curr_value);
                setRemarks(result.data.remarks);
                setcargo(result.data.cnf);
                setSelectedValue(result.data.currency);
                setAmtinwords(result.data.amt_in_words)
                setVendorRef(result.data.vendor_ref);
                setCreditDays(result.data.creditdays);
                setCreditNo(result.data.credit_no);
                setref_no(result.data.ref_no);

                var invoice = result.data.creditnote_charges;

                invoice.total = result.data.total_amt;
                getbkngid = result.data.bkg_no;

                setAllcharges(invoice)


                const containercount = "20GP:" + ' ' + result.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40GP/HC:" + ' ' + result.quantity_40;


                let {

                    to_address,
                    agent,
                    Address,
                    bkg_no,
                    liner_ref_number,
                    cnf,
                    consignee,
                    container_count,
                    contatainer_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    curr_value,
                    notify_party,
                    ref_no,
                    remarks,
                    shipper,
                    to_customer_name,
                    total_amt,
                    creditnote_charges,
                    amt_in_words,
                    vendor_ref,
                    creditdays,
                    credit_no,
                } = result.data;
                console.log(result.data);

                let temp = {
                    ...customerdetails,
                    cus_name: to_address,
                    cus_address: Address,

                };

                setcustomerdetails({ ...temp });
                console.log(customerdetails);

                let tempbooking = {
                    ...bookingDetails,
                    liner_ref_number: liner_ref_number,
                    vessel: mlo,
                    qty_Conc: container_count,
                }

                setBookingDetails({ ...tempbooking });

                let tempdetails = {
                    ...details,
                    place_of_delivery: destination,
                    shipper_name: shipper,
                    consignee_name: consignee,
                    notify_party_name: notify_party,
                    container_details: contatainer_no,
                    forwarding_agent: agent,
                    // remarks: remarks,
                }
                setDetails({ ...tempdetails });
                let year = new Date().getFullYear();
                const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
                setconstno(profomainvoiceAutogen)
                // }
            });


        setBookingid(getbkngid);
        let bkng = await customAxios.post('/booking/getById', { id: getbkngid }).then((resp) => {
            let res = resp.data.data;

            getbkngid = res;
            // 
            var qty = (res.quantity_20 !== 0 ? (("20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '))) : (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '));
            setcontainerno(qty)
            setHouseBL(res.house_bl);
            if (res.buying_id) {
                setBookingDetails({
                    port_of_discharge: res.port_of_discharge,
                    buying_id: res.buying_id,
                    selling_id: res.selling_id,
                    liner_ref_number: res.liner_ref_number,
                    quantity_20: res.quantity_20,
                    quantity_40: res.quantity_40,
                    qty_Conc: qty,
                    house_bl: res.house_bl,
                    vessel: res.sailing_schedule.vessel,
                    voyage: res.sailing_schedule.voyage,
                    pol: res.sailing_schedule.pol,
                    eta: res.sailing_schedule.eta,
                    pod: res.sailing_schedule.pod,
                    etd: res.sailing_schedule.etd,
                    remarks: res.remarks,
                    yard_detail: res.yard_detail,
                });

            } else {
                // alert("sss");
                setBookingDetails(initBooking);
            }
        });


        // setBookingid(val)
    };

    // Getting data by ref id 
    async function getinvoiceDetail(evt) {
        let getbkngid;
        if ((evt.key === 'Enter' || evt.keyCode === 13) && ref_no) {
            await customAxios.post('/voucher/proformacreditnote/getbyID', { ref_no: ref_no }).then((data) => {

                let result = data.data;

                if (Object.keys(result.data || {}).length == 0) {
                    window.alert('Ref-ID not found');
                    return;
                }


                if (result.data.is_approved == 1) {
                    setinputdisable(true);
                    window.alert('Invoice Issued!!');
                    document.getElementById('myButton').disabled = true;
                } else {
                    setinputdisable(false);
                }

                setBookingNo(result.data.bkg_no);
                setProfoInvInp(result.data.curr_value);
                setRemarks(result.data.remarks);
                setcargo(result.data.cnf);
                setSelectedValue(result.data.currency);
                setAmtinwords(result.data.amt_in_words)
                setVendorRef(result.data.vendor_ref);
                setCreditDays(result.data.creditdays);
                setCreditNo(result.data.credit_no);

                var invoice = result.data.creditnote_charges;

                invoice.total = result.data.total_amt;
                getbkngid = result.data.bkg_no;

                setAllcharges(invoice)


                const containercount = "20'Ft:" + ' ' + result.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40'Ft:" + ' ' + result.quantity_40;


                let {

                    to_address,
                    agent,
                    bkg_no,
                    liner_ref_number,
                    cnf,
                    consignee,
                    container_count,
                    contatainer_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    curr_value,
                    notify_party,
                    ref_no,
                    remarks,
                    shipper,
                    to_customer_name,
                    total_amt,
                    creditnote_charges,
                    amt_in_words,
                    vendor_ref,
                    creditdays,
                    credit_no,
                    Address,
                } = result.data;

                let temp = {
                    ...customerdetails,
                    cus_address: Address,
                    cus_name: to_address,

                };

                setcustomerdetails({ ...temp });

                let tempbooking = {
                    ...bookingDetails,
                    liner_ref_number: liner_ref_number,
                    vessel: mlo,
                    qty_Conc: container_count,
                }

                setBookingDetails({ ...tempbooking });

                let tempdetails = {
                    ...details,
                    place_of_delivery: destination,
                    shipper_name: shipper,
                    consignee_name: consignee,
                    notify_party_name: notify_party,
                    container_details: contatainer_no,
                    forwarding_agent: agent,
                    // remarks: remarks,
                }
                setDetails({ ...tempdetails });
                let year = new Date().getFullYear();
                const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
                setconstno(profomainvoiceAutogen)

            });


            setBookingid(getbkngid);
            let bkng = await customAxios.post('/booking/getById', { id: getbkngid }).then((resp) => {
                let res = resp.data.data;

                getbkngid = res;
                // 
                var qty = (res.quantity_20 !== 0 ? (("20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '))) : (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '));
                setcontainerno(qty)

                setHouseBL(res.house_bl);
                if (res.buying_id) {
                    setBookingDetails({
                        port_of_discharge: res.port_of_discharge,
                        buying_id: res.buying_id,
                        selling_id: res.selling_id,
                        liner_ref_number: res.liner_ref_number,
                        quantity_20: res.quantity_20,
                        quantity_40: res.quantity_40,
                        qty_Conc: qty,
                        house_bl: res.house_bl,
                        vessel: res.sailing_schedule.vessel,
                        voyage: res.sailing_schedule.voyage,
                        pol: res.sailing_schedule.pol,
                        eta: res.sailing_schedule.eta,
                        pod: res.sailing_schedule.pod,
                        etd: res.sailing_schedule.etd,
                        remarks: res.remarks,
                        yard_detail: res.yard_detail,
                    });

                } else {
                    // alert("sss");
                    setBookingDetails(initBooking);
                }
            });
        }
        else if (evt.key === 'Enter' || evt.keyCode === 13) confirmCancel();

        // setBookingid(val)
    };

    const cancelDetails = () => {
        if (window.confirm('Are you sure to cancel?')) confirmCancel();
    };
    const confirmCancel = () => {
        setAllcharges([]);
        setBuyingDetails(initBuying);
        setBookingDetails(initBooking);
        setrefNo('');
        setSellingNo('');
        setBookingNo('');

    };
    return (
        <>
            <div className={classes.container}>
                <h5>Proforma Credit Note <span className={classes.title}>Export / Proforma CreditNote</span></h5>
            </div>

            <Container className={classes.inputsContainer}>

                <table style={{ width: '100%' }}>
                    <tbody><tr>
                        <td colSpan={1}><span>BKG.No#*</span></td>
                        <td colSpan={1} style={{ width: '100px', }}>
                            <section>
                                <div style={{ position: 'relative', width: '150px' }}>

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={bookingNumbers}
                                        // value={currencies.value}
                                        value={bookingNumbers.filter(
                                            (option) => option.value === bookingNo
                                        )}
                                        // selectedValue={selectedValue}
                                        onChange={(selected) => { handleShippingNo(selected) }}
                                        isDisabled={creditNum || inputdisable}
                                    />
                                </div>


                            </section>
                        </td>
                        <td colSpan={1}><span style={{ paddingLeft: '35px' }}>BL#*</span></td>
                        <td colSpan={1}><input className={classes.inputText} type="text" value={bookingDetails.liner_ref_number} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })}  disabled={creditNum || inputdisable} /></td>

                        <td colSpan={1} style={{ width: '10px', paddingLeft: '35px' }}><span>CN#*</span></td>
                        <td colSpan={1}><Select
                            components={{ IndicatorSeparator, Placeholder }}
                            styles={style}
                            isClearable={true}
                            isSearchable={true}
                            options={cnNo}
                            // value={cnNo.value}
                            value={cnNo.filter(
                                (option) => {
                                    return option.value === creditNum
                                }
                            )}

                            // selectedValue={selectedValue}
                            onChange={(selected) => {
                                const selectedValue = selected ? selected.value : null;
                                getcreditDetail(selectedValue);
                            }}

                        />
                        </td>
                        {/* disabled={true} */}
                        <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                        <td colSpan={1}><input className={classes.inputText} type="text" value={constno} /* disabled={creditNum} */ disabled={creditNum || inputdisable} /></td>
                        <td colSpan={1}><input className={classes.inputText} type="text"
                            value={ref_no}
                            onChange={(evt) => handleref_no(evt)}

                            onKeyUp={(evt) => getinvoiceDetail(evt)} /* disabled={creditNum} */ disabled={creditNum || inputdisable} /></td>

                    </tr>
                        <tr>
                            <td>To<span>*</span></td>
                            <td colSpan={5}>
                                {creditNum || inputdisable ? (<input
                                    className={classes.inputText}
                                    value={customerdetails.cus_name}
                                    type="text"
                                    disabled
                                />) : (<Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={customerList.find((customer) => customer.value === customerdetails.cus_name)}
                                    options={customerList}
                                    onChange={(selected) => {
                                        handleBookingDetails({
                                            key: 'cus_name',
                                            value: selected ? selected.value : '',
                                        }); updateCustomerAddress(selected ? selected.value : '')
                                    }} />)}
                            </td>

                            <td style={{ paddingLeft: '35px' }}><span>MBL#</span></td>
                            <td colSpan={2}><input className={classes.inputText} value={date} type="text" disabled={creditNum || inputdisable} /></td>
                        </tr>
                        <tr>
                            <td colSpan={1} >Address</td>
                            <td colSpan={5}><input className={classes.inputText} value={customerdetails.cus_address} onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} type="text" disabled={creditNum || inputdisable} /></td>
                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                            <td colSpan={1}>

                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    // value={currencies.value}
                                    value={currencies.filter(
                                        (option) => option.value === selectedValue
                                    )}
                                    // selectedValue={selectedValue}
                                    onChange={(e) => {
                                        handleSelectChange(e)
                                        // 
                                    }}
                                    isDisabled={creditNum || inputdisable}

                                />
                            </td>
                            <td colSpan={1}><input className={classes.inputText} value={profoInvInp} onChange={(e) => { handleProfoInvInp(e) }} type="text" disabled={creditNum || inputdisable} /* disabled={inputdisable ? true : false} */ /></td>
                        </tr>

                        <tr>
                            <td colSpan={1} >Destination</td>
                            <td colSpan={5}><input className={classes.inputText} value={details.place_of_delivery} onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} type="text" disabled={creditNum || inputdisable} /></td>


                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                            <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Vendor Ref#</span></td>
                            {/* <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Credit Days</span></td> */}

                            {/* <td colSpan={2} rowSpan={2}><textarea style={{ height: '100px'}} value={bookingDetails.qty_Conc} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} /></td> */}
                        </tr>
                        <tr>
                            <td colSpan={1}>Shipper</td>
                            <td colSpan={2}><input className={classes.inputText} value={details.shipper_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'shipper_name',
                                    value: evt.target.value,
                                })} type="text" disabled={creditNum || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                            <td colSpan={2}><input className={classes.inputText} value={details.forwarding_agent} onChange={(evt) => {

                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })
                            }} type="text" disabled={creditNum || inputdisable} /></td>


                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                            <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} value={vendorRef} onChange={(e) => setVendorRef(e.target.value)} type="text" disabled={creditNum || inputdisable} /></td>
                            {/* <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} value={creditDays} onChange={(e) => setCreditDays(e.target.value)} type="text" disabled={creditNum || inputdisable} /></td> */}

                            <td style={{ padding: '0px 0px 17px 3%', textTransform: 'uppercase' }}>
                                <input type="file" ref={fileInputRef} id="fileInput" accept=".pdf" style={{ display: 'none' }} onChange={handleFileChange} disabled={creditNum || inputdisable} />

                                <button className={classes.footerButton} style={{ textTransform: 'uppercase', paddingBottom: '8px', width: '100px' }} type="button"
                                    onClick={() => fileInputRef.current.click()} disabled={creditNum || inputdisable} > Upload
                                </button>

                            </td>

                        </tr>
                        <tr>
                            <td colSpan={1}>Cosingnee</td>
                            <td colSpan={2}><input className={classes.inputText} value={details.consignee_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'consignee_name',
                                    value: evt.target.value,
                                })} type="text" disabled={creditNum || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                            <td colSpan={2}><input className={classes.inputText} value={bookingDetails.vessel} onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} type="text" disabled={creditNum || inputdisable} /></td>


                            <td colSpan={1} style={{ paddingLeft: '35px', }}></td>
                            <td colSpan={2} rowspan={2}><textarea style={{ height: '100px', marginTop: '-18px', marginBottom: '20px' }} value={bookingDetails.qty_Conc} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} placeholder='CONTAINER COUNT' disabled={creditNum || inputdisable} />

                                {/* <td colSpan={1} style={{ paddingLeft: '35px', }}></td> */}

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1}>Notify Party</td>
                            <td colSpan={2}><input className={classes.inputText} value={details.notify_party_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} type="text" disabled={creditNum || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                            <td colSpan={2}><input className={classes.inputText} value={cargo} type="text" onChange={(e) => setcargo(e.target.value)} disabled={creditNum || inputdisable} /></td>

                        </tr>

                        <tr>

                            <td colSpan={1}>Remarks</td>
                            <td colSpan={5} rowspan={2}><textarea style={{ height: '100px', marginTop: '-42px' }} value={remarks} onChange={(e) => setRemarks(e.target.value)} disabled={creditNum || inputdisable} /></td>
                            {/* <td colSpan={3} rowspan={2}><textarea style={{ height: '100px' }} value={remarks}  /></td> */}
                            <td colSpan={1} style={{ paddingLeft: '35px', }}></td>
                            <td colspan={2} rowspan={2}><textarea style={{ height: '118px', marginTop: '-20px' }} value={details.container_details} onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} placeholder='CONTAINER NO' disabled={creditNum || inputdisable} /></td>


                        </tr>

                        <br></br>
                        <br></br>
                        <tr><td colSpan="9">
                            <hr></hr>
                        </td></tr>

                        <tr>
                            <td style={{ padding: '10px' }} colSpan={2}>Charge Description</td>
                            <td style={{ padding: '10px' }} colSpan={2}>Curr</td>
                            <td style={{ padding: '10px' }} colSpan={1}>Rate</td>
                            <td style={{ padding: '10px' }} colSpan={1}>Base</td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px' }} colSpan={1}>Exchange Rate</td>
                                : null}
                        </tr>

                        <tr>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={charges}
                                    value={charges.filter(
                                        (option) => option.value === newCharge
                                    )}
                                    onChange={(e) => saveCharge({ key: 'charge', evt: e })}
                                    isDisabled={creditNum || inputdisable} sDisabled={creditNum ? true : false}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    value={currencies.filter(
                                        (option) => option.value === newCurrency
                                    )}
                                    onChange={(e) => saveCharge({ key: 'currency', evt: e })}
                                    isDisabled={creditNum || inputdisable}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase',width:'130px' }} colSpan={1}><input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="text" name="rate" value={formInput.rate} onChange={handleChange} disabled={creditNum || inputdisable}></input></td>
                            <td style={{ padding: '10px', textTransform: 'uppercase', width:'134px' }} colSpan={1}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    name='base'
                                    options={containers}
                                    value={containers.filter(
                                        (option) => option.value === newBase
                                    )}
                                    onChange={(e) => saveCharge({ key: 'base', evt: e })}
                                    isDisabled={creditNum || inputdisable}
                                />
                            </td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                    <input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="number" name="exchangeRate" value={formInput.exchangeRate} onChange={handleChange} disabled={creditNum || inputdisable}>
                                    </input></td> : null}
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={1}><button className={classes.footerButton} style={{ textTransform: 'uppercase' }} type="submit" onClick={handleSubmit} disabled={creditNum || inputdisable}>Add</button></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                    <table style={{ width: '100%' }}>
                        <thead className={classes.theadDark}>
                            <tr>
                                <th>charges</th>
                                <th>Curr</th>
                                <th>Rate</th>
                                <th>Base</th>
                                <th>Exchange Rate</th>
                                <th>No.Of.Containers</th>
                                <th>Amount</th>
                                <th>Action</th>


                            </tr>
                        </thead>

                        <tbody>
                            {allCharges ? <>
                                {allCharges.map((data, index) => (
                                    <tr>
                                        <td>{data.charge}</td>
                                        <td style={{ textAlign: 'center' }}>{data.currency}</td>
                                        <td style={{ textAlign: 'center' }}>{data.rate ? parseFloat(data.rate).toFixed(2) : 0}</td>
                                        <td style={{ textAlign: 'center' }}>{data.base}</td>
                                        {/* {data.currency == 'EUR' ? <td style={{ textAlign: 'center' }}>{data.exchangeRate}</td> : <td style={{ textAlign: 'center' }}>{profoInvInp}</td>} */}
                                        {data.currency == 'EUR' ? <td style={{ textAlign: 'center' }}>{data.exchangeRate}</td> : <td style={{ textAlign: 'center' }}>{data.currency == selectedValue ? '1' : profoInvInp}</td>}
                                        {(data.base == '20GP' || data.base == '20OT') ? <td style={{ textAlign: 'center' }}>{bookingDetails.quantity_20}</td> : <td style={{ textAlign: 'center' }}>{bookingDetails.quantity_40}</td>}
                                        {data.total > 0 ? <td style={{ textAlign: 'center' }}>{parseFloat(data.total).toFixed(2)}</td> : <td style={{ textAlign: 'center' }}>0</td>}
                                        {/* <td style={{display: 'none'}}>{calculateAmount(data, index)}</td> */}
                                        <td style={{ textAlign: 'center' }}>
                                            <img
                                                src={EditIcon}
                                                className={classes.icon}
                                                alt='edit_icon'
                                                // style={{ borderRadius: '50%' }}
                                                onClick={() => editCharge(index, data)}
                                                style={{ pointerEvents: creditNum || inputdisable ? 'none' : 'auto', opacity: creditNum || inputdisable ? 0.5 : 1 }}
                                            />
                                            &nbsp;&nbsp;
                                            <img
                                                src={DeleteIcon}
                                                className={classes.icon}

                                                onClick={() => deleteCharge(index)}
                                                style={{ pointerEvents: creditNum || inputdisable ? 'none' : 'auto', opacity: creditNum || inputdisable ? 0.5 : 1 }}
                                            />
                                        </td>
                                    </tr>
                                )
                                )} </>
                                : null}
                        </tbody>

                    </table>

                </div>
                <div>
                    <table>
                        <tr>
                            <td colSpan={5} style={{ width: '50%' }}></td>
                            <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                            <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoInvInp ? parseFloat(allCharges.total).toFixed(2) : 0} onChange={(e) => { handleshippingDetails({ key: 'total', value: e.target.checked }) }} disabled={creditNum || inputdisable} /></td>
                        </tr>

                        <tr>
                            <td colSpan={5} />
                            <td colSpan={4}>
                                <button id="myButton" className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={savedata} disabled={creditNum || inputdisable}>{ref_no ? 'Update' : 'Save'} </button>
                                {/* <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }}>Cancel</button> */}
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }}>
                                    {<Template

                                        buyingDetails={buyingDetails}
                                        bookingDetails={bookingDetails}
                                        bookingNo={bookingNo}
                                        details={details}
                                        allCharges={allCharges}
                                        customerdetails={customerdetails}
                                        remarks={remarks}
                                        cargo={cargo}
                                        date={date}
                                        creditDays={creditDays}
                                        vendorRef={vendorRef}
                                        // debitnote={debitnote}
                                        creditNo={creditNo}
                                        shippingDetails={shippingDetails}
                                        selectedValue={selectedValue}
                                        ref_no={ref_no}
                                        amtinwords={amtinwords}
                                        credit_No={creditNo}
                                        totalInWords={totalInWords}
                                    />}
                                </button>
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={reload}>New</button>
                            </td>
                        </tr>
                    </table>
                </div>

            </Container>
        </>
    )
}
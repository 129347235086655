import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import customAxios from '../Axios';
import { Logo } from '../Images';
import classes from './AdminLogin.module.css';


export default function AdminLogin() {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  let navigate = useNavigate();

  const LoginCheck = () => {
    
    let url = '/admin/login';
    customAxios
      .post(url, { username: name, password: password })
      .then((resp) => {
        
        if (resp.data.error) {
          setError(resp.data.message);
        } else {
          localStorage.setItem('access_token', resp.data.token);
          localStorage.setItem('userId', resp.data.userid);
          localStorage.setItem('name', resp.data.name);
          localStorage.setItem('roll', resp.data.roll);
          localStorage.setItem('role', resp.data.role);
          localStorage.setItem('userMail', resp.data.mail);
          return navigate('/admin/dashboard/master/home');
        }
      },[name]);
    // return navigate('/admin/dashboard/master/home');
  };

  return (
    <div className={classes.LoginPage}>
      <div className={classes.content}>
        <div className={classes.LogoContainer}>
          <img className={classes.Logo} src={Logo} alt='Logo' />
        </div>
        <h4 className={classes.adminLogin}>ADMIN LOGIN</h4>
        <div className={classes.dummy}>
          <div>
            <input
              className={classes.inputField}
              type='text'
              value={name}
              placeholder='Username'
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <div>
            <input
              className={classes.inputField}
              type='password'
              value={password}
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={classes.errorMessage}>{error ? error : ''}</div>
          <div className={classes.checkBoxContainer}>
            <input type='checkbox' className={classes.checkBox} />
            Remember me
          </div>
          <button className={classes.signinButton} onClick={LoginCheck}>
            Sign In
          </button>
        </div>
        <footer>Forgot Password</footer>
      </div>
    </div>
  );
}

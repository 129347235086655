import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';
import AdminLogin from './Components/AdminLogin';
import AdminDashboard from './Components/AdminDashboard';
import './App.css';

// Masters
import Home from './Components/Master/Home';
import Customer from './Components/Master/Customer';
import Charges from './Components/Master/Charges';
import Vessel from './Components/Master/Vessel';
import Port from './Components/Master/Port';
import Containers from './Components/Master/Container';
import Cargo from './Components/Master/Cargo';
import Company from './Components/Master/Company';
import Employee from './Components/Master/Employee';
import Department from './Components/Master/Department';
import Designation from './Components/Master/Designation';
import Currency from './Components/Master/Currency';
import Package from './Components/Master/Package';
import Instructions from './Components/Master/Instructions';
import InvoiceEOE from './Components/Master/invoiceEOE';
import BankAccDetails from './Components/Master/BankAccDetails';

// Export Sales
import Buyer from './Components/Exports/Sales/Buyer';
import Selling from './Components/Exports/Sales/Selling';
import Booking from './Components/Exports/Sales/Booking';
import Approval from './Components/Exports/Sales/Approval';
import Approved from './Components/Exports/Sales/Approved';

// Export SI 
import BillOfLading from './Components/Exports/Shipping/BillOfLading';

// Export Invoice
import ProformaInvoice from './Components/Exports/Invoice/ProformaInvoice';
import ProformaInvoicepdf from './Components/Exports/Invoice/proformainvoicepdf';
import ProfomaApproval from './Components/Exports/Invoice/ProfomaApproval';
import ApprvdInvoice from './Components/Exports/Invoice/ApprvdInvoice';
import ProfomaApprovedList from './Components/Exports/Invoice/ProfomaApprovedList';
import ProfomaCNOPS from './Components/Exports/Invoice/ProfomaCNOPS';
import ApprovalCN from './Components/Exports/Invoice/ApprovalCN';
import ApprvdCnOps from './Components/Exports/Invoice/ApprvdCnOps';
// import Upload_View from './Components/Exports/ExpAttachment/Upload_View';

// Export Accounts 
import Invoice from './Components/Exports/Accounts/Invoice';
import CnOps from './Components/Exports/Accounts/CnOps';
import ProfomaCreditNt from './Components/Exports/Accounts/ProfomaCreditNt';
import ProfomaDebitNt from './Components/Exports/Accounts/ProfomaDebitNt';
import DebitNote from './Components/Exports/Accounts/DebitNote';
import CreditNote from './Components/Exports/Accounts/CreditNote';
import ProDebitApproval from './Components/Exports/Accounts/ProDebitApproval';
import ProCreditApproval from './Components/Exports/Accounts/ProCreditApproval';
import ReceiptProfoCreditNt from './Components/Exports/Accounts/ReceiptProfoCreditNt';
import ReceiptProfoDebitNt from './Components/Exports/Accounts/ReceiptProfoDebitNt';

//  Export Job close & Enquiry 
import Jobclose from './Components/Exports/Jobclose';
import JobEnquiry from './Components/Exports/JobEnquiry';

// Import Sales 
import ImpBuyer from './Components/Imports/Sales/Buyer';
import ImpSelling from './Components/Imports/Sales/Selling';
import ImpApproval from './Components/Imports/Sales/Approval';
import ImpBooking from './Components/Imports/Sales/Booking';
import Impsellingapproved from './Components/Imports/Sales/Impsellingapproved';

// Import SI 
import ImpBillOfLading from './Components/Imports/Shipping/BillOfLading';

// Import Invoice  
import ImpProformaInvoice from './Components/Imports/Invoice/ProformaInvoice';
import ImpProformaInvoicepdf from './Components/Imports/Invoice/proformainvoicepdf';
import ImpProfomaApproval from './Components/Imports/Invoice/ProfomaApproval';
import ImpProCreditpdf from './Components/Imports/Invoice/ProCreditpdf';
import ImpProfomaCNOPS from './Components/Imports/Invoice/ProfomaCNOPS';
import ImpProfomaApprovedList from './Components/Imports/Invoice/ProfomaApprovedList';
import ImpApprovalCN from './Components/Imports/Invoice/ApprovalCN';
import ImpApprvdInv from './Components/Imports/Invoice/ApprvdInv';
import ImpApprvdCno from './Components/Imports/Invoice/ApprvdCno';

//  import Accounts 
import ImpInvoice from './Components/Imports/Accounts/Invoice';
import ImpCnOps from './Components/Imports/Accounts/CnOps';
import ImpProfomaCreditNt from './Components/Imports/Accounts/ProfomaCreditNt';
import ImpProfomaDebitNt from './Components/Imports/Accounts/ProfomaDebitNt';
import ImpDebitNote from './Components/Imports/Accounts/DebitNote';
import ImpProDebitApproval from './Components/Imports/Accounts/ProDebitApproval';
import ImpProDebitNTReceipt from './Components/Imports/Accounts/ProDebitNTReceipt';
import ImpCreditNote from './Components/Imports/Accounts/CreditNote'
import ImpProCreditApproval from './Components/Imports/Accounts/ProCreditApproval'
import ImpReceiptProfoCreditNt from './Components/Imports/Accounts/ReceiptProfoCreditNt'

// Import JOb Close & Enquiry 
import ImpJobclose from './Components/Imports/Jobclose';
import ImpJobEnquiry from './Components/Imports/JobEnquiry';

//  Document Attachment 
import ExpUpload from './Components/DocAttachment/ExpUpload';
import ImpUpload from './Components/DocAttachment/ImpUpload';

// Export Reports 
import Buyingreport from './Components/Reports/Exports/Buyingreport';
import Sellingreport from './Components/Reports/Exports/Sellingreport';
import BookingReport from './Components/Reports/Exports/BookingReport';
import InvoiceReport from './Components/Reports/Exports/InvoiceReport';
import CnopsReport from './Components/Reports/Exports/CnopsReport';
import CreditReport from './Components/Reports/Exports/CreditReport';
import DebitReport from './Components/Reports/Exports/DebitReport';

// Import Reports 
import BuyerReport from './Components/Reports/Imports/buyerReport';
import SellingReport from './Components/Reports/Imports/SellingReport';
import ImpBookingReport from './Components/Reports/Imports/BookingReport';
import ImpInvoiceReport from './Components/Reports/Imports/InvoiceReport';
import ImpCnopsReport from './Components/Reports/Imports/CnopsReport';
import ImpCreditReport from './Components/Reports/Imports/CreditReport';
import ImpDebitReport from './Components/Reports/Imports/DebitReport';

// PL Report
import JobCloseReport from './Components/Reports/P&L/Exports/JobcloseReport'
import JobUnCloseReport from './Components/Reports/P&L/Exports/jobUncloseReport'
import ImpJobCloseReport from './Components/Reports/P&L/Imports/JobcloseReport'
import ImpJobUnCloseReport from './Components/Reports/P&L/Imports/JobUncloseReport'



// Maintenace
import Addusers from './Components/Maintenance/Addusers';


function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route
            path='/'
            element={<NavLink to='/admin/login'>Admin Login</NavLink>}
          />
          <Route path='admin/login' element={<AdminLogin />} />
          <Route path='admin/dashboard' element={<AdminDashboard />}>

            {/* Masters */}
            <Route path='master/home' element={<Home />} />
            <Route path='master/customer' element={<Customer />} />
            <Route path='master/charges' element={<Charges />} />
            <Route path='master/currency' element={<Currency />} />
            <Route path='master/vessel' element={<Vessel />} />
            <Route path='master/port' element={<Port />} />
            <Route path='master/container' element={<Containers />} />
            <Route path='master/cargo' element={<Cargo />} />
            <Route path='master/package' element={<Package />} />
            <Route path='master/company' element={<Company />} />
            <Route path='master/employee' element={<Employee />} />
            <Route path='master/department' element={<Department />} />
            <Route path='master/designation' element={<Designation />} />
            <Route path='master/instructions' element={<Instructions />} />
            <Route path='master/bankAccDetails' element={<BankAccDetails />} />
            <Route path='master/invoiceEOE' element={<InvoiceEOE />} />

            {/* Export Sales */}
            <Route path='exports/sales/buyer' element={<Buyer />} />
            <Route path='exports/sales/quotation' element={<Selling />} />
            <Route path='exports/sales/approval' element={<Approval />} />
            <Route path='exports/sales/approved' element={<Approved />} />
            <Route path='exports/sales/booking' element={<Booking />} />

            {/* Export SI */}
            <Route path='exports/shipping/creation' element={<BillOfLading />} />

            {/* Export Invoice */}
            <Route path='exports/Invoice/proformaInvoice' element={<ProformaInvoice />} />
            <Route path='exports/Invoice/proformaInvoicepdf' element={<ProformaInvoicepdf />} />
            <Route path='exports/Invoice/ProfomaApproval' element={<ProfomaApproval />} />
            <Route path='exports/Invoice/ApprvdInvoice' element={<ApprvdInvoice />} />
            <Route path='exports/Invoice/ProfomaApprovedList' element={<ProfomaApprovedList />} />
            <Route path='exports/Invoice/ProfomaCNOPS' element={<ProfomaCNOPS />} />
            <Route path='exports/Invoice/ApprovalCN' element={<ApprovalCN />} />
            <Route path='exports/Invoice/ApprvdCnOps' element={<ApprvdCnOps />} />

            {/* Export Accounts */}
            <Route path='exports/Accounts/Invoice' element={<Invoice />} />
            <Route path='exports/Accounts/CnOps' element={<CnOps />} />
            <Route path='exports/Accounts/ProfomaCreditNt' element={<ProfomaCreditNt />} />
            <Route path='exports/Accounts/ProCreditApproval' element={<ProCreditApproval />} />
            <Route path='exports/Accounts/CreditNt' element={<CreditNote />} />
            <Route path='exports/Accounts/ProfomaDebitNt' element={<ProfomaDebitNt />} />
            <Route path='exports/Accounts/ProDebitApproval' element={<ProDebitApproval />} />
            <Route path='exports/Accounts/DebitNt' element={<DebitNote />} />
            <Route path='exports/Accounts/ReceiptProfoCreditNt' element={<ReceiptProfoCreditNt />} />
            <Route path='exports/Accounts/ReceiptProfoDebitNt' element={<ReceiptProfoDebitNt />} />
            {/* <Route path='exports/expAttachment/Upload_View' element={<Upload_View/>}/> */}

            {/* Export Job close & Enquiry */}
            <Route path='exports/Jobclose' element={<Jobclose />} />
            <Route path='exports/JobEnquiry' element={<JobEnquiry />} />

            {/* Import Sales */}
            <Route path='Imports/sales/buyer' element={<ImpBuyer />} />
            <Route path='Imports/sales/quotation' element={<ImpSelling />} />
            <Route path='Imports/sales/Approval' element={<ImpApproval />} />
            <Route path='Imports/sales/Approved' element={<Impsellingapproved />} />
            <Route path='Imports/sales/booking' element={<ImpBooking />} />

            {/* Import SI */}
            <Route path='Imports/shipping/creation' element={<ImpBillOfLading />} />

            {/* Import Invoice  */}
            <Route path='Imports/Invoice/proformaInvoice' element={<ImpProformaInvoice />} />
            <Route path='Imports/Invoice/proformaInvoicepdf' element={<ImpProformaInvoicepdf />} />
            <Route path='Imports/Invoice/ProfomaApproval' element={<ImpProfomaApproval />} />
            <Route path='Imports/Invoice/ProCreditpdf' element={<ImpProCreditpdf />} />
            <Route path='Imports/Invoice/ProfomaCNOPS' element={<ImpProfomaCNOPS />} />
            <Route path='Imports/Invoice/ProfomaApprovedList' element={<ImpProfomaApprovedList />} />
            <Route path='Imports/Invoice/ApprovalCN' element={<ImpApprovalCN />} />
            <Route path='Imports/Invoice/ApprvdInv' element={<ImpApprvdInv />} />
            <Route path='Imports/Invoice/ApprvdCno' element={<ImpApprvdCno />} />

            {/* import Accounts */}
            <Route path='Imports/Accounts/Invoice' element={<ImpInvoice />} />
            <Route path='Imports/Accounts/CnOps' element={<ImpCnOps />} />
            <Route path='Imports/Accounts/ProfomaCreditNt' element={<ImpProfomaCreditNt />} />
            <Route path='Imports/Accounts/ProfomaDebitNt' element={<ImpProfomaDebitNt />} />
            <Route path='Imports/Accounts/DebitNote' element={<ImpDebitNote />} />
            <Route path='Imports/Accounts/ProDebitApproval' element={<ImpProDebitApproval />} />
            <Route path='Imports/Accounts/ProDebitNTReceipt' element={<ImpProDebitNTReceipt />} />
            <Route path='Imports/Accounts/CreditNote' element={<ImpCreditNote />} />
            <Route path='Imports/Accounts/ReceiptProfoCreditNt' element={<ImpReceiptProfoCreditNt />} />
            <Route path='Imports/Accounts/ProCreditApproval' element={<ImpProCreditApproval />} />

            {/* Import JOb Close & Enquiry */}
            <Route path='Imports/Jobclose' element={<ImpJobclose />} />
            <Route path='Imports/JobEnquiry' element={<ImpJobEnquiry />} />

            {/* Document Attachment */}
            <Route path='DocAttachment/ExpUpload' element={<ExpUpload />} />
            <Route path='DocAttachment/ImpUpload' element={<ImpUpload />} />

            {/* Export Reports */}
            <Route path='Reports/Exports/Buyingreport' element={<Buyingreport />} />
            <Route path='Reports/Exports/Sellingreport' element={<Sellingreport />} />
            <Route path='Reports/Exports/BookingReport' element={<BookingReport />} />
            <Route path='Reports/Exports/InvoiceReport' element={<InvoiceReport />} />
            <Route path='Reports/Exports/CnopsReport' element={<CnopsReport />} />
            <Route path='Reports/Exports/CreditReport' element={<CreditReport />} />
            <Route path='Reports/Exports/DebitReport' element={<DebitReport />} />

            {/* Import Reports */}
            <Route path='Reports/Imports/buyerReport' element={<BuyerReport />} />
            <Route path='Reports/Imports/SellingReport' element={<SellingReport />} />
            <Route path='Reports/Imports/BookingReport' element={<ImpBookingReport />} />
            <Route path='Reports/Imports/InvoiceReport' element={<ImpInvoiceReport />} />
            <Route path='Reports/Imports/CnopsReport' element={<ImpCnopsReport />} />
            <Route path='Reports/Imports/CreditReport' element={<ImpCreditReport />} />
            <Route path='Reports/Imports/DebitReport' element={<ImpDebitReport />} />

            {/* PL Reports */}
            <Route path='Reports/P&L/Exports/JobcloseReport' element={<JobCloseReport />} />
            <Route path='Reports/P&L/Exports/JobUnCloseReport' element={<JobUnCloseReport />} />
            <Route path='Reports/P&L/Imports/JobcloseReport' element={<ImpJobCloseReport />} />
            <Route path='Reports/P&L/Imports/JobUnCloseReport' element={<ImpJobUnCloseReport />} />


            {/* Maintenance */}
            <Route path='Maintenance/Addusers' element={<Addusers />} />

            {/* <Route path='exports/shipping/test' element={<Template />} /> */}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

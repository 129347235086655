import { Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import classes from "./Customer.module.css";
import Select from 'react-select';


function Charges() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    code: "",
    name: "",
  };
  const [charge, setCharge] = useState(initValue);
  const [charges, setCharges] = useState([]);
  const [showList, setShowList] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [chargeUpdate, setChargeUpdate] = useState(false);
  const [error, setError] = useState("");
  const [chargesList, setChargesList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const setChargeDetail = ({ key, value }) => {
    let detail = { ...charge };

    detail[key] = value.toUpperCase();

    if (key === "name") {
      detail["code"] = generateChargeCode(value);
    }

    setCharge(detail);

  };

  const generateChargeCode = (str) => {
    const code = str
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();

    return code;
  };

  const saveCharge = () => {
    let temp = { ...charge };

    if (temp._id) {
      customAxios.post("/charges/update", { temp }).then((data) => {

        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/charges";
      customAxios
        .post(url, charge)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setCharge(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };
  const searchCharges = () => {
    let url = "/charges/search";
    customAxios.post(url, { name: searchValue }).then((charges) => {
      setCharges(charges.data.data);
    });
  };

  const deleteCharge = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios.delete("/charges", { data: { userId: id } }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Charges deleted succesfully!");
          let temp = [...charges];
          temp.splice(index, 1);
          setCharges(temp);
        }
      });
    }
  };

  const editCharge = (index, id) => {
    let temp = [...charges];
    setCharge({ ...temp[index] });
    setShowList(false);
    setChargeUpdate(true);
  };

  useEffect(() => {
    let url = "/charges";
    customAxios.get(url).then((charges) => {
      setCharges(charges.data.data);
      setSearchList(charges.data.data);
      console.log(charges.data.data);
    });

    let chargeurl = "/charges";
    customAxios.get(chargeurl).then((charges) => {
      let temp = [...charges.data.data];
      let charge = temp.map((data) => {
        return { label: data.name, value: data.name };
      })
      setChargesList([...charge]);
    });
  }, [showList]);

  const handleSearch = (option) => {
    
    if (option == null) {
      let url = "/charges";
      customAxios.get(url).then((charges) => {
        setCharges(charges.data.data);
        setSearchList(charges.data.data);
        setSelectedVal(null);
        console.log(charges.data.data);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setCharges(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Charges</h5>
        <section className={classes.title}>Dashboard / charges</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Charges Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchCharges}
                  />
                </div> */}

                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={chargesList}
                    value={selectedVal}
                    onChange={handleSearch}
                  />
                </div>

                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setCharge(initValue);
                      setChargeUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Charge Code</th>
                    <th scope="col" style={theadStyle}>Charge Name</th>
                     <th scope="col" style={theadStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.length
                    ? charges.map((charge, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{charge.code}</td>
                          <td style={{textTransform: 'uppercase'}}>{charge.name}</td>
                          {/* {isSuperuser && ( */}
                            <td>
                              {/* <a href='#'> */}
                              <img
                                src={EditIcon}
                                alt="edit_icon"
                                onClick={() => editCharge(index, charge._id)}
                              />
                              {/* </a>{' '} */}
                              &nbsp;&nbsp; {/* <a href='#'> */}
                              <img
                                src={DeleteIcon}
                                alt="delete_Icon"
                                onClick={() =>
                                  deleteCharge(index, charge._id)
                                }
                              />
                              {/* </a> */}
                            </td>
                          {/* )} */}
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {charges.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Charge</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Charge Code</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={charge.code}
                  onChange={(e) =>
                    setChargeDetail({ key: "code", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Charge Name</p>
                <input
                style={{textTransform: 'uppercase'}}
                  type="text"
                  className={classes.inputText}
                  value={charge.name}
                  onChange={(e) =>
                    setChargeDetail({ key: "name", value: e.target.value })
                  }

                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveCharge}>
                {chargeUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setCharge(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Charges;

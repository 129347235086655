import { Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import classes from "./Customer.module.css";
import Select from 'react-select';

function Currency() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    code: "",
    name: "",
  };
  const [currency, setCurrency] = useState(initValue);
  const [currencies, setCurrencies] = useState([]);
  const [showList, setShowList] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currencyUpdate, setCurrencyUpdate] = useState(false);
  const [error, setError] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');

  const setCurrencyDetail = ({ key, value }) => {
    let detail = { ...currency };
    detail[key] = value.toUpperCase();
    setCurrency(detail);

  };


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const saveCurrency = () => {
    let temp = { ...currency };

    if (temp._id) {
      customAxios.post("/currency/update", { temp }).then((data) => {

        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/currency";
      customAxios
        .post(url, currency)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setCurrency(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };
  const searchCurrency = () => {
    let url = "/currency/search";
    customAxios.post(url, { name: searchValue }).then((currencies) => {
      setCurrencies(currencies.data.data);
    });
  };

  const deleteCurrency = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      customAxios
        .delete("/currency", {
          data: { userId: id },
        })
        .then((data) => {
          if (data.data.error) {
            setError(data.message);
          } else {

            window.alert("Currency deleted succesfully!");
            let temp = [...currencies];
            temp.splice(index, 1);
            setCurrencies(temp);
          }
        });
    }
  };

  const editCurrency = (index, id) => {
    let temp = [...currencies];
    setCurrency({ ...temp[index] });
    setShowList(false);
    setCurrencyUpdate(true);
  };

  useEffect(() => {
    let url = "/currency";
    customAxios.get(url).then((currencies) => {
      console.log(currencies);
      setCurrencies(currencies.data.data);
      setSearchList(currencies.data.data);
    });

    let Currencyurl = "/currency";
    customAxios.get(Currencyurl).then((currency) => {

      let temp = [...currency.data.data];
      let curren = temp.map((data) => {
        return { label: data.name, value: data.name };
      })
      setCurrencyList([...curren]);
    });
  }, [showList]);

  const handleSearch = (option) => {

    if (option == null) {
      let url = "/currency";
      customAxios.get(url).then((currencies) => {
        setCurrencies(currencies.data.data);
        setSearchList(currencies.data.data);
        setSelectedVal(null);

      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setCurrencies(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Currency</h5>
        <section className={classes.title}>Dashboard / currency</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Currency Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchCurrency}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={currencyList}
                    value={selectedVal}
                    onChange={handleSearch}
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setCurrency(initValue);
                      setCurrencyUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Currency Code</th>
                    <th scope="col" style={theadStyle}>Currency Name</th>
                    {/* {isSuperuser &&  */}<th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {currencies.length
                    ? currencies.map((currency, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td style={{textTransform: 'uppercase'}}>{currency.code}</td>
                          <td style={{ textTransform: 'uppercase' }}>{currency.name}</td>
                          {/* {isSuperuser && ( */}
                          <td>
                            {/* <a href='#'> */}
                            <img
                              src={EditIcon}
                              alt="edit_icon"
                              onClick={() =>
                                editCurrency(index, currency._id)
                              }
                            />
                            {/* </a>{' '} */}
                            &nbsp;&nbsp; {/* <a href='#'> */}
                            <img
                              src={DeleteIcon}
                              alt="delete_Icon"
                              onClick={() =>
                                deleteCurrency(index, currency._id)
                              }
                            />
                            {/* </a> */}
                          </td>
                          {/* )} */}
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {currencies.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Currency</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Currency Code</p>
                <input
                style={{textTransform: 'uppercase'}}
                  type="text"
                  className={classes.inputText}
                  value={currency.code}
                  onChange={(e) =>
                    setCurrencyDetail({ key: "code", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Currency Name</p>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={classes.inputText}
                  value={currency.name}
                  onChange={(e) =>
                    setCurrencyDetail({ key: "name", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveCurrency}>
                {currencyUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setCurrency(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Currency;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../Axios';
import { DeleteIcon, EditIcon } from '../../Images';
import classes from './user.module.css';


export default function Addusers() {

    const [tableData, setTableData] = useState('');
    const [formInput, setFormInput] = useState(
        {
            charge: '',
            currency: '',
            rate: '',
            amount: '',
            base: '',
            exchangeRate: ''
        }
    );

    const initDetails = {
        booking_number: '',
        //carrier_booking_no: '',
        //carrier_bl: '',
        issued_at: '',
        issued_on: '',
        bl_type: '',
        //bl_by: '',
        shipper_name: '',
        shipper_address: '',
        consignee_name: '',
        consignee_address: '',
        notify_party_name: '',
        notify_party_address: '',
        also_notify_party_name: '',
        also_notify_party_address: '',
        forwarding_agent: '',
        mark_and_no: '',
        mark_description: '',
        container_details: [],
    };

    const initBooking = {
        buying_id: '',
        selling_id: '',
        carrier_booking_no: '',
        quantity_20: '',
        quantity_40: '',
        house_bl: false,
        vessel: '',
        voyage: '',
        pol: '',
        etd: '',
        pod: '',
        etd: '',

    };

    const initContDetail = {
        container_no: '',
        seal_no: '',
        package_no: '',
        package_type: '',
        gross_wt: '',
        net_wt: '',
        measurement: '',
    };

    const initBuying = {
        freight: '',
        source: '',
        destination: '',
        loading_port: '',
        discharge_port: '',
        carrier: '',
        _id: '',
    };




    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        menu: base => ({
            ...base,
            zIndex: 2
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };

    let initBookingValue = {
        shipping_id: ""/* shippingNo */,
        carrier_no: "",
        customer_name: '',
        Address: '',
        remarks: '',
        currency: '',
        Destination: '',
        shipper: '',
        Agent: 0,
        container: 0,
        cosingnee: 21000,
        MLO: 28000,
        Notify_party: '',
        CNF: '',
        total: '',
    };

    // set the data to bind the value for ref id  
    const [shippingDetails, setshippingDetails] = useState(initBookingValue);

    const handleshippingDetails = ({ key, value }) => {
        console.log(value);
        // handleProfoInvInp(value)

        let tempshipping = { ...shippingDetails };
        tempshipping[key] = value;
        setshippingDetails({ ...tempshipping });
    };

    const handleBookingDetails = ({ key, value }) => {
        // console.log("key", value);
        let temp = { ...customerdetails };
        temp[key] = value;
        setcustomerdetails({ ...temp });
    };
    const handleDetails = ({ key, value }) => {
        // console.log("key", value);
        let tempdetails = { ...details };
        tempdetails[key] = value;
        setDetails({ ...tempdetails });
    };

    const handlebookingDetails = ({ key, value }) => {
        // console.log("key", value);
        let tempbooking = { ...bookingDetails };
        tempbooking[key] = value;
        setBookingDetails({ ...tempbooking });
    };

    const [remarks, setRemarks] = useState('');
    const [sellingNo, setSellingNo] = useState('');
    const [bookingNumbers, setBookingNumbers] = useState([]);
    const [bookingNo, setBookingNo] = useState('');
    const [constno, setconstno] = useState('');
    const [containerno, setcontainerno] = useState('');
    const [buyingDetails, setBuyingDetails] = useState(initBuying);
    const [bookingDetails, setBookingDetails] = useState(initBooking);
    const [details, setDetails] = useState(initDetails);
    const [customerdetails, setcustomerdetails] = useState([]);
    const [contDetail, setContDetail] = useState(initContDetail);
    const [shippingNo, setShippingNo] = useState('');
    const [houseBL, setHouseBL] = useState(false);
    const [cargo, setcargo] = useState();
    const [allCharges, setAllcharges] = useState([]);
    const [refNo, setrefNo] = useState();
    const [profoInvInp, setProfoInvInp] = useState(0);
    const [Total, setTotal] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [selectedValue, setSelectedValue] = useState("USD");
    const [bookingid, setBookingid] = useState('')
    const [editFunc, setEditFunc] = useState(false);
    const [amtinwords, setAmtinwords] = useState('');
    const [charges, setCharges] = useState([]);
    const [containers, setContainers] = useState([]);
    const [newCurrency, setNewCurrency] = useState();
    const [newBase, setNewBase] = useState();
    const [newCharge, setNewCharge] = useState();
    const [inputdisable, setinputdisable] = useState(false);

    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [menusTable, setMenusTable] = useState(false);
    const [menuVal, setMenuVal] = useState([]);
    const [module, setModule] = useState('');
    const [menuName, setMenuName] = useState('');
    const [subMenu, setSubMenu] = useState('');
    const [roleOptions, setroleOptions] = useState([]);
    const [idrole,setIdrole] = useState([]);



    const reload = () => {
        window.location.reload();
    };

    /* useEffect(() => {
        // menuTable();
        customAxios.get('/booking/ids').then((ids) => {
            let temp = [...ids.data.data];
            let details = temp.filter(data => data.is_closed === 0).map((data) => {
                return { label: 'RUKEX' + ' ' + data.booking_id, value: data.booking_id };
            });
            setBookingNumbers([...details]);

        });


        let currencyUrl = '/currency';
        customAxios.get(currencyUrl).then((currencies) => {
            let temp = [...currencies.data.data];
            let currenciesDetails = temp.map((data) => {
                return { label: data.code, value: data.code };
            });

            setCurrencies([...currenciesDetails]);
            console.log(currencies);
            console.log('currenciestemp', currenciesDetails);
        });

        customAxios.get('/container').then((containers) => {
            let temp = [...containers.data.data];
            let containersDetails = temp.map((data) => {
                return { label: data.type, value: data.type };
            });
            setContainers([...containersDetails]);
        });

        customAxios.get('/charges').then((charges) => {
            let temp = [...charges.data.data];
            let chargesDetails = temp.map((data) => {
                return { label: data.name, value: data.name };
            });
            setCharges([...chargesDetails]);
        });

    }, []); */

    const savedata = () => {

        let temp = {
            username: userName,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            designation: designation,
            rolename: department,
        }
        if (Object.values(temp).includes('')) {
            window.alert('Please fill all the mandatory fields');
        }

        else {

            customAxios
                .post("/admin/signup",

                    {
                        "username": userName,
                        "email": email,
                        "password": password,
                        "confirmPassword": confirmPassword,
                        "designation": designation,
                        "rolename": department,

                    }).then((res) => {
                        console.log(res.data.message);
                        // console.log("final", res);
                        if (res.data.error) window.alert(res.data.message);

                        else {
                            window.alert('Detail saved successfully');
                            console.log(res.data);
                        }
                    })
                .catch((err) =>
                    window.alert(err.response.data.message)
                );
        }
    }

    const theadStyle = {
        backgroundColor: '#3d4d76',
        color: 'white',
        fontWeight: 'normal',
        fontSize: '12px',
        // textAlign: 'center'
    };




    /* var obj = [
        { "#": 1, "Module": "Master" },
        { "#": 2, "Module": "Exports", "Main Menu": "Sales", "Sub Menu": "Buying" },
        { "Sub Menu": "Selling Quotation" },
        { "Sub Menu": "Booking" },
        { "Main Menu": "Shipping Instruction", "Sub Menu": "S/I Creation" },
        { "Main Menu": "Invoice", "Sub Menu": "Creation" },
        { "Sub Menu": "Approved" },
        { "Main Menu": "Accounts Voucher", "Sub Menu": "Creation" },
        { "Sub Menu": "Approved" },
        { "Main Menu": "Job Close" },
        { "#": 3, "Module": "Doc Attachment", "Main Menu": "Exp Upload" }
    ]; */
    useEffect(() => {
        let url = '/get/rolesmaster';
        customAxios.get(url).then((roles) => {
            console.log(roles);
            let temp = [...roles.data.data];
            let role = temp.map((data) => {
                return { label: data.Rolename, value: data.RoleID };
            })
           
            setroleOptions([...role]);
        })

    }, [])

    const handledepartchange = async (selected) => {
        const roleId = selected ? selected.value : ' ';
        const roleName = selected ? selected.label : ' ';
        setDepartment(roleName);
        setIdrole(roleId);
        console.log(roleName);
        setMenusTable(true);
        try {
            const response = await customAxios.post('/roles/getbyId', { "RoleID": roleId });
            setMenuVal(response.data.data.Menus);
            
            console.log(response);
        }
        catch (error) {
            console.error('error', error);
        }
    }

    return (
        <>
            <div className={classes.container}>
                <h5>Add Users<span className={classes.title}>Maintenance / Add User</span></h5>
            </div>

            <Container className={classes.inputsContainer}>

                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td colSpan={1}>UserName<span>*</span></td>
                            <td colSpan={2}><input className={classes.inputText} type="text" value={userName} onChange={(event) => setUserName(event.target.value)} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>Email<span>*</span></td>
                            <td colSpan={2}><input className={classes.inputText} type="text" value={email} onChange={(event) => setEmail(event.target.value)} /></td>

                        </tr>
                        <tr>
                            <td colSpan={1}>Designation<span>*</span></td>
                            <td colSpan={2}><input className={classes.inputText} type="text" value={designation} onChange={(event) => setDesignation(event.target.value)} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1} >Department<span>*</span></td>
                            <td colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={roleOptions}
                                    value={roleOptions.filter(
                                        (option) => option.value === idrole
                                    )}
                                    onChange={(selected) => { handledepartchange(selected) }}
                                />
                            </td>

                        </tr>
                        <tr>
                            <td colSpan={1}>Password<span>*</span></td>
                            <td colSpan={2}><input className={classes.inputText} type="password" value={password} onChange={(event) => setPassword(event.target.value)} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>Confirm Password<span>*</span></td>
                            <td colSpan={2}><input className={classes.inputText} type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} /></td>
                        </tr>

                        <tr><td colSpan="9">
                            <hr></hr>
                        </td></tr>

                    </tbody>
                </table>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <table>
                        <tr>
                            <td colSpan={5} />
                            <td colSpan={4}>
                                <button id="myButton" className={classes.footerButton} style={{ margin: '1%', width: '100px' }} onClick={savedata}> {/* refNo ? 'Update User' :  */'Save User'} </button></td>
                            <td><button className={classes.footerButton} style={{ margin: '1%', width: '100px' }} onClick={reload}>New</button></td>
                        </tr>
                    </table>
                </div>
                <>
                    {department ?
                        <h6>Menu Screens based on Roles</h6> : null}
                    <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                        <table class='table'>
                            {department ?
                                <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: '1' }}>
                                    <tr>
                                        <th scope='col' style={{ textAlign: 'center', ...theadStyle }}>#</th>
                                        <th scope='col' style={{ textAlign: 'center', ...theadStyle }}>Module</th>
                                        <th scope='col' style={{ textAlign: 'center', ...theadStyle }}>Main Menu</th>
                                        <th scope='col' style={{ textAlign: 'center', ...theadStyle }}>Sub Menu</th>
                                    </tr>
                                </thead>
                                : null}
                            <tbody>

                                {department ?
                                    <>

                                        {menuVal.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td style={{ textAlign: 'center' }}>{item["#"]}</td>
                                                    <td style={{ textAlign: 'center' }}>{item["Module"]}</td>
                                                    <td style={{ textAlign: 'center' }}>{item["Main Menu"]}</td>
                                                    <td style={{ textAlign: 'center' }}>{item["Sub Menu"]}</td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                    : null}

                            </tbody>
                        </table>
                    </div>
                </>
            </Container>
        </>
    )
}
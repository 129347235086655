import React from "react";
import { useTable, usePagination } from "react-table";
import classes from '../Components/Exports/Invoice/profoapproval.module.css';



export default function CustomTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    textAlign: 'center'
  };


  // Table rendering
  return (
    <>
      <table {...getTableProps()} className="table">
        <thead className={classes.theadDark} style={{textAlign: 'center'}}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={theadStyle}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{textAlign: 'center'}}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <li className={`page-item ${!canPreviousPage && "disabled"}`}>
            <button
              className="page-link"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>
          </li>
          <li className="page-item">
            <span className="page-link">
              Page {pageIndex + 1} of {Math.ceil(data.length / 5)}
            </span>
          </li>
          <li className={`page-item ${!canNextPage && "disabled"}`}>
            <button
              className="page-link"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};



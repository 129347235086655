import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from "react-select";

function Cargo() {
  let initValue = {
    name: "",
  };
  const [cargo, setCargo] = useState(initValue);
  const [cargos, setCargos] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  const [cargoUpdate, setCargoUpdate] = useState(false);
  const [error, setError] = useState("");
  const [cargoList, setCargoList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');

  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;

  const setCargoDetail = ({ key, value }) => {
    let detail = { ...cargo };
    detail[key] = value.toUpperCase();
    setCargo(detail);
    
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const saveCargo = () => {
    let temp = { ...cargo };
    
    if (temp._id) {
      customAxios.post("/cargo/update", { temp }).then((data) => {
        
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/cargo";
      customAxios
        .post(url, cargo)
        .then((resp) => {
          
          if (resp.data.error) {
            
            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setCargo(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchCargo = () => {
    let url = "/cargo/search";
    customAxios.post(url, { searchValue: searchValue }).then((cargos) => {
      setCargos(cargos.data.data);
    });
  };

  const deleteCargo = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios.delete("/cargo", { data: { userId: id } }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Cargo deleted succesfully!");
          let temp = [...cargos];
          temp.splice(index, 1);
          setCargos(temp);
        }
      });
    }
  };

  const editCargo = (index, id) => {
    let temp = [...cargos];
    setCargo({ ...temp[index] });
    setShowList(false);
    setCargoUpdate(true);
  };

  useEffect(() => {
    let url = "/cargo";
    customAxios.get(url).then((cargos) => {
      setCargos(cargos.data.data);
      setSearchList(cargos.data.data);
    });

    let cargourl = "/cargo";
    customAxios.get(cargourl).then((cargo) => {
      let temp = [...cargo.data.data];
      let carg = temp.map((data)=>{
        return{label: data.name, value: data.name}
      })
      setCargoList([...carg]);
    });
  }, [showList]);

  const handleSearch = (option) => {
    
    if (option == null) {
      let url = "/cargo";
      customAxios.get(url).then((cargos) => {
        setCargos(cargos.data.data);
        setSearchList(cargos.data.data);
        setSelectedVal(null);
     
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setCargos(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Cargo</h5>
        <section className={classes.title}>Dashboard / cargo</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Cargo Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchCargo}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                     options={cargoList}
                    value={selectedVal}
                    onChange={handleSearch} 
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setCargo(initValue);
                      setCargoUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Cargo Name</th>
                    {/* {isSuperuser && */} <th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {cargos.length
                    ? cargos.map((cargo, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td style={{textTransform: 'uppercase'}}>{cargo.name}</td>
                            {/* {isSuperuser && ( */}
                              <td>
                                {/* <a href='#'> */}
                                <img
                                  src={EditIcon}
                                  alt="edit_icon"
                                  onClick={() => editCargo(index, cargo._id)}
                                />
                                {/* </a>{' '} */}
                                &nbsp;&nbsp; {/* <a href='#'> */}
                                <img
                                  src={DeleteIcon}
                                  alt="delete_Icon"
                                  onClick={() => deleteCargo(index, cargo._id)}
                                />
                                {/* </a> */}
                              </td>
                            {/* )} */}
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              {cargos.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Cargo</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Cargo Name</p>
                <input
                style={{textTransform: 'uppercase'}}
                  type="text"
                  className={classes.inputText}
                  value={cargo.name}
                  onChange={(e) =>
                    setCargoDetail({ key: "name", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveCargo}>
                {cargoUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setCargo(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Cargo;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import classes from './Style.module.css';
import CustomTable from '../../customTable';
import ExcelJS from 'exceljs';

export default function BookingReport() {

  const [bookingDetails, setBookingDetails] = useState('');
  const [allCharges, setAllcharges] = useState([]);
  const [contDetails, setcontDetils] = useState([]);
  const [showList, setShowList] = useState(true);
  const [bookinglist, setBookinglist] = useState([]);
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [shippinginfo, setshippinginfo] = useState([]);

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };


  const showbookingReport = (index, booking_id) => {
    setShowList(false);

    customAxios.post('booking/report/getbyID', { bkg_no: booking_id }).then((result) => {
      if (!result.data.error && result.data.data) {
        console.log(result.data.data);

        const bookingData = result.data.data.find(item => item.booking_id === booking_id);

        if (bookingData) {
          let {
            buying_id,
            carrier,
            source,
            loading_port,
            discharge_port,
            destination,
            customer,
            cargo,
            freight,
            shipment,
            current_date,
            remarks,
            selling_rates,
            shipping_info,
          } = bookingData;

          setshippinginfo(shipping_info.length === 0 ? 'unused' : 'used');

          if (shipping_info && shipping_info.length > 0) {
            const containerDetails = shipping_info[0].container_details;

            if (containerDetails && containerDetails.length > 0) {
              const {
                container_no,
                seal_no,
                package_no,
                package_type,
                gross_wt,
                net_wt,
                measurement,
                desc,
                mrkno,
              } = containerDetails[0];
              setcontDetils(containerDetails);

              console.log(container_no, seal_no, package_no, package_type, gross_wt, net_wt, measurement, desc, mrkno);
            }
          }

          setBookingDetails({
            buying_id,
            booking_id,
            carrier,
            source,
            customer,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            current_date/* .substring(0, 10) */,
            remarks,
            selling_rates,
          });

          console.log(bookingDetails);
          setAllcharges(selling_rates);
        } else {
          window.alert('No data found for the specified booking_id');
        }
      }
      else {
        window.alert('No data found');
      }
    })
  }

  const handleClick = () => {
    if (fromdate && todate) {
      const payload = {
        fromdate: fromdate,
        todate: todate
      };

      customAxios.post("/booking/report/getbydate", payload)
        .then((response) => {
          const responseData = response.data;

          if (responseData && responseData.length > 0) {
            const files = responseData;
            console.log(files);
            setBookinglist(files);
          } else {
            setBookinglist([]);
            alert('No files found for the selected date range.');
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert('An error occurred while fetching files.');
        });

    } else {
      alert('Please choose both From Date and To Date');
    }
  };


  //Extracting Data For Excel 
  useEffect(() => {
    { console.log(bookinglist); }
    const extractData = bookinglist && bookinglist.flatMap((bookinglists, index) => {

      const formattedDate1 = bookinglists.current_date;
      // const formattedDate2 = bookinglists.valid_till;

      const validfrom = formattedDate1.split('T')[0];
      // const valid_till = formattedDate2.split('T')[0];

      { console.log(bookinglists.sailing_schedule); }
      const rowData = {
        ID: index + 1,
        BookingNo: bookinglists.booking_id,
        QuotationNo: bookinglists.buying_id,
        date: /* bookinglists.date */ validfrom,
        Customer: bookinglists.customer,
        ContainerQty: (bookinglists.quantity_20 ? bookinglists.quantity_20 + ' -20Gp' : '') + (bookinglists.quantity_40 ? '  ' + bookinglists.quantity_40 + '-40Gp': ''),
        vessel: bookinglists.sailing_schedule.vessel,
        voyage: bookinglists.sailing_schedule.voyage,
        etd: bookinglists.sailing_schedule.etd,
        pod: bookinglists.sailing_schedule.pod,
        pol: bookinglists.sailing_schedule.pol,
        polEta: bookinglists.sailing_schedule.pol_eta,
        eta: bookinglists.sailing_schedule.eta,
        remarks: bookinglists.sailing_schedule.vessel,
        Carrier: bookinglists.carrier,
        POR: bookinglists.source,
        POD: bookinglists.loading_port,
        DischargePort: bookinglists.discharge_port,
        DEL: bookinglists.destination,
        cargo: bookinglists.cargo,
        Freight: bookinglists.freight,

        Remarks: bookinglists.shipping_info.length === 0 ? 'unused' : 'used',
      };
      { console.log(bookinglists); }


      // rowData.selling_rates = bookinglists.selling_rates.map((charge) => ({
      //   charge: charge.charge,
      //   currency: charge.currency,
      //   rate: charge.rate,
      //   buyingRate: charge.buyingRate,
      //   base: charge.base,
      // }));


      // if (bookinglists.shipping_info && bookinglists.shipping_info.length > 0) {
      //   rowData.containerDetails = bookinglists.shipping_info.map((shippingItem) => {
      //     if (shippingItem.container_details && shippingItem.container_details.length > 0) {
      //       return shippingItem.container_details.map((containdetails) => ({
      //         container_no: containdetails.container_no,
      //         seal_no: containdetails.seal_no,
      //         package_no: containdetails.package_no,
      //         package_type: containdetails.package_type,
      //         gross_wt: containdetails.gross_wt,
      //         net_wt: containdetails.net_wt,
      //         measurement: containdetails.measurement,
      //         desc: containdetails.desc,
      //         mrkno: containdetails.mrkno,
      //       }));
      //     }

      //   });
      // } 

      return rowData;
    });

    setExcelData(extractData);
  }, [bookinglist]);



  const convertToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    const mainHeaderRow = sheet.addRow([
      '#', 'BookingNo', 'QuotationNo', 'Date ', 'Customer', 'Carrier', 'POR', 'POD', 'Discharge Port', 'DEL', 'Cargo',
      'Freight', 'ContainerQty', 'vessel', 'voyage', 'etd', 'pod ', 'pol', 'polEta', 'eta',
      'Remarks'
    ]);

    //Main Row 
    mainHeaderRow.font = { bold: true };

    excelData.forEach((row) => {
      sheet.addRow([
        row.ID, row.BookingNo, row.QuotationNo, row.date, row.Customer, row.Carrier, row.POR, row.POD, row.DischargePort, row.DEL, row.cargo,
        row.Freight, row.ContainerQty, row.vessel, row.voyage, row.etd, row.pod, row.pol, row.polEta, row.eta, row.Remarks
      ]);

      // Add blank row as separator
      sheet.addRow([]);

      //Charges Row
      // const chargesHeaderRow = sheet.addRow(['', '', 'Charge', 'Currency', 'BuyingRate', 'SellingRate', 'Base', 'container_no', 'seal_no',
      //   'package_no', 'package_type', 'gross_wt', 'net_wt', 'measurement', 'desc', 'mrkno',]);

      // chargesHeaderRow.font = { bold: true };

      // if (row.selling_rates && Array.isArray(row.selling_rates)) {
      //   row.selling_rates.forEach((charge) => {
      //     if (row.containerDetails && row.containerDetails.length > 0) {
      //       row.containerDetails.forEach((container) => {
      //         sheet.addRow(['', '', charge.charge, charge.currency, charge.rate, charge.buyingRate, charge.base, container.container_no,
      //           container.seal_no, container.package_no, container.package_type, container.gross_wt, container.net_wt, container.measurement, container.desc, container.mrkno]);
      //       });
      //     } else {
      //       sheet.addRow(['', '', charge.charge, charge.currency, charge.rate, charge.buyingRate, charge.base]);
      //     }
      //   });
      // }

    });
    console.log(excelData);


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'Booking Report.xlsx');
    });
  };

  const saveAs = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    let url = '/booking/report';
    customAxios.get(url).then((booking) => {
      setBookinglist(booking.data);
      console.log(booking.data);
      { console.log(bookinglist); }

    });
  }, [showList]);

  const buyingcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Booking No",
      accessor: "booking_id",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
    {
      Header: "Cargo",
      accessor: "cargo",
    },
    {
      Header: "Action",
      accessor: "action",
    }

  ];

  const bookingDatas = bookinglist && bookinglist.length ? bookinglist.map((item, index) => {


    return {
      id: index + 1,
      booking_id: item.booking_id,
      customer: item.customer,
      carrier: item.carrier,
      source: item.source,
      loading_port: item.loading_port,
      discharge_port: item.discharge_port,
      destination: item.destination,
      cargo: item.cargo,
      action: (
        <div style={{ textAlign: 'center' }}>
          <img src={icon_view} alt='icon_view' onClick={() => showbookingReport(index, item.booking_id)} />
        </div>
      )
    };

  }) : [];

  if (bookingDatas && bookingDatas.length === 0 || null) {
    bookingDatas.push({
      id: '',
      booking_id: '',
      customer: '',
      valid_till: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
      action: ''
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Booking Report <span className={classes.title}>Export / BookingReport</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Booking Report Listing</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div style={{ display: 'inline-flex', padding: '1%', width: '100%' }}>
                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>From Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={fromdate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>To Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={todate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%', width: '100%' }}>
                  <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  &nbsp;
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <button className={classes.footerButton} style={{ float: 'right' }} onClick={convertToExcel}>
                    Download
                  </button>
                </Col>

              </div>
              <CustomTable columns={buyingcolumns} data={bookingDatas} />
            </div>
          </>
        ) : (
          <>
            <Container className={classes.inputsContainer}>
              <Row className={classes.row}>
                <Col className={classes.input}>
                  <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Quotation#</span>
                  <section>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={bookingDetails ? bookingDetails.buying_id : ''}
                      disabled={true}
                    />
                  </section>
                </Col>

                <Col className={classes.input}>
                  <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Booking#</span>
                  <section>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={bookingDetails ? bookingDetails.booking_id : ''}
                      disabled={true}
                    />
                  </section>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.current_date : ''}
                    disabled={true}
                  />
                </Col>

                {/* <Col className={classes.input}>
                  <section>
                    <p>Valid Till</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    min={bookingDetails.date}
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.valid_till : ''}
                    disabled={true}
                  />
                </Col> */}

                <Col className={classes.input}>
                  <section>
                    <p>Customer</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.customer : ''}
                    disabled={true}
                  />
                </Col>
                {/* 
                <Col className={classes.input}>
                  <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Buying#</span>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.buying_id : ''}
                    disabled={true}
                  />
                </Col> */}

                {/* <Col className={classes.input}>
                  <section>
                    <p>Status</p>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    // value={approved ? 'Approved' : 'Registered'}
                    disabled={true}
                  />
                </Col> */}

                <Col className={classes.input}>
                  <section>
                    <p>Carrier</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.carrier : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Cargo</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.cargo : ''}
                    disabled={true} />
                </Col>
              </Row>

              <Row className={classes.row}>
                <Col className={classes.input}>
                  <section>
                    <p>Freight</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.freight : ''}
                    disabled={true} />
                </Col>

                {/* <Col className={cla
                sses.input}>
                  <section>
                    <p>Shipment</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.shipment : ''}
                    disabled={true} />
                </Col> */}

                <Col className={classes.input}>
                  <section>
                    <p>POR</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={bookingDetails ? bookingDetails.source : ''}
                      disabled={true} />
                  </div>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>POL</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={bookingDetails ? bookingDetails.loading_port : ''}
                      disabled={true} />
                  </div>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>POD</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.discharge_port : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>DEL</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={bookingDetails ? bookingDetails.destination : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <p>Remarks</p>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={shippinginfo} disabled={true}
                  />
                </Col>
              </Row>

              &nbsp;&nbsp;

              <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead className={classes.theadDark}>
                    <tr>
                      <th scope='col' style={theadStyle}>
                        Charges
                      </th>
                      <th scope='col' style={theadStyle}>Curr</th>
                      <th scope='col' style={theadStyle}>BuyingRate</th>
                      <th scope='col' style={theadStyle}>SellingRate</th>
                      <th scope='col' style={theadStyle}>Base</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCharges.length
                      ? allCharges.map((charge, index) => {
                        return (
                          <tr key={index} style={{ borderBottomWidth: 'none' }}>
                            <td>{charge.charge}</td>
                            <td>{charge.currency}</td>
                            <td>{parseFloat(charge.rate).toFixed(2)}</td>
                            <td>{parseFloat(charge.buyingRate).toFixed(2)}</td>
                            <td>{charge.base}</td>

                          </tr>
                        );
                      })
                      : ''}
                  </tbody>
                </table>
              </div>

              <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead className={classes.theadDark}>
                    <tr>
                      <th scope='col' style={theadStyle}>container_no</th>
                      {/* <th scope='col' style={theadStyle}>desc</th> */}
                      <th scope='col' style={theadStyle}>gross_wt</th>
                      <th scope='col' style={theadStyle}>measurement</th>
                      {/* <th scope='col' style={theadStyle}>mrkno</th> */}
                      <th scope='col' style={theadStyle}>net_wt</th>
                      <th scope='col' style={theadStyle}>package_no</th>
                      <th scope='col' style={theadStyle}>package_type</th>
                      <th scope='col' style={theadStyle}>seal_no</th>

                    </tr>
                  </thead>
                  <tbody>
                    {contDetails.length
                      ? contDetails.map((contDetail, index) => {
                        return (
                          <tr key={index} style={{ borderBottomWidth: 'none' }}>
                            <td>{contDetail.container_no}</td>
                            {/* <td>{contDetail.desc}</td> */}
                            <td>{contDetail.gross_wt}</td>
                            <td>{contDetail.measurement}</td>
                            {/* <td>{contDetail.mrkno}</td> */}
                            <td>{contDetail.net_wt}</td>
                            <td>{contDetail.package_no}</td>
                            <td>{contDetail.package_type}</td>
                            <td>{contDetail.seal_no}</td>

                          </tr>
                        );
                      })
                      : ''}
                  </tbody>
                </table>
              </div>

            </Container>

            <div className={classes.footer} style={{ display: 'flex', justifyContent: 'end', gridGap: '20px', padding: '10px', gap: '20px' }}>
              <button
                className={classes.footerButton} style={{ margin: '1%' }}
                onClick={() => { setShowList(true) }}
              >
                View List
              </button>
            </div>

          </>)}
      </Container>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import customAxios from '../../../Axios';
import { Logo, HeaderLogo } from '../../../Images';
import { Loader } from '../../../Images';
import './profomainvpdf.css';

export default function Template({

  pdfDatas,
  profoList,
  profoDetails,
  invoiceNumber,
  bkgDetail,
  bkgSailSched


  // formInput
}) {

  const style = {
    control: (base) => ({
      ...base,
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const username = localStorage.getItem('name');
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth();
  // const day = date.getDate();
  // const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  let data = {
    customer: 'Test',
    ...pdfDatas,
    ...profoList,
    ...profoDetails,
    invoiceNumber,
    ...bkgDetail,
    ...bkgSailSched,
  };

  console.log(data);

  let count = 0;
  //if (data.container_detail.length) {
  //  let arr = bookingDetails.container_detail.split(',');
  //  for (let str of arr) {
  //    count = count + parseInt(str);
  //  }
  //}

  count = parseInt(data.quantity_20) + parseInt(data.quantity_40);

  //const [noContainers, setNoContainers] = useState(count);
  //const [containers, setContainers] = useState('');
  //
  //const [details, setDetails] = useState(data);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [instructions, setInstructions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [autogenInvNo, setAutogenInvNo] = useState(false);
  const [invNo, setInvNo] = useState('');

  let containers = '';
  if (count > 0) {
    if (data.quantity_20 > 0) {
      containers = containers + data.quantity_20 + ' x 20FT ';
    }

    if (data.quantity_40 > 0) {
      containers = containers + data.quantity_40 + ' x 40FT ';
    }
  }

  const styles = `
  @media print{
  
    
    
  
    @page { 
      // margin: 0 !important;     
      margin: 96px 120px 96px 120px;
      background-color: #FAFAFA; 
     }
     #pageHeader {
      /* Styles for the header section */
      font-family: Arial, sans-serif;
      font-size: 11px;
      // text-align: center;
      padding: 10px;
      // background-color: #f2f2f2;
      // border-bottom: 1px solid #ccc;
      // height:50px;
      letter-spacing: 0.5px;
      
      
    }
    body{
      padding: 2px;
      border-spacing: 0px;
      
      position:relative;
    
   }
  
   .tablepdf {
    padding: 0px;
    margin: 0px;
    line-height: 0;
  }
 

.img{
  height: 50px;
  width:auto;
}
    th .headerLeft{
      text-align: left !important;
   }
  
   .tableContainer {
      // display: flex;
    //   margin: 20px 10px;
    //   border-width: 5px;
      // margin-top:10px; 
      bordercolor: 'white';
   }

   .tablepdf tr {
    //   border: 2px solid !important ;

   }

    .tablepdf th {
      //   border: 2px solid !important ;
    }

    .tablepdf td {
      //   border: 2px solid !important ;
         padding: 5px !important      
    }

    .container {
      padding: 3px; 
    }

    .bankdetails{
    //  padding:100px !important;
      text-decoration:underline !important ;
      text-decroration-thickness:5px !important;
    }

    .nextpage{
    page-break-before:always;
    }
  }

 
  }
  @media print {
    .footer {
      position: fixed;
      bottom: 0;
      width: 97%;
     
      visibility: hidden;
     
    }
  
    .footer:last-child {
      visibility: visible;
    }
  
    .page-break {
      page-break-after: always;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 97%;
    visibility: hidden;
   
   }
   
   .footer:last-child {
    visibility: visible;
   }

   #toPdf{
    font-family : Arial;
    letter-spacing: 0.5px;
    
   }

   #footerhtml{
    font-family : Arial;
    font-size: 11px;
    letter-spacing: 0.5px;

   }

   #parent > td, #child > td{
    border : 1px solid #C3C3C3;
   }

   .tab {
    display: inline-block;
    margin-left: 4em;
}


   /* th, td{
    padding: 1%;
   
   } */
  
  `;

  const [isLoading, setIsLoading] = useState(false);

  const name = localStorage.getItem('name');

  const print = () => {
    let html = document.getElementById('toPdf');
    let style = styles;
    let content;
    if (html.outerHTML) content = html.outerHTML;
    else if (XMLSerializer)
      content = new XMLSerializer().serializeToString(html);

    let headerhtml = document.getElementById('pageHeader');
    // let style = styles;
    let headercontent;
    if (headerhtml.outerHTML) headercontent = headerhtml.outerHTML;

    else if (XMLSerializer)
      headercontent = new XMLSerializer().serializeToString(headerhtml);
    console.log(headercontent);
    setIsLoading(true);

    // DOWNLOADING THE FILE AND UPLOADING AUTOMATICALLY TO DOCUMENT ATTACHMENT WHEN THE REF NO IS APPROVED

    customAxios
      .post('/booking/pdf', {
        header: headercontent,
        template: content,
        style: style,
        bookingRefNo: data.bkg_no,
        refNo: data.invoiceNumber,
        logo: Logo,
      })
      .then((resp) => {
        if (resp.status == 200) {
          customAxios
            .get('/booking/getPdf', { responseType: 'blob' })
            .then((resp) => {
              const blob = new Blob([resp.data], {
                type: 'application/pdf',
                mode: 'no-cors',
              });


              const fileName = `CNOP-${String(data.invoiceNumber).padStart(4, '0')}.pdf`;

              let link = document.createElement('a');
              link.download = `CNOP -${String(data.invoiceNumber).padStart(4, '0')}.pdf`;
              link.href = URL.createObjectURL(blob);
              link.click();
              URL.revokeObjectURL(link.href);
              setIsLoading(false);

              customAxios
                .post('/cnops/approve/getbyId', {
                  inv_apprv_no: data.invoiceNumber,
                })
                .then((data) => {
                  console.log(data);

                  if (data.data.data.is_approved === 1) {
                    const formData = new FormData();
                    formData.append('name', data.data.data.bkg_no);
                    formData.append('images', blob, fileName);

                    customAxios
                      .post('/exp/upload', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      })
                      .then((uploadResponse) => {
                        console.log('File uploaded successfully!');
                        console.log('Response:', uploadResponse.data);
                      })
                      .catch((err) => console.log(err.message || 'Some error'));
                  } else {
                    console.log('PDF upload not allow');
                  }
                })
                .catch((err) => console.log(err.message || 'Some error'));
            })
            .catch((err) => console.log(err.message || 'Some error'));
        }
      });
  };

  useEffect(() => {
    customAxios.post('/customer/byName', { name: data.customer }).then((resp) => {
      if (resp && resp.data && resp.data.data)
        setCustomerDetails(resp.data.data);
      console.log(resp.data.data);
      console.log(data.sailing_schedule);
    });
  }, [data.customer]);

  useEffect(() => {
    if (invoiceNumber) {
      customAxios
        .post('/cnops/approve/getbyId', {
          ref_id: invoiceNumber,
        })
        .then((responseData) => {
          console.log(responseData);
          const invoiceNo = responseData.data.data.inv_apprv_no;
          if (responseData.data.data.is_approved === 1) {
            setAutogenInvNo(true);
            setInvNo(invoiceNo);
          }
          console.log(responseData.data.data.is_approved === 1);
          console.log("67676", invoiceNo);
        })
        .catch((err) => console.log(err.message || 'Some error'));
    }
  }, []);

  //converted logo image as a base64
  const [base64Image, setBase64Image] = useState('');
  useEffect(() => {
    // Load the image and convert it to base64
    const loadImageAsBase64 = async () => {
      const response = await fetch(HeaderLogo); // Replace with your image URL
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(blob);
    };

    loadImageAsBase64();
  }, []);

  //polEta
  const etaDate = new Date(data.eta);

  const dayy = etaDate.getDate().toString().padStart(2, '0');
  const monthhh = (etaDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const yearrr = etaDate.getFullYear();

  const formattedEta = `${dayy}/${monthhh}/${yearrr}`;

  //for amount in words value
  var amount = data.amt_in_words;
  var amtinwords = amount && amount.replace('-', ' ');

  return (
    <>
      <div id="pageHeader" style={{ padding: "5px 20px 0px 20px", display: 'none' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <td colspan="4" style={{ textAlign: 'left' }}><img src={base64Image} alt="Image" style={{ height: '15%', width: '55%', verticalAlign: 'top' }} /></td>
              <td colspan="3" style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center', textAlign: 'justify', fontFamily: 'Arimo', width: '12%' }}>CN-OPS</td>
            </tr>
          </thead>
        </table>
      </div>
      <div id="toPdf" style={{ padding: "5px 20px 5px 20px", display: 'none' }}>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            <tr>

              <td colspan="5" style={{ fontSize: '7px'/* , width: '50%' */, fontFamily: 'Arimo' }}>CNOPS TO:</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>BOOKING NO#.</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>{/* {`${"RILWEM/FE/" + year + (autogenInvNo ? '/' + (String(invNo).padStart(4, '0')) : '(' + data.refNo + ')')}`} */}{`${data.bkg_no}`}</td>
            </tr>
            <tr >

              <td colspan="5" rowspan="3" style={{ fontSize: '7px', fontFamily: 'Arimo' }}>
                {data && data.to_customer_name}
                <br />
                {data && data.address && data.address.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>REF NO#</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${"FE/WBY/" + year + "/" + String(data.invoiceNumber).padStart(4, '0')}`}</td>
            </tr>

            <tr>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>VESSEL</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>{`${data.vessel}`}</td>
            </tr>
            <tr>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '26%' */, fontFamily: 'Arimo' }}>DATE</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${date}`}</td>
            </tr>


            <tr>
              <td colspan="1" style={{ fontSize: '7px'/* , width: '30%' */, borderBottom: 'none' }}>&nbsp;</td>
              <td colspan="4" style={{ fontSize: '7px'/* , width: '20%' */, borderBottom: 'none' }}>&nbsp;</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px', borderBottom: '1px solid #C3C3C3', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}>&nbsp;</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}>&nbsp;</td>
            </tr>


            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>AGENT{/* &emsp;&emsp;&emsp;&emsp;&ensp;&ensp;&nbsp;: */}</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.agent}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px', borderTop: '1px solid #C3C3C3', width: '26%', fontFamily: 'Arimo' }}>BL</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', borderTop: '1px solid #C3C3C3', width: '24%', fontFamily: 'Arimo' }}>{`${data.bl_no}`}</td>
            </tr>
            <tr >
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>VOLUME{/* &emsp;&emsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;: */}</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${"20'Ft:" + ' ' + data.quantity_20 + ' ' + "40'Ft:" + ' ' + data.quantity_40}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>POD/ETA</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.pod + ' ' + '&' + ' ' + formattedEta}`}</td>
            </tr>
            <tr>
              <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>Remarks{/* &emsp;&nbsp;&nbsp;&nbsp;&ensp;: */}</td>
              <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
              <td colspan="2" style={{ fontSize: '7px', width: '36%',/* , width: '40%', */ fontFamily: 'Arimo' }}>{`${pdfDatas.remarks}`}</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', width: '20%', fontFamily: 'Arimo' }}>VENDOR REF#</td>
              <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%'/* , width: '20%' */, fontFamily: 'Arimo' }}>{`${data.vendor_ref ? data.vendor_ref : ' '}`}</td>
            </tr>
            {/* <tr>
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>LOADING POINT</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.yard_detail}`}</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>POD/ETA</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.pod + ' ' + '&' + ' ' + formattedEta}`}</td>
          </tr>
          <tr>
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>REMARKS</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.remarks}`}</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>CARGO</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.cargo}`}</td>
          </tr>
          <tr>
            <td colspan="2" style={{ fontSize: '7px', fontFamily: 'Arimo' }}></td>
            <td colspan="3" style={{ fontSize: '7px', fontFamily: 'Arimo' }}></td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', fontFamily: 'Arimo' }}>VOLUME</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', fontFamily: 'Arimo' }}>{`${data.quantity_20 ? ("20'Ft:" + ' ' + data.quantity_20 + ' ' + (data.quantity_40 ? ("40'Ft:" + ' ' + data.quantity_40) : ' ')) : ("40'Ft:" + ' ' + data.quantity_40)}`}</td>
          </tr> */}



            <tr>
              <td colSpan={7}>&nbsp;</td>
            </tr>
          </tbody>

        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>

            <tr style={{ border: '1px solid #C3C3C3', backgroundColor: '#F2F2F2', fontSize: '7px' }} id="parent">
              <td style={{ width: '50%', textAlign: 'center', fontFamily: 'Arimo' }}>CHARGES</td>
              <td style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>CURR</td>
              <td style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>RATE</td>
              <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>BASE</td>
              <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>EX RATE</td>
              <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>QUANTITY</td>
              <td style={{ width: '10%', textAlign: 'center', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>AMOUNT({`${data.currency}`})</td>
            </tr>

            {profoList ? <>

              {profoList.map((data, index) => (
                <tr style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px' }} id="child">
                  <td style={{ width: '20%', textAlign: 'Left', fontFamily: 'Arimo' }}>{`${data.charge}`}</td>
                  <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.currency}`}</td>
                  <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.rate ? parseFloat(data.rate).toFixed(2) : 0.00}`}</td>
                  <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.base}`}</td>
                  <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.exchangeRate == null ? ' ' : data.exchangeRate}`}</td>
                  <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.quantity}`}</td>
                  <td style={{ width: '20%', textAlign: 'center', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>{`${data.total == null ? '0.00' : parseFloat(data.total).toFixed(2)}`}</td>

                </tr>

              )
              )} </>
              : null}
            <tr style={{ fontSize: '7px' }}>
              <td colspan={6} style={{ width: '20%', textAlign: 'left', borderRight: '1px solid #C3C3C3', borderLeft: '1px solid #C3C3C3'/* borderTop: 'none', borderBottom: 'none' */, fontFamily: 'Arimo' }}>TOTAL</td>
              <td colspan={1} style={{ width: '80%', textAlign: 'center', fontWeight: 'bold', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>{`${/* profoList ? (profoList.total == null || profoList.total == 0 ? ' ' : parseFloat(profoList.total).toFixed(2)) : 0 */pdfDatas.total_amt ? parseFloat(pdfDatas.total_amt).toFixed(2) : ' '}`}</td>

            </tr>
            <tr style={{ fontSize: '7px', borderBottom: 'none' }} id="child">
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            </tr>
          </tbody>

        </table>
        <div style={{ minHeight: '170px', position: 'relative' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>

            <tbody>
              <tr style={{ fontSize: '7px' }} >
                <td colspan={4} style={{ textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
                <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borederBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
                <td style={{ width: '20%', textAlign: 'left', borderRight: '1px solid #C3C3C3', fontWeight: 'bold', textAlign: 'center', borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              </tr>

              {/* <tr style={{ fontSize: '7px' }}>
            <td colspan={2} style={{ width: '20%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>TOTAL<span class="tab"></span><span class="tab"></span>&emsp;&nbsp;:</td>
            <td colspan={5} style={{ width: '80%', textAlign: 'left', fontWeight: 'bold' }}>{`${allCharges ? (allCharges.total == null || allCharges.total == 0 ? '0' : parseFloat(allCharges.total).toFixed(2)) : 0}`}</td>

          </tr> */}

              <tr style={{ fontSize: '7px' }}>
                <td colspan={2} style={{ width: '20%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>Amount In Words{/* &emsp;&emsp;&emsp;&ensp;&nbsp;: */}</td>
                <td colspan={5} style={{ width: '60%', textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo', textTransform: 'uppercase' }}>{`${data.currency}`}  {`${amtinwords}`}{/* {`${data.amtinwords.toUpperCase()}`} */}</td>

              </tr>
              {/* <tr style={{ fontSize: '7px' }}>
              <td colspan={2} style={{ width: '20%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td colspan={5} style={{ width: '60%', textAlign: 'left', fontWeight: 'bold' }}>&nbsp;</td>

            </tr>

            <tr style={{ fontSize: '7px' }}>
              <td colspan={4} style={{ textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>CONTAINER DETAILS</td>
              <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ textAlign: 'left', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>
            <tr style={{ fontSize: '7px' }}>
              <td colspan={4} style={{ textAlign: 'left', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>{`${data.container_details}`}</td>
              <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr> */}

            </tbody>

          </table>

        </div>
      </div>



      <div onClick={print} > {/* ref={loaderRef}  disabled={IsLoading} */}
        {/* Download PDF */}
        {isLoading ?

          <img src={Loader} width="25px" alt="" /> : 'Download PDF'
        }
      </div>
    </>
  );
}
export { default as Logo } from './logo.png';
export { default as LogoText } from './logo_text.png';
export { default as NotificationIcon } from './icon_alert.png';
export { default as ProfileIcon } from './icon_myprofile.png';
export { default as Icon1 } from './icon_db_1.png';
export { default as Icon2 } from './icon_db_2.png';
export { default as Icon3 } from './icon_db_3.png';
export { default as Icon4 } from './icon_db_4.png';
export { default as SearchIcon } from './icon_search.png';
export { default as EditIcon } from './icon_edit.png';
export { default as DeleteIcon } from './icon_delete.png';
export { default as Loader } from './loader.gif'; 
export { default as upload } from './upload.jpg';
export { default as icon_download } from './icon_download.png';
export { default as icon_view } from './icon_view.png';
export {default as HeaderLogo} from './header_image_1.jpg';
// export {default as HeadLogo} from './header_image_2.png';

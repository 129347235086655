import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './Shipping.module.css';
import Template from './BLPdfTemplate';


export default function BillOfLading() {

  const initDetails = {
    booking_number: '',
    //carrier_booking_no: '',
    //carrier_bl: '',
    issued_at: '',
    issued_on: '',
    bl_type: '',
    //bl_by: '',
    shipper_name: '',
    shipper_address: '',
    consignee_name: '',
    consignee_address: '',
    notify_party_name: '',
    notify_party_address: '',
    also_notify_party_name: '',
    also_notify_party_address: '',
    forwarding_agent: '',
    mark_and_no: '',
    mark_description: '',
    container_details: [],
  };

  const initBooking = {
    buying_id: '',
    selling_id: '',
    //  teus: '',
    //  container_detail: '',
    carrier_booking_no: '',
    quantity_20: '',
    quantity_40: '',
    house_bl: false,
    vessel: '',
    voyage: '',
  };

  const initContDetail = {
    container_no: '',
    seal_no: '',
    package_no: '',
    package_type: '',
    gross_wt: '',
    net_wt: '',
    measurement: '',
  };

  const initBuying = {
    freight: '',
    source: '',
    destination: '',
    loading_port: '',
    discharge_port: '',
    carrier: '',
    _id: '',
  };

  const [bookingNumbers, setBookingNumbers] = useState([]);
  const [details, setDetails] = useState(initDetails);
  const [bookingDetails, setBookingDetails] = useState(initBooking);
  const [buyingDetails, setBuyingDetails] = useState(initBuying);
  const [contDetail, setContDetail] = useState(initContDetail);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [no_containers, setNoContainers] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [shippingNo, setShippingNo] = useState('');
  const [houseBL, setHouseBL] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [bookingNo, setBookingNo] = useState('');
  const [Isreleased, setIsreleased] = useState(0);
  const [bkngReleaseId, setBkngReleaseId] = useState('');
  const [inputdisable, setinputdisable] = useState(false);

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  const handleclose = () => setShowPreview(false);

  const blOptions = [
    { value: 'Reudan', label: 'House B/L' },
    { value: 'Carrier', label: 'Carrier B/L' },
  ];

  const blTypes = [
    { value: 'Seaway', label: 'Seaway' },
    { value: 'Original', label: 'Original' },
  ];

  let buttonEnabled = !!(
    contDetail.container_no &&
    contDetail.package_no &&
    contDetail.package_type &&
    contDetail.seal_no &&
    contDetail.gross_wt
    // contDetail.net_wt 
    // contDetail.measurement
  );

  let removeButton = !!(details.container_details.length < no_containers);

  const saveDetails = ({ key, e }) => {

    let temp = { ...details };

    //let value = e ? e.value : '';
    temp[key] = e ? e.value : '';
    //
    //
    setDetails({ ...temp });

    //setDetails({ ...details, key: value });

  };

  const saveContDetail = ({ key, evt }) => {
    let temp = { ...contDetail };
    temp[key] = evt && evt.value ? evt.value : '';
    setContDetail(temp);
    //
  };

  const addContDetail = () => {
    let temp = { ...details };
    temp['container_details'] = [...details.container_details, contDetail];
    setDetails(temp);
    setContDetail(initContDetail);
  };

  const editContainerDetails = (index) => {
    let temp = { ...details };
    let temp_container_details = temp.container_details;
    setContDetail(temp_container_details[index]);

    temp['container_details'] = temp_container_details.splice(index, 1);
    setDetails({ ...details, container_details: temp_container_details });
    //setDetails(temp);
  };

  const deleteContainerDetails = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      let temp = { ...details };
      let temp_container_details = temp.container_details;
      temp_container_details.splice(index, 1);

      setDetails({ ...details, container_details: temp_container_details });
      setContDetail(initContDetail);
    }
  };

  const saveData = () => {
    let temp = {
      ...details,
      //  shipper_name: bookingDetails.customer,
      current_date:date,
      freight: buyingDetails.freight,
      place_of_receipt: buyingDetails.source,
      place_of_delivery: buyingDetails.destination,
      port_of_loading: buyingDetails.loading_port,
      port_of_discharge: buyingDetails.discharge_port,
      bl_by: bookingDetails.house_bl ? 'House B/L' : 'Carrier B/L',
      Isreleased: 0,
    };

    if (
      details.booking_number &&
      details.issued_at &&
      details.issued_on &&
      //details.bl_by &&
      details.bl_type &&
      details.container_details &&
      temp.shipper_name &&
      details.shipper_address &&
      details.consignee_name &&
      details.consignee_address
      && date
      //  details.notify_party_name &&
      //  details.also_notify_party_address
    ) {
      if (!shippingNo) {
        customAxios
          .post('/shipping/creation', { ...temp })
          .then((res) => {
            if (res.data.error) window.alert('Unable to save the details!!');
            else {
              window.alert('Detail saved successfully');
              setBkngReleaseId(res._id);
              setShippingNo(res.data.count);
            }
          })
          .catch((err) =>
            window.alert(err.message || 'Some internal error occured')
          );
      } else {
        customAxios
          .post('/shipping/update', {
            id: shippingNo,
            shippingDetails: temp,
          })
          .then((res) => {
            if (res.data.error) window.alert('Unable to save the details!!');
            else {
              window.alert('Detail saved successfully');

            }
          })
          .catch((err) =>
            window.alert(err.message || 'Some internal error occured')
          );
      }

    } else {
      window.alert('Please fill all the fields');
    }
  };

  const ReleaseId = () => {
    var result = window.confirm("Are you sure you want to release?");

    if (result) {
      customAxios
        .post('/shipping/updaterelease', {
          "temp": {
            "_id": bkngReleaseId,
            "Isreleased": 1
          }
        })
        .then((res) => {
          // 
          if (res.data.error) window.alert('Unable to update the details!!');
          else {
            window.alert('Released successfully');
            setIsreleased(1)
          }
        });

    } else {
      // user clicked Cancel, do not proceed with the deletion
    }
  }

  const handleShippingNo = (val) => {
    if (val) {
      customAxios
        .post('/booking/getById', { id: val })
        .then((resp) => {
          let res = resp.data.data;


          setHouseBL(res.house_bl);
          if (res.buying_id)
            setBookingDetails({
              buying_id: res.buying_id,
              selling_id: res.selling_id,
              //  teus: res.teus,
              //  container_detail: res.container_detail,
              carrier_booking_no: res.liner_ref_number,
              quantity_20: res.quantity_20,
              quantity_40: res.quantity_40,
              house_bl: res.house_bl,
              vessel: res.sailing_schedule.vessel,
              voyage: res.sailing_schedule.voyage,
            });
          else setBookingDetails(initBooking);
        });


      customAxios
        .post('/selling/getById', { id: bookingDetails.selling_id })
        .then((result) => {

          if (!result.data.error && result.data.data) {
            let {
              customer,
            } = result.data.data;
            setCustomerName(customer);

          }
        });

      customAxios
        .post('/customer/byName', { name: customerName })
        .then((resp) => {
          let res = resp.data.data;
          setCustomerAddress(res ? res.address : '');
        });

      customAxios
        .post('/shipping/getById', { id: val })
        .then((resp) => {
          let res = resp.data.data;
          setIsreleased(res.Isreleased ? res.Isreleased : 0);
          setBkngReleaseId(res._id);


          if (res.shipping_id) {

            setShippingNo(res.shipping_id);
            setDetails({
              booking_number: res.booking_number,
              current_date: res.date,
              //carrier_booking_no: '',
              //carrier_bl: '',
              issued_at: res.issued_at,
              issued_on: res.issued_on.substring(0, 10),
              bl_type: res.bl_type,
              //  bl_by: res.bl_by,
              shipper_name: res.shipper_name,
              shipper_address: res.shipper_address,
              consignee_name: res.consignee_name,
              consignee_address: res.consignee_address,
              notify_party_name: res.notify_party_name,
              notify_party_address: res.notify_party_address,
              also_notify_party_name: res.also_notify_party_name,
              also_notify_party_address: res.also_notify_party_address,
              forwarding_agent: res.forwarding_agent,
              mark_and_no: res.mark_and_no,
              mark_description: res.mark_description,
              container_details: res.container_details,
            });


          }
          else {
            setShippingNo('');
            setDetails({
              booking_number: val,
              current_date: '',
              issued_at: '',
              issued_on: '',
              shipper_name: customerName,
              shipper_address: customerAddress,
              consignee_name: '',
              consignee_address: '',
              notify_party_name: '',
              notify_party_address: '',
              also_notify_party_name: '',
              also_notify_party_address: '',
              forwarding_agent: '',
              mark_and_no: '',
              mark_description: '',
              container_details: [],
            });
          }
        });

      setContDetail(initContDetail);
    } else {
      setShippingNo('');
      setBookingDetails(initBooking);
      setDetails(initDetails);
      setContDetail(initContDetail);
      setHouseBL(false);
    }
  };

  useEffect(() => {
    //if (
    //  bookingDetails.container_detail &&
    //  bookingDetails.container_detail.length
    //) {

    let count = 0;
    count = parseInt(bookingDetails.quantity_20) + parseInt(bookingDetails.quantity_40);
    if (count > 0) {
      //let arr = bookingDetails.container_detail.split(',');


      //for (let str of arr) {
      //  count = count + parseInt(str);
      //}
      setNoContainers(count);
    } else {
      setNoContainers(0);
    }
  }, [parseInt(bookingDetails.quantity_20) + parseInt(bookingDetails.quantity_40)]);

  /*useEffect(() => {
    customAxios
      .post('/booking/getById', { id: details.booking_number })
      .then((resp) => {
        let res = resp.data.data;
        
        
        setHouseBL(res.house_bl); 
        if (res.buying_id)
          setBookingDetails({
            buying_id: res.buying_id,
            selling_id: res.selling_id,
          //  teus: res.teus,
          //  container_detail: res.container_detail,
            carrier_booking_no: res.liner_ref_number,
            quantity_20: res.quantity_20,
            quantity_40: res.quantity_40,
            house_bl: res.house_bl,
            vessel: res.sailing_schedule.vessel,
            voyage: res.sailing_schedule.voyage,
          });
        else setBookingDetails(initBooking);
      });

      
      customAxios
        .post('/selling/getById', { id: bookingDetails.selling_id })
        .then((result) => {
          
          if (!result.data.error && result.data.data) {
            let {
              customer,
            } = result.data.data;
            setCustomerName(customer);
          }
      });

      customAxios
      .post('/shipping/getById', { id: details.booking_number })
      .then((resp) => {
        let res = resp.data.data;
        
        if (res.shipping_id){
          setShippingNo(res.shipping_id);             
          setDetails({
            booking_number: res.booking_number,
            //carrier_booking_no: '',
            //carrier_bl: '',
            issued_at: res.issued_at,
            issued_on: res.issued_on.substring(0, 10),
            bl_type: res.bl_type,
          //  bl_by: res.bl_by,
            shipper_name: res.shipper_name,
            shipper_address: res.shipper_address,
            consignee_name: res.consignee_name,
            consignee_address: res.consignee_address,
            notify_party_name: res.notify_party_name,
            notify_party_address: res.notify_party_address,
            also_notify_party_name: res.also_notify_party_name,
            also_notify_party_address: res.also_notify_party_address,
            forwarding_agent: res.forwarding_agent,
            mark_and_no: res.mark_and_no,
            mark_description: res.mark_description,
            container_details: res.container_details,
          });
        }
        else{
          setShippingNo('');
          setDetails({
            booking_number: details.booking_number,
            issued_at: '',
            issued_on: '',
            shipper_name: customerName,
            shipper_address: '',
            consignee_name: '',
            consignee_address: '',
            notify_party_name: '',
            notify_party_address: '',
            also_notify_party_name: '',
            also_notify_party_address: '',
            forwarding_agent: '',
            mark_and_no: '',
            mark_description: '',
            container_details: [],
          });
        }
      });

      setContDetail(initContDetail);
  }, [details.booking_number]);
  */

  useEffect(() => {
    customAxios
      .post('/buyer/getById', { id: bookingDetails.buying_id })
      .then((resp) => {
        let res = resp.data.data;
        //
        if (res) setBuyingDetails({ ...res });
        else setBuyingDetails(initBuying);
      });
  }, [bookingDetails.buying_id]);

  //useEffect(() => {
  //  
  //  customAxios
  //    .post('/selling/getById', { id: bookingDetails.selling_id })
  //    .then((result) => {
  //      
  //      if (!result.data.error && result.data.data) {
  //        let {
  //          customer,
  //        } = result.data.data;
  //        saveDetails({
  //          key: 'shipper_name',
  //          e: { value: customer },
  //        });
  //      }
  //    });
  //}, [shippingNo=='']);

  useEffect(() => {
    customAxios.get('/booking/ids').then((ids) => {
      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: 'RUKEX' + data.booking_id, value: data.booking_id };
      });
      setBookingNumbers([...details]);
    });
    customAxios.get('/customer').then((customers) => {
      let temp = [...customers.data.data];
      let customersDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCustomerOptions([...customersDetails]);
    });
    customAxios.get('/package').then((packages) => {
      let temp = [...packages.data.data];
      let packageDetails = temp.map((data) => {
        return { label: data.code, value: data.code };
      });
      setPackageTypes([...packageDetails]);
    });
  }, []);

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    menu: base => ({
      ...base,
      zIndex: 2
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const handleView = () => {
    setShowPreview(!showPreview);
  };

  const updateShippingAddress = (val) => {
    customAxios
      .post('/customer/byName', { name: val })
      .then((resp) => {
        let res = resp.data.data;
        setDetails({ ...details, shipper_name: val, shipper_address: res ? res.address : '' });
      });
  }

  const updateConsigneeAddress = (val) => {
    customAxios
      .post('/customer/byName', { name: val })
      .then((resp) => {
        let res = resp.data.data;
        setDetails({ ...details, consignee_name: val, consignee_address: res ? res.address : '' });
      });

  }
  const updateNotifyPartyAddress = (val) => {
    customAxios
      .post('/customer/byName', { name: val })
      .then((resp) => {
        let res = resp.data.data;
        setDetails({ ...details, notify_party_name: val, notify_party_address: res ? res.address : '' });
      });

  }
  const updateAlsoNotifyPartyAddress = (val) => {
    customAxios
      .post('/customer/byName', { name: val })
      .then((resp) => {
        let res = resp.data.data;
        setDetails({ ...details, also_notify_party_name: val, also_notify_party_address: res ? res.address : '' })
      });

  }
  return (
    <>
      <div className={classes.container}>
        <h5>Shipping Instruction <span className={classes.title}>Export / Shipping</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>Booking#</p>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={bookingNumbers}
              value={bookingNumbers.filter(
                (option) => option.value === bookingNo
              )}
              onChange={(e) => { saveDetails({ key: 'booking_number', e: e }); setBookingNo(e ? e.value : ''); handleShippingNo(e ? e.value : ''); }}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Date</p>
              <span>*</span>
            </section>
            <input
              className={classes.inputText}
              value={date}
              disabled={inputdisable ? true : false}
              // onChange={(e) =>
              //   saveDetails({
              //     key: 'current_date',
              //     e: e.target
              //   })
              // }
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Carrier Booking#</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={bookingDetails.carrier_booking_no}
                //onChange={(e) =>
                //  saveDetails({ key: 'carrier_booking_no', e: e.target })
                //}
                disabled={true}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>B/L Release</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={bookingDetails.house_bl ? 'House B/L' : 'Carrier B/L'}
                //onChange={(e) =>
                //  saveDetails({ key: 'bl_by', e: e.target })
                //}
                disabled={true}
              />
            </section>

          </Col>
          { /*<Col lg='2' className={classes.input}>
            <section>
              <p>Carrier BL#</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={details.carrier_bl}
                onChange={(e) =>
                  saveDetails({ key: 'carrier_bl', e: e.target })
                }
              />
            </section>
              </Col>*/ }
          <Col className={classes.input}>
            <section>
              <p>Issued at</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={details.issued_at}
                onChange={(e) => saveDetails({ key: 'issued_at', e: e.target })}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Issued on</p>
            </section>
            <section>
              <input
                type='date'
                className={classes.inputText}
                value={details.issued_on}
                onChange={(e) => saveDetails({ key: 'issued_on', e: e.target })}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Freight</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={buyingDetails.freight}
                disabled={true}
              />
            </section>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col lg='3' className={classes.input}>
            <section>
              <p>Shipper</p>
            </section>

            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Name</div>
              </Col>
              <Col>
                { /*<input
                    type='text'
                    className={classes.inputText}
                    value={buyingDetails.carrier}
                    disabled={true}
                    // onChange={(evt) => handleBookingNo(evt)}
                    // onKeyUp={(evt) => getBookingDetail(evt)}
              />*/ }
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={customerOptions}
                  value={customerOptions.filter(
                    (option) => option.value === details.shipper_name
                  )}
                  onChange={(e) => { saveDetails({ key: 'shipper_name', e: e }); updateShippingAddress(e ? e.value : ''); }}
                />
              </Col>
            </Row>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Address</div>
              </Col>
              <Col>
                { /*<textarea
                    className={classes.newtextarea}
                    value={details.shipper_address}
                    disabled={true}
                    ></textarea> */ }
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'shipper_address',
                      e: e.target,
                    })
                  }
                  value={details.shipper_address}
                ></textarea>
              </Col>
            </Row>
          </Col>

          <Col lg='3' className={classes.input}>
            <section>
              <p>Consignee</p>
            </section>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Name</div>
              </Col>
              <Col>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={customerOptions}
                  value={customerOptions.filter(
                    (option) => option.value === details.consignee_name
                  )}
                  onChange={(e) => { saveDetails({ key: 'consignee_name', e: e }); updateConsigneeAddress(e ? e.value : ''); }}
                />
              </Col>
            </Row>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Address</div>
              </Col>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'consignee_address',
                      e: e.target,
                    })
                  }
                  value={details.consignee_address}
                ></textarea>
              </Col>
            </Row>
          </Col>

          <Col lg='3' className={classes.input}>
            <section>
              <p>Notify Party</p>
            </section>

            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Name</div>
              </Col>
              <Col>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={customerOptions}
                  value={customerOptions.filter(
                    (option) => option.value === details.notify_party_name
                  )}
                  onChange={(e) => {
                    saveDetails({ key: 'notify_party_name', e: e });
                    updateNotifyPartyAddress(e ? e.value : '');
                  }
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Address</div>
              </Col>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'notify_party_address',
                      e: e.target,
                    })
                  }
                  value={details.notify_party_address}
                ></textarea>
              </Col>
            </Row>
          </Col>

          <Col lg='3' className={classes.input} style={{ height: '100%' }}>
            <section>
              <p>Also Notify Party</p>
            </section>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Name</div>
              </Col>
              <Col>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={customerOptions}
                  value={customerOptions.filter(
                    (option) => option.value === details.also_notify_party_name
                  )}
                  onChange={(e) => {
                    saveDetails({ key: 'also_notify_party_name', e: e });
                    updateAlsoNotifyPartyAddress(e ? e.value : '');
                  }
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row} style={{ marginTop: '10px' }}>
              <Col lg='2'>
                <div className={classes.label_sec}>Address</div>
              </Col>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'also_notify_party_address',
                      e: e.target,
                    })
                  }
                  value={details.also_notify_party_address}
                ></textarea>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>Place of Receipt</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={buyingDetails.source}
                disabled={true}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Port of Loading</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={buyingDetails.loading_port}
                disabled={houseBL ? true : false}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Port of Discharge</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={buyingDetails.discharge_port}
                disabled={houseBL ? true : false}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Place of Delivery</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={buyingDetails.destination}
                disabled={true}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p style={{ paddingBottom: '8px' }}>Forwarding Agent</p>
            </section>
            <Row className={classes.row} style={{ marginBottom: '0px' }}>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'forwarding_agent',
                      e: e.target,
                    })
                  }
                  value={details.forwarding_agent}
                ></textarea>
              </Col>
            </Row>
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Vessel</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={bookingDetails.vessel}
                disabled={true}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Voyage</p>
            </section>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={bookingDetails.voyage}
                disabled={true}
              />
            </section>
          </Col>
        </Row>

        <Row className={classes.row}>
          <Col className={classes.input}>
            <Row>
              <Col lg='2'>
                <p>Mark & No</p>
              </Col>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'mark_and_no',
                      e: e.target,
                    })
                  }
                  value={details.mark_and_no}
                ></textarea>
              </Col>
            </Row>
          </Col>
          <Col className={classes.input}>
            <Row>
              <Col lg='2'>
                <p>Description</p>
              </Col>
              <Col>
                <textarea
                  className={classes.newtextarea}
                  onChange={(e) =>
                    saveDetails({
                      key: 'mark_description',
                      e: e.target,
                    })
                  }
                  value={details.mark_description}
                ></textarea>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col lg='3' className={classes.input}>
            <section>
              <p>B/L Type</p>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={blTypes}
              value={blTypes.filter(
                (option) => option.value === details.bl_type
              )}
              onChange={(e) => saveDetails({ key: 'bl_type', e: e })}
            />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <p>{`Container Information (No of Containers: ${no_containers})`}</p>
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <div>Container No</div>
            <input
              type='text'
              className={classes.inputText}
              value={contDetail.container_no}
              onChange={(e) =>
                saveContDetail({ key: 'container_no', evt: e.target })
              }
            />
          </Col>
          <Col className={classes.input}>
            <div>Seal No</div>
            <input
              type='text'
              className={classes.inputText}
              value={contDetail.seal_no}
              onChange={(e) =>
                saveContDetail({ key: 'seal_no', evt: e.target })
              }
            />
          </Col>
          <Col className={classes.input}>
            <div>Package No</div>
            <input
              type='text'
              className={classes.inputText}
              value={contDetail.package_no}
              onChange={(e) =>
                saveContDetail({ key: 'package_no', evt: e.target })
              }
            />
          </Col>
          <Col className={classes.input}>
            <div>Package Type</div>
            {/* <input
              type='text'
              className={classes.inputText}
              value={contDetail.package_type}
              onChange={(e) =>
                saveContDetail({ key: 'package_type', evt: e.target })
              }
            /> */}
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={packageTypes}
              value={packageTypes.filter(
                (option) => option.value === contDetail.package_type
              )}
              onChange={(e) => saveContDetail({ key: 'package_type', evt: e })}
            />
          </Col>
          <Col className={classes.input}>
            <div>Gross Wt</div>
            <input
              type='number'
              className={classes.inputText}
              value={contDetail.gross_wt}
              onChange={(e) =>
                saveContDetail({ key: 'gross_wt', evt: e.target })
              }
            />
          </Col>
          <Col className={classes.input}>
            <div>Net Wt</div>
            <input
              type='number'
              className={classes.inputText}
              value={contDetail.net_wt}
              onChange={(e) => saveContDetail({ key: 'net_wt', evt: e.target })}
            />
          </Col>

          <Col className={classes.input}>
            <div>Measurement</div>
            <input
              type='number'
              className={classes.inputText}
              value={contDetail.measurement}
              onChange={(e) =>
                saveContDetail({ key: 'measurement', evt: e.target })
              }
            />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'flex-end' }} md='1'>
            <button
              className={classes.footerButton}
              onClick={addContDetail}
              disabled={!buttonEnabled}
              style={!removeButton ? { display: 'block' } : {}}
            >
              Add
            </button>
          </Col>
        </Row>
        <div
          style={{
            height: '200px',
            overflowY: 'scroll',
            backgroundColor: '#eff0f1',
          }}
        >
          <table class='table'>
            <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: '1' }}>
              <tr>
                <th scope='col' style={theadStyle}>S.No</th>
                <th scope='col' style={theadStyle}>Container No</th>
                <th scope='col' style={theadStyle}>Seal No</th>
                <th scope='col' style={theadStyle}>Package No</th>
                <th scope='col' style={theadStyle}>Package Type</th>
                <th scope='col' style={theadStyle}>Gross Wt</th>
                <th scope='col' style={theadStyle}>Net Wt</th>
                <th scope='col' style={theadStyle}>Measurement</th>
                <th scope='col' style={theadStyle}></th>
              </tr>
            </thead>
            <tbody>
              {details.container_details.length
                ? details.container_details.map((detail, index) => {
                  return (
                    <tr key={index} style={{ backgroundColor: 'lightgray' }}>
                      <th scope='row'>{index + 1}</th>
                      <td>{detail.container_no}</td>
                      <td>{detail.seal_no}</td>
                      <td>{detail.package_no}</td>
                      <td>{detail.package_type}</td>
                      <td>{detail.gross_wt}</td>
                      <td>{detail.net_wt}</td>
                      <td>{detail.measurement}</td>
                      <td>
                        <img
                          src={EditIcon}
                          className={classes.icon}
                          alt='edit_icon'
                          // style={{ borderRadius: '50%' }}
                          onClick={() => editContainerDetails(index)}
                        />
                        &nbsp;&nbsp;
                        <img
                          src={DeleteIcon}
                          className={classes.icon}
                          alt='delete_Icon'
                          // style={{ borderRadius: '50%' }}
                          onClick={() => deleteContainerDetails(index)}
                        />
                      </td>
                    </tr>
                  );
                })
                : ''}
            </tbody>
          </table>
        </div>

        {/*<Modal
          size='md'
          show={showPreview}
          onHide={handleclose}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Booking Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Template
              shippingDetails={details}
              buyingDetails={buyingDetails}
              bookingDetails={bookingDetails}
              shippingNo={shippingNo}
              contDetail={contDetail}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>*/}

        <div className={classes.bookingBtns}>
          {Isreleased == 0 ? <button className={classes.footerBtnRel} onClick={ReleaseId} disabled={!bookingNo}>Release</button>
            : null}

          {/* <button className={classes.footerButton}>ReUse</button> */}

          {Isreleased == 0 ? <button className={classes.footerButton} onClick={saveData}>
            {shippingNo ? 'Update' : 'Save'}
          </button> : null}

          <button className={classes.footerButton}>
            <Template
              shippingDetails={details}
              buyingDetails={buyingDetails}
              bookingDetails={bookingDetails}
              shippingNo={shippingNo}
              contDetail={contDetail}
              details={details}
            />
          </button>
          {Isreleased == 0 ? <button className={classes.footerButton}>Cancel</button> : null}

        </div>
      </Container>
    </>
  );
}
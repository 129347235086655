import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import classes from './Style.module.css';
import CustomTable from '../../customTable';
import ExcelJS from 'exceljs';
import { logDOM } from '@testing-library/react';

export default function ImpDebitReport() {

  const [DebitDetails, setDebitDetails] = useState('');
  const [allCharges, setAllcharges] = useState([]);
  const [contDetails, setcontDetils] = useState([]);
  const [showList, setShowList] = useState(true);
  const [DebitList, setDebitLists] = useState([]);
  const [fromdate, setFromDate] = useState('');
  const [dateFile, setDatefile] = useState([]);
  const [todate, setToDate] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [DebitReceipt, setDebitReceipt] = useState([]);

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };


  const showDebitReport = (index, ref_no) => {
    setShowList(false);

    customAxios.post('/import/voucher/proformadebitnote/report/getbyID', { inv_no: ref_no }).then((result) => {
      if (!result.data.error && result.data.data) {
        console.log(result.data.data);

        const cnopsData = result.data.data.find(item => item.ref_no === ref_no);

        if (cnopsData) {
          let {

            Address,
            agent,
            bkg_no,
            bl_no,
            debit_no,
            cnf,
            consignee,
            container_count,
            contatainer_no,
            currency,
            destination,
            current_date,
            mlo,
            curr_value,
            notify_party,
            ref_no,
            remarks,
            shipper,
            to_address,
            total_amt,
            creditnote_charges,
            amt_in_words,
            DebitReceipt,
            vendor_ref,
          } = cnopsData;

          setDebitDetails({

            Address,
            agent,
            bkg_no,
            bl_no,
            debit_no,
            cnf,
            consignee,
            container_count,
            contatainer_no,
            currency,
            destination,
            current_date,
            mlo,
            curr_value,
            notify_party,
            ref_no,
            remarks,
            shipper,
            to_address,
            total_amt,
            creditnote_charges,
            amt_in_words,
            vendor_ref,
          });
          setDebitReceipt(DebitReceipt);

          console.log(DebitDetails);
          setAllcharges(creditnote_charges);
        } else {
          window.alert('No data found for the specified booking_id');
        }
      }
      else {
        window.alert('No data found');
      }
    })
  }

  const handleClick = () => {
    if (fromdate && todate) {
      const payload = {
        fromdate: fromdate,
        todate: todate
      };

      customAxios.post("/import/voucher/proformadebitnote/report/getbydate", payload)
        .then((response) => {

          const responseData = response.data.data;

          if (responseData && Object.keys(responseData).length > 0) {
            const files = responseData;
            console.log('Files:', files);
            setDebitLists(files);
            console.log('Files:', files);
            console.log(DebitList[0]);
          } else {
            setDebitLists([]);
            alert('No files found for the selected date range.');
          }
        })

        .catch((error) => {
          console.error("Error:", error);
          alert('An error occurred while fetching files.');
        });

    } else {
      alert('Please choose both From Date and To Date');
    }
  };


  //Extracting Data For Excel 
  useEffect(() => {
    // console.log(DebitList);

    const extractData = DebitList && DebitList.flatMap((DebitLists, index) => {
      const formattedDate1 = DebitLists.current_date;
      const validfrom = formattedDate1.split('T')[0];

      const DebitReceipts = DebitLists.debitnoteReceipt || [];
      const balanceAmount = DebitReceipts.length > 0 ? DebitReceipts[DebitReceipts.length - 1].balance_amt : '';

      const rowDataItem = {
        ID: index + 1,
        invoiceno: DebitLists.ref_no,
        BookingNo: DebitLists.bkg_no,
        Debitno: DebitLists.debit_no ? DebitLists.debit_no : 'Not Approved',
        bl_no: DebitLists.liner_ref_number,
        date: validfrom,
        Customer: DebitLists.to_address,
        currency: DebitLists.currency,
        POL: DebitLists.loading_port,
        POD: DebitLists.discharge_port,
        cargo: DebitLists.cnf,
        volume: DebitLists.container_count,
        Amount: DebitReceipts.length > 0 ? (balanceAmount <= 0 ? 'Collected' : 'Not Collected') : 'No Receipt Data',
        DebitReceipt: DebitReceipts,
      };

      return rowDataItem;
    });

    setExcelData(extractData);
  }, [DebitList]);


  const convertToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    const mainHeaderRow = sheet.addRow([
      'S.NO', 'Invoice No', 'Invoice Date', 'BookingNo', 'BL NO', 'DebitNo', 'Customer', 'POL', 'POD', 'Cargo', 'Volume', 'Currency', 'Amount'
    ]);

    //Main Row 
    mainHeaderRow.font = { bold: true };

    excelData.forEach((row) => {
      sheet.addRow([
        row.ID, row.invoiceno, row.date, row.BookingNo, row.bl_no, row.Debitno, row.Customer, row.POL, row.POD, row.cargo, row.volume,
        row.currency, row.Amount,
      ]);

      // Add blank row as separator
      sheet.addRow([]);
    });
    console.log(excelData);


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'Import Debit Report.xlsx');
    });
  };

  const saveAs = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };


  useEffect(() => {
    console.log('file', dateFile);
  }, [dateFile]);

  useEffect(() => {
    let url = '/import/voucher/proformadebitnote/reportall';
    customAxios.get(url).then((Debit) => {
      setDebitLists(Debit.data.data);
      console.log(Debit.data.data);
      { console.log(DebitList); }

    });
  }, [showList]);

  const buyingcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Booking No",
      accessor: "booking_id",
    },
    {
      Header: "Invoice No",
      accessor: "refid",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
    {
      Header: "Cargo",
      accessor: "cargo",
    },
    {
      Header: "Action",
      accessor: "action",
    }

  ];

  console.log(DebitList);
  const DebitDatas = DebitList && DebitList.length ? DebitList.map((item, index) => {
    console.log(item);

    return {
      id: index + 1,
      booking_id: item.bkg_no,
      refid: item.ref_no,
      customer: item.to_address,
      carrier: item.carrier,
      source: item.source,
      loading_port: item.loading_port,
      discharge_port: item.discharge_port,
      destination: item.destination,
      cargo: item.cnf,
      action: (
        <div style={{ textAlign: 'center' }}>
          <img src={icon_view} alt='icon_view' onClick={() => showDebitReport(index, item.ref_no)} />
        </div>
      )
    };

  }) : [];

  if (DebitDatas && DebitDatas.length === 0 || null) {
    DebitDatas.push({
      id: '',
      booking_id: '',
      customer: '',
      valid_till: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
      action: ''
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Debit Report <span className={classes.title}>Import / DebitReport</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Debit Report Listing</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div style={{ display: 'inline-flex', padding: '1%', width: '100%' }}>
                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>From Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={fromdate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>To Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={todate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%', width: '100%' }}>
                  <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  &nbsp;
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <button className={classes.footerButton} style={{ float: 'right' }} onClick={convertToExcel}>
                    Download
                  </button>
                </Col>

              </div>
              <CustomTable columns={buyingcolumns} data={DebitDatas} />
            </div>
          </>
        ) : (
          <>
            {/* <Container className={classes.inputsContainer}> */}


            <table style={{ width: '100%' }}>
              <tbody><tr>
                <td colSpan={1} style={{ width: '10px' }}><span>DN#*</span></td>
                <td colSpan={2} style={{ width: '100px' }}>
                  <input className={classes.inputText} value={DebitDetails ? DebitDetails.debit_no : ' '} disabled={true} type="text" />
                </td>
                <td colSpan={1} style={{ paddingLeft: '97px' }}><span >BKG.No#*</span></td>


                <td colSpan={2} style={{ width: '100px' }}>
                  <section>
                    <div style={{ position: 'relative' }}>
                      <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                      <input
                        type='text'
                        className={classes.inputText}
                        style={{ paddingLeft: '60px' }}
                        value={DebitDetails ? DebitDetails.bkg_no : ''}
                        disabled={true} />
                    </div>
                  </section>
                </td>

                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                <td colSpan={1}><input className={classes.inputText} type="text" value={"RILWEM/FE/"} disabled={true} /></td>
                <td colSpan={1}><input className={classes.inputText} type="text" value={DebitDetails ? DebitDetails.ref_no : ''} disabled={true} /></td>

              </tr>
                <tr>
                  <td>To<span>*</span></td>
                  <td colSpan={5}><input className={classes.inputText} value={DebitDetails ? DebitDetails.to_address : ''}
                    disabled={true} type="text" /></td>

                  <td style={{ paddingLeft: '35px' }}>Date</td>
                  <td colSpan={2}><input className={classes.inputText} value={DebitDetails ? DebitDetails.current_date : ''} type="text" disabled={true} /></td>
                </tr>

                <tr>

                  <td style={{ paddingLeft: '2px' }} colSpan={1} >Address</td>
                  <td colSpan={5}><input className={classes.inputText} value={DebitDetails ? DebitDetails.Address : ''}
                    disabled={true} type="text" /></td>


                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                  <td colSpan={1} ><input className={classes.inputText}
                    type="text" value={DebitDetails ? DebitDetails.currency : ''}
                    disabled={true} >
                  </input>
                  </td>
                  <td colSpan={1}><input className={classes.inputText} value={DebitDetails ? DebitDetails.curr_value : ''} disabled={true} type="text" /></td>
                </tr>

                <tr></tr>
                <tr>

                  <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                  <td colSpan={5}><input className={classes.inputText} value={DebitDetails ? DebitDetails.destination : ''}
                    disabled={true} type="text" /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px', }}>Container</td>
                  <td colSpan={4} rowSpan={3}><textarea style={{ height: '80px'/* , marginTop: '-18px', marginBottom: '0px' */ }} value={DebitDetails ? DebitDetails.container_count : ''}
                    disabled={true} /></td>
                </tr>
                <tr >

                  <td colSpan={1}>Shipper</td>
                  <td colSpan={2}><input className={classes.inputText} value={DebitDetails ? DebitDetails.shipper : ''} disabled={true} type="text" /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                  <td colSpan={2}><input className={classes.inputText} value={DebitDetails ? DebitDetails.agent : ''} disabled={true} type="text" /></td>
                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                </tr>

                <tr >

                  <td colSpan={1} style={{ paddingRight: '50px', paddingBottom: '22px' }}  >Cosingnee</td>
                  <td colSpan={2} ><input className={classes.inputText} value={DebitDetails ? DebitDetails.consignee : ''} disabled={true} type="text" /></td>

                  <td style={{ paddingLeft: '100px', paddingBottom: '22px' }} colSpan={1} >MLO</td>
                  <td colSpan={2} ><input className={classes.inputText} value={DebitDetails ? DebitDetails.mlo : ''} disabled={true} type="text" /></td>
                </tr>

                <tr>
                  <td colSpan={1} >Notify Party</td>
                  <td colSpan={2} ><input className={classes.inputText} value={DebitDetails ? DebitDetails.notify_party : ''}
                    disabled={true} type="text" /></td>

                  <td style={{ paddingLeft: '100px', }} colSpan={1}>CNF</td>
                  <td colSpan={2} ><input className={classes.inputText} value={DebitDetails ? DebitDetails.cnf : ''}
                    disabled={true} type="text" /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px'/* , paddingBottom: '87px' */ }}> Container No.</td>
                  <td colSpan={3} rowSpan={2} style={{ verticalAlign: 'top' }}><textarea style={{ height: '155px'/* , marginTop: '-20px' */ }} value={DebitDetails ? DebitDetails.contatainer_no : ''}
                    disabled={true} /></td>

                </tr>

                <tr>
                  <td colSpan={1} style={{ paddingBottom: '32px' }}>Remarks</td>
                  <td colSpan={5} style={{ paddingTop: '15px', paddingBottom: '46px' }}><textarea style={{ height: '90px' }} value={DebitDetails ? DebitDetails.remarks : ''} disabled={true} /></td>


                </tr>
                <tr><td colSpan="9">
                  <hr></hr>
                </td></tr>


              </tbody>
            </table>

            &nbsp;&nbsp;

            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col' style={theadStyle}> Charges</th>
                    <th scope='col' style={theadStyle}>Curr</th>
                    <th scope='col' style={theadStyle}>Buyingrate</th>
                    <th scope='col' style={theadStyle}>Base</th>
                    <th scope='col' style={theadStyle}>Rate</th>
                    <th scope='col' style={theadStyle}>Exchange Rate</th>
                    <th scope='col' style={theadStyle}>No.Of.Containers</th>
                    <th scope='col' style={theadStyle}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {allCharges.length
                    ? allCharges.map((charge, index) => {
                      return (
                        <tr key={index} style={{ borderBottomWidth: 'none' }}>
                          <td>{charge.charge}</td>
                          <td>{charge.currency}</td>
                          <td>{charge.buyingRate}</td>
                          <td>{charge.base}</td>
                          <td>{parseFloat(charge.rate).toFixed(2)}</td>
                          <td>{charge.exchangeRate}</td>
                          <td>{charge.quantity}</td>
                          {charge.total > 0 ? <td>{parseFloat(charge.total).toFixed(2)}</td> : '0'}
                        </tr>
                      );
                    })
                    : ''}
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <tr>
                  <td colSpan={5} style={{ width: '50%' }}></td>
                  <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                  <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={DebitDetails ? (parseFloat(DebitDetails.total_amt).toFixed(2)) : ''} type="text" disabled={true} ></input></td>

                </tr>
              </table>
            </div>

            <div className={classes.footer} style={{ display: 'flex', justifyContent: 'end', gridGap: '20px', padding: '10px', gap: '20px' }}>
              <button
                className={classes.footerButton} style={{ margin: '1%' }}
                onClick={() => { setShowList(true) }}
              >
                View List
              </button>
            </div>

          </>)}
      </Container>
    </>
  );
}

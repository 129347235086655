import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import classes from './Style.module.css';
import CustomTable from '../../customTable';
import ExcelJS from 'exceljs';

export default function Sellingreport() {

  const [sellingDetails, setSellingDetails] = useState('');
  const [allCharges, setAllcharges] = useState([]);
  const [showList, setShowList] = useState(true);
  const [sellinglist, setSellinglist] = useState([]);
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [approved, setApproved] = useState(false);

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };


  const showSellReport = (index, selling_id) => {
    setShowList(false);

    customAxios.post('import/selling/getById', { id: selling_id }).then((result) => {
      if (!result.data.error && result.data.data) {
        console.log(result.data.data);

        let {
          buying_id,
          carrier,
          source,
          loading_port,
          discharge_port,
          destination,
          customer,
          cargo,
          freight,
          shipment,
          date,
          valid_till,
          remarks,
          selling_rates,
          status,

        } = result.data.data;
        setSellingDetails({
          buying_id,
          selling_id,
          carrier,
          source,
          customer,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          date: date.substring(0, 10),
          valid_till: valid_till.substring(0, 10),
          remarks,
          selling_rates,
          status

        });
        console.log(sellingDetails);
        setAllcharges(selling_rates);
        setApproved(status == 'Approved' ? true : false);
      }
      else {
        window.alert('No data found');
      }
    })
  }

  const handleClick = () => {
    if (fromdate && todate) {
      const payload = {
        fromdate: fromdate,
        todate: todate
      };

      customAxios.post("/import/selling/report/getbydate", payload)
        .then((response) => {
          const responseData = response.data.data;

          if (responseData && responseData.length > 0) {
            const files = responseData;
            console.log(files);
            setSellinglist(files);
          } else {
            setSellinglist([]);
            alert('No files found for the selected date range.');
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert('An error occurred while fetching files.');
        });

    } else {
      alert('Please choose both From Date and To Date');
    }
  };


  //Extracting Data For Excel 
  useEffect(() => {
    const extractData = sellinglist && sellinglist.flatMap((sellinglists, index) => {

      const formattedDate1 = sellinglists.date;
      const formattedDate2 = sellinglists.valid_till;

      const validfrom = formattedDate1.split('T')[0];
      const valid_till = formattedDate2.split('T')[0];

      const rowData = {
        ID: index + 1,
        BuyNo: sellinglists.buying_id,
        SellingNo: sellinglists.selling_id,
        Customer: sellinglists.customer,
        Carrier: sellinglists.carrier,
        POR: sellinglists.source,
        POD: sellinglists.loading_port,
        DischargePort: sellinglists.discharge_port,
        DEL: sellinglists.destination,
        cargo: sellinglists.cargo,
        Freight: sellinglists.freight,
        shipment: sellinglists.shipment,
        date: /* sellinglists.date */ validfrom,
        valid_till: /* sellinglists.valid_till */ valid_till,
        Remarks: sellinglists.remarks
      };

      rowData.selling_rates = sellinglists.selling_rates.map((charge) => ({
        charge: charge.charge,
        currency: charge.currency,
        rate: charge.rate,
        buyingRate: charge.buyingRate,
        base: charge.base,
      }));

      return rowData;
    });

    setExcelData(extractData);
  }, [sellinglist]);



  const convertToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    const mainHeaderRow = sheet.addRow([
      '#', 'BuyNo', 'SellingNo', 'Customer', 'Carrier', 'POR', 'POD', 'Discharge Port', 'DEL', 'Cargo',
      'Freight', 'Shipment', 'Valid From', 'Valid To', 'Remarks'
    ]);

    //Main Row 
    mainHeaderRow.font = { bold: true };

    excelData.forEach((row) => {
      sheet.addRow([
        row.ID, row.BuyNo, row.SellingNo, row.Customer, row.Carrier, row.POR, row.POD, row.DischargePort, row.DEL, row.cargo,
        row.Freight, row.shipment, row.date, row.valid_till, row.Remarks
      ]);

      // Add blank row as separator
      sheet.addRow([]);

      //Charges Row
      const chargesHeaderRow = sheet.addRow(['', '', 'Charge', 'Currency', 'BuyingRate', 'SellingRate', 'Base']);

      chargesHeaderRow.font = { bold: true };

      if (row.selling_rates && Array.isArray(row.selling_rates)) {
        row.selling_rates.forEach((charge) => {
          sheet.addRow(['', '', charge.charge, charge.currency, charge.rate, charge.buyingRate, charge.base]);
        });
      }
      sheet.addRow([]);
    });
    console.log(excelData);


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, ' Import Selling Report.xlsx');
    });
  };

  const saveAs = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    let url = 'import/selling ';
    customAxios.get(url).then((buying) => {
      setSellinglist(buying.data.data);

    });
  }, [showList]);

  const buyingcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Sell No",
      accessor: "selling_id",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
    {
      Header: "Cargo",
      accessor: "cargo",
    },
    {
      Header: "Action",
      accessor: "action",
    }

  ];

  const buyingDatas = sellinglist && sellinglist.length ? sellinglist.map((item, index) => {


    return {
      id: index + 1,
      selling_id: item.selling_id,
      customer: item.customer,
      carrier: item.carrier,
      source: item.source,
      loading_port: item.loading_port,
      discharge_port: item.discharge_port,
      destination: item.destination,
      cargo: item.cargo,
      action: (
        <div style={{ textAlign: 'center' }}>
          <img src={icon_view} alt='icon_view' onClick={() => showSellReport(index, item.selling_id)} />
        </div>
      )
    };

  }) : [];

  if (buyingDatas && buyingDatas.length === 0 || null) {
    buyingDatas.push({
      id: '',
      selling_id: '',
      customer: '',
      valid_till: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
      action: ''
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Selling Report <span className={classes.title}>Import / SellingReport</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Selling Report Listing</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div style={{ display: 'inline-flex', padding: '1%', width: '100%' }}>
                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>From Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={fromdate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <section>
                    <p>To Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={todate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%', width: '100%' }}>
                  <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  &nbsp;
                </Col>

                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                  <button className={classes.footerButton} style={{ float: 'right' }} onClick={convertToExcel}>
                    Download
                  </button>
                </Col>

              </div>
              <CustomTable columns={buyingcolumns} data={buyingDatas} />
            </div>
          </>
        ) : (
          <>
            <Container className={classes.inputsContainer}>
              <Row className={classes.row}>
                <Col className={classes.input}>
                  <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Quotation#</span>
                  <section>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={sellingDetails ? sellingDetails.selling_id : ''}
                      disabled={true}
                    />
                  </section>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Date</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.date : ''}
                    disabled={true}
                  />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Valid Till</p>
                    <span>*</span>
                  </section>
                  <input
                    type='date'
                    min={sellingDetails.date}
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.valid_till : ''}
                    disabled={true}
                  />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Customer</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.customer : ''}
                    disabled={true}
                  />
                </Col>

                <Col className={classes.input}>
                  <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Buying#</span>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.buying_id : ''}
                    disabled={true}
                  />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Status</p>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={approved ? 'Approved' : 'Registered'}
                    disabled={true}
                  />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Carrier</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.carrier : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Cargo</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.cargo : ''}
                    disabled={true} />
                </Col>
              </Row>

              <Row className={classes.row}>
                <Col className={classes.input}>
                  <section>
                    <p>Freight</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.freight : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>Shipment</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.shipment : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>POR</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={sellingDetails ? sellingDetails.source : ''}
                      disabled={true} />
                  </div>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>POL</p>
                    <span>*</span>
                  </section>
                  <div style={{ width: '150px' }}>
                    <input
                      type='text'
                      className={classes.inputText}
                      value={sellingDetails ? sellingDetails.loading_port : ''}
                      disabled={true} />
                  </div>
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>POD</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.discharge_port : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <section>
                    <p>DEL</p>
                    <span>*</span>
                  </section>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.destination : ''}
                    disabled={true} />
                </Col>

                <Col className={classes.input}>
                  <p>Remarks</p>
                  <input
                    type='text'
                    className={classes.inputText}
                    value={sellingDetails ? sellingDetails.remarks : ''} disabled={true}
                  />
                </Col>
              </Row>

              &nbsp;&nbsp;

              <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead className={classes.theadDark}>
                    <tr>
                      <th scope='col' style={theadStyle}>
                        Charges
                      </th>
                      <th scope='col' style={theadStyle}>Curr</th>
                      <th scope='col' style={theadStyle}>BuyingRate</th>
                      <th scope='col' style={theadStyle}>SellingRate</th>
                      <th scope='col' style={theadStyle}>Base</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCharges.length
                      ? allCharges.map((charge, index) => {
                        return (
                          <tr key={index} style={{ borderBottomWidth: 'none' }}>
                            <td>{charge.charge}</td>
                            <td>{charge.currency}</td>
                            <td>{parseFloat(charge.rate).toFixed(2)}</td>
                            <td>{parseFloat(charge.buyingRate).toFixed(2)}</td>
                            <td>{charge.base}</td>

                          </tr>
                        );
                      })
                      : ''}
                  </tbody>
                </table>
              </div>
            </Container>

            <div className={classes.footer} style={{ display: 'flex', justifyContent: 'end', gridGap: '20px', padding: '10px', gap: '20px' }}>
              <button
                className={classes.footerButton} style={{ margin: '1%' }}
                onClick={() => { setShowList(true) }}
              >
                View List
              </button>
            </div>

          </>)}
      </Container>
    </>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from "react-select";

function Vessel() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    code: "",
    name: "",
  };
  const [vessel, setVessel] = useState(initValue);
  const [vessels, setVessels] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  const [vesselUpdate, setVesselUpdate] = useState(false);
  const [error, setError] = useState("");
  const [vesselList, setVesselList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };
  const setVesselDetail = ({ key, value }) => {
    let detail = { ...vessel };
    detail[key] = value.toUpperCase();
    setVessel(detail);

  };

  const saveVessel = () => {
    let temp = { ...vessel };

    if (temp._id) {
      customAxios.post("/vessel/update", { temp }).then((data) => {

        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated successfully!");
        }
      });
    } else {
      let url = "/vessel";
      customAxios
        .post(url, vessel)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setVessel(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const deleteVessel = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios.delete("/vessel", { data: { userId: id } }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Vessel deleted succesfully!");
          let temp = [...vessels];
          temp.splice(index, 1);
          setVessels(temp);
        }
      });
    }
  };

  const editVessel = (index, id) => {
    let temp = [...vessels];
    setVessel({ ...temp[index] });
    setShowList(false);
    setVesselUpdate(true);
  };

  const searchVessel = () => {
    let url = "/vessel/search";
    customAxios.post(url, { searchValue: searchValue }).then((vessels) => {
      setVessels(vessels.data.data);
    });
  };

  useEffect(() => {
    let url = "/vessel";
    customAxios.get(url).then((vessels) => {
      setVessels(vessels.data.data);
      setSearchList(vessels.data.data);
    });

    let vesselurl = "/vessel";
    customAxios.get(vesselurl).then((vessel) => {
      let temp = [...vessel.data.data];
      let vess = temp.map((data) => {
        return { label: data.name, value: data.name }
      })
      setVesselList([...vess]);
    });


  }, [showList]);

  const handleSearch = (option) => {

    if (option == null) {
      let url = "/vessel";
      customAxios.get(url).then((vessels) => {
        setVessels(vessels.data.data);
        setSearchList(vessels.data.data);
        setSelectedVal(null);

      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setVessels(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Vessel</h5>
        <section className={classes.title}>Dashboard / vessel</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Vessel Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchVessel}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={vesselList}
                    value={selectedVal}
                    onChange={handleSearch}
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setVessel(initValue);
                      setVesselUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Vessel Code</th>
                    <th scope="col" style={theadStyle}>Vessel Name</th>

                    {/* {isSuperuser &&  */}<th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {vessels.length
                    ? vessels.map((vessel, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td style={{ textTransform: 'uppercase' }}>{vessel.code}</td>
                          <td style={{ textTransform: 'uppercase' }}>{vessel.name}</td>
                          {/* {isSuperuser && ( */}
                          <td>
                            {/* <a href='#'> */}
                            <img
                              src={EditIcon}
                              alt="edit_icon"
                              onClick={() => editVessel(index, vessel._id)}
                            />
                            {/* </a>{' '} */}
                            &nbsp;&nbsp; {/* <a href='#'> */}
                            <img
                              src={DeleteIcon}
                              alt="delete_Icon"
                              onClick={() =>
                                deleteVessel(index, vessel._id)
                              }
                            />
                            {/* </a> */}
                          </td>
                          {/* )} */}
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {vessels.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Vessel</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Vessel Code</p>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={classes.inputText}
                  value={vessel.code}
                  onChange={(e) =>
                    setVesselDetail({ key: "code", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Vessel Name</p>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={classes.inputText}
                  value={vessel.name}
                  onChange={(e) =>
                    setVesselDetail({
                      key: "name",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveVessel}>
                {vesselUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setVessel(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Vessel;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './profoapproval.module.css';
import './invoice.module.css';
import CustomTable from '../../customTable';
import Template from './ApprvcnopPdf';

export default function ImpApprvdCno() {


  const initProdetails = {
    /* booking_number: '',
    carrier_booking_no: '',
    bill_type: '',
    to_customer_name: '',
    current_date: '',
    Address: '',
    currency: '',
    shipper: '',
    agent: '',
    mlo: '',
    notify_party: '',
    cnf: '',
    remarks: '',
    invoice_charges: '', */

    bkg_no: '',
    bl_no: '',
    address: '',
    to_customer_name: '',
    vendor_ref: '',
    current_date: '',
    agent: '',
    destination: '',
    notify_party: '',
    vessel_name: '',
    container_no: '',
    container_count: '',
    remarks: '',
    credit_charges: '',
    currency: '',
    currency_value: '',
  }  
  const [tableLists, settableLists] = useState([]);
  const [showList, setShowList] = useState(true);
  const [tableListNo, settableListNo] = useState('');
  const [profoDetails, setProfoDetails] = useState(initProdetails);
  const [profoList, setProfoList] = useState('');
  const [isApproved, setIsApproved] = useState(0);
  const [isApprovedId, setIsApprovedId] = useState('');
  const [constno, setconstno] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [invnumber, setInvnumber] = useState([]);
  const [searchList, setSearchList] = useState('');
  const [selectedVal, setSelectedVal] = useState('');
  const [pdfDatas, setPdfDatas] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [bkgDetail, setBkgDetail] = useState('');
  const [bkgnumber, setBkgnumber] = useState('');
  const [bkgSailSched, setBkgSailSched] = useState('');


  



  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  console.log(date);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };



  const editprofolist = (index, invnum) => {
    setShowList(false);
    // settableListNo(invnum);

    customAxios.post('/import/proformacnops/approve/getbyId', { inv_apprv_no: invnum }).then((result) => {
      if (!result.data.error && result.data.data) {
        let {
          _id,
          ref_no,
          bkg_no,
          bl_no,
          address,
          to_customer_name,
          vendor_ref,
          current_date,
          agent,
          destination,
          notify_party,
          vessel_name,
          container_no,
          container_count,
          remarks,
          credit_charges,
          currency,
          currency_value,
          total_amt,
          inv_apprv_no
        } = result.data.data

        setInvoiceNumber(inv_apprv_no);
        setBkgnumber(result.data.data);
        console.log(bkgnumber);
        console.log(result.data.data);

        setPdfDatas(result.data.data);

        console.log(result.data);
        setProfoDetails({
          _id: _id,
          isApproved: 0,
          ref_no: ref_no,
          bkg_no: bkg_no,
          bl_no: bl_no,
          address: address,
          to_customer_name: to_customer_name,
          vendor_ref: vendor_ref,
          current_date: current_date,
          agent: agent,
          destination: destination,
          notify_party: notify_party,
          vessel_name: vessel_name,
          container_no: container_no,
          container_count: container_count,
          remarks: remarks,
          credit_charges: credit_charges,
          currency: currency,
          currency_value: currency_value,
          total_amt: total_amt,
          inv_apprv_no: inv_apprv_no
        })

        setProfoList(credit_charges);
        setIsApproved(result.data.isApproved ? result.data.isApproved : 0);
        setIsApprovedId(result.data.data._id);
        console.log(result);

        console.log(profoDetails);
        customAxios.post('import/booking/getById', { id: bkg_no }).then((resp) => {
          let res = resp.data.data;
          setBkgDetail(resp.data.data);
          setBkgSailSched(resp.data.data.sailing_schedule);
          console.log(resp.data.data.sailing_schedule);


        });

      }
      else {
        window.alert('No data found');
      }
    })
  }




  const ApprovedId = () => {


    customAxios
      .post('/import/proformacnops/approved', {
        "temp": {
          "_id": isApprovedId,
          "is_approved": 1
        }
      })
      .then((data) => {
        console.log(data);
        if (data.data.error) window.alert('Unable to update the details!!');
        else {
          window.alert('Approved successfully');
          setIsApprovedId(1)

        }
      });

  }

  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "WEM/ WBY " + " ";
    setconstno(profomainvoiceAutogen)


    let url = '/import/proformacnops';
    customAxios.get(url).then((response) => {

      settableLists(response.data.data);
      setSearchList(response.data.data);
      console.log(response.data.data);
      // console.log('dataaa', sellings.data.data);
    });

    let invNumUrl = '/import/proformacnops/approve/ids';
    customAxios.get(invNumUrl).then((invNos) => {
      let temp = [...invNos.data.data];
      let invno = temp.map((data) => {
        return { label: String(data.inv_apprv_no).padStart(4, '0'), value: String(data.inv_apprv_no).padStart(4, '0') };
      })
      setInvnumber([...invno]);

    })
  }, [showList]);

  const handleSearch = (option) => {
    if (option == null) {
      let url = '/import/proformacnops';
      customAxios.get(url).then((tableLists) => {

        settableLists(tableLists.data.data);
        setSearchList(tableLists.data.data);
        setSelectedVal(null);
      });

    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => String(item.inv_apprv_no).padStart(4, '0') === (option ? option.value : ' '));
      settableLists(filterdata);
    }
  }

  const columns = [

    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "BKG.NO",
      accessor: "bkg_no",

    },
    {
      Header: "REF#",
      accessor: "ref_no",
    },
    {
      Header: "ApprovedNo#",
      accessor: "inv_apprv_no",
    },
    {
      Header: "To",
      accessor: "to_customer_name",
    },
    {
      Header: "Curr",
      accessor: "currency",
    },
    {
      Header: "Total Amount",
      accessor: "total_amt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  { console.log(tableLists); }
  const DATA = tableLists.filter(item => item.is_approved === 1).map((item, index) => {
    const refNo = item.ref_no;
    // { console.log(DATA, refNo); }

    return {
      id: index + 1,
      bkg_no: 'RUKIM' + item.bkg_no,
      ref_no: 'RILWEM/FI/' + item.ref_no,
      inv_apprv_no: String(item.inv_apprv_no).padStart(4, '0'),
      to_customer_name: item.to_customer_name,
      currency: item.currency,
      total_amt: item.total_amt.toFixed(2),
      action: (
        <div style={{ textAlign: 'center' }}>
          {console.log(refNo)}
          <div style={{ textAlign: 'center' }}>

            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, item.inv_apprv_no,)} />
            &nbsp;&nbsp;
          </div>
          {console.log(item.refNo)}
        </div>
      )
    };

  });

  if(DATA.length == 0 || null){
    DATA.push({
      id: '',
      bkg_no: '',
      ref_no: '',
      inv_apprv_no: 'No Data Available',
      to_customer_name: '',
      currency: '',
      total_amt: '',
      action: ''
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Credit Note - OPS Approved <span className={classes.title}> Import / CN-OPS Approved</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <table>
              <tr>
                <h6>Approved REF# Listing</h6>
                <td colSpan={1} style={{ width: '10px', paddingLeft: '642px' }}>Search</td>
                <td colSpan={4} style={{ width: '175px' }}>
                  {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={invnumber}
                    // value={cnNo.value}
                    /* value={invnumber.filter(
                        (option) =>{ 
                            return option.value === tableListNo
                        }
                    )} */
                    value={selectedVal}
                    onChange={handleSearch}
                  // selectedValue={selectedValue}
                  /* onChange={(selected) => {
                      editprofolist(selected ? selected.value:'');
                    }}  */

                  />

                </td>
              </tr>
            </table>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <CustomTable columns={columns} data={DATA} />



              {/* {totalItems > 0 && (
                <>
                  <table class='table'>
                    <thead className={classes.theadDark}>
                      <tr>
                        <th scope='col' style={{ 'text-align': 'center ' }}>#</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>BKG.NO</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>REF#</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>ApprovedNo#</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>To</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>Curr</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>Total Amount</th>
                        <th scope='col' style={{ 'text-align': 'center ' }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {currentItems.map((tableList, index) => {
                        return tableList.is_approved === 1 ? (
                          <tr key={index}>
                            <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                            <td style={{ 'text-align': 'center ' }}>RUKEX{tableList.bkg_no}</td>
                            <td style={{ 'text-align': 'center ' }}>WEM/ WBY/{tableList.ref_no}</td>
                            <td style={{ 'text-align': 'center ' }}>{String(tableList.inv_apprv_no).padStart(4, '0')}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.to_customer_name}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.total_amt != null ? parseFloat(tableList.total_amt).toFixed(2) : '0'}</td>
                            <td style={{ 'text-align': 'center ' }}>
                              <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, tableList.inv_apprv_no)} />

                            </td>
                          </tr>
                        ) : null;
                      })}
                    </tbody>
                  </table>


                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {currentPage > 1 && (
                      <button
                        style={paginationButtonStyle}
                        onClick={() => handlePageClick(currentPage - 1)}
                      >
                        {'<'}
                      </button>
                    )}

                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        style={currentPage === pageNumber ? activePaginationButtonStyle : paginationButtonStyle}
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}

                    {currentPage < totalPages && (
                      <button
                        style={paginationButtonStyle}
                        onClick={() => handlePageClick(currentPage + 1)}
                      >
                        {'>'}
                      </button>
                    )}
                  </div>

                </>
              )} */}
              {tableLists.length ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <Container className={classes.inputsContainer}>

              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <td colSpan={1}><span>BKG.No#*</span></td>
                  <td colSpan={2} style={{ width: '100px' }}>
                    <section>
                      <div style={{ position: 'relative' }}>
                        <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKIM</p>
                        <input
                          type='text'
                          className={classes.inputText}
                          style={{ paddingLeft: '58px', width: '115px' }}
                          value={profoDetails ? profoDetails.bkg_no : ''}
                          // onChange={(e) => setBookingNo(e.target.value)}
                          // onKeyUp={(e) => { handleShippingNo(e ? e : 'edfc') }}
                          disabled={true} />
                      </div>
                    </section>
                  </td>

                  <td colSpan={1} style={{ width: '10px' }}><span>ApprovedNo#*</span></td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? String(profoDetails.inv_apprv_no).padStart(4, '0') : ''}  /* onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked }); console.log(e.target.checked); }} */ type="text" disabled={true} /></td>
                  {/* disabled={true} */}
                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                  <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                  <td colSpan={1}><input className={classes.inputText} type="text"
                    value={profoDetails ? profoDetails.ref_no : ''}
                            /* onChange={(evt) => handlerefNo(evt)}

                            onKeyUp={(evt) => getinvoiceDetail(evt)} */ disabled={true} /></td>

                </tr>
                  <tr>
                    <td>To<span>*</span></td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.to_customer_name : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_name',
                                    value: evt.target.value,
                                })} */
                      type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}><span>VendorRef#*</span></td>
                    <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.vendor_ref : ''} /* onChange = {handleVendor} */ disabled={true} type="text" /></td>
                  </tr>
                  <tr>
                    <td >Date</td>
                    <td colSpan={5}><input className={classes.inputText} value={date} type="text" disabled={true} /></td>

                    <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                    <td colSpan={1} ><input className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                      styles={style}
                      isClearable={true}
                      isSearchable={true}
                      // options={currencies}
                      type="text"
                      value={profoDetails ? profoDetails.currency : ''}
                      // value={currencies.filter(
                      //     (option) => option.value === selectedValue
                      // )}
                      // selectedValue={selectedValue}
                      // onChange={(e) => {
                      //     if (e) handleSelectChange(e);
                      // }}
                      disabled={true} >
                    </input>
                    </td>
                    <td colSpan={1}><input className={classes.inputText} value={profoDetails ? profoDetails.currency_value : ''} /* onChange={(e) => { handleProfoInvInp(e) }} */ type="text" disabled={true} /></td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.address : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }} colSpan={1}>Agent</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.agent : ''} /* onChange={(evt) => {

                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })
                            }} */ type="text" disabled={true} /></td>

                  </tr>
                  <tr>

                    <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.destination : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }} colSpan={1}>Notify Party</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.notify_party : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  </tr>

                  <tr>

                    <td style={{ paddingLeft: '2px' }} colSpan={1} >MLO</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.vessel_name : ''} /* onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} */ type="text" disabled={true} /></td>

                    <td colSpan={1} style={{ paddingLeft: '35px' }}>Container</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.container_count : ''} /* onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>


                  </tr>

                  <tr style={{ height: '120px' }}>
                    <td colSpan={1}>Remarks</td>
                    <td colSpan={5}><textarea style={{ height: '100px' }} value={profoDetails ? profoDetails.remarks : ''} /* onChange={(e) => setRemarks(e.target.value)} */ disabled={true} /></td>

                    <td colSpan={1} style={{ paddingLeft: '35px' }}>Container No</td>
                    <td colSpan={2}><textarea style={{ height: '100px' }} value={profoDetails ? profoDetails.container_no : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} */ disabled={true} /></td>
                  </tr>

                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tbody>
              </table>

              <Row className={classes.row}>
                <Col className={classes.input} md='5'>
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      padding: '10px 0px',
                    }}
                  >
                    Profoma CN-OPS
                  </p>
                </Col>
              </Row>

              <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                <table style={{ width: '100%' }}>
                  <thead className={classes.theadDark}>
                    <tr>
                      <th>charges</th>
                      <th>Curr</th>
                      <th>Rate</th>
                      <th>Base</th>
                      <th>Exchange Rate</th>
                      <th>No.Of.Containers</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profoList ? <>
                      {profoList.map((data, index) => (
                        <tr>
                          <td>{data.charge}</td>
                          <td>{data.currency}</td>
                          <td>{parseFloat(data.rate).toFixed(2)}</td>
                          <td>{data.base}</td>
                          <td>{data.exchangeRate}</td>
                          <td>{data.quantity}</td>
                          <td>{parseFloat(data.total).toFixed(2)}</td>
                          {/* {data.total > 0 ? <td>{data.total}</td> : '0'}  */}

                          {/*                                         {
                                            data.currency == 'USD' ? (
                                            <td>{data.rate * profoInvInp}</td>
                                            ): <td>{data.rate * details.containerlength }</td>
                                            } */}
                        </tr>
                      )
                      )} </>
                      : null}
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tr>
                    <td colSpan={5} style={{ width: '50%' }}></td>
                    <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                    <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoDetails ? parseFloat(profoDetails.total_amt).toFixed(2) : ''} type="text" disabled={true} ></input></td>

                  </tr>
                </table>

              </div>
              <div className={classes.footer}>
                {/* {isApprovedId == 1 ? <button className={classes.footerButton} onClick={ApprovedId} disabled={true}>
                  Approved
                </button> : <button className={classes.footerButton} onClick={ApprovedId}>
                  Approve
                </button>} */}
                <button className={classes.footerButton} style={{ float: 'right', margin: '1%', marginRight: '-20px' }} ><Template
                  //   sellingDetails={sellingDetails}
                  pdfDatas={pdfDatas}
                  profoList={profoList}
                  profoDetails={profoDetails}
                  invoiceNumber={invoiceNumber}
                  bkgDetail={bkgDetail}
                  bkgSailSched={bkgSailSched}

                /></button>

                <button
                  className={classes.footerButton} style={{ float: 'right', margin: '1%' }}
                  onClick={() => { setShowList(true) }}
                >
                  View List
                </button>
              </div>
            </Container>
          </>
        )}
      </Container>
    </>
  );

}


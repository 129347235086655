import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './accountsCssCls.module.css';
import CustomTable from '../../customTable';
import Template from './DebitNotePdf';

export default function DebitNote() {

    const initProdetails = {
        bkg_no: '',

        bill_type: '',
        to_address: '',
        current_date: '',
        to_address: '',
        currency: '',
        shipper: '',
        agent: '',
        mlo: '',
        notify_party: '',
        cnf: '',
        remarks: '',
        creditnote_charges: '',
    }

    const [tableLists, setTableLists] = useState([]);
    const [showList, setShowList] = useState(true);
    const [tableListNo, setTableListNo] = useState('');
    const [prodebitDetails, setprodebitDetails] = useState(initProdetails);
    const [prodebitlist, setprodebitlist] = useState('');
    const [isApproved, setIsApproved] = useState(0);
    const [isApprovedId, setIsApprovedId] = useState('');
    const [constno, setconstno] = useState('');
    const [vendor, setvendor] = useState();
    const [creditdays, setcreditdays] = useState();
    const [debitno, setdebitno] = useState();
    const [deleteId, setDeleteId] = useState('');
    const [refnumber, setRefnumber] = useState([]);
    const [selectedVal, setSelectedVal] = useState('');
    const [searchList, setSearchList] = useState('');
    const [pdfDatas, setPdfDatas] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [bkgDetail, setBkgDetail] = useState('');
    const [bkgnumber, setBkgnumber] = useState('');
    const [bkgSailSched, setBkgSailSched] = useState('');





    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log(date);

    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };


    const handledebitNo = (evt) => {
        setdebitno(evt.target.value);

    };

    const editprodebitlist = (index, debit_no) => {
        setShowList(false);
        // setTableListNo(ref_no);

        customAxios.post('/voucher/proformadebitnoteid/getbyID', { debit_no: debit_no }).then((result) => {
            if (!result.data.error && result.data.data) {
                let {
                    _id,
                    debitno,
                    ref_no,
                    agent,
                    bkg_no,
                    cnf,
                    consignee,
                    vendor_ref,
                    creditdays,
                    container_count,
                    contatainer_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    curr_value,
                    notify_party,
                    remarks,
                    shipper,
                    to_address,
                    total_amt,
                    creditnote_charges,
                    amt_in_words,
                    is_approved,
                    Address
                } = result.data.data;

                console.log(bkg_no);
                console.log(result.data.data);

                setPdfDatas(result.data.data);

                setprodebitDetails({
                    _id: _id,
                    bkg_no: bkg_no,
                    current_date: current_date,
                    to_address: to_address,
                    currency: currency,
                    shipper: shipper,
                    agent: agent,
                    mlo: mlo,
                    notify_party: notify_party,
                    cnf: cnf,
                    remarks: remarks,
                    creditnote_charges: creditnote_charges,
                    destination: destination,
                    consignee: consignee,
                    ref_no: ref_no,
                    curr_value: curr_value,
                    contatainer_no: contatainer_no,
                    container_count: container_count,
                    debit_no: debit_no,
                    vendor_ref: vendor_ref,
                    creditdays: creditdays,
                    total_amt: total_amt,
                    Address: Address
                })

                setprodebitlist(creditnote_charges);
                // setIsApproved(result.data.isApproved ? result.data.isApproved : 0);
                // setIsApprovedId(result.data.data._id);
                console.log(result);

                console.log(prodebitDetails);
                console.log('11111');
                customAxios.post('/booking/getById', { id: bkg_no }).then((resp) => {
                    let res = resp.data.data;
                    console.log('2222');
                    setBkgDetail(resp.data.data);
                    setBkgSailSched(resp.data.data.sailing_schedule);
                    console.log('1133311');

                    console.log(resp.data.data.sailing_schedule);
                    console.log("dfsd", resp.data.data);


                });
            }
            else {
                window.alert('No data found');
            }
        })
    }


    useEffect(() => {
        let year = new Date().getFullYear();
        const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
        setconstno(profomainvoiceAutogen)


        let url = '/voucher/proformadebitnote/list';
        customAxios.get(url).then((tableLists) => {

            setTableLists(tableLists.data.data);
            setSearchList(tableLists.data.data);
            console.log(tableLists.data.data);
            // console.log('dataaa', sellings.data.data);
        });

        let refNumUrl = '/voucher/proformadebitnote/list';
        customAxios.get(refNumUrl).then((refNos) => {
            let temp = [...refNos.data.data];
            let refno = temp.map((data) => {
                return { label: data.debit_no, value: data.debit_no };
            })
            setRefnumber([...refno]);

        })
    }, [showList]);



    const handleSearch = (option) => {
        if (option == null) {
            let url = '/voucher/proformadebitnote/list';
            customAxios.get(url).then((tableLists) => {

                setTableLists(tableLists.data.data);
                setSearchList(tableLists.data.data);
                setSelectedVal(null);
            });
        }
        else {
            setSelectedVal(option);
            const filterdata = searchList.filter(item => item.debit_no === (option ? option.value : ' '));
            setTableLists(filterdata);
        }
    }

    const columns = [

        {
            Header: "#",
            accessor: "id",
        },
        {
            Header: "BKG.NO",
            accessor: "bkg_no",

        },
        {
            Header: "REF#",
            accessor: "ref_no",
        },
        {
            Header: "ApprovedNo#",
            accessor: "debit_no",
        },
        {
            Header: "To",
            accessor: "to_address",
        },
        {
            Header: "Curr",
            accessor: "currency",
        },
        {
            Header: "Total Amount",
            accessor: "total_amt",
        },
        {
            Header: "Action",
            accessor: "action",
        },
    ];
    { console.log(tableLists); }
    const DATA = tableLists.filter(item => item.is_approved === 1).map((item, index) => {
        // { console.log(DATA); }

        return {
            id: index + 1,
            bkg_no: 'RUKEX' + item.bkg_no,
            ref_no: 'RILWEM/FE/' + item.ref_no,
            debit_no: item.debit_no,
            to_address: item.to_address,
            currency: item.currency,
            total_amt: item.total_amt.toFixed(2),
            action: (
                <div style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        {console.log('err', typeof item.debit_no)}
                        <img src={EditIcon} alt='edit_icon' onClick={() => editprodebitlist(index, item.debit_no)} />
                        &nbsp;&nbsp;
                    </div>

                    {console.log(item.debit_no)}
                </div>
            )

        };

    });

    if (DATA.length == 0 || null) {
        DATA.push({
            id: '',
            bkg_no: '',
            ref_no: '',
            debit_no: 'No Data Available',
            to_address: '',
            currency: '',
            total_amt: '',
            action: ''
        })
    }

    return (
        <>
            <div className={classes.container}>
                <h5>Proforma DebitNote Approved <span className={classes.title}>Export / proforma DebitNote Approved</span></h5>
            </div>
            <Container className={classes.inputsContainer}>
                {showList ? (
                    <>
                        <table>
                            <tr>
                                <h6 style={{ width: '300px' }}>Proforma DebitNote Approved</h6>
                                <td colSpan={1} style={{ width: '10px', paddingLeft: '557px' }}>Search</td>
                                <td colSpan={4} style={{ width: '175px' }}>
                                    {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={refnumber}
                                        value={selectedVal}
                                        onChange={handleSearch}

                                    />

                                </td>
                            </tr>
                        </table>
                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>

                            <CustomTable columns={columns} data={DATA} />

                            {/* <table class='table'>
                                <thead className={classes.theadDark}>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'>Debit RefNo.</th>
                                        <th scope='col'>REF#</th>
                                        <th scope='col'>To</th>
                                        <th scope='col'>Total Amount</th>
                                        <th scope='col'>CURR</th>
                                        <th scope='col'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableLists.length
                                        ? tableLists.map((tableList, index) => {

                                            return (
                                                <>
                                                    {tableList.is_approved == 0 ?
                                                        <tr key={index}>
                                                            <th scope='row' style={{ textAlign: 'center' }}>{index + 1}</th>
                                                            <td style={{ textAlign: 'center' }}>{tableList.bkg_no}</td>
                                                            <td style={{ textAlign: 'center' }}>{tableList.ref_no}</td>
                                                            <td style={{ textAlign: 'center' }}>{tableList.to_address}</td>
                                                            <td style={{ textAlign: 'center' }}>{tableList.total_amt.toFixed(2)}</td>
                                                            <td style={{ textAlign: 'center' }}>{tableList.currency}</td>
                                                            <td style={{ 'text-align': 'center ' }}>
                                                                <img src={EditIcon} alt='edit_icon' onClick={() => editprodebitlist(index, tableList.ref_no)} />
                                                                &nbsp;&nbsp;<span><img src={DeleteIcon} alt='delete_icon' onClick={() => deleteCharge(index, tableList._id)}/></span>
                                                            </td>
                                                        </tr>
                                                        : null}
                                                </>
                                            );

                                        })
                                        : ''}
                                </tbody>
                            </table> */}
                            {tableLists.length ? (
                                ''
                            ) : (
                                <div className={classes.noData}>No data available</div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <table style={{ width: '100%' }}>
                            <tbody><tr>
                                <td colSpan={1} style={{ width: '10px' }}><span>DN#*</span></td>
                                <td colSpan={2} style={{ width: '100px' }}>
                                    <input className={classes.inputText} value={prodebitDetails ? prodebitDetails.debit_no : ''} disabled={true} type="text" />
                                </td>
                                <td colSpan={1} style={{ paddingLeft: '97px' }}><span >BKG.No#*</span></td>


                                <td colSpan={2} style={{ width: '100px' }}>
                                    <section>
                                        <div style={{ position: 'relative' }}>
                                            <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                                            <input
                                                type='text'
                                                className={classes.inputText}
                                                style={{ paddingLeft: '57px' }}
                                                value={prodebitDetails ? prodebitDetails.bkg_no : ''}
                                                disabled={true} />
                                        </div>
                                    </section>
                                </td>

                                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                                <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                                <td colSpan={1}><input className={classes.inputText} type="text" value={prodebitDetails ? prodebitDetails.ref_no : ''}
                                    onChange={(evt) => handledebitNo(evt)} disabled={true} /></td>

                            </tr>
                                <tr>
                                    <td>To<span>*</span></td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.to_address : ''}
                                        disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '35px' }}>Date</td>
                                    <td colSpan={2}><input className={classes.inputText} value={date} type="text" disabled={true} /></td>
                                </tr>

                                <tr>

                                    <td style={{ paddingLeft: '2px' }} colSpan={1} >Address</td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.Address : ''}
                                        disabled={true} type="text" /></td>


                                    <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                                    <td colSpan={1} ><input className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        type="text" value={prodebitDetails ? prodebitDetails.currency : ''}
                                        disabled={true} >
                                    </input>
                                    </td>
                                    <td colSpan={1}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.curr_value : ''} disabled={true} type="text" /></td>
                                </tr>

                                <tr></tr>
                                <tr>
                                    <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                                    <td colSpan={5}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.destination : ''}
                                        disabled={true} type="text" /></td>

                                    {/* <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td> */}
                                    <td colSpan={1} style={{ paddingLeft: '35px', }}>Container</td>
                                    <td colSpan={2} rowSpan={3}><textarea style={{ height: '90px'/* , marginTop: '-18px', marginBottom: '20px' */ }} value={prodebitDetails ? prodebitDetails.container_count : ''}
                                        disabled={true} /></td>
                                    {/* <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Vendo Ref #</span></td>
                                    <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Credit Days</span></td> */}


                                </tr>
                                <tr >
                                    <td colSpan={1}>Shipper</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.shipper : ''} disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.agent : ''} disabled={true} type="text" /></td>


                                    <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                                    {/*  <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={prodebitDetails ? prodebitDetails.vendor_ref : ''}
                                        disabled={true} /></td>
                                    <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={prodebitDetails ? prodebitDetails.creditdays : ''}
                                        disabled={true} /></td> */}


                                </tr>
                                <tr >
                                    <td colSpan={1} style={{ paddingRight: '50px' }}  >Cosingnee</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.consignee : ''} disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.mlo : ''} disabled={true} type="text" /></td>


                                </tr>
                                <tr></tr>
                                <tr>
                                    <td colSpan={1}>Notify Party</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.notify_party : ''}
                                        disabled={true} type="text" /></td>

                                    <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                                    <td colSpan={2}><input className={classes.inputText} value={prodebitDetails ? prodebitDetails.cnf : ''}
                                        disabled={true} type="text" /></td>

                                    <td colSpan={1} style={{ paddingLeft: '35px', }}> Container No.</td>
                                    <td colSpan={2} rowSpan={2} style={{verticalAlign: 'top'}}><textarea style={{ height: '155px'/* , marginTop: '-20px' */ }} value={prodebitDetails ? prodebitDetails.contatainer_no : ''}
                                        disabled={true} /></td>

                                </tr>
                                <tr>
                                    <td colSpan={1}>Remarks</td>
                                    <td colSpan={5}><textarea style={{ height: '90px' }} value={prodebitDetails ? prodebitDetails.remarks : ''} disabled={true} /></td>
                                </tr>
                                <tr><td colSpan="9">
                                    <hr></hr>
                                </td></tr>


                            </tbody>
                        </table>

                        <Row className={classes.row}>
                            <Col className={classes.input} md='5'>
                                <p
                                    style={{
                                        color: 'red',
                                        fontWeight: 'normal',
                                        padding: '10px 0px',
                                    }}
                                >
                                    Invoices
                                </p>
                            </Col>
                        </Row>

                        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                            <table style={{ width: '100%' }}>
                                <thead className={classes.theadDark}>
                                    <tr>
                                        <th>charges</th>
                                        <th>Curr</th>
                                        <th>Rate</th>
                                        <th>Base</th>
                                        <th>Exchange Rate</th>
                                        <th>No.Of.Containers</th>
                                        <th>Amount</th>
                                        {/* <th>Action</th> */}


                                    </tr>
                                </thead>

                                <tbody>
                                    {prodebitlist ? <>
                                        {prodebitlist.map((data, index) => (
                                            <tr>
                                                <td>{data.charge}</td>
                                                <td style={{ textAlign: 'center' }}>{data.currency}</td>
                                                <td style={{ textAlign: 'center' }}>{parseFloat(data.rate).toFixed(2)}</td>
                                                <td style={{ textAlign: 'center' }}>{data.base}</td>
                                                <td style={{ textAlign: 'center' }}>{data.exchangeRate}</td>
                                                <td style={{ textAlign: 'center' }}>{data.quantity}</td>
                                                <td>{data.total == null || data.total == 0 ? '0' : parseFloat(data.total).toFixed(2)}</td>

                                            </tr>
                                        )
                                        )} </>
                                        : null}
                                </tbody>

                            </table>
                        </div>

                        <div>
                            <table>
                                <tr>
                                    <td colSpan={5} style={{ width: '50%' }}></td>
                                    <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                                    <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={prodebitDetails ? parseFloat(prodebitDetails.total_amt).toFixed(2) : ''} type="text" disabled={true} ></input></td>

                                </tr>
                            </table>
                        </div>

                        <div className={classes.footer}>
                            <button className={classes.footerButton} style={{ float: 'right', margin: '1%', marginRight: '-20px' }} ><Template
                                pdfDatas={pdfDatas}
                                prodebitlist={prodebitlist}
                                prodebitDetails={prodebitDetails}
                                bkgDetail={bkgDetail}
                                bkgSailSched={bkgSailSched}

                            /></button>
                            <button
                                className={classes.footerButton} style={{ float: 'right', margin: '1%' }}
                                onClick={() => { setShowList(true) }}
                            >
                                View List
                            </button>
                        </div>
                    </>
                )}
            </Container>
        </>
    );

}


import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './Buyer.module.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomTable from '../../customTable';

export default function ImpSelling() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;
  const username = localStorage.getItem('name');
  const userId = localStorage.getItem('userId');

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  let initBuyValue = {
    carrier: '',
    source: '',
    loading_port: '',
    discharge_port: '',
    destination: '',
    cargo: '',
    freight: '',
    shipment: '',
    charges: [],
  };

  let initSellValue = {
    created_by: username,
    customer: '',
    date: '',
    valid_till: '',
    remarks: '',
    selling_rates: [],
    status: '',
  };

  let initCharge = {
    charge: '',
    currency: '',
    rate: '',
    base: '',
  };

  const freightOptions = [
    { value: 'Prepaid', label: 'Prepaid' },
    { value: 'Collect', label: 'Collect' },
  ];
  const shipmentOptions = [
    { value: 'FCL', label: 'FCL' },
    { value: 'LCL', label: 'LCL' },
  ];

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      overflow: 'hidden'
    }),
    valueContainer: (base) =>({
      ...base,
      padding: '0px',
    }),
    input: (base) =>({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const saveDetail = () => {
    let {
      source,
      loading_port,
      discharge_port,
      destination,
      cargo,
      freight,
      shipment,
      charges,
      carrier,
    } = buyingDetails;
    let { created_by, customer, date, valid_till, remarks, selling_rates } = {
      ...sellingDetails,
    };
    console.log(selling_rates);
  //  selling_rates = selling_rates.map((data) => {
  //    return { charge: data.charge, currency: data.currency, base: data.base, rate: data.rate };
  //  });

  let hasMissingField = false; // Flag to track missing fields

  if (
    !carrier ||
    !source ||
    !loading_port ||
    !discharge_port ||
    !destination ||
    !cargo ||
    !freight ||
    !shipment ||
    !charges ||
    !customer ||
    !date ||
    !valid_till 
    // ||
    // !remarks
  ) {
    // Check if any of the required fields are missing
    setError('Please fill all the required fields');
    hasMissingField = true;
  } else {
    setError(''); // Clear the error message if all fields are filled
  }

  if(!hasMissingField){
    if (
      source &&
      loading_port &&
      discharge_port &&
      destination &&
      cargo &&
      freight &&
      shipment &&
      // created_by &&
      customer &&
      date &&
      valid_till
    ) {
      console.log('save details');
      let temp = {
        created_by: userId,
        customer,
        date,
        valid_till,
        source,
        loading_port,
        discharge_port,
        destination,
        cargo,
        freight,
        shipment,
        remarks,
        selling_rates,
        carrier,
      };
      if (sellingId) {
        console.log(temp);
        customAxios
          .post('import/selling/update', {
            sellingNo: sellingId,
            data: {
              ...temp,
              buying_id: buyingNumber,
              buying_rates: buyingDetails.charges,
            },
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Detail updated succesfully!');
            }
            console.log(data);
          });
      } else
        customAxios
          .post('import/selling', {
            ...temp,
            buying_id: buyingNumber,
            buying_rates: buyingDetails.charges,
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Entry added succesfully!');
              setSellingId(data.data.count);
            }
            console.log(data);
          });
    } 
  }/* else {
      setError('Please fill all required fields');
    } */
  };

  const saveNew = () => {
    console.log("buy det", buyingDetails);
    let {
      source,
      loading_port,
      discharge_port,
      destination,
      cargo,
      freight,
      shipment,
      charges,
      carrier,
    } = buyingDetails;
    let { created_by, customer, date, valid_till, remarks, selling_rates } = {
      ...sellingDetails,
    };
    console.log(selling_rates);
  //  selling_rates = selling_rates.map((data) => {
  //    return { charge: data.charge, currency: data.currency, base: data.base, rate: data.rate };
  //  });
    if (
      source &&
      loading_port &&
      discharge_port &&
      destination &&
      cargo &&
      freight &&
      shipment &&
      created_by &&
      customer &&
      date &&
      valid_till
    ) {
      console.log('save details');
      let temp = {
        created_by: userId,
        customer,
        date,
        valid_till,
        source,
        loading_port,
        discharge_port,
        destination,
        cargo,
        freight,
        shipment,
        remarks,
        selling_rates,
        carrier,
      };
    /*  if (sellingNo) {
        console.log(temp);
        customAxios
          .post('/selling/update', {
            sellingNo: sellingNo,
            data: {
              ...temp,
              buying_id: buyingNumber,
              buying_rates: buyingDetails.charges,
            },
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Detail updated succesfully!');
            }
            console.log(data);
          });
      } else*/
        customAxios
          .post('import/selling', {
            ...temp,
            buying_id: buyingNumber,
            buying_rates: buyingDetails.charges,
          })
          .then((data) => {
            if (data.data.error) {
              setError(data.message);
            } else {
              console.log(data.data);
              window.alert('Entry added succesfully!');
              setSellingNo(data.data.count);
              setApproved(false);
            }
            console.log(data);
          });
    } else {
      setError('Please fill all required fields');
    }
  };

  const [buyingNumber, setBuyingNumber] = useState('');
  const [sellingNo, setSellingNo] = useState('');
  const [buyingDetails, setBuyingDetails] = useState(initBuyValue);
  const [sellingDetails, setSellingDetails] = useState(initSellValue);
  const [buyingNumbers, setBuyingNumbers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [charges, setCharges] = useState([]);
  const [newCharge, setNewCharge] = useState(initCharge);
  const [allCharges, setAllcharges] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState('');
  const [approved, setApproved] = useState(false);
  const [isOpenBuying, setIsOpenBuying] = useState(false);
  const [isOpenQuota, setIsOpenQuota] = useState(false);
  const [buyingpopdata, setbuyingpopdata] = useState('');
  const [quotpopdata, setquotpopdata] = useState('');
  const [sellingId, setSellingId] = useState('');

  let buttonEnabled = !!(
    newCharge.charge &&
    newCharge.currency &&
    newCharge.rate &&
    newCharge.base
  );

  useEffect(() => {
    customAxios.get('/import/buyer/rateIds').then((ids) => {
      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: data.rate_id, value: data.rate_id };
      });
      setBuyingNumbers([...details]);
    });

    customAxios.get('/customer').then((customers) => {
      let temp = [...customers.data.data];
      let customersDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCustomers([...customersDetails]);
    });

    customAxios.get('/container').then((containers) => {
      let temp = [...containers.data.data];
      let containersDetails = temp.map((data) => {
        return { label: data.type, value: data.type };
      });
      setContainers([...containersDetails]);
    });

    customAxios.get('/charges').then((charges) => {
      let temp = [...charges.data.data];
      let chargesDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCharges([...chargesDetails]);
    });

    customAxios.get('/currency').then((currencies) => {
      let temp = [...currencies.data.data];
      let currenciesDetails = temp.map((data) => {
        return { label: data.code, value: data.code };
      });
      setCurrencies([...currenciesDetails]);
    });

    let buypopupUrl = '/import/buyer/getall';
    customAxios.get(buypopupUrl).then(response => {
      setbuyingpopdata(response.data.data);
    }).catch(error => {
      console.error('Error fetching data:', error);

    });

    let quotationpopUrl = '/import/selling';
    customAxios.get(quotationpopUrl).then(response => {
      console.log(response);
      setquotpopdata(response.data.data);
    }).catch(error => {
      console.log('Error fetching data:', error);
    });
  }, []);

  useEffect(() => {
    // console.log('buyingDetails', buyingDetails);
    console.log('sellingDetails', sellingDetails);
    console.log('new charge', newCharge);
  }, [newCharge, sellingDetails]);

  const updateSellingDetail = ({ key, value }) => {
    let temp = { ...sellingDetails };
    temp[key] = value;
    setSellingDetails({ ...temp });
  };

  const saveCharge = ({ key, evt }) => {
    let temp = { ...newCharge };
    temp[key] = evt && evt.value ? evt.value : '';
    setNewCharge(temp);
  };

  const addCharge = () => {
    console.log('newww', newCharge);
    let temp = [...allCharges, newCharge];
    setAllcharges(temp);
    setSellingDetails({ ...sellingDetails, selling_rates: temp });
    setNewCharge(initCharge);
  };

  const editCharge = (index) => {
    let temp = [...allCharges];
    setNewCharge({ ...temp[index] });
    temp.splice(index, 1);
    setAllcharges([...temp]);
  };

  const deleteCharge = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      let temp = [...allCharges];
      temp.splice(index, 1);
      setAllcharges([...temp]);
      setSellingDetails({ ...sellingDetails, selling_rates: temp });
    }
  };

  const getBuyingDetails = (selected, rateId, fromClick = false) => {
    
    setIsOpenBuying(false);
    const newValue = selected || rateId;
    // setBuyingNumber(newValue);
    setBuyingNumber(newValue);
  
    if (selected) {
      customAxios
        .post('/import/buyer/getById', { id: selected })
        .then((data) => {
          console.log(data);
          if (!data.error) {
            let temp = data.data.data;
            console.log(temp);
          //  const temp_charge = temp.charges;
            let temp_charge = temp && temp.charges.map((data) => {
              return { charge: data.charge, currency: data.currency, buyingRate: data.rate, base: data.base, rate: '' };
            });
            setAllcharges(temp_charge);
            
            setBuyingDetails({ ...temp });
           
          }
        });
    } else {
      setBuyingDetails(initBuyValue);
    }
  };

  const cancelDetails = () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      confirmCancel();
    }
  };

  const confirmCancel = () => {
    setAllcharges([]);
    setNewCharge(initCharge);
    setBuyingDetails(initBuyValue);
    setSellingDetails(initSellValue);
    setError('');
    setBuyingNumber('');
    setSellingNo('');
    setApproved(false);
  };

  const handleSellingNo = (/* evt */ sellId, fromClick = false) => {
    setIsOpenQuota(false);
    if (/* (evt.key === 'Enter' || evt.keyCode === 13) && sellingNo */fromClick || sellId) {
      customAxios.post('/import/selling/getById', { id: /* sellingNo */sellId }).then((result) => {
        setSellingId(sellId);
        console.log('result data', result.data.data);
        if (!result.data.error && result.data.data) {
          let {
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            created_by,
            customer,
            date,
            valid_till,
            remarks,
            selling_rates,
            carrier,
            buying_rates,
            buying_id,
            status,
          } = result.data.data;
          setBuyingDetails({
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            charges: buying_rates,
            carrier,
          });
          setSellingDetails({
            created_by: created_by,
            customer,
            date: date.substring(0, 10),
            valid_till: valid_till.substring(0, 10),
            remarks,
            selling_rates,
            carrier,
            status,
          });
          setBuyingNumber(buying_id);
          // getBuyingDetails({value:buying_id})

          setAllcharges(selling_rates);
          setApproved(status=='Approved'?true:false);
        } else {
          window.alert('No data found');
          confirmCancel();
        }
      });
    } else if (/* evt.key === 'Enter' */!sellId) {
      confirmCancel();
    }
  };

  const closePop = () => {
    setIsOpenBuying(false);
    setIsOpenQuota(false);
  }

  const openbuyingpop = () => {
    setIsOpenBuying(true);
    setIsOpenQuota(false);
  }

  const openquotationpop = () => {
    setIsOpenBuying(false);
    setIsOpenQuota(true);
  }

  //Buying popup datas
  const buyingcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Buy No",
      accessor: "rate_id",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    }
    
  ];

  const BuyDATAPOPUP = buyingpopdata.length ? buyingpopdata.map((data, index)=> {

    const rateIdStyle = {
      color: '#D82C8D',
      cursor: 'pointer'
    }
    return{
      id: index + 1,
      rate_id: (
      <span onClick={(e) => getBuyingDetails(data.rate_id, true)} style={rateIdStyle}>{data.rate_id}{console.log(data.rate_id)}</span>
      ),
      carrier: data.carrier,
      source: data.source,
      loading_port: data.loading_port,
      discharge_port: data.discharge_port,
      destination: data.destination,
    }
  }) : [];

  if(BuyDATAPOPUP.length === 0 || null){
    BuyDATAPOPUP.push({
      id: '',
      rate_id: '',
      source: '',
      loading_port: 'No Data Available',
      discharge_port: '',
      destination: '',
      carrier: ''
    })
  }

  //quotation popup datas
  const quotationcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Quotation No",
      accessor: "selling_id",
    },
    {
      Header: "customer",
      accessor: "customer",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
  ];

  const QuotationDATAPOPUP = quotpopdata.length ? quotpopdata.map((data, index)=> {

    const SellIdStyle = {
      color: '#D82C8D',
      cursor: 'pointer'
    }
    return{
      id: index + 1,
      selling_id: (
      <span onClick={(e) => handleSellingNo(data.selling_id, true)} style={SellIdStyle}>{data.selling_id}</span>
      ),
      customer: data.customer,
      source: data.source,
      loading_port: data.loading_port,
      discharge_port: data.discharge_port,
      destination: data.destination,
    }
  }) : [];

  if(QuotationDATAPOPUP.length === 0 || null){
    QuotationDATAPOPUP.push({
      id: '',
      selling_id: '',
      customer: '',
      source: '',
      loading_port: 'No Data Available',
      discharge_port: '',
      destination: '',
    })
  }


  return (
    <>
      <div className={classes.container}>
        <h5>Quotation<span className={classes.title}> Import / Sales</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <span style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={openquotationpop}>Quotation#</span>
            <section>
              <input
                type='text'
                className={classes.inputText}
                value={sellingId}
                // disabled={true}
                // style={{ border: '0' }}
                onChange={(evt) => setSellingId(evt.target.value)}
                onKeyUp={(evt) => {
                  if (evt.key === 'Enter') {
                    handleSellingNo(evt.target.value, false);
                  }
                }}
              />
            </section>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Date</p>
              <span>*</span>
            </section>
            <input
              type='date'
              className={classes.inputText}
              value={sellingDetails.date}
              onChange={(e) =>
                updateSellingDetail({
                  key: 'date',
                  value: e.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Valid Till</p>
              <span>*</span>
            </section>
            <input
              type='date'
              min={sellingDetails.date}
              className={classes.inputText}
              value={sellingDetails.valid_till}
              onChange={(e) =>
                updateSellingDetail({
                  key: 'valid_till',
                  value: e.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Customer</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={customers}
              value={customers.filter(
                (option) => option.value === sellingDetails.customer
              )}
              onChange={(e) =>
                updateSellingDetail({
                  key: 'customer',
                  value: e ? e.value : '',
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <span style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={openbuyingpop}>Buying#</span>
              <input
                  type='text'
                  className={classes.inputText}
                value={buyingNumber}
                // onChange={(selected) => getBuyingDetails(selected)}
                onChange={(evt)=> setBuyingNumber(evt.target.value)}
                onKeyUp={(evt) => {
                  if (evt.key === 'Enter') {
                    getBuyingDetails(evt.target.value, false);
                  }
                }}
              />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Status</p>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={approved?'Approved':'Registered'}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Carrier</p>
            </section>
            <input
              type='text'
              value={buyingDetails.carrier}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Cargo</p>
            </section>
            <input
              type='text'
              value={buyingDetails.cargo}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>POR</p>
            </section>
            <input
              type='text'
              value={buyingDetails.source}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POL</p>
            </section>
            <input
              type='text'
              value={buyingDetails.loading_port}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POD</p>
            </section>
            <input
              type='text'
              value={buyingDetails.discharge_port}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>DEL</p>
            </section>
            <input
              type='text'
              value={buyingDetails.destination}
              className={classes.inputText}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Freight</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={freightOptions}
              value={freightOptions.filter(
                (option) => option.value === buyingDetails.freight
              )}
              onChange={(selected) =>
                setBuyingDetails({
                  ...buyingDetails,
                  freight: selected ? selected.value : '',
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Shipment</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={shipmentOptions}
              value={shipmentOptions.filter(
                (option) => option.value === buyingDetails.shipment
              )}
              onChange={(selected) =>
                setBuyingDetails({
                  ...buyingDetails,
                  shipment: selected ? selected.value : '',
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Remarks</p>
            </section>
            <input
              type='text'
              value={sellingDetails.remarks}
              className={classes.inputText}
              onChange={(evt) =>
                setSellingDetails({
                  ...sellingDetails,
                  remarks: evt.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>By</p>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.created_by}
              disabled={true}
            />
          </Col>
        </Row>
        <div style={{ height: '15px' }}>
          <span>{error}</span>
        </div>
        <div className={classes.footer}>
        {/*  {sellingDetails.status=='Approved' ? ( */}
          {approved ? (
            <>
              <button className={classes.footerButton} onClick={saveNew}>
              Save New
              </button>
              </>
            ) : (
            <>
              <button className={classes.footerButton} onClick={saveDetail}>
              {sellingId ? 'Update' : 'Save'}
              </button>
            </>
            )}
          
          {/* <button className={classes.footerButton}>View</button> */}
          <button className={classes.footerButton} onClick={cancelDetails}>
            Cancel
          </button>
          <button className={classes.footerButton} onClick={confirmCancel}>
            Add New
          </button>
        </div>
        {!approved ? (
          <>
            <Row className={classes.row}>
              <Col className={classes.input} md='3'>
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    padding: '5px 0px',
                   
                  }}
                >
                Rates
                </p>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={charges}
                  value={charges.filter(
                    (option) => option.value === newCharge.charge
                  )}
                  onChange={(e) => saveCharge({ key: 'charge', evt: e })}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Curr</p>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={currencies}
                  value={currencies.filter(
                    (option) => option.value === newCharge.currency
                  )}
                  onChange={(e) => saveCharge({ key: 'currency', evt: e })}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Buying Rate</p>
                <input
                  type='number'
                  className={classes.inputText}
                  value={newCharge.buyingRate}
                  onChange={(e) => saveCharge({ key: 'buyingRate', evt: e.target })}
                  // disabled={true}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Selling Rate</p>
                <input
                  type='number'
                  className={classes.inputText}
                  value={newCharge.rate}
                  onChange={(e) => saveCharge({ key: 'rate', evt: e.target })}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Base</p>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  name='base'
                  options={containers}
                  value={containers.filter(
                    (option) => option.value === newCharge.base
                  )}
                  onChange={(e) => saveCharge({ key: 'base', evt: e })}
                />
              </Col>
              <Col style={{ display: 'flex', alignItems: 'flex-end' }} md='1'>
                <button
                  className={classes.footerButton}
                  onClick={addCharge}
                  disabled={!buttonEnabled}
                >
                  Add
                </button>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            height: '200px',
            // overflowY: 'scroll',
            backgroundColor: '#eff0f1',
          }}
        >
          <table class='table'>
            <thead className={classes.theadDark}>
              <tr>
                <th scope='col' style={theadStyle}>#</th>
                <th scope='col' style={theadStyle}>
                  Charges
                </th>
                <th scope='col' style={theadStyle}>Curr</th>
                <th scope='col' style={theadStyle}>Base</th>
                <th scope='col' style={theadStyle}>Buying Rates</th>
                <th scope='col' style={theadStyle}>Selling Rate</th>
                <th scope='col' style={theadStyle}>Diff</th>
                {!approved ? (
                  <>
                    <th scope='col'style={theadStyle}></th>
                  </>
                  ) : (
                  <>
                  </>
                  )}
              </tr>
            </thead>
            <tbody>
              {allCharges && allCharges.length
                ? allCharges.map((charge, index) => {
                    return (
                      <tr key={index} style={{ backgroundColor: 'lightgray' }}>
                        <th scope='row'>{index + 1}</th>
                        <td>{charge.charge}</td>
                        <td>{charge.currency}</td>
                        <td>{charge.base}</td>
                        <td>{charge.buyingRate == null ? ' ':parseFloat(charge.buyingRate).toFixed(2)}</td>
                        <td>{charge.rate}</td>
                        <td>{charge.rate - (charge.buyingRate ? charge.buyingRate : 0)}</td>
                        {!approved ? (
                        <>
                        <td>
                          <img
                            src={EditIcon}
                            className={classes.icon}
                            alt='edit_icon'
                            // style={{ borderRadius: '50%' }}
                            onClick={() => editCharge(index)}
                          />
                          &nbsp;&nbsp;
                          <img
                            src={DeleteIcon}
                            className={classes.icon}
                            alt='delete_Icon'
                            // style={{ borderRadius: '50%' }}
                            onClick={() => deleteCharge(index)}
                          />
                        </td>
                        </>
                        ):(<></>)}
                      </tr>
                    );
                  })
                : ''}
            </tbody>
          </table>
        </div>
      </Container>

      {isOpenBuying ?
        <div
          className="modal show"
          style={{ display: 'block', position: 'fixed' }}
        >
          <Modal.Dialog style={{ marginRight: '0px', marginLeft: '0px', left: '10%' }}>
            <div class='modal-content' style={{ width: '215%', height: '500px', position: 'relative' }}>
              <Modal.Header onClick={closePop} closeButton>
                <Modal.Title style={{ fontSize: '15px' }}>BUYING LIST</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ display: 'flex', justifyContent: 'center', height: '50%', overflowY: 'scroll' }}>
                <table style={{ width: '100%', border: '1px solid #C3C3C3', /* position: 'fixed' */ }}>
                  <tbody>
                    <CustomTable columns={buyingcolumns} data={BuyDATAPOPUP}/>
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>Close</Button>
              </Modal.Footer>
            </div>
          </Modal.Dialog>
        </div>
        : isOpenQuota ?
        <div
          className="modal show"
          style={{ display: 'block', position: 'fixed' }}
        >
          <Modal.Dialog style={{ marginRight: '0px', marginLeft: '0px', left: '10%' }}>
            <div class='modal-content' style={{ width: '215%', height: '500px', position: 'relative' }}>
              <Modal.Header onClick={closePop} closeButton>
                <Modal.Title style={{ fontSize: '15px' }}>QUOTATION LIST</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ display: 'flex', justifyContent: 'center', height: '50%', overflowY: 'scroll' }}>
                {/* <p>Modal body text goes here.</p> */}
                <table style={{ width: '100%', border: '1px solid #C3C3C3', /* position: 'fixed' */ }}>
                  <tbody>
                    <CustomTable columns={quotationcolumns} data={QuotationDATAPOPUP}/>
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>Close</Button>
              </Modal.Footer>
            </div>
          </Modal.Dialog>
        </div> : null }
    </>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";

function Instructions() {
  let initValue = {
    instruction: "",
  };
  const [instruction, setInstruction] = useState(initValue);
  const [instructions, setInstructions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);

  // const [noOfEmails, setNoOfEmails] = useState(1);
  const [error, setError] = useState("");
  const [instructionUpdate, setInstructionUpdate] = useState(false);
  const [index, setIndex] = useState("");

  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;

  const setInstructionDetail = ({ key, value }) => {
    let detail = { ...instruction };
    detail[key] = value.toUpperCase();
    setInstruction(detail);

  };

  // const setEmailKey = ({key, value }) => {
  //    let detail = {...instruction};
  //    detail.mailId[key] = '';
  // }

  // const setEmailValue

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  const saveinstruction = () => {
    let temp = { ...instruction };


    if (temp._id) {
      customAxios.post("/instruction/update", { temp }).then((data) => {

        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated succesfully!");
        }
      });
    } else {

      let url = "/instruction";
      customAxios
        .post(url, temp)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setInstruction(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchinstruction = () => {
    let url = "/instruction/search";
    customAxios.post(url, { searchValue: searchValue }).then((resp) => {
      setInstructions(resp.data.data);

    });
  };

  const deleteinstruction = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      customAxios
        .delete("/instruction", { data: { userId: id } })
        .then((data) => {
          if (data.data.error) {
            setError(data.message);
          } else {
            window.alert("Instruction deleted succesfully!");
            let temp = [...instructions];
            temp.splice(index, 1);
            setInstructions(temp);
          }
        });
    }
  };

  const editinstruction = (index) => {
    let temp = [...instructions];
    setInstruction({ ...temp[index] });
    setShowList(false);
    setInstructionUpdate(true);
    //setIndex(index);
  };

  useEffect(() => {
    let url = "/instruction";
    customAxios.get(url).then((resp) => {
      setInstructions(resp.data.data);
    });
  }, [showList]);

  return (
    <>
      <div className={classes.container}>
        <h5>Instructions</h5>
        <section className={classes.title}>Dashboard / instructions</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Instructions Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    {/* <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    /> */}
                    {/* {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>

                  {/* <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchinstruction}
                  /> */}
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setInstruction(initValue);
                      setInstructionUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: 1 }}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Instruction</th>
                    <th scope="col" style={theadStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {instructions.length
                    ? instructions.map((instruction, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td style={{ textTransform: 'uppercase' }}>{instruction.instruction}</td>

                          <td>
                            {/* <a href='#'> */}
                            <img
                              src={EditIcon}
                              alt="edit_icon"
                              onClick={() =>
                                editinstruction(index, instruction._id)
                              }
                            />
                            {/* </a>{' '} */}
                            &nbsp;&nbsp; {/* <a href='#'> */}
                            <img
                              src={DeleteIcon}
                              alt="delete_Icon"
                              onClick={() =>
                                deleteinstruction(index, instruction._id)
                              }
                            />
                            {/* </a> */}
                          </td>

                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {instructions.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add instruction</h6>
            <Row className={classes.row}>
              <Col sm={12} lg={12} xs={12} className={classes.input}>
                <p style={{paddingBottom:'14px'}}>
                  Instruction<span>*</span>
                </p>
                <textarea
                  style={{ textTransform: 'uppercase' }}
                  placeholder="Maximum of 1000 characters"
                  maxlength="1000"
                  value={instruction.instruction}
                  onChange={(e) =>
                    setInstructionDetail({
                      key: "instruction",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button
                className={classes.footerButton}
                onClick={saveinstruction}
              >
                {instructionUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => {
                  setShowList(true);
                  setInstruction(initValue);
                }}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setInstruction(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Instructions;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import './acnts.module.css';
import classes from './accountsCssCls.module.css';
import CustomTable from '../../customTable';


export default function ImpInvoice() {

  const initProdetails = {
    bkg_no: '',
    bl_no: '',
    address: '',
    to_customer_name: '',
    vendor_ref: '',
    current_date: '',
    agent: '',
    destination: '',
    notify_party: '',
    vessel_name: '',
    container_no: '',
    container_count: '',
    remarks: '',
    credit_charges: '',
    currency: '',
    currency_value: '',
    ref_id: '',
  }

  const initHistodetails = {
    _id: '',
    invoice_no: '',
    curr: '',
    date: '',
    total_amt: '',
    utr_no: '',
    balance_amt: '',
    rec_amt: '',
    remarks: '',
    receipt_no: ''
  }

  const [tableLists, setTableLists] = useState([]);
  const [showList, setShowList] = useState(true);
  const [tableListNo, setTableListNo] = useState('');
  const [historyListNo, setHistoryListNo] = useState('');
  const [profoDetails, setProfoDetails] = useState(initProdetails);
  const [histoDetails, setHistoDetails] = useState(initHistodetails);
  const [profoList, setProfoList] = useState('');
  const [isApproved, setIsApproved] = useState(0);
  const [isApprovedId, setIsApprovedId] = useState('');
  const [constno, setconstno] = useState('');
  const [receivedAmnt, setReceivedAmnt] = useState();
  const [balanceAmnt, setBalanceAmnt] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [viewHistoryDetails, setViewHistoryDetails] = useState(false);
  const [receiptDetails, setReceiptDetails] = useState(false);
  const [utr, setUtr] = useState();
  const [remarks, setRemarks] = useState();
  const [viewHisto, setViewHisto] = useState();
  const [amtCalc, setAmtCalc] = useState();
  const [isPaid, setIsPaid] = useState();
  const [refnumber, setRefnumber] = useState([]);
  const [searchList, setSearchList] = useState('');
  const [selectedVal, setSelectedVal] = useState('');


  

  /* const handleChange=(event)=>{
  setUtr(event.target.value);
  setRemarks(event.target.value);
  } */
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  console.log(date);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };



  const editprofolist = (index, refId) => {
    setReceiptDetails(true);
    setShowList(false);
    setShowHistory(false);
    // setTableListNo(refId);

    customAxios.post('import/profomainvoice/getById', { refId: refId }).then((result) => {
      if (!result.data.error && result.data.data) {
        let {
          ref_id,
          totalamt,
          currency,
        } = result.data.data
        
        //  console.log(booking_number);
        console.log(result.data);
        setProfoDetails({
          //   _id: _id,
          //   isApproved: 0,
          ref_id: ref_id,
          totalamt: totalamt,
          currency: currency,
        })

        //  setProfoList(invoice_charges);
        //  setIsApproved(result.data.isApproved? result.data.isApproved : 0);
        //  setIsApprovedId(result.data.data._id);
        //  console.log(profoDetails.ref_id);

        console.log(profoDetails);
      }
      else {
        window.alert('No data found');
      }
    })

    customAxios.post('/import/accounts_inv/getbyID', { invoice_no: refId }).then((result) => {
      console.log(result.data.data)
      if (!result.data.error && result.data.data) {
        // console.log(invoice_no);
        let {
          _id,
          invoice_no,
          curr,
          date,
          total_amt,
          utr_no,
          balance_amt,
          rec_amt,
          remarks,
          receipt_no
        } = result.data.data
        console.log(result.data);
        setHistoDetails(result.data.data)

        setBalanceAmnt(result.data.data && result.data.data.length > 0 ? result.data.data[result.data.data.length - 1].balance_amt.toFixed(2) : '')

        setAmtCalc(result.data.data && result.data.data.length > 0 ? result.data.data[result.data.data.length - 1].balance_amt : '')
        setIsPaid(result.data.data && result.data.data.length > 0 && result.data.data[result.data.data.length - 1].balance_amt === 0 ? true : false);
      }
      else {
        window.alert('No data found');
      }
    })
  }





  //received nd balance amount function
  const handleamountR = (event) => {
    setReceivedAmnt(event.target.value);
    var bal = amtCalc == 0 ? profoDetails.totalamt - event.target.value : amtCalc - event.target.value;
    setBalanceAmnt(bal.toFixed(2));
    console.log(tableLists);
  }

  const paymentSaving = () => {
    if (profoDetails.ref_id && profoDetails.currency && date && profoDetails.totalamt && utr && receivedAmnt) {
      var ref = profoDetails.ref_id;
      var refid = parseInt(ref);
      customAxios
        .post('/import/accounts_inv/save', {
          /* "temp": { */
          "invoice_no": refid,
          "curr": profoDetails.currency.toString(),
          "date": date,
          "total_amt": profoDetails.totalamt,
          "utr_no": utr,
          "balance_amt": balanceAmnt,
          "rec_amt": receivedAmnt,
          "remarks": remarks,
          /*  } */
        })
        .then((data) => {
          console.log(data);
          if (data.data.error) window.alert('Unable to make payment');
          else {
            window.alert('paid successfully');
            window.location.reload();
          }
        });
    } else {
      window.alert('Please fill all the required fields');
    }

  }

  const viewHistoryList = (index, invoice_no) => {
    setShowList(false);
    setShowHistory(true);
    // setTableListNo(invoice_no);
    console.log(invoice_no);
    customAxios.post('/import/accounts_inv/getbyID', { invoice_no: profoDetails.ref_id }).then((result) => {
      console.log(result.data.data)
      if (!result.data.error && result.data.data) {
        console.log(invoice_no);
        let {
          _id,
          invoice_no,
          curr,
          date,
          total_amt,
          utr_no,
          balance_amt,
          rec_amt,
          remarks,
          receipt_no
        } = result.data.data
        console.log(result.data);
        setHistoDetails(result.data.data)

      }
      else {
        window.alert('No data found');
      }
    })
  }


  const historyDetails = (index, invoice_no) => {
    console.log(index);
    setShowList(false);
    setShowHistory(false);
    setReceiptDetails(false);
    setViewHistoryDetails(true);
    // setTableListNo(invoice_no);
    console.log(invoice_no);
    customAxios.post('/import/accounts_inv/getbyID', { invoice_no: profoDetails.ref_id }).then((result) => {
      console.log(result.data.data)
      if (!result.data.error && result.data.data) {
        console.log(invoice_no);
        let {
          _id,
          invoice_no,
          curr,
          date,
          total_amt,
          utr_no,
          balance_amt,
          rec_amt,
          remarks,
          receipt_no
        } = result.data.data
        console.log(result.data);
        //  setViewHisto(result.data.data)
        setViewHisto(result.data.data[index])

      }
      else {
        window.alert('No data found');
      }
    })
  }

  const cancel = () => {
    window.location.reload();
  }

  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
    setconstno(profomainvoiceAutogen)


    let url = '/import/profomainvoice';
    customAxios.get(url).then((response) => {

      setTableLists(response.data.data);
      setSearchList(response.data.data);
      console.log(response.data.data);
      // console.log('dataaa', sellings.data.data);
    });

    let refNumUrl = '/import/profomainvoice';
    customAxios.get(refNumUrl).then((refNos) => {
      let temp = [...refNos.data.data];
      let refno = temp.map((data) => {
        return { label: data.ref_id, value: data.ref_id };
      })
      setRefnumber([...refno]);

    })
  }, [showList]);

  const handleSearch = (option) => {
    if (option == null) {
      let url = '/import/profomainvoice';
      customAxios.get(url).then((tableLists) => {

        setTableLists(tableLists.data.data);
        setSearchList(tableLists.data.data);
        setSelectedVal(null);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.ref_id === (option ? option.value : ' '));
      setTableLists(filterdata);
    }
  }

  const columns = [

    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "BKG.NO",
      accessor: "booking_number",

    },
    {
      Header: "REF#",
      accessor: "ref_id",
    },
    {
      Header: "To",
      accessor: "to_customer_name",
    },
    {
      Header: "Curr",
      accessor: "currency",
    },
    {
      Header: "Total Amount",
      accessor: "totalamt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const DATA = tableLists.filter(item => item.is_approved === 1).map((item, index) => {
    const refNo = item.ref_no;
    // { console.log(DATA, refNo); }

    return {
      id: index + 1,
      booking_number: 'RUKIM' + item.booking_number,
      ref_id: 'RILWEM/FI/' + item.ref_id,
      to_customer_name: item.to_customer_name,
      currency: item.currency,
      totalamt: item.totalamt.toFixed(2),
      action: (
        <div style={{ textAlign: 'center' }}>
          {console.log(refNo)}
          <button
            className={classes.footerButton}
            onClick={() => editprofolist(index, item.ref_id)}
          >
            Receipt
          </button>
          {console.log(item.refNo)}
        </div>
      )
    };

  });


  return (
    <>
      <div className={classes.container}>
        <h5>Accounts Invoice <span className={classes.title}>Imports / Accounts Invoice</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (

          <>
            <table>
              <tr>
                <h6>Invoice Listing</h6>
                <td colSpan={1} style={{ width: '10px', paddingLeft: '715px' }}>Search</td>
                <td colSpan={4} style={{ width: '175px' }}>
                  {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={refnumber}
                    // value={cnNo.value}
                    /* value={invnumber.filter(
                        (option) =>{ 
                            return option.value === tableListNo
                        }
                    )} */
                    value={selectedVal}
                    onChange={handleSearch}
                  // selectedValue={selectedValue}
                  /* onChange={(selected) => {
                      editprofolist(selected ? selected.value:'');
                    }}  */

                  />

                </td>
              </tr>
            </table>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>

              <CustomTable columns={columns} data={DATA} />

              {/* <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Invoice.No</th>
                    <th scope='col'>BKG.NO</th>
                    <th scope='col'>Customer Name</th>
                    <th scope='col'>Currency</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {tableLists.length
                    ? tableLists.map((tableList, index) => { 
                     
                      return ( 
                    <>
                      <tr key={index}>
                        <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                        <td style={{ 'text-align': 'center ' }}>{tableList.ref_id}</td>
                        <td style={{ 'text-align': 'center ' }}>{tableList.booking_number}</td>
                        <td style={{ 'text-align': 'center ' }}>{tableList.to_customer_name}</td>
                        <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                        <td style={{ 'text-align': 'center ' }}>{tableList.totalamt.toFixed(2)}</td>

                        <td style={{ 'text-align': 'center ' }}>
                          <button
                            className={classes.footerButton}
                            onClick={() => editprofolist(index, tableList.ref_id)}
                          >
                            Receipt
                          </button>
                        </td>
                      </tr>
                    </>
                       ); 

                     })
                     : ''}  

                  
                </tbody>
              </table> */}


              {/* {tableLists.length ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )} */}
            </div>
          </>
        ) : ' '}
        {receiptDetails ? (
          <>
            <Container className={classes.inputsContainer}>

              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <td >Date</td>
                  <td colSpan={5}><input className={classes.inputText} value={date} type="text" disabled={true} /></td>

                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Invoice.No#*</span></td>
                  <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                  <td colSpan={1}><input className={classes.inputText} type="text"
                    value={profoDetails ? profoDetails.ref_id : ''} disabled={true}
                            /* onChange={(evt) => handlerefNo(evt)}

                            onKeyUp={(evt) => getinvoiceDetail(evt)} */ /></td>

                </tr>
                  <tr style={{ height: '50px' }}>
                    <td>Currency</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.currency : ''} /* onChange = {handleVendor} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>UTR</td>
                    <td colSpan={2}><input className={classes.inputText} value={utr} onChange={(event) => setUtr(event.target.value)}
                      type="text" /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Total Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={profoDetails ? parseFloat(profoDetails.totalamt).toFixed(2) : ''} /* onChange = {handleVendor} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>Remarks</td>
                    <td colSpan={5} rowSpan={4}><textarea style={{ height: '133px', marginTop: '-30px' }} value={remarks} onChange={(event) => setRemarks(event.target.value)} /></td>


                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Amount</td>
                    {isPaid ? (
                      <td colSpan={5}><input className={classes.inputText} value={receivedAmnt} type="number" disabled /></td>
                    ) : (
                      <td colSpan={5}><input className={classes.inputText} value={receivedAmnt} onChange={handleamountR} type="number" /></td>
                    )}
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Balance</td>
                    <td colSpan={5}><input className={classes.inputText} value={balanceAmnt} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_name',
                                    value: evt.target.value,
                                })} */
                      type="text" disabled={true} /></td>
                  </tr>



                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tbody>
              </table>
              <div className={classes.footer}>
                {isPaid ? <button className={classes.footerButton} onClick={paymentSaving} disabled={true}>
                  Paid
                </button> : <button className={classes.footerButton} onClick={paymentSaving}>
                  Pay
                </button>}

                <button className={classes.footerButton} onClick={cancel}>
                  Cancel
                </button>

                <button
                  className={classes.footerButton}
                  onClick={() => { setShowList(true); setShowHistory(false); setViewHistoryDetails(false); setReceiptDetails(false) }}
                >
                  View List
                </button>

                <button
                  className={classes.footerButton}
                  onClick={viewHistoryList}
                >
                  View History
                </button>
              </div>
            </Container>
          </>
        ) : ''}



        {showHistory ? (
          <>
            <h6>Payment History</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Invoice.No</th>
                    <th scope='col'>Total</th>
                    <th scope='col'>Balance</th>
                    <th scope='col'>Paid</th>
                    <th scope='col'>Receipt No</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {histoDetails && showHistory && histoDetails.length > 0
                    ? histoDetails.map((histoDetail, index) => {

                      return (
                        <>

                          <tr key={index}>
                            <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                            <td style={{ 'text-align': 'center ' }}>{histoDetail.invoice_no}</td>
                            <td style={{ 'text-align': 'center ' }}>{histoDetail.total_amt.toFixed(2)}</td>
                            <td style={{ 'text-align': 'center ' }}>{histoDetail.balance_amt.toFixed(2)}</td>
                            <td style={{ 'text-align': 'center ' }}>{histoDetail.rec_amt.toFixed(2)}</td>
                            <td style={{ 'text-align': 'center ' }}>{histoDetail.receipt_no}</td>
                            <td style={{ 'text-align': 'center ' }}>
                              <img src={icon_view} alt='icon_view' onClick={() => historyDetails(index, histoDetail.invoice_no)} />
                            </td>

                          </tr>

                        </>
                      );

                    })
                    : ''}
                </tbody>
              </table>
              {/* {histoDetails && histoDetails.length < 0 ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )} */}
            </div>
          </>
        ) : (' ')}

        {viewHistoryDetails ? (
          <>
            <Container className={classes.inputsContainer}>

              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <td >Date</td>
                  <td colSpan={5}><input className={classes.inputText} value={date} type="text" disabled={true} /></td>

                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Invoice.No#*</span></td>
                  <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                  <td colSpan={1}><input className={classes.inputText} type="text"
                    value={viewHisto ? viewHisto.invoice_no : ''} disabled={true}
                           /* onChange={(evt) => handlerefNo(evt)}

                           onKeyUp={(evt) => getinvoiceDetail(evt)} */ /></td>

                </tr>
                  <tr style={{ height: '50px' }}>
                    <td>Currency</td>
                    <td colSpan={5}><input className={classes.inputText} value={viewHisto ? viewHisto.curr : ''} /* onChange = {handleVendor} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>UTR</td>
                    <td colSpan={2}><input className={classes.inputText} value={viewHisto ? viewHisto.utr_no : ''} /* onChange={(event)=>setUtr(event.target.value)} */
                      type="text" disabled={true} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Total Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={viewHisto ? parseFloat(viewHisto.total_amt).toFixed(2) : ''} /* onChange = {handleVendor} */ type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>Remarks</td>
                    <td colSpan={5} rowSpan={4}><textarea style={{ height: '133px', marginTop: '-30px' }} value={viewHisto ? viewHisto.remarks : ''} /* onChange={(event)=>setRemarks(event.target.value)} */ disabled={true} /></td>


                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={viewHisto ? parseFloat(viewHisto.rec_amt).toFixed(2) : ''} /* onChange = {handleamountR} */ type="text" disabled={true} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Balance</td>
                    <td colSpan={5}><input className={classes.inputText} value={viewHisto ? parseFloat(viewHisto.balance_amt).toFixed(2) : ''} /* onChange={(evt) =>
                               handleBookingDetails({
                                   key: 'cus_name',
                                   value: evt.target.value,
                               })} */
                      type="text" disabled={true} /></td>
                  </tr>



                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tbody>
              </table>
              <div className={classes.footer}>
                <button className={classes.footerButton} onClick={() => { setReceiptDetails(true); setShowHistory(true); setViewHistoryDetails(false) }}>
                  Back
                </button>
              </div>

            </Container>
          </>
        ) : (' ')}


      </Container>
    </>
  );

}


import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './profoapproval.module.css';
import './invoice.module.css';
import Template from './ProApprovalpdf';


export default function ImpProfomaApprovedList() {
  const [tableLists, setTableLists] = useState(true);
  const [showList, setShowList] = useState(true);
  const [tableListNo, setTableListNo] = useState('');
  const [profoDetails, setProfoDetails] = useState(initProdetails);
  const [profoList, setProfoList] = useState('');
  const [isApproved, setIsApproved] = useState(0);
  const [isApprovedId, setIsApprovedId] = useState('');
  const [constno, setconstno] = useState('');
  const [bookingDetails, setBookingDetails] = useState(initBooking);
  const [amtinwords, setAmtinwords] = useState('');
  const [bookingid, setBookingid] = useState('')

  const initBooking = {
    buying_id: '',
    selling_id: '',
    carrier_booking_no: '',
    quantity_20: '',
    quantity_40: '',
    house_bl: false,
    vessel: '',
    voyage: '',
    pol: '',
    etd: '',
    pod: '',
    etd: '',

};

  const initProdetails = {
            booking_number: '',
            carrier_booking_no: '',
            bill_type: '',
            to_customer_name: '',
            current_date: '',
            Address: '',
            currency: '',
            shipper: '',
            agent: '',
            mlo: '',
            notify_party: '',
            cnf: '',
            remarks: '',
            invoice_charges: '',
            totalamt:'',

  }



  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  console.log(date);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  

  async function editprofolist (index, refId) {
    setShowList(false);
    setTableListNo(refId);
    let getbkngid;
    await customAxios.post('/profomainvoice/getById', { refId: refId }).then((result) => {
      if (!result.data.error && result.data.data) {
         let{
            _id,
            booking_number,
            carrier_booking_no,
            bill_type,
            to_customer_name,
            current_date,
            Address,
            currency,
            shipper,
            agent,
            mlo,
            notify_party,
            cnf,
            remarks,
            invoice_charges,
            destination,
            consignee,
            ref_id,
            currency_value,
            container_no,
            container_count,
            totalamt,
            invoice_no,
            amt_in_words,
         } = result.data.data

         console.log(booking_number);
         console.log(result.data);
         setProfoDetails({
          _id: _id,
          booking_number: booking_number,
          carrier_booking_no: carrier_booking_no,
          bill_type: bill_type,
          to_customer_name: to_customer_name,
          current_date: current_date,
          Address: Address,
          currency: currency,
          shipper: shipper,
          agent: agent,
          mlo: mlo,
          notify_party: notify_party,
          cnf: cnf,
          remarks: remarks,
          invoice_charges: invoice_charges,
          isApproved: 0,
          destination: destination,
          consignee: consignee,
          ref_id: ref_id,
          currency_value: currency_value,
          container_no: container_no,
          container_count: container_count,
          totalamt: totalamt,
          invoice_no: invoice_no,
          amt_in_words: amt_in_words,
         })
         setBookingid(booking_number);
         setProfoList(invoice_charges);
         setIsApproved(result.data.isApproved? result.data.isApproved : 0);
         setIsApprovedId(result.data.data._id);
         setAmtinwords(result.data.data.amt_in_words);
         console.log(result.data.data.amt_in_words);
         getbkngid = result.data.data.booking_number;
        //  console.log(getbkngid);
        
         console.log(profoDetails);
      }
      else{
        window.alert('No data found');
      }
    })

    let bkng = await customAxios.post('/booking/getById', { id: getbkngid }).then((resp) => {
      let res = resp.data.data;
      console.log(res);
      getbkngid = res;
      // console.log('booking res--', res);

      // setHouseBL(res.house_bl);
      if (res.buying_id) {
          setBookingDetails({
              voyage: res.sailing_schedule.voyage,
              yard_detail: res.yard_detail,
              pol: res.sailing_schedule.pol,
              eta: res.sailing_schedule.eta,
              pod: res.sailing_schedule.pod,
              etd: res.sailing_schedule.etd,
              remarks: res.remarks,
          });

      } else {
         
          setBookingDetails(initBooking);
      }
  });

  
  }

const ApprovedId = () =>{

 
  customAxios
          .post('/profomainvoice/updateapproved', {
            "temp": {
             "_id": isApprovedId,
            "is_approved": 1
            }
        })
          .then((data) => {
            console.log(data);
            if (data.data.error) window.alert('Unable to update the details!!');
            else {
              window.alert('Approved successfully');
              setIsApprovedId(1)
            }
          });

}
  
  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
    setconstno(profomainvoiceAutogen)


    let url = '/profomainvoice';
    customAxios.get(url).then((tableLists) => {
      
      setTableLists(tableLists.data.data);
      console.log(tableLists.data.data);
      // console.log('dataaa', sellings.data.data);
    });
  }, [showList]);



  return (
    <>
      <div className={classes.container}>
      <h5>Invoice <span className={classes.title}>Import / Approved List</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Proforma Invoice Approved Listing</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              {/*<div className={classes.searchContainer}>
                <div className={classes.searchBox}>
                  <div style={{ position: 'relative' }}>
                    <input
                      type='text'
                      placeholder='Search'
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue('')}
                      >
                        X
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt='search-icon'
                    className={classes.searchIcon}
                    onClick={searchCustomer}
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {setShowList(false); setCustomer(initValue);}}
                  >
                    Add
                  </button>
                </div>
                    </div> */}
              <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>BKG.NO</th>
                    <th scope='col'>REF#</th>
                    <th scope='col'>To</th>
                    <th scope='col'>Total Amount</th>
                    <th scope='col'>Date</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {tableLists.length
                    ? tableLists.map((tableList, index) => {
                     
                      return (
                        <>
                        {tableList.is_approved == 1 ? 
                        <tr key={index}>
                          <th scope='row'>{index + 1}</th>
                          <td>{tableList.booking_number}</td>
                          <td>{tableList.ref_id}</td>
                          <td>{tableList.to_customer_name}</td>
                          <td>{tableList.totalamt}</td>
                          <td>{tableList.current_date}</td>
                          <td style={{ 'text-align': 'center ' }}>
                            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, tableList.ref_id)} />
                          </td>
                        </tr>
                        : null}
                        </>
                      );
                    
                    })
                    : ''}
                </tbody>
              </table>
              {tableLists.length ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <table style={{ width: '100%' }}>
              <tbody><tr>
                <td colSpan={1}><span>BKG.No#*</span></td>
                <td colSpan={2} style={{ width: '100px' }}>
                  <section>
                    <div style={{ position: 'relative' }}>
                      <p style={{ position: 'absolute', maxWidth: '45px', padding: '10px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKIM</p>
                      <input
                        type='text'
                        className={classes.inputText}
                        style={{ paddingLeft: '50px' }}
                      value={profoDetails?profoDetails.booking_number : ''}
                      // onChange={(e) => setBookingNo(e.target.value)}
                      // onKeyUp={(e) => { handleShippingNo(e ? e : 'edfc') }}
                     disabled={true} />
                    </div>
                  </section>
                </td>

                <td colSpan={1} style={{ width: '10px' }}><span>BL#*</span></td>
                <td colSpan={2}><input className={classes.inputText}  value={profoDetails?profoDetails.carrier_booking_no: ''}  disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'carrier_no', value: e.target.checked }); console.log(e.target.checked); }} */  type="text" /></td>
                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Invoice.No#*</span></td>
                <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true}/></td>
                <td colSpan={1}><input className={classes.inputText} type="text"
                value={profoDetails && profoDetails.invoice_no ? (profoDetails.invoice_no.toString().padStart(5,'0')) : ''}
                // value={refNo}
                // onChange={(evt) => handlerefNo(evt)}
                // onChange={(e) => { handleshippingDetails({ key: 'refNo', value: e.target.checked }); console.log(e.target.checked); }}
                // onKeyUp={(evt) => getinvoiceDetail(evt)} 
                disabled={true}/>
                </td>

              </tr>
                <tr>
                  <td>To<span>*</span></td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.to_customer_name : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_name',
                                    value: evt.target.value,
                                })} */ 
                    type="text" disabled={true}/></td>

                  <td style={{ paddingLeft: '35px' }}>Date</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails?profoDetails.current_date : ''} type="text" disabled={true}/></td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td colSpan={5}><input className={classes.inputText}  value={profoDetails?profoDetails.Address : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true}/></td>
                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                  <td colSpan={1} ><input  className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                      styles={style}
                      isClearable={true}
                      isSearchable={true}
                    // options={currencies}
                    type="text"
                    value = {profoDetails ? profoDetails.currency: ''}
                    // value={currencies.filter(
                    //     (option) => option.value === selectedValue
                    // )}
                    // selectedValue={selectedValue}
                    // onChange={(e) => {
                    //     if (e) handleSelectChange(e);
                    // }}
                    disabled={true} >
                    </input>
                  </td>
                  <td colSpan={1}><input className={classes.inputText} value={profoDetails ? profoDetails.currency_value: ''} /* onChange={(e) => { handleProfoInvInp(e) }} */ type="text" disabled={true} /></td>
                </tr>
                <tr>

                  <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                  <td colSpan={5}><input className={classes.inputText}  value={profoDetails?profoDetails.destination: ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true}/></td>

                </tr>
                <tr>
                  <td colSpan={1}>Shipper</td>
                  <td colSpan={2}><input className={classes.inputText}  value={profoDetails?profoDetails.shipper : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'shipper_name',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true}/></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                  <td colSpan={2}><input className={classes.inputText}  value={profoDetails?profoDetails.agent : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true}/></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container</td>
                  <td colSpan={2} rowSpan={2}><textarea style={{ height: '100px', marginTop: '-18px' }} value={profoDetails ? profoDetails.container_count : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} */ disabled={true} /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Cosingnee</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails?profoDetails.consignee: ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'consignee_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true}/></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                  <td colSpan={2}><input className={classes.inputText}  value={profoDetails? profoDetails.mlo : ''} /* onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} */ type="text" disabled={true}/></td>

                </tr>
                <tr>
                  <td colSpan={1}>Notify Party</td>
                  <td colSpan={2}><input className={classes.inputText}  value={profoDetails?profoDetails.notify_party : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true}/></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails?profoDetails.cnf : ''} type="text" disabled={true} /* onChange={(e) => { handleBookingDetails({ key: 'CNF', value: e.target.checked }); console.log(e.target.checked); }} */ /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Remarks</td>
                  <td colSpan={5}><textarea style={{ height: '100px' }}  value={profoDetails?profoDetails.remarks : ''}  disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'remarks', value: e.target.checked }); console.log(e.target.checked); }} */ /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container No</td>
                  <td colSpan={2}><textarea style={{ height: '118px', marginTop: '-20px' }} value={profoDetails ? profoDetails.container_no: ''}  disabled={true} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} */  /></td>
                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tr>  
              </tbody>
            </table>
            
            <Row className={classes.row}>
              <Col className={classes.input} md='5'>
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    padding: '10px 0px',
                  }}
                >
                  Invoices
                </p>
              </Col>
            </Row>

            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className={classes.theadDark}>
                  <tr>
                    <th>charges</th>
                    <th>Curr</th>
                    <th>Rate</th>
                    <th>Base</th>
                     <th>Amount</th> 
                    {/* <th>Action</th> */}


                  </tr>
                </thead>

                <tbody>
                   {profoList ? <>
                                {profoList.map((data, index) => ( 
                  <tr>
                    <td>{data.charge}</td>
                    <td>{data.currency}</td>
                    <td>{data.rate}</td>
                    <td>{data.base}</td>
                    <td>{data.total}</td>
                    {/* {data.total > 0 ? <td>{data.total}</td> : '0'}  */}

                    {/*                                         {
                                            data.currency == 'USD' ? (
                                            <td>{data.rate * profoInvInp}</td>
                                            ): <td>{data.rate * details.containerlength }</td>
                                            } */}
                  </tr>
                  )
                                )} </>
                                : null} 
                </tbody>

              </table>

            </div>
            <div className={classes.footer}>
              {/* {isApproved == 0 ? <button className={classes.footerButton} onClick={ApprovedId}>
                Approve
              </button>: null} */}
              <button
                className={classes.footerButton}
               onClick={() => {setShowList(true)}} 
              >
                View List
              </button>
              <button className={classes.footerButton} /* style={{ float: 'right', margin: '1%' }} */><Template
              /* sellingDetails={sellingDetails}
              buyingDetails={buyingDetails}
              bookingDetails={bookingDetails}
              bookingNo={bookingNo}
              charges_inc={charges_inc} */
              // detalils={details}
              /* allCharges={allCharges}
              customerdetails={customerdetails}
              remarks={remarks}
              cargo={cargo}
              shippingDetails={shippingDetails}
              selectedValue={selectedValue} */
              amtinwords={amtinwords}
              profoDetails={profoDetails}
              profoList={profoList}
              bookingDetails={bookingDetails}
            
              />
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );

  }


import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";

function BankAccDetails() {
  let initValue = {
    name: "",
    branch: "",
    address: "",
    usd_account_number: "",
    iban: "",
    sort_code: "",
    swift_bic: "",
  };
  
  const [bank, setBank] = useState(initValue);
  const [bankId, setBankId] = useState('');
  const [error, setError] = useState("");

  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;

  const setBankDetails = ({ key, value }) => {
    let detail = { ...bank };
    detail[key] = value.toUpperCase();
    setBank(detail);
    
  };

  const saveDetail = () => {
    let temp = { ...bank };
    
    if (temp._id) {
      customAxios.post("/bank/update", { temp }).then((data) => {
        
        if (data.data.error) {
          setError(data.message);
        } else {
          
          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/bank/save";
      customAxios
        .post(url, bank)
        .then((resp) => {
          
          if (resp.data.error) {
            
            if (
              resp.data.message ===
              "Some error occurred while creating the bank."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  // useEffect(() => {
  //   let url = "/bank";
  //   customAxios.get(url).then((bank) => {
  //     setBank({...bank.data.data[0]});
  //     setBankId(bank.data.data[0]._id);
  //   });
  // }, []);

  
  useEffect(() => {
    let url = "/bank";
    customAxios.get(url).then((bank) => {
      if (bank.data.data && bank.data.data[0]) {
        setBank({ ...bank.data.data[0] });
        setBankId(bank.data.data[0]._id);
      } else {
        console.error("No data");
      }
    });
  }, []);

  return (
    <>
      <div className={classes.container}>
        <h5>Bank A/C Details</h5>
        <section className={classes.title}>Dashboard / Bank A/C Details</section>
      </div>
      <Container className={classes.inputsContainer}>
        <Row className={classes.row}>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            Bank<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.name}
              onChange={(e) =>
                setBankDetails({ key: "name", value: e.target.value })
              }
            />
          </Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            Branch<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.branch}
              onChange={(e) =>
                setBankDetails({ key: "branch", value: e.target.value })
              }
            />
          </Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p style={{paddingBottom:'7px'}}>
            Address<span>*</span>
            </p>
             <textarea
             style={{textTransform: 'uppercase'}}
                  placeholder="Maximum of 100 characters"
                  maxlength="100"
                  value={bank.address}
                  onChange={(e) =>
                    setBankDetails({
                      key: "address",
                      value: e.target.value,
                    })
                  }
                />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            USD A/C NO<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.usd_account_number}
              onChange={(e) =>
                setBankDetails({ key: "usd_account_number", value: e.target.value })
              }
            />
          </Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            IBAN<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.iban}
              onChange={(e) =>
                setBankDetails({ key: "iban", value: e.target.value })
              }
            />
          </Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            Sort Code<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.sort_code}
              onChange={(e) =>
                setBankDetails({
                  key: "sort_code",
                  value: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col sm={6} lg={4} xs={12} className={classes.input}>
            <p>
            SWIFT BIC<span>*</span>
            </p>
            <input
            style={{textTransform: 'uppercase'}}
              type="text"
              className={classes.inputText}
              value={bank.swift_bic}
              onChange={(e) =>
                setBankDetails({ key: "swift_bic", value: e.target.value })
              }
            />
          </Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}></Col>
          <Col sm={6} lg={4} xs={12} className={classes.input}></Col>
        </Row>
        <div style={{ height: '30px' }}>
          <span>{error}</span>
        </div>
        {/* <span>{error}</span> */}
        <div className={classes.footer}>
          <button className={classes.footerButton} onClick={saveDetail}>
            {bankId ? 'Update' : 'Save'}
          </button>
        </div>
      </Container>
    </>
  );
}

export default BankAccDetails;

import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from "react-select";

function Containers() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    type: "",
    //  code: '',
    description: "",
  };
  const [container, setContainer] = useState(initValue);
  const [containers, setContainers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  const [containerUpdate, setContainerUpdate] = useState(false);
  const [error, setError] = useState("");
  const [containerList, setContainerList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');

  const setContainerDetail = ({ key, value }) => {
    let detail = { ...container };
    detail[key] = value.toUpperCase();

    //if(key === 'type'){
    //  detail['code'] = generateIntraeCode(value);
    //}

    setContainer(detail);

  };

  //const generateIntraeCode = (str) => {
  //  str = str.replace('GP', '10');
  //  str = str.replace('GB', '10');
  //  str = str.replace('HC', '11');
  //  return str;
  //}

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    position: 'sticky',
    zIndex: 1
    // textAlign: 'center'
  };

  const saveContainer = () => {
    let temp = { ...container };

    if (temp._id) {
      customAxios.post("/container/update", { temp }).then((data) => {

        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/container";
      customAxios
        .post(url, container)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setContainer(initValue);
            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchContainer = () => {
    let url = "/container/search";
    customAxios.post(url, { name: searchValue }).then((containers) => {
      setContainers(containers.data.data);
    });
  };

  const deleteContainer = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      //
      customAxios
        .delete("/container", { data: { userId: id } })
        .then((data) => {
          if (data.data.error) {
            setError(data.message);
          } else {

            window.alert("Container deleted succesfully!");
            let temp = [...containers];
            temp.splice(index, 1);
            setContainers(temp);
          }
        });
    }
  };

  const editContainer = (index, id) => {
    let temp = [...containers];
    setContainer({ ...temp[index] });
    setShowList(false);
    setContainerUpdate(true);
  };

  useEffect(() => {
    let url = "/container";
    customAxios.get(url).then((containers) => {
      console.log(containers);
      setContainers(containers.data.data);
      setSearchList(containers.data.data);
    });

    let containerurl = "/container";
    customAxios.get(containerurl).then((container) => {
      let temp = [...container.data.data];
      let contain = temp.map((data) => {
        return { label: data.type, value: data.type }
      })
      setContainerList([...contain]);
    });
  }, [showList]);

  const handleSearch = (option) => {

    if (option == null) {
      let url = "/container";
      customAxios.get(url).then((containers) => {
        setContainers(containers.data.data);
        setSearchList(containers.data.data);
        setSelectedVal(null);

      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.type === (option ? option.value : ''));
      setContainers(filterdata);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Container</h5>
        <section className={classes.title}>Dashboard / container</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Container Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchContainer}
                  />
                </div> */}
                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={containerList}
                    value={selectedVal}
                    onChange={handleSearch}
                  />
                </div>
                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setContainer(initValue);
                      setContainerUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Container Type</th>
                    {/* <th scope='col'>Inttra code</th> */}
                    <th scope="col" style={theadStyle}>Container Description</th>
                    {/* {isSuperuser && */} <th scope="col" style={theadStyle}>Action</th>{/* } */}
                  </tr>
                </thead>
                <tbody>
                  {containers.length
                    ? containers.map((container, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td style={{ textTransform: 'uppercase' }}>{container.type}</td>
                          {/* <td>{container.code}</td> */}
                          <td style={{ textTransform: 'uppercase' }}>{container.description}</td>
                          {/* {isSuperuser && ( */}
                          <td>
                            {/* <a href='#'> */}
                            <img
                              src={EditIcon}
                              alt="edit_icon"
                              onClick={() =>
                                editContainer(index, container._id)
                              }
                            />
                            {/* </a>{' '} */}
                            &nbsp;&nbsp; {/* <a href='#'> */}
                            <img
                              src={DeleteIcon}
                              alt="delete_Icon"
                              onClick={() =>
                                deleteContainer(index, container._id)
                              }
                            />
                            {/* </a> */}
                          </td>
                          {/* )} */}
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {containers.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Container</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Container Type</p>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={classes.inputText}
                  value={container.type}
                  onChange={(e) =>
                    setContainerDetail({ key: "type", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            {/*<Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Container Code</p>
                <input
                  type='text'
                  value={container.code}
                  onChange={(e) =>
                    setContainerDetail({
                      key: 'code',
                      value: e.target.value,
                    })
                  }
                />
              </Col>
                </Row> */}
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Container Description</p>
                <input
                  style={{ textTransform: 'uppercase' }}
                  type="text"
                  className={classes.inputText}
                  value={container.description}
                  onChange={(e) =>
                    setContainerDetail({
                      key: "description",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveContainer}>
                {containerUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setShowList(true)}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => setContainer(initValue)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Containers;

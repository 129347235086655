import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const customAxios = axios.create({
 baseURL: 'https://service.reudaninternational.com',
});

// const customAxios = axios.create({
//   baseURL: 'http://192.168.1.51:4052',
// });
//  const customAxios = axios.create({
//    baseURL: 'http://localhost:4052',
//  });

customAxios.interceptors.request.use((config) => {
  let token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

customAxios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default customAxios;

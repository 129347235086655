import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import classes from './invoice.module.css';
import customAxios from '../../../Axios';
import Customer from '../../Master/Customer';
import { DeleteIcon, EditIcon } from '../../../Images';
import Template from './ProCreditpdf';
import { async } from 'q';


export default function ProfomaCNOPS() {


    const [tableData, setTableData] = useState('');
    const [formInput, setFormInput] = useState(
        {
            charge: '',
            currency: '',
            rate: '',
            amount: '',
            base: '',
            exchangeRate: ''
        }
    );


    const initDetails = {
        booking_number: '',
        //carrier_booking_no: '',
        //carrier_bl: '',
        issued_at: '',
        issued_on: '',
        bl_type: '',
        //bl_by: '',
        shipper_name: '',
        shipper_address: '',
        consignee_name: '',
        consignee_address: '',
        notify_party_name: '',
        notify_party_address: '',
        also_notify_party_name: '',
        also_notify_party_address: '',
        forwarding_agent: '',
        mark_and_no: '',
        mark_description: '',
        container_details: [],
    };

    const initBooking = {
        buying_id: '',
        selling_id: '',
        carrier_booking_no: '',
        quantity_20: '',
        quantity_40: '',
        house_bl: false,
        vessel: '',
        voyage: '',
        pol: '',
        etd: '',
        pod: '',
        etd: '',

    };

    const initContDetail = {
        container_no: '',
        seal_no: '',
        package_no: '',
        package_type: '',
        gross_wt: '',
        net_wt: '',
        measurement: '',
    };

    const initBuying = {
        freight: '',
        source: '',
        destination: '',
        loading_port: '',
        discharge_port: '',
        carrier: '',
        _id: '',
    };
    const handleChange = (evnt) => {
        const newInput = (data) => ({ ...data, [evnt.target.name]: evnt.target.value })
        setFormInput(newInput)

    }

    const handleSubmit = (evnt) => {

        evnt.preventDefault();
        const checkEmptyInput = !Object.values(formInput).every(res => res === "")

        if (checkEmptyInput) {

            var data = allCharges;
            // setAllcharges([])
            var dat = { charge: newCharge, currency: newCurrency, rate: formInput.rate, base: newBase, exchangeRate: formInput.exchangeRate }
            data.push(dat);
            setAllcharges(data);


            // var containercount = "20GP:" + ' ' + bookingDetails.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40GP/HC:" + ' ' + bookingDetails.quantity_40;


            var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
            var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;


            var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
            var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;

            allCharges.total = 0;

            {
                allCharges.forEach((data, index) => {

                    if (selectedValue === 'USD') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }

                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                    else if (selectedValue === 'GBP') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }

                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }
                    if (data.currency == 'EUR') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = data.rate * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                        else /* (data.base == '20GP' || data.base == '40GP' || data.base == '40HC' || data.base == '40OT' || data.base == '20OT') */ {
                            allCharges[index].total = data.rate * (data.base == '20GP' ? q20 : q40) * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                    }
                })
                setTotalInWords(numberToWords(allCharges.total) + ' Only');
            }
        }
        var data = [...allCharges];
        data.total = allCharges.total;
        setAllcharges(data);
        allCharges.total = data.total;
    }

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };

    let initBookingValue = {
        shipping_id: "", //shippingNo
        carrier_no: "",
        customer_name: '',
        Address: '',
        remarks: '',
        currency: '',
        Destination: '',
        shipper: '',
        Agent: 0,
        container: 0,
        cosingnee: 21000,
        MLO: 28000,
        Notify_party: '',
        CNF: '',
        total: '',
    };


    const [shippingDetails, setshippingDetails] = useState(initBookingValue);

    const handleshippingDetails = ({ key, value }) => {

        handleProfoInvInp(value)
        let tempshipping = { ...shippingDetails };
        tempshipping[key] = value;
        setshippingDetails({ ...tempshipping });

    };


    const handleBookingDetails = ({ key, value }) => {

        let temp = { ...customerdetails };
        temp[key] = value;
        setcustomerdetails({ ...temp });
    };

    const updateCustomerAddress = (val) => {
        customAxios
            .post('/customer/byName', { name: val })
            .then((resp) => {
                let res = resp.data.data;
                setcustomerdetails({ ...customerdetails, cus_name: val, cus_address: res ? res.address : '' });
            });
    }

    const handleDetails = ({ key, value }) => {

        let tempdetails = { ...details };
        tempdetails[key] = value;
        setDetails({ ...tempdetails });
    };

    const handlebookingDetails = ({ key, value }) => {

        let tempbooking = { ...bookingDetails };
        tempbooking[key] = value;
        setBookingDetails({ ...tempbooking });
    };


    const [remarks, setRemarks] = useState('');
    const [sellingNo, setSellingNo] = useState('');
    const [bookingNumbers, setBookingNumbers] = useState([]);
    const [bookingNo, setBookingNo] = useState('');
    const [constno, setconstno] = useState('');
    const [containerno, setcontainerno] = useState('');
    const [buyingDetails, setBuyingDetails] = useState(initBuying);
    const [bookingDetails, setBookingDetails] = useState(initBooking);
    const [details, setDetails] = useState(initDetails);
    const [customerdetails, setcustomerdetails] = useState([]);
    const [contDetail, setContDetail] = useState(initContDetail);
    const [shippingNo, setShippingNo] = useState('');
    const [houseBL, setHouseBL] = useState(false);
    const [cargo, setcargo] = useState();
    const [allCharges, setAllcharges] = useState([]);
    const [refNo, setrefNo] = useState();
    const [profoInvInp, setProfoInvInp] = useState(0);
    const [Total, setTotal] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [selectedValue, setSelectedValue] = useState("USD");
    const [bookingid, setBookingid] = useState('')
    const [editFunc, setEditFunc] = useState(false);
    const [amt_in_words, setAmtinwords] = useState('');
    const [charges, setCharges] = useState([]);
    const [containers, setContainers] = useState([]);
    const [newCurrency, setNewCurrency] = useState();
    const [newBase, setNewBase] = useState();
    const [newCharge, setNewCharge] = useState();
    const [vendor_ref, setVendor_ref] = useState();
    const [inputdisable, setinputdisable] = useState(false);
    const [uploadedFile, setUploadedFile] = useState();
    const fileInputRef = useRef(null);
    const [wordsOfAmount, setWordsOfAmount] = useState('')
    const [customerList, setCustomerList] = useState([]);
    const [totalInWords, setTotalInWords] = useState('');

    const handleProfoInvInp = (event) => {

        setProfoInvInp(event.target ? event.target.value : event)

        let a = 0;
        var temp = allCharges;

        var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
        var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;


        // var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
        var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : 0;
        var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;
        allCharges.total = 0;
        {
            allCharges.forEach((data, index) => {

                if (selectedValue === 'USD') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }

                else if (selectedValue === 'GBP') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }

            })

            // setAllcharges(allCharges);
        }
    }

    //Converting total amount in to a words
    const numberToWords = (number) => {
        // Define arrays for the words
        const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
        const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
        const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
        const thousands = ["", "thousand", "million", "billion"];

        // Function to convert a two-digit number to words
        const twoDigitToWords = (num) => {
            if (num === 0) return "";
            else if (num < 10) return units[num];
            else if (num < 20) return teens[num - 10];
            else {
                const ten = Math.floor(num / 10);
                const unit = num % 10;
                return tens[ten] + (unit > 0 ? `-${units[unit]}` : "");
            }
        };



        // Function to convert a three-digit number to words
        const threeDigitToWords = (num) => {

            const hundred = Math.floor(num / 100);
            const remainder = num % 100;
            if (hundred > 0 && remainder > 0) {

                return `${units[hundred]} hundred and ${twoDigitToWords(remainder)}`;
            } else if (hundred > 0) {
                return `${units[hundred]} hundred`;
            } else {
                return twoDigitToWords(remainder);
            }
        };

        // Function to convert a number to words
        const convert = (num) => {
            if (num === 0) return "zero";
            let result = "";
            for (let i = 0; num > 0; i++) {
                const chunk = num % 1000;
                if (chunk > 0) {
                    const chunkWords = threeDigitToWords(chunk);
                    result = chunkWords + (i > 0 ? ` ${thousands[i]}` : "") + (result ? ` ${result} ` : "");
                }
                num = Math.floor(num / 1000);
            }
            return result;
        };

        return convert(number);
    };

    const editCharge = (index, value) => {

        setEditFunc(true);

        let temp = [...allCharges];
        var total = allCharges.total - allCharges[index].total;
        temp.total = total;

        setFormInput({ ...temp[index] });
        setNewCharge(value.charge);
        setNewBase(value.base);
        setNewCurrency(value.currency);
        temp.splice(index, 1);
        setAllcharges([...temp]);

        allCharges.total = temp.total;
        setTotalInWords(numberToWords(allCharges.total) + ' Only');
        allCharges.splice(index, 1);
        setAllcharges(allCharges);



    };

    const deleteCharge = (index, value) => {
        if (window.confirm('Are you sure to delete?')) {
            let temp = [...allCharges];

            var total = allCharges.total - allCharges[index].total;
            temp.total = total;

            setFormInput({ ...temp[index] });

            temp.splice(index, 1);
            setAllcharges([...temp]);

            allCharges.total = temp.total;
            setTotalInWords(numberToWords(allCharges.total) + ' Only');
            allCharges.splice(index, 1);
            setAllcharges(allCharges);

        }
    }

    const saveCharge = ({ key, evt }) => {

        if (key == 'charge') {
            setNewCharge(evt && evt.value ? evt.value : '');
        }
        else if (key == 'base') {
            setNewBase(evt && evt.value ? evt.value : '');
        }
        else if (key == 'currency') {
            setNewCurrency(evt && evt.value ? evt.value : '');
        }
    };


    let initCharge = {
        charge: '',
        currency: '',
        rate: '',
        base: '',
    };

    const reload = () => {
        window.location.reload();
    };

    const calculateAmount = (data, index) => {
        allCharges.total = allCharges.total ? allCharges.total : 0;
        if (selectedValue == 'USD') {
            if (data.currency == 'USD') {
                allCharges[index].total = data.rate * (data.base == '20GP' ? bookingDetails.quantity_20 : bookingDetails.quantity_40);
                allCharges.total += allCharges[index].total

                return allCharges.total;
            }
            else if (data.currency == 'GBP') {
                allCharges[index].total = data.rate * (data.base == '20GP' ? bookingDetails.quantity_20 : bookingDetails.quantity_40) * profoInvInp;
                allCharges.total += allCharges[index].total

                return allCharges.total;
            }
        }

        else {
            if (data.currency == 'USD') {
                allCharges[index].total = data.rate * (data.base == '20GP' ? bookingDetails.quantity_20 : bookingDetails.quantity_40)/* details.containerlength ? details.containerlength : 1 */ * profoInvInp;
                allCharges.total += allCharges[index].total

                return allCharges.total;
            }
            else if (data.currency == 'GBP') {
                allCharges[index].total = data.rate * (data.base == '20GP' ? bookingDetails.quantity_20 : bookingDetails.quantity_40);
                allCharges.total += allCharges[index].total
                return allCharges.total;
            }
        }
    }


    useEffect(() => {

        customAxios.get('/booking/ids').then((ids) => {
            let temp = [...ids.data.data];
            let details = temp.filter(data => data.is_closed === 0).map((data) => {
                return { label: 'RUKEX' + data.booking_id, value: data.booking_id };
            });
            setBookingNumbers([...details]);
        });


        let currencyUrl = '/currency';
        customAxios.get(currencyUrl).then((currencies) => {
            let temp = [...currencies.data.data];
            let currenciesDetails = temp.map((data) => {
                return { label: data.code, value: data.code };
            })
            setCurrencies([...currenciesDetails]);

        });

        customAxios.get('/container').then((containers) => {
            let temp = [...containers.data.data];
            let containersDetails = temp.map((data) => {
                return { label: data.type, value: data.type };
            });
            setContainers([...containersDetails]);
        });

        customAxios.get('/charges').then((charges) => {
            let temp = [...charges.data.data];
            let chargesDetails = temp.map((data) => {
                return { label: data.name, value: data.name };
            });
            setCharges([...chargesDetails]);
        });

        let customerurl = "/customer";
        customAxios.get(customerurl).then((customers) => {
            let temp = [...customers.data.data];
            let custo = temp.map((data) => {
                return { label: data.name, value: data.name };
            })
            setCustomerList([...custo]);
        });

    }, []);


    // const handleUploadClick = () => {
    //     const file = fileInputRef.current.files[0];
    //     setUploadedFile(file); // Store the selected file in state
    //   };



    console.log('5656', allCharges);

    for (const iterator of allCharges) {
        console.log('777', iterator);
        iterator.exchangeRate = iterator.currency === "EUR" ? iterator.exchangeRate : iterator.currency == selectedValue ? '1' : profoInvInp;
        iterator.quantity = ((iterator.base === "20GP" || iterator.base == '20OT') ? bookingDetails.quantity_20 : bookingDetails.quantity_40);

    }

    const savedata = async () => {
   
     if (!refNo) {
            //  alert(bookingDetails.qty_Conc);
            let temp = {
                bkg_no: bookingid,
                bl_no: bookingDetails.carrier_booking_no,
                to_customer_name: customerdetails.cus_name,
                current_date: date,
                address: customerdetails.cus_address,
                currency: selectedValue,
                currency_value: profoInvInp,
                // shipper: details.shipper_name,
                vendor_ref: vendor_ref,
                destination: details.place_of_delivery,
                agent: details.forwarding_agent,
                // consignee: details.consignee_name,
                mlo: bookingDetails.vessel,
                vessel_name: bookingDetails.vessel,
                notify_party: details.notify_party_name,
                cargo: cargo,
                container_no: details.container_details,
                container_count: bookingDetails.qty_Conc,
                liner_ref_number:bookingDetails.liner_ref_number,
                loading_port:bookingDetails.loading_port,
                discharge_port:bookingDetails.discharge_port,
                source: bookingDetails.source,
                carrier: bookingDetails.carrier
            }
            console.log('Temp Object:', temp);
            if (Object.values(temp).includes('')) {
                window.alert('Please fill all the mandatory fields');
            }
            else {

            customAxios
                .post("/proformacnops/save",

                    {
                        "bkg_no": bookingid,
                        "bl_no": bookingDetails.carrier_booking_no,
                        "to_customer_name": customerdetails.cus_name,
                        "current_date": date,
                        "address": customerdetails.cus_address,
                        "currency": selectedValue,
                        "currency_value": profoInvInp,
                        "shipper": details.shipper_name,
                        "vendor_ref": vendor_ref,
                        /*  "agent": details.forwarding_agent, */
                        "destination": details.place_of_delivery,
                        "agent": details.forwarding_agent,
                        "consignee": details.consignee_name,
                        "mlo": bookingDetails.vessel,
                        "vessel_name": bookingDetails.vessel,
                        "notify_party": details.notify_party_name,
                        "cargo": cargo,
                        "container_no": details.container_details,
                        "container_count": containerno, // details.containerlength, // bookingDetails.qty_Conc,
                        "remarks": remarks,
                        "credit_charges": allCharges,
                        "total_amt": allCharges.total,
                        "is_approved": 0,
                        "amt_in_words": amt_in_words,
                        "liner_ref_number":bookingDetails.liner_ref_number,
                        "loading_port":bookingDetails.loading_port,
                        "discharge_port":bookingDetails.discharge_port,
                        "source": bookingDetails.source,
                        "carrier": bookingDetails.carrier



                    }).then((res) => {

                        if (res.data.error) window.alert('Unable to save the details!!');
                        else {
                            window.alert('Detail saved successfully');

                            setrefNo(res.data.count);

                            setAmtinwords(res.data.amt_in_words)

                            let year = new Date().getFullYear();
                            const profomainvoiceAutogen = "WEM/ WBY" + " ";

                            setconstno(profomainvoiceAutogen)

                        }
                        // if (!res.data.totalamt) window.alert('Total amount is required');
                    })
                .catch((err) =>
                    window.alert(err.message || 'Some internal error occured')
                );

            if (fileInputRef.current.files.length > 0) {
                const file = fileInputRef.current.files[0];
                const selectedBookingNo = bookingNo;

                const folderName = `${selectedBookingNo}`;

                try {
                    const formData = new FormData();
                    formData.append('name', folderName);
                    formData.append('images', file);

                    const uploadResponse = await customAxios.post('/exp/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    // alert('File Uploaded Successfully!');
                    console.log('File uploaded successfully!');
                    console.log('Response:', uploadResponse.data);
                } catch (error) {
                    // alert('Error Ocuured While Uploading The File:', error);
                    console.error('Error occurred while uploading the file:', error);
                }
            }
        }
    }
        else {

            customAxios
                .post('/proformacnops/update', {
                    "ref_no": refNo,
                    // shippingDetails: temp,

                    "bkg_no": bookingNo,
                    "bl_no": bookingDetails.carrier_booking_no,
                    "to_customer_name": customerdetails.cus_name,
                    "current_date": date,
                    "address": customerdetails.cus_address,
                    "currency": selectedValue,
                    "currency_value": profoInvInp,
                    "destination": details.place_of_delivery,
                    "shipper": details.shipper_name,
                    "agent": details.forwarding_agent,
                    "consignee": details.consignee_name,
                    "mlo": bookingDetails.vessel,
                    "notify_party": details.notify_party_name,
                    // "cnf": cargo,
                    "container_no": details.container_details,
                    "container_count": bookingDetails.qty_Conc,
                    "remarks": remarks,
                    "credit_charges": allCharges,
                    "total_amt": allCharges.total,
                    "vendor_ref": vendor_ref,
                    "amt_in_words": totalInWords
                })
                .then((data) => {

                    if (data.data.error) {
                        window.alert('Unable to update the details!!');
                        setTotalInWords(totalInWords);
                    }
                    else {
                        window.alert('Detail updated successfully');
                    }
                });
        }
    }



    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const selectedBookingNo = bookingNo;
        const folderName = `${selectedBookingNo}`;
        window.alert('File inserted successfully');

        // try {
        //     const formData = new FormData();
        //     formData.append('name', folderName);
        //     formData.append('images', file);

        //     const uploadResponse = await customAxios.post('/exp/upload', formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     });
        //     alert('File Uploaded Successfully!');
        //     /*  console.log('File uploaded successfully!');
        //      console.log('Response:', uploadResponse.data); */
        // } catch (error) {
        //     alert('Error Ocuured While Uploading The File:', error);
        //     // console.error('Error occurred while uploading the file:', error);
        // }
    };

    //   const uploadpdf = async (file, bookingNo) => {
    //     try {

    //         const selectedBookingNo = bookingNo;

    //       const formData = new FormData();
    //       formData.append('name', `${selectedBookingNo}`);
    //       formData.append('images', file);

    //       const uploadResponse = await customAxios.post('/exp/upload', formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //         },
    //       });

    //       console.log('File uploaded successfully!');
    //       console.log('Response:', uploadResponse.data);
    //     } catch (error) {
    //       console.error('Error occurred while uploading the file:', error);
    //     }
    //   };


    const handleShippingNo = (selected) => {

        setBookingNo(selected ? selected.value : '');
        if (selected && selected.value) {
            handleShippingNoasync(selected.value)
            if (!selected.myValue)
                setBookingDetails({
                    ...bookingDetails,
                    bkg_no: bookingNo ? bookingNo : selected.myValue,
                });
        } else {
            setBookingDetails(initBooking)
        }
    };
    async function handleShippingNoasync(val, refIdCal = 0) {

        setBookingid(val)


        if (val) {
            let name;
            let cus;
            let valueee;


            let bookingById = await customAxios
                .post('/booking/getById', { id: val })
                .then((resp) => {
                    let res = resp.data.data;

                    setHouseBL(res.house_bl);
                    var qty = (res.quantity_20 !== 0 ? (("20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '))) : (res.quantity_40 !== 0 ? (' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40) : ' '));
                    setcontainerno(qty)
                    name = res;
                    if (res.buying_id) {
                        setBookingDetails({
                            port_of_discharge: res.port_of_discharge,
                            buying_id: res.buying_id,
                            selling_id: res.selling_id,
                            carrier_booking_no: res.liner_ref_number,
                            quantity_20: res.quantity_20,
                            quantity_40: res.quantity_40,
                            qty_Conc: qty,
                            house_bl: res.house_bl,
                            vessel: res.sailing_schedule.vessel,
                            voyage: res.sailing_schedule.voyage,
                            pol: res.sailing_schedule.pol,
                            eta: res.sailing_schedule.eta,
                            pod: res.sailing_schedule.pod,
                            etd: res.sailing_schedule.etd,
                            remarks: res.remarks,
                            yard_detail: res.yard_detail,
                            liner_ref_number: res.liner_ref_number,
                            loading_port: res.loading_port,
                            discharge_port: res.discharge_port,
                            source: res.source,
                            carrier:res.carrier,
                        });


                    }

                    else {
                        setBookingDetails(initBooking);
                    }
                });
            await customAxios
                .post('/selling/getById', { id: name.selling_id }).then((ress) => {
                    // setcargo(ress.cargo)
                    setcargo(ress.data.data.cargo)
                    cus = ress.data.data.customer;
                    console.log(cus);
                });

            await customAxios
                .post('/customer/byName', { name: cus }).then((respon) => {
                    console.log("respon", respon);
                    valueee = respon.data.data;

                    if (valueee == null) {
                        // window.alert('BKG ID not found');
                        return;
                    }
                    else if (valueee) {
                        // alert("yes")

                        setcustomerdetails({
                            cus_name: valueee.name,
                            cus_address: valueee.address,
                        });

                    } else {
                        // alert("no")
                        setcustomerdetails({
                            cus_name: '',
                            cus_address: '',

                        });
                    }
                });

            await customAxios
                .post('/shipping/getById', { id: val })
                .then((resp) => {
                    let res = resp.data.data;
                    console.log('shipping res--', res);
                    let a = "";
                    if (res.container_details && res.container_details.length > 0) {
                        for (let i = 0; i < res.container_details.length; i++) {
                            a += i == 0 ? "" + res.container_details[i].container_no : " , " + res.container_details[i].container_no
                        }
                    }
                    console.log("a", a);
                    if (res.shipping_id) {
                        setShippingNo(res.shipping_id);
                        setDetails({
                            booking_number: res.booking_number,

                            bl_type: res.bl_type,
                            // bl_by: res.bl_by,
                            shipper_name: res.shipper_name,
                            shipper_address: res.shipper_address,
                            consignee_name: res.consignee_name,
                            consignee_address: res.consignee_address,
                            notify_party_name: res.notify_party_name,
                            notify_party_address: res.notify_party_address,
                            also_notify_party_name: res.also_notify_party_name,
                            also_notify_party_address: res.also_notify_party_address,
                            forwarding_agent: res.forwarding_agent,
                            mark_and_no: res.mark_and_no,
                            mark_description: res.mark_description,
                            container_details: a,
                            containerlength: res.container_details.length,
                            place_of_delivery: res.place_of_delivery
                        });
                    }

                    else {
                        setShippingNo('');
                        setDetails({
                            booking_number: " ",
                            //carrier_booking_no: '',
                            //carrier_bl: '',
                            issued_at: " ",
                            issued_on: "",
                            bl_type: "",
                            // bl_by: res.bl_by,
                            shipper_name: "",
                            shipper_address: "",
                            consignee_name: "",
                            consignee_address: "",
                            notify_party_name: "",
                            notify_party_address: "",
                            also_notify_party_name: "",
                            also_notify_party_address: "",
                            forwarding_agent: "",
                            mark_and_no: "",
                            mark_description: "",
                            container_details: '',
                            containerlength: ""
                        });
                    }
                });

            if (refIdCal === 0) {
                await customAxios
                    .post('/selling/getById', { id: name.selling_id }).then((resstt) => {
    

                        // while first choosing bookignId this will be disable
                        // .
                        // .
                        // setAllcharges(resstt.data.data.selling_rates)
                        // .
                        // .

                        console.log("resstt", allCharges);

                    });
            }
            // await customAxios
            //     .post('/booking/getById', { id: val })
            //     .then((resp) => {
            //         let res = resp.data.data;
            //         console.log('booking res--', res);
            //         setHouseBL(res.house_bl);


            //         if (res.buying_id) {
            //             setBookingDetails({
            //                 port_of_discharge: res.port_of_discharge,
            //                 buying_id: res.buying_id,
            //                 selling_id: res.selling_id,
            //                 carrier_booking_no: res.liner_ref_number,
            //                 quantity_20: res.quantity_20,
            //                 quantity_40: res.quantity_40,
            //                 house_bl: res.house_bl,
            //                 vessel: res.sailing_schedule.vessel,
            //                 voyage: res.sailing_schedule.voyage,
            //                 pol: res.sailing_schedule.pol,
            //                 eta: res.sailing_schedule.eta,
            //                 pod: res.sailing_schedule.pod,
            //                 etd: res.sailing_schedule.etd,
            //                 remarks: res.remarks,
            //                 yard_detail: res.yard_detail,
            //             });

            //         } else {
            //             // alert("sss");
            //             setBookingDetails(initBooking);
            //         }
            //     });


            setContDetail(initContDetail);
        } else {
            setShippingNo('');
            setBookingDetails(initBooking);
            setDetails(initDetails);
            setContDetail(initContDetail);
            setHouseBL(false);
        }
        // }
        // }
    };


    useEffect(() => {
        console.log('bookig details', bookingDetails);
    }, [bookingDetails]);

    const handleSelectChange = (event) => {
        console.log(event);
        if (event != null) {
            setSelectedValue(event.value ? event.value : event)


            var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
            var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;
            console.log('hgyf', containerquantity20)
            console.log('hg', containerquantity40);

            // var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
            var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : 0;
            var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;

            allCharges.total = 0;
            {
                allCharges.forEach((data, index) => {

                    if (event.value === 'USD') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                                console.log(allCharges.total);
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (profoInvInp);
                                allCharges.total += allCharges[index].total
                                console.log(allCharges.total);
                            }
                        }
                    }

                    else if (event.value === 'GBP') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (profoInvInp);
                                allCharges.total += allCharges[index].total
                                console.log(allCharges.total)
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                })
            }
        }
        else {
            setSelectedValue(null);
        }

    };

    const handleVendor = (event) => {
        setVendor_ref(event.target.value);
    }


    const handlerefNo = (evt) => {
        setrefNo(evt.target.value);
        let year = new Date().getFullYear();
        const profomainvoiceAutogen = "WEM/ WBY" + " ";
        // console.log("profomainvoiceAutogen", profomainvoiceAutogen);
        setconstno(profomainvoiceAutogen)
    };
    console.log()

    async function getinvoiceDetail(evt) {
        let getbkngid;
        if ((evt.key === 'Enter' || evt.keyCode === 13) && refNo) {
            await customAxios.post('/proformacnops/getbyId', { ref_id: refNo }).then((data) => {
                console.log(refNo);
                let result = data.data;

                if (Object.keys(result.data).length == 0) {
                    window.alert('Ref-ID not found');
                    return;
                }

                if (result.data.is_approved == 1) {
                    setinputdisable(true);
                    window.alert('Invoice Issued!!');
                    document.getElementById('myButton').disabled = true;
                } else {
                    setinputdisable(false);
                }

                setBookingNo(result.data.bkg_no);
                setProfoInvInp(result.data.currency_value);
                setRemarks(result.data.remarks);
                setcargo(result.data.cnf);
                setSelectedValue(result.data.currency);
                setAmtinwords(result.data.amt_in_words);
                setVendor_ref(result.data.vendor_ref);

                var invoice = result.data.credit_charges;
                invoice.total = result.data.total_amt;
                getbkngid = result.data.bkg_no;

                setAllcharges(invoice)


                const containercount = "20GP:" + ' ' + result.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40GP/HC:" + ' ' + result.quantity_40;

                let {

                    address,
                    agent,
                    bkg_no,
                    bl_no,
                    cnf,
                    consignee,
                    container_count,
                    container_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    currency_value,
                    notify_party,
                    ref_no,
                    remarks,
                    shipper,
                    to_customer_name,
                    totalamt,
                    invoice_charges,
                    amt_in_words,
                    vendor_ref,
                } = result.data;

                setWordsOfAmount(result.data);


                console.log(

                    address,
                    agent,
                    bkg_no,
                    bl_no,
                    cnf,
                    currency_value,
                    consignee,
                    container_count,
                    container_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    notify_party,
                    ref_no,
                    remarks,
                    shipper,
                    to_customer_name,
                    totalamt,
                    invoice_charges,
                    vendor_ref,
                    amt_in_words
                )

                let temp = {
                    ...customerdetails,
                    cus_address: address,
                    cus_name: to_customer_name,

                };

                setcustomerdetails({ ...temp });

                let tempbooking = {

                    ...bookingDetails,
                    bl_no: bl_no,
                    bkg_no: bkg_no,
                    vessel: mlo,
                    qty_Conc: container_count,
                }

                setBookingDetails({ ...tempbooking });

                let tempdetails = {

                    ...details,
                    place_of_delivery: destination,
                    shipper_name: shipper,
                    consignee_name: consignee,
                    notify_party_name: notify_party,
                    container_details: container_no,
                    forwarding_agent: agent,
                    // remarks: remarks,
                }
                setDetails({ ...tempdetails });



            });

            setBookingid(getbkngid);
            let bkng = await customAxios.post('/booking/getById', { id: getbkngid }).then((resp) => {
                let res = resp.data.data;

                getbkngid = res;


                setHouseBL(res.house_bl);
                if (res.buying_id) {
                    setBookingDetails({
                        port_of_discharge: res.port_of_discharge,
                        buying_id: res.buying_id,
                        selling_id: res.selling_id,
                        carrier_booking_no: res.liner_ref_number,
                        quantity_20: res.quantity_20,
                        quantity_40: res.quantity_40,
                        house_bl: res.house_bl,
                        vessel: res.sailing_schedule.vessel,
                        voyage: res.sailing_schedule.voyage,
                        pol: res.sailing_schedule.pol,
                        eta: res.sailing_schedule.eta,
                        pod: res.sailing_schedule.pod,
                        etd: res.sailing_schedule.etd,
                        remarks: res.remarks,
                        yard_detail: res.yard_detail,
                    });

                } else {
                    // alert("sss");
                    setBookingDetails(initBooking);
                }
            });
        }
        else if (evt.key === 'Enter' || evt.keyCode === 13) confirmCancel();



        // setBookingid(val)

    };

    const cancelDetails = () => {
        if (window.confirm('Are you sure to cancel?')) confirmCancel();
    };
    const confirmCancel = () => {
        setAllcharges([]);
        setBuyingDetails(initBuying);
        setBookingDetails(initBooking);
        setrefNo('');
        setSellingNo('');
        setBookingNo('');

    };
    return (
        <>
            <div className={classes.container}>
                <h5>Profoma CN-Ops <span className={classes.title}>Export /  Profoma CN-Ops</span></h5>
            </div>

            <Container className={classes.inputsContainer}>

                <table style={{ width: '100%' }}>
                    <tbody><tr>
                        <td colSpan={1}><span>BKG.No#*</span></td>
                        <td colSpan={2} style={{ width: '100px' }}>
                            <section>
                                <div style={{ position: 'relative', width: '150px' }}>

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style} isClearable={true}
                                        isSearchable={true}
                                        options={bookingNumbers}
                                        value={bookingNumbers.filter(
                                            (option) => {

                                                return option.value === bookingNo
                                            }
                                        )}
                                        onChange={(selected) => handleShippingNo(selected)}
                                        isDisabled={inputdisable ? true : false}
                                    />
                                </div>

                            </section>
                        </td>

                        <td colSpan={1} style={{ width: '10px' }}><span>BL#*</span></td>
                        <td colSpan={2}><input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked }); }} type="text" disabled={inputdisable ? true : false} /></td>
                        {/* disabled={true} */}
                        <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                        <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={inputdisable ? true : false} /></td>
                        <td colSpan={1}><input className={classes.inputText} type="text"
                            value={refNo}
                            onChange={(evt) => handlerefNo(evt)}

                            onKeyUp={(evt) => getinvoiceDetail(evt)} disabled={inputdisable ? true : false} /></td>

                    </tr>
                        <tr>
                            <td>To<span>*</span></td>
                            <td colSpan={5}>
                                {inputdisable ? (<input className={classes.inputText}
                                    value={customerdetails.cus_name}
                                    type='text'
                                    disabled />) : (<Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style}
                                        isClearable={true}
                                        isSearchable={true}
                                        value={customerList.find((customer) => customer.value === customerdetails.cus_name)}
                                        options={customerList}
                                        onChange={(selected) => {
                                            handleBookingDetails({
                                                key: 'cus_name',
                                                value: selected ? selected.value : '',
                                            }); updateCustomerAddress(selected ? selected.value : '')
                                        }} />)}
                            </td>

                            <td style={{ paddingLeft: '35px' }}><span>VendorRef#*</span></td>
                            <td colSpan={1}><input className={classes.inputText} value={vendor_ref} onChange={handleVendor} type="text" disabled={inputdisable ? true : false} /></td>
                            <td style={{ padding: '10px', textTransform: 'uppercase', paddingLeft: '3%' }}> <input type="file" ref={fileInputRef} id="fileInput" accept=".pdf" style={{ display: 'none' }} onChange={handleFileChange} />
                                <button className={classes.footerButton} style={{ textTransform: 'uppercase', width: '75%' }} type="button"
                                    onClick={() => fileInputRef.current.click()} disabled={inputdisable ? true : false} > Upload
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td >Date</td>
                            <td colSpan={5}><input className={classes.inputText} value={date} type="text" disabled={inputdisable ? true : false} /></td>

                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                            <td colSpan={1}>

                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    value={currencies.filter(
                                        (option) => option.value === selectedValue
                                    )}
                                    onChange={(e) => { handleSelectChange(e) }}

                                    isDisabled={inputdisable ? true : false}
                                />
                            </td>
                            <td colSpan={1}><input className={classes.inputText} value={profoInvInp} onChange={(e) => { handleProfoInvInp(e) }} type="text" disabled={inputdisable ? true : false} /></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td colSpan={5}><input className={classes.inputText} value={customerdetails.cus_address} onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} type="text" disabled={inputdisable ? true : false} /></td>

                            <td style={{ paddingLeft: '35px' }} colSpan={1}>Agent</td>
                            <td colSpan={5}><input className={classes.inputText} value={details.forwarding_agent} onChange={(evt) => {

                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })
                            }} type="text" disabled={inputdisable ? true : false} /></td>

                        </tr>
                        <tr>

                            <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                            <td colSpan={5}><input className={classes.inputText} value={details.place_of_delivery} onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} type="text" disabled={inputdisable ? true : false} /></td>

                            <td style={{ paddingLeft: '35px' }} colSpan={1}>Notify Party</td>
                            <td colSpan={5}><input className={classes.inputText} value={details.notify_party_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} type="text" disabled={inputdisable ? true : false} /></td>

                        </tr>

                        <tr>

                            <td style={{ paddingLeft: '2px' }} colSpan={1} >MLO</td>
                            <td colSpan={5}><input className={classes.inputText} value={bookingDetails.vessel} onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} type="text" disabled={inputdisable ? true : false} /></td>

                            <td colSpan={1} style={{ paddingLeft: '35px' }}>Container</td>
                            <td colSpan={5}><input className={classes.inputText} value={bookingDetails.qty_Conc} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} type="text" disabled={inputdisable ? true : false} /></td>


                        </tr>

                        <tr style={{ height: '120px' }}>
                            <td colSpan={1}>Remarks</td>
                            <td colSpan={5}><textarea style={{ height: '100px' }} value={remarks} onChange={(e) => setRemarks(e.target.value)} disabled={inputdisable ? true : false} /></td>

                            <td colSpan={1} style={{ paddingLeft: '35px' }}>Container No</td>
                            <td colSpan={2}><textarea style={{ height: '100px' }} value={details.container_details} onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,

                                })} disabled={inputdisable ? true : false} /></td>
                        </tr>

                        <tr><td colSpan="9">
                            <hr></hr>
                        </td></tr>

                        <tr>
                            <td style={{ padding: '10px' }} colSpan={2}>Charge Description</td>
                            <td style={{ padding: '10px' }} colSpan={2}>Curr</td>
                            <td style={{ padding: '10px' }} colSpan={2}>Rate</td>
                            <td style={{ padding: '10px' }} colSpan={1}>Base</td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px' }} colSpan={1}>Exchange Rate</td>
                                : null}
                        </tr>

                        <tr>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={charges}
                                    value={charges.filter(
                                        (option) => option.value === newCharge
                                    )}
                                    onChange={(e) => saveCharge({ key: 'charge', evt: e })}
                                    isDisabled={inputdisable ? true : false}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    value={currencies.filter(
                                        (option) => option.value === newCurrency
                                    )}
                                    onChange={(e) => saveCharge({ key: 'currency', evt: e })}
                                    isDisabled={inputdisable ? true : false}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}><input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="text" name="rate" value={formInput.rate} onChange={handleChange} disabled={inputdisable ? true : false}></input></td>
                            <td style={{ padding: '10px', textTransform: 'uppercase', width: '15%' }} colSpan={1}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    name='base'
                                    options={containers}
                                    value={containers.filter(
                                        (option) => option.value === newBase
                                    )}
                                    onChange={(e) => saveCharge({ key: 'base', evt: e })}
                                    isDisabled={inputdisable ? true : false}
                                />
                            </td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={1}>
                                    <input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="number" name="exchangeRate" value={formInput.exchangeRate} onChange={handleChange}>
                                    </input></td> : null}
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}><button className={classes.footerButton} style={{ textTransform: 'uppercase' }} type="submit" onClick={handleSubmit} disabled={inputdisable ? true : false} >Add</button></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                    <table style={{ width: '100%' }}>
                        <thead className={classes.theadDark}>
                            <tr>
                                <th>charges</th>
                                <th>Curr</th>
                                <th>Rate</th>
                                <th>Base</th>
                                <th>Exchange Rate</th>
                                <th>No.Of.Containers</th>
                                <th>Amount</th>
                                <th>Action</th>


                            </tr>
                        </thead>

                        <tbody>
                            {allCharges ? <>
                                {allCharges.map((data, index) => (
                                    <tr>
                                        <td>{data.charge}</td>
                                        <td>{data.currency}</td>
                                        <td>{data.rate ? parseFloat(data.rate).toFixed(2) : ' '}</td>
                                        <td>{data.base}</td>
                                        {/* {data.currency == 'EUR' ? <td>{data.exchangeRate}</td> : <td>{profoInvInp}</td>} */}
                                        {data.currency == 'EUR' ? <td>{data.exchangeRate}</td> : <td>{data.currency == selectedValue ? '1' : profoInvInp}</td>}

                                        {(data.base == '20GP' || data.base == '20OT') ? <td>{bookingDetails.quantity_20}</td> : <td>{bookingDetails.quantity_40}</td>}
                                        {data.total > 0 ? <td>{parseFloat(data.total).toFixed(2)}</td> : '0'}
                                        {/* <td style={{display: 'none'}}>{calculateAmount(data, index)}</td> */}
                                        <td>
                                            <img
                                                src={EditIcon}
                                                className={`${classes.icon}`}
                                                alt='edit_icon'
                                                onClick={() => editCharge(index, data)}
                                                style={{ pointerEvents: inputdisable ? 'none' : 'auto', opacity: inputdisable ? 0.5 : 1 }}
                                            />
                                            &nbsp;&nbsp;
                                            <img
                                                src={DeleteIcon}
                                                className={`${classes.icon}`}
                                                onClick={() => deleteCharge(index)}
                                                style={{ pointerEvents: inputdisable ? 'none' : 'auto', opacity: inputdisable ? 0.5 : 1 }}
                                            />
                                        </td>
                                    </tr>
                                )
                                )} </>
                                : null}
                        </tbody>

                    </table>

                </div>
                <div>
                    <table>
                        <tr>
                            <td colSpan={5} style={{ width: '50%' }}></td>
                            <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                            <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoInvInp ? (parseFloat(allCharges.total).toFixed(2)) : 0} onChange={(e) => { handleshippingDetails({ key: 'total', value: e.target.checked }); }} required type="text" disabled={inputdisable ? true : false} >{console.log(Total)}</input></td>
                        </tr>

                        <tr>
                            <td colSpan={5} />
                            <td colSpan={4}>
                                <button id="myButton" className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={savedata}>{refNo ? 'Update' : 'Save'} </button>
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={reload}>New</button>
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }} ><Template
                                    //   sellingDetails={sellingDetails}
                                    buyingDetails={buyingDetails}
                                    bookingDetails={bookingDetails}
                                    bookingNo={bookingNo}
                                    details={details}
                                    allCharges={allCharges}
                                    customerdetails={customerdetails}
                                    remarks={remarks}
                                    cargo={cargo}
                                    date={date}
                                    shippingDetails={shippingDetails}
                                    selectedValue={selectedValue}
                                    refNo={refNo}
                                    vendor_ref={vendor_ref}
                                    amt_in_words={amt_in_words}
                                    wordsOfAmount={wordsOfAmount}
                                    formInput={formInput}
                                    totalInWords={totalInWords}
                                //   totalamtinword={totalamtinword}

                                /*   pol={pol}
                                  eta={eta}
                                  etd={etd} */
                                //   formInput={formInput}
                                //   charges_inc={charges_inc}
                                /></button>
                            </td>
                        </tr>
                    </table>
                </div>

            </Container>
        </>
    )
}
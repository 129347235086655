import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import classes from './pro.module.css';
import customAxios from '../../../Axios';
import Customer from '../../Master/Customer';
import { DeleteIcon, EditIcon } from '../../../Images';
import Template from './ProDebitPdf';

export default function ProfomaDebitNt() {

    const [tableData, setTableData] = useState('');
    const [formInput, setFormInput] = useState(
        {
            charge: '',
            currency: '',
            rate: '',
            amount: '',
            base: '',
            exchangeRate: ''
        }
    );

    const initDetails = {
        booking_number: '',
        //liner_ref_number: '',
        //carrier_bl: '',
        issued_at: '',
        issued_on: '',
        bl_type: '',
        //bl_by: '',
        shipper_name: '',
        shipper_address: '',
        consignee_name: '',
        consignee_address: '',
        notify_party_name: '',
        notify_party_address: '',
        also_notify_party_name: '',
        also_notify_party_address: '',
        forwarding_agent: '',
        mark_and_no: '',
        mark_description: '',
        container_details: [],
    };

    const initBooking = {
        buying_id: '',
        selling_id: '',
        liner_ref_number: '',
        quantity_20: '',
        quantity_40: '',
        house_bl: false,
        vessel: '',
        voyage: '',
        pol: '',
        etd: '',
        pod: '',
        etd: '',

    };

    const initContDetail = {
        container_no: '',
        seal_no: '',
        package_no: '',
        package_type: '',
        gross_wt: '',
        net_wt: '',
        measurement: '',
    };

    const initBuying = {
        freight: '',
        source: '',
        destination: '',
        loading_port: '',
        discharge_port: '',
        carrier: '',
        _id: '',
    };
    const handleChange = (evnt) => {
        const newInput = (data) => ({ ...data, [evnt.target.name]: evnt.target.value })
        setFormInput(newInput)
    }

    // calculation when data is added to table 
    const handleSubmit = (evnt) => {
        evnt.preventDefault();
        const checkEmptyInput = !Object.values(formInput).every(res => res === "")

        if (checkEmptyInput) {

            var data = allCharges;
            // setAllcharges([])
            var dat = { charge: newCharge, currency: newCurrency, rate: formInput.rate, base: newBase, exchangeRate: formInput.exchangeRate }
            data.push(dat);
            setAllcharges(data);
            console.log(newBase);

            console.log(allCharges);
            var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
            var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;
            console.log('hgyf', containerquantity20)
            console.log('hg', containerquantity40);

            var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
            var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;

            allCharges.total = 0;
            {
                allCharges.forEach((data, index) => {
                    if (selectedValue === 'USD') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }

                    else if (selectedValue === 'GBP') {

                        if (data.currency === 'USD') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * profoInvInp;
                                allCharges.total += allCharges[index].total
                            }
                        }
                        else if (data.currency === 'GBP') {
                            if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                                allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * profoInvInp);
                                allCharges.total += allCharges[index].total
                            }
                            else {
                                allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                                allCharges.total += allCharges[index].total
                            }
                        }
                    }
                    if (data.currency == 'EUR') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = data.rate * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                        else /* (data.base == '20GP' || data.base == '40GP' || data.base == '40HC'|| data.base == '40OT' || data.base == '20OT') */ {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * data.exchangeRate;
                            allCharges.total += allCharges[index].total
                        }
                    }
                })
                setTotalInWords(numberToWords(allCharges.total) + ' Only');

                console.log(selectedValue);
                console.log(allCharges);
                // setAllcharges(allCharges);
            }

        }
        var data = [...allCharges];
        data.total = allCharges.total;
        setAllcharges(data);
        allCharges.total = data.total;
    }

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const IndicatorSeparator = () => null;
    const Placeholder = () => null;
    const style = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
        }),
    };

    const baseStyle = {
        control: (base) => ({
            ...base,
            minHeight: '35px',
            height: '35px',
            padding: '0',
            outline: 'none',
            // This line disable the blue border
            boxShadow: 'none',
            fontSize: '14px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        input: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
            width: '75px'
        }),
    };

    let initBookingValue = {
        shipping_id: "", //shippingNo,
        carrier_no: "",
        customer_name: '',
        Address: '',
        remarks: '',
        currency: '',
        Destination: '',
        shipper: '',
        Agent: 0,
        container: 0,
        cosingnee: 21000,
        MLO: 28000,
        Notify_party: '',
        CNF: '',
        total: '',
    };

    // set the data to bind the value for ref id  
    const [shippingDetails, setshippingDetails] = useState(initBookingValue);

    const handleshippingDetails = ({ key, value }) => {
        console.log(value);
        handleProfoInvInp(value)

        let tempshipping = { ...shippingDetails };
        tempshipping[key] = value;
        setshippingDetails({ ...tempshipping });
    };

    const handleBookingDetails = ({ key, value }) => {
        // console.log("key", value);
        let temp = { ...customerdetails };
        temp[key] = value;
        setcustomerdetails({ ...temp });
    };

    const updateCustomerAddress = (val) => {
        customAxios
            .post('/customer/byName', { name: val })
            .then((resp) => {
                let res = resp.data.data;
                setcustomerdetails({ ...customerdetails, cus_name: val, cus_address: res ? res.address : '' });
            });
    }
    const handleDetails = ({ key, value }) => {
        // console.log("key", value);
        let tempdetails = { ...details };
        tempdetails[key] = value;
        setDetails({ ...tempdetails });
    };

    const handlebookingDetails = ({ key, value }) => {
        // console.log("key", value);
        let tempbooking = { ...bookingDetails };
        tempbooking[key] = value;
        setBookingDetails({ ...tempbooking });
    };

    const [remarks, setRemarks] = useState('');
    const [sellingNo, setSellingNo] = useState('');
    const [bookingNumbers, setBookingNumbers] = useState([]);
    const [bookingNo, setBookingNo] = useState('');
    const [constno, setconstno] = useState('');
    const [containerno, setcontainerno] = useState('');
    const [buyingDetails, setBuyingDetails] = useState(initBuying);
    const [bookingDetails, setBookingDetails] = useState(initBooking);
    const [details, setDetails] = useState(initDetails);
    const [customerdetails, setcustomerdetails] = useState([]);
    const [contDetail, setContDetail] = useState(initContDetail);
    const [shippingNo, setShippingNo] = useState('');
    const [houseBL, setHouseBL] = useState(false);
    const [cargo, setcargo] = useState();
    const [allCharges, setAllcharges] = useState([]);
    const [ref_no, setref_no] = useState();
    const [profoInvInp, setProfoInvInp] = useState(0);
    const [Total, setTotal] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [selectedValue, setSelectedValue] = useState("USD");
    const [bookingid, setBookingid] = useState('')
    const [editFunc, setEditFunc] = useState(false);
    const [amtinwords, setAmtinwords] = useState('');
    const [charges, setCharges] = useState([]);
    const [containers, setContainers] = useState([]);
    const [newCurrency, setNewCurrency] = useState();
    const [newBase, setNewBase] = useState();
    const [newCharge, setNewCharge] = useState();
    const [vendor, setvendor] = useState();
    const [creditdays, setcreditdays] = useState();
    const [debitnote, setdebitnote] = useState([]);
    const [debitno, setdebitno] = useState();
    const [inputdisable, setinputdisable] = useState(false);
    const fileInputRef = useRef(null);
    const [customerList, setCustomerList] = useState([]);
    const [totalInWords, setTotalInWords] = useState('');


    // calculation based on currency value and currency type
    const handleProfoInvInp = (event) => {

        setProfoInvInp(event.target ? event.target.value : event)
        console.log(currencies.value);
        let a = 0;
        var temp = allCharges;
        console.log(allCharges);
        var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP' || '20OT').length;
        var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP" || '40OT').length;
        console.log('hgyf', containerquantity20)
        console.log('hg', containerquantity40);

        var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
        var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;
        
        allCharges.total = 0;
        {
            allCharges.forEach((data, index) => {
                if (selectedValue === 'USD') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }

                else if (selectedValue === 'GBP') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (event.target ? event.target.value : event);
                            allCharges.total += allCharges[index].total
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == selectedValue ? (data.rate * 1) : data.rate * (event.target ? event.target.value : event));
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }
            })


            console.log(selectedValue);
            console.log(allCharges);
            // setAllcharges(allCharges);
        }
    }

    //Converting total amount in to a words
    const numberToWords = (number) => {
        // Define arrays for the words
        const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
        const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
        const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
        const thousands = ["", "thousand", "million", "billion"];

        // Function to convert a two-digit number to words
        const twoDigitToWords = (num) => {
            if (num === 0) return "";
            else if (num < 10) return units[num];
            else if (num < 20) return teens[num - 10];
            else {
                const ten = Math.floor(num / 10);
                const unit = num % 10;
                return tens[ten] + (unit > 0 ? ` ${units[unit]}` : "");
            }
        };


        // Function to convert a three-digit number to words
        const threeDigitToWords = (num) => {

            const hundred = Math.floor(num / 100);
            const remainder = num % 100;
            if (hundred > 0 && remainder > 0) {

                return `${units[hundred]} hundred and ${twoDigitToWords(remainder)}`;
            } else if (hundred > 0) {
                return `${units[hundred]} hundred`;
            } else {
                return twoDigitToWords(remainder);
            }
        };

        // Function to convert a number to words
        const convert = (num) => {
            if (num === 0) return "zero";
            let result = "";
            for (let i = 0; num > 0; i++) {
                const chunk = num % 1000;
                if (chunk > 0) {
                    const chunkWords = threeDigitToWords(chunk);
                    result = chunkWords + (i > 0 ? ` ${thousands[i]}` : "") + (result ? ` ${result} ` : "");
                }
                num = Math.floor(num / 1000);
            }
            return result;
        };

        return convert(number);
    };

    const editCharge = (index, value) => {
        setEditFunc(true);
        let temp = [...allCharges];
        var total = allCharges.total - allCharges[index].total;
        temp.total = total;

        setFormInput({ ...temp[index] });
        setNewCharge(value.charge);
        setNewBase(value.base);
        setNewCurrency(value.currency);
        temp.splice(index, 1);
        setAllcharges([...temp]);

        allCharges.total = temp.total;
        setTotalInWords(numberToWords(allCharges.total) + ' Only');
        allCharges.splice(index, 1);
        setAllcharges(allCharges);

    };

    const deleteCharge = (index) => {
        if (window.confirm('Are you sure to delete?')) {
            let temp = [...allCharges];

            var total = allCharges.total - allCharges[index].total;
            temp.total = total;

            setFormInput({ ...temp[index] });
            temp.splice(index, 1);
            setAllcharges([...temp]);

            allCharges.total = temp.total;
            setTotalInWords(numberToWords(allCharges.total) + ' Only');

            allCharges.splice(index, 1);
            setAllcharges(allCharges);
        }
    };

    const saveCharge = ({ key, evt }) => {
        console.log(evt);

        if (key == 'charge') {
            setNewCharge(evt && evt.value ? evt.value : '');
        }
        else if (key == 'base') {
            setNewBase(evt && evt.value ? evt.value : '');
        }
        else if (key == 'currency') {
            setNewCurrency(evt && evt.value ? evt.value : '');
        }
    };

    let initCharge = {
        charge: '',
        currency: '',
        rate: '',
        base: '',
    };

    const reload = () => {
        window.location.reload();
    };

    useEffect(() => {
        customAxios.get('/import/booking/getallId').then((ids) => {
            let temp = [...ids.data.data];
            let details = temp.filter(data => data.is_closed === 0).map((data) => {
                return { label: 'RUKIM' + data.booking_id, value: data.booking_id };
            });
            setBookingNumbers([...details]);
        });

        customAxios.get('/import/voucher/proformadebitnote/ids').then((ids) => {
            let temp = [...ids.data.data];
            let debitnum = temp.map((data) => {
                return { label: data.debit_no, value: data.debit_no };
            });

            setdebitnote([...debitnum]);

        });

        let currencyUrl = '/currency';
        customAxios.get(currencyUrl).then((currencies) => {
            let temp = [...currencies.data.data];
            let currenciesDetails = temp.map((data) => {
                return { label: data.code, value: data.code };
            });

            setCurrencies([...currenciesDetails]);
            console.log(currencies);
            console.log('currenciestemp', currenciesDetails);
        });

        customAxios.get('/container').then((containers) => {
            let temp = [...containers.data.data];
            let containersDetails = temp.map((data) => {
                return { label: data.type, value: data.type };
            });
            setContainers([...containersDetails]);
        });

        customAxios.get('/charges').then((charges) => {
            let temp = [...charges.data.data];
            let chargesDetails = temp.map((data) => {
                return { label: data.name, value: data.name };
            });
            setCharges([...chargesDetails]);
        });

        let customerurl = "/customer";
        customAxios.get(customerurl).then((customers) => {
            let temp = [...customers.data.data];
            let custo = temp.map((data) => {
                return { label: data.name, value: data.name };
            })
            setCustomerList([...custo]);
        });

    }, []);

    console.log('5656', allCharges);

    for (const iterator of allCharges) {
        console.log('777', iterator);
        iterator.exchangeRate = iterator.currency === "EUR" ? iterator.exchangeRate : iterator.currency == selectedValue ? '1' : profoInvInp;
        iterator.quantity = iterator.base === "20GP" ? bookingDetails.quantity_20 : bookingDetails.quantity_40;

    }

    // save and update 
    const savedata = async () => {

        if (!ref_no) {

        let temp = {
            bkg_no: bookingid,
            to_address: customerdetails.cus_name,
            current_date: date,
            currency: selectedValue,
            curr_value: profoInvInp != 0 ? profoInvInp : '',
            shipper: details.shipper_name,
            destination: details.place_of_delivery,
            agent: details.forwarding_agent,
            // vendor_ref: vendor,
            creditdays: creditdays,
            consignee: details.consignee_name,
            mlo: bookingDetails.vessel,
            notify_party: details.notify_party_name,
            cnf: cargo,
            contatainer_no: details.container_details,
            container_count: containerno || bookingDetails.qty_Conc,
            Address: customerdetails.cus_address,   liner_ref_number:bookingDetails.liner_ref_number,
            loading_port:bookingDetails.loading_port,
            discharge_port:bookingDetails.discharge_port,
            source: bookingDetails.source,
            carrier: bookingDetails.carrier
        }
        console.log('Temp Object:', temp);

        if (Object.values(temp).includes('')) {
            window.alert('Please fill all the mandatory fields');
        }

        else {
            console.log("222222", details);
            console.log("33333333", bookingDetails);


            customAxios
                .post("/import/voucher/proformadebitnote/save",
                    // console.log(creditnote_charges),
                    {

                        "bkg_no": bookingid,
                        "to_address": customerdetails.cus_name,
                        "Address": customerdetails.cus_address,
                        "current_date": date,
                        "currency": selectedValue,
                        "curr_value": profoInvInp,
                        "shipper": details.shipper_name,
                        "destination": details.place_of_delivery,
                        "agent": details.forwarding_agent,
                        "vendor_ref": vendor,
                        "creditdays": creditdays,
                        "consignee": details.consignee_name,
                        "mlo": bookingDetails.vessel,
                        "notify_party": details.notify_party_name,
                        "cnf": cargo,
                        "contatainer_no": details.container_details,
                        "container_count": containerno,
                        "remarks": remarks,
                        "creditnote_charges": allCharges,
                        "total_amt": allCharges.total,
                        "is_approved": 0,
                        "ref_no": ref_no, 
                        "liner_ref_number":bookingDetails.liner_ref_number,
                        "loading_port":bookingDetails.loading_port,
                        "discharge_port":bookingDetails.discharge_port,
                        "source": bookingDetails.source,
                        "carrier": bookingDetails.carrier

                    }).then((res) => {
                        // console.log("final", res);
                        if (res.data.error) window.alert('Unable to save the details!!');
                        else {
                            window.alert('Detail saved successfully');
                            console.log(res.data);
                            // setref_no(res.data.count)
                            handleref_no(res.data.count)
                            setAmtinwords(res.data.amt_in_words)

                            let year = new Date().getFullYear();
                            const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
                            setconstno(profomainvoiceAutogen)
                            console.log('count', res.data.count)
                        }
                        // if (!res.data.totalamt) window.alert('Total amount is required');
                    })
                .catch((err) =>
                    window.alert(err.message || 'Some internal error occured')
                );
            /* if (fileInputRef.current.files.length > 0) {
                const file = fileInputRef.current.files[0];
                const selectedBookingNo = bookingid;

                const folderName = `${selectedBookingNo}`;

                try {
                    const formData = new FormData();
                    formData.append('name', folderName);
                    formData.append('documents', file);

                    const uploadResponse = await customAxios.post('/imp/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    console.log('File uploaded successfully!');
                    console.log('Response:', uploadResponse.data);
                } catch (error) {
                    console.error('Error occurred while uploading the file:', error);
                }
            } */
    }
} 
        else {


            customAxios
                .post("import/voucher/proformadebitnote/update", {
                    ref_no: ref_no,

                    "bkg_no": bookingid,
                    "to_address": customerdetails.cus_name,
                    "date": date,
                    "Address": customerdetails.cus_address,
                    "currency": selectedValue,
                    "curr_value": profoInvInp,
                    "shipper": details.shipper_name,
                    "destination": details.place_of_delivery,
                    "agent": details.forwarding_agent,
                    "vendor_ref": vendor,
                    "creditdays": creditdays,
                    "consignee": details.consignee_name,
                    "mlo": bookingDetails.vessel,
                    "notify_party": details.notify_party_name,
                    "cnf": cargo,
                    "contatainer_no": details.container_details,
                    "container_count": bookingDetails.qty_Conc,
                    "remarks": remarks,
                    "creditnote_charges": allCharges,
                    "total_amt": allCharges.total,
                    "is_approved": 0,
                    "amt_in_words": totalInWords

                })
                .then((data) => {
                    console.log(data);
                    console.log(details.remarks);
                    if (data.data.error) {
                        window.alert('Unable to update the details!!');
                        setTotalInWords(totalInWords);
                    }
                    else {
                        window.alert('Detail updated successfully');
                    }
                });
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    };

    // Getting data by bookingno (using dropdown)
    const handleShippingNo = (selected) => {
        console.log('kkk', selected);
        setBookingNo(selected ? selected.value : '');
        if (selected && selected.value) {
            console.log('kkk', selected);
            console.log('mmm', selected.value);
            console.log(handleShippingNoasync(selected.value));
            handleShippingNoasync(selected.value)
            if (!selected.myValue)
                setBookingDetails({
                    ...bookingDetails,
                    booking_number: bookingNo ? bookingNo : selected.myValue,
                });
        } else {
            setBookingDetails(initBooking)
        }

    };

    async function handleShippingNoasync(val, refIdCal = 0) {
        console.log(val);
        setBookingid(val)

        console.log(val);
        if (val) {
            let name;
            let cus;
            let valueee;

            let bookingById = await customAxios
                .post('/import/booking/getbyId', { id: val })
                .then((resp) => {
                    let res = resp.data.data;
                    console.log(res);
                    setHouseBL(res.house_bl);
                    var qty = "20'Ft:" + ' ' + res.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40'Ft:" + ' ' + res.quantity_40;
                    setcontainerno(qty)
                    console.log(qty);
                    name = res;
                    if (res.buying_id) {
                        setBookingDetails({
                            port_of_discharge: res.port_of_discharge,
                            buying_id: res.buying_id,
                            selling_id: res.selling_id,
                            liner_ref_number: res.liner_ref_number,
                            quantity_20: res.quantity_20,
                            quantity_40: res.quantity_40,
                            qty_Conc: qty,
                            house_bl: res.house_bl,
                            vessel: res.sailing_schedule.vessel,
                            voyage: res.sailing_schedule.voyage,
                            pol: res.sailing_schedule.pol,
                            eta: res.sailing_schedule.eta,
                            pod: res.sailing_schedule.pod,
                            etd: res.sailing_schedule.etd,
                            remarks: res.remarks,
                            yard_detail: res.yard_detail,
                            liner_ref_number: res.liner_ref_number,
                            loading_port: res.loading_port,
                            discharge_port: res.discharge_port,
                            source: res.source,
                            carrier:res.carrier,
                        });
                    }

                    else {
                        setBookingDetails(initBooking);
                    }
                });

            await customAxios
                .post('/import/selling/getById', { id: name.selling_id }).then((ress) => {
                    setcargo(ress.data.data.cargo)
                    cus = ress.data.data.customer;
                    console.log(cus);
                });

            await customAxios
                .post('/customer/byName', { name: cus }).then((respon) => {
                    console.log("respon", respon);
                    valueee = respon.data.data;

                    if (valueee == null) {
                        window.alert('BKG ID not found');
                        return;
                    }
                    else if (valueee) {
                        setcustomerdetails({
                            cus_name: valueee.name,
                            cus_address: valueee.address,
                        });

                    } else {
                        setcustomerdetails({
                            cus_name: '',
                            cus_address: '',

                        });
                    }
                });

            await customAxios
                .post('/import/shipping/getById', { id: val })
                .then((resp) => {
                    let res = resp.data.data;
                    console.log('shipping res--', res);
                    let a = "";
                    if (res.container_details && res.container_details.length > 0) {
                        for (let i = 0; i < res.container_details.length; i++) {
                            a += i == 0 ? "" + res.container_details[i].container_no : " , " + res.container_details[i].container_no
                        }
                    }
                    console.log("a", a);
                    if (res.shipping_id) {
                        setShippingNo(res.shipping_id);
                        setDetails({
                            booking_number: res.booking_number,

                            bl_type: res.bl_type,
                            // bl_by: res.bl_by,
                            shipper_name: res.shipper_name,
                            shipper_address: res.shipper_address,
                            consignee_name: res.consignee_name,
                            consignee_address: res.consignee_address,
                            notify_party_name: res.notify_party_name,
                            notify_party_address: res.notify_party_address,
                            also_notify_party_name: res.also_notify_party_name,
                            also_notify_party_address: res.also_notify_party_address,
                            forwarding_agent: res.forwarding_agent,
                            mark_and_no: res.mark_and_no,
                            mark_description: res.mark_description,
                            container_details: a,
                            containerlength: res.container_details.length,
                            place_of_delivery: res.place_of_delivery
                        });
                    }

                    else {
                        setShippingNo('');
                        setDetails({
                            booking_number: " ",
                            //liner_ref_number: '',
                            //carrier_bl: '',
                            issued_at: " ",
                            issued_on: "",
                            bl_type: "",
                            // bl_by: res.bl_by,
                            shipper_name: "",
                            shipper_address: "",
                            consignee_name: "",
                            consignee_address: "",
                            notify_party_name: "",
                            notify_party_address: "",
                            also_notify_party_name: "",
                            also_notify_party_address: "",
                            forwarding_agent: "",
                            mark_and_no: "",
                            mark_description: "",
                            container_details: '',
                            containerlength: ""
                        });
                    }
                });

            if (refIdCal === 0) {
                await customAxios
                    .post('/import/selling/getById', { id: name.selling_id }).then((resstt) => {
                        setAllcharges(resstt.data.data.buying_rates)
                        console.log("resstt", allCharges);
                    });
            }
            // await customAxios
            //     .post('/import/booking/getbyId', { id: val })
            //     .then((resp) => {
            //         let res = resp.data.data;
            //         console.log('booking res--', res);
            //         setHouseBL(res.house_bl);

            //         if (res.buying_id) {
            //             setBookingDetails({
            //                 port_of_discharge: res.port_of_discharge,
            //                 buying_id: res.buying_id,
            //                 selling_id: res.selling_id,
            //                 liner_ref_number: res.liner_ref_number,
            //                 quantity_20: res.quantity_20,
            //                 quantity_40: res.quantity_40,
            //                 house_bl: res.house_bl,
            //                 vessel: res.sailing_schedule.vessel,
            //                 voyage: res.sailing_schedule.voyage,
            //                 pol: res.sailing_schedule.pol,
            //                 eta: res.sailing_schedule.eta,
            //                 pod: res.sailing_schedule.pod,
            //                 etd: res.sailing_schedule.etd,
            //                 remarks: res.remarks,
            //                 yard_detail: res.yard_detail,
            //             });

            //         } else {
            //             setBookingDetails(initBooking);
            //         }
            //     });

            setContDetail(initContDetail);

        } else {
            setShippingNo('');
            setBookingDetails(initBooking);
            setDetails(initDetails);
            setContDetail(initContDetail);
            setHouseBL(false);
        }
    };

    
    useEffect(() => {
        console.log('booking details', bookingDetails);
    }, [bookingDetails]);

    const handleSelectChange = (event) => {
        console.log(event);
        setSelectedValue(event.value ? event.value : event)


        var containerquantity20 = allCharges.filter(quantity20 => quantity20.base === '20GP').length;
        var containerquantity40 = allCharges.filter(quantity40 => quantity40.base === "40GP").length;
        console.log('hgyf', containerquantity20)
        console.log('hg', containerquantity40);

        var q20 = bookingDetails.quantity_20 ? bookingDetails.quantity_20 : containerquantity20;
        var q40 = bookingDetails.quantity_40 ? bookingDetails.quantity_40 : 0;
        
        allCharges.total = 0;
        {
            allCharges.forEach((data, index) => {

                if (event.value === 'USD') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                            console.log(allCharges.total);
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40) * (profoInvInp);
                            allCharges.total += allCharges[index].total
                            console.log(allCharges.total);
                        }
                    }
                }

                else if (event.value === 'GBP') {

                    if (data.currency === 'USD') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40)/* details.containerlength ? details.containerlength : 1 */ * (profoInvInp);
                            allCharges.total += allCharges[index].total
                            console.log(allCharges.total)
                        }
                    }
                    else if (data.currency === 'GBP') {
                        if (data.base != '20GP' && data.base != '20OT' && data.base != '20HC' && data.base != '40GP' && data.base != '40OT' && data.base != '40HC') {
                            allCharges[index].total = (data.currency == event.value ? (data.rate * 1) : data.rate * profoInvInp);
                            allCharges.total += allCharges[index].total
                        }
                        else {
                            allCharges[index].total = data.rate * ((data.base == '20GP' || data.base == '20OT') ? q20 : q40);
                            allCharges.total += allCharges[index].total
                        }
                    }
                }

            })
        }

    };

    const handleref_no = (evt) => {
        setref_no(evt.target ? evt.target.value : evt);
        let year = new Date().getFullYear();
        const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
        // console.log("profomainvoiceAutogen", profomainvoiceAutogen);
        setconstno(profomainvoiceAutogen)
    };
    console.log()


    // Getting data by ref id 
    async function getDebitNtDetail(evt) {
        let getbkngid;
        if ((evt.key === 'Enter' || evt.keyCode === 13) && ref_no) {
            await customAxios.post('/import/voucher/proformadebitnote/getbyID', { ref_no: ref_no }).then((data) => {

                let result = data.data;
                console.log(result);
                if (Object.keys(result.data || {}).length == 0) {
                    window.alert('Ref-ID not found');
                    return;
                }

                if (result.data.is_approved == 1) {
                    setinputdisable(true);
                    window.alert('Invoice Issued!!');
                    document.getElementById('myButton').disabled = true;
                } else {
                    setinputdisable(false);
                }

                setBookingNo(result.data.bkg_no);
                setProfoInvInp(result.data.curr_value);
                setRemarks(result.data.remarks);
                setcargo(result.data.cnf);
                setSelectedValue(result.data.currency);
                setAmtinwords(result.data.amt_in_words);
                setvendor(result.data.vendor_ref);
                setcreditdays(result.data.creditdays);

                var invoice = result.data.creditnote_charges;
                invoice.total = result.data.total_amt;
                getbkngid = result.data.bkg_no;
                console.log(getbkngid);
                setAllcharges(invoice)
                console.log("resstt", allCharges);

                const containercount = "20'Ft:" + ' ' + result.quantity_20 + ' ' + ' ' + ' ' + ' ' + "40'Ft:" + ' ' + result.quantity_40;
                console.log(containercount);

                let {
                    agent,
                    bkg_no,
                    cnf,
                    consignee,
                    vendor_ref,
                    creditdays,
                    liner_ref_number,
                    container_count,
                    contatainer_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    curr_value,
                    notify_party,
                    remarks,
                    shipper,
                    to_address,
                    total_amt,
                    creditnote_charges,
                    amt_in_words,
                    Address,
                } = result.data;

                console.log(

                    agent,
                    bkg_no,
                    cnf,
                    curr_value,
                    consignee,
                    vendor_ref,
                    creditdays,
                    container_count,
                    contatainer_no,
                    currency,
                    destination,
                    current_date,
                    mlo,
                    notify_party,
                    remarks,
                    shipper,
                    to_address,
                    total_amt,
                    creditnote_charges,
                    amt_in_words
                )

                console.log(shippingDetails);
                console.log(bookingDetails);
                console.log(currency);
                let temp = {
                    ...customerdetails,
                    cus_name: to_address,
                    cus_address: Address,
                };
                console.log('test after', { ...temp });
                setcustomerdetails({ ...temp });

                let tempbooking = {
                    ...bookingDetails,
                    vessel: mlo,
                    qty_Conc: container_count,
                }
                console.log(tempbooking);
                setBookingDetails({ ...tempbooking });

                let tempdetails = {
                    ...details,
                    place_of_delivery: destination,
                    shipper_name: shipper,
                    consignee_name: consignee,
                    notify_party_name: notify_party,
                    container_details: contatainer_no,
                    forwarding_agent: agent,
                    // remarks: remarks,
                }
                setDetails({ ...tempdetails });

            });

            console.log(getbkngid);
            setBookingid(getbkngid);
            let bkng = await customAxios.post('/import/booking/getbyId', { id: getbkngid }).then((resp) => {
                let res = resp.data.data;
                console.log(res);
                getbkngid = res;
                // console.log('booking res--', res);

                setHouseBL(res.house_bl);
                if (res.buying_id) {
                    setBookingDetails({
                        port_of_discharge: res.port_of_discharge,
                        buying_id: res.buying_id,
                        selling_id: res.selling_id,
                        liner_ref_number: res.liner_ref_number,
                        quantity_20: res.quantity_20,
                        quantity_40: res.quantity_40,
                        house_bl: res.house_bl,
                        vessel: res.sailing_schedule.vessel,
                        voyage: res.sailing_schedule.voyage,
                        pol: res.sailing_schedule.pol,
                        eta: res.sailing_schedule.eta,
                        pod: res.sailing_schedule.pod,
                        etd: res.sailing_schedule.etd,
                        remarks: res.remarks,
                        yard_detail: res.yard_detail,
                    });

                } else {
                    // alert("sss");
                    setBookingDetails(initBooking);
                }
            });
        }
        else if (evt.key === 'Enter' || evt.keyCode === 13) confirmCancel();
        console.log(bookingid);
        // setBookingid(val)
    };

    const cancelDetails = () => {
        if (window.confirm('Are you sure to cancel?')) confirmCancel();
    };
    const confirmCancel = () => {
        setAllcharges([]);
        setBuyingDetails(initBuying);
        setBookingDetails(initBooking);
        setref_no('');
        setSellingNo('');
        setBookingNo('');

    };

    // Getting data by Debit NO 
    async function debitdetail(debitno) {
        setdebitno(debitno);

        let debitbyno = await customAxios.post('/import/voucher/proformadebitnoteid/getbyID', { debit_no: debitno }).then((data) => {

            let result = data.data;
            console.log(result);

            setBookingNo(result.data.bkg_no);
            setProfoInvInp(result.data.curr_value);
            setRemarks(result.data.remarks);
            setcargo(result.data.cnf);
            setSelectedValue(result.data.currency);
            setAmtinwords(result.data.amt_in_words);
            setvendor(result.data.vendor_ref);
            setcreditdays(result.data.creditdays);
            setref_no(result.data.ref_no);

            var invoice = result.data.creditnote_charges;
            invoice.total = result.data.total_amt;
            setAllcharges(invoice)
            console.log("resstt", allCharges);


            let {
                debit_no,
                ref_no,
                agent,
                bkg_no,
                cnf,
                consignee,
                vendor_ref,
                creditdays,
                container_count,
                contatainer_no,
                liner_ref_number,
                currency,
                destination,
                current_date,
                mlo,
                curr_value,
                notify_party,
                remarks,
                shipper,
                to_address,
                total_amt,
                creditnote_charges,
                amt_in_words,
                is_approved,
            } = result.data;

            console.log(

                debit_no,
                ref_no,
                agent,
                bkg_no,
                cnf,
                curr_value,
                consignee,
                vendor_ref,
                creditdays,
                container_count,
                contatainer_no,
                currency,
                destination,
                current_date,
                mlo,
                notify_party,
                remarks,
                shipper,
                to_address,
                total_amt,
                creditnote_charges,
                amt_in_words,
                is_approved,
            )

            console.log(shippingDetails);
            console.log(bookingDetails);
            console.log(currency);
            let temp = {
                ...customerdetails,
                cus_name: to_address,
            };
            console.log('test after', { ...temp });
            setcustomerdetails({ ...temp });

            let tempbooking = {
                ...bookingDetails,
                vessel: mlo,
                qty_Conc: container_count,
            }
            console.log(tempbooking);
            setBookingDetails({ ...tempbooking });

            let tempdetails = {
                ...details,
                place_of_delivery: destination,
                shipper_name: shipper,
                consignee_name: consignee,
                notify_party_name: notify_party,
                container_details: contatainer_no,
                forwarding_agent: agent,
                // remarks: remarks,
            }
            setDetails({ ...tempdetails });
            let year = new Date().getFullYear();
            const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
            setconstno(profomainvoiceAutogen)
        });

    };

    return (
        <>
            <div className={classes.container}>
                <h5>Proforma DebitNote <span className={classes.title}>Import / Proforma DebitNote</span></h5>
            </div>

            <Container className={classes.inputsContainer}>

                <table style={{ width: '100%' }}>
                    <tbody><tr>

                        <td colSpan={1} style={{ paddingLeft: '3px' }}><span >BKG.No#*</span></td>


                        <td colSpan={1} style={{ width: '100px' }}>
                            <section>
                                <div style={{ position: 'relative', width: '200px' }}>

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style} isClearable={true}
                                        isSearchable={true}
                                        options={bookingNumbers}
                                        value={bookingNumbers.filter(
                                            (option) => {
                                                console.log(option.value, bookingNo);
                                                console.log('bb', bookingNo);
                                                return option.value === bookingNo
                                            }
                                        )}
                                        onChange={(selected) => handleShippingNo(selected)}
                                        isDisabled={debitno || inputdisable}
                                    />
                                </div>

                            </section>
                        </td>


                        <td colSpan={1}><span style={{ paddingLeft: '35px' }}>BL#*</span></td>
                        <td colSpan={1}><input className={classes.inputText} type="text" value={bookingDetails.liner_ref_number} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })}  disabled={debitno || inputdisable} /></td>

                        <td colSpan={1} style={{ width: '10px', paddingLeft: '84px' }}><span>DN#*</span></td>
                        <td colSpan={1} style={{ width: '100px' }}>
                            <section>
                                <div style={{ position: 'relative', width: '200px' }}>

                                    <Select
                                        components={{ IndicatorSeparator, Placeholder }}
                                        styles={style} isClearable={true}
                                        isSearchable={true}
                                        options={debitnote}
                                        value={debitnote.filter(
                                            (option) => {
                                                console.log(option.value, debitno);
                                                console.log(option);
                                                console.log('dddd', debitno);
                                                return option.value === debitno
                                            }
                                        )}
                                        onChange={(selected) => {
                                            const selectedValue = selected ? selected.value : null;
                                            debitdetail(selectedValue);
                                        }}
                                    // isDisabled={debitno || inputdisable}
                                    />
                                </div>

                            </section>
                        </td>

                        <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                        <td colSpan={1}><input className={classes.inputText} type="text" value={constno} /* disabled={debitno} */ disabled={debitno || inputdisable} /></td>
                        <td colSpan={1}><input className={classes.inputText} type="text"
                            value={ref_no}
                            onChange={(evt) => handleref_no(evt)}

                            onKeyUp={(evt) => getDebitNtDetail(evt)} /* disabled={debitno} */ disabled={debitno || inputdisable} /></td>

                    </tr>
                        <tr>
                            <td>To<span>*</span></td>
                            <td colSpan={5}>
                                {debitno || inputdisable ? (<input
                                    className={classes.inputText}
                                    value={customerdetails.cus_name}
                                    type="text"
                                    disabled
                                />) : (<Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={customerList.find((customer) => customer.value === customerdetails.cus_name)}
                                    options={customerList}
                                    onChange={(selected) => {
                                        handleBookingDetails({
                                            key: 'cus_name',
                                            value: selected ? selected.value : '',
                                        }); updateCustomerAddress(selected ? selected.value : '')
                                    }} />)}
                            </td>

                            <td style={{ paddingLeft: '35px' }}>Date</td>
                            <td colSpan={2}><input className={classes.inputText} value={date} type="text" disabled={debitno || inputdisable} /></td>
                        </tr>

                        <tr>

                            {/* <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                            <td colSpan={5}><input className={classes.inputText} value={details.place_of_delivery} onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td> */}
                            <td style={{ paddingLeft: '2px' }} colSpan={1} >Address</td>
                            <td colSpan={5} style={{ paddingTop: '5px' }}><input className={classes.inputText} value={customerdetails.cus_address} onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td>

                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                            <td colSpan={1}>

                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    value={currencies.filter(
                                        (option) => option.value === selectedValue
                                    )}
                                    onChange={(e) => {
                                        handleSelectChange(e)
                                    }}

                                    isDisabled={debitno || inputdisable} />
                            </td>
                            <td colSpan={1}><input className={classes.inputText} value={profoInvInp} onChange={(e) => { handleProfoInvInp(e) }} type="text" disabled={debitno || inputdisable} /></td>
                        </tr>

                        <tr></tr>
                        <tr>
                            <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                            <td colSpan={5} style={{ paddingTop: '5px' }}><input className={classes.inputText} value={details.place_of_delivery} onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td>


                            {/* <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td> */}
                            <td colSpan={1} style={{ paddingLeft: '35px', }}>Container</td>
                            <td colSpan={2} rowSpan={3}><textarea style={{ height: '120px' }} value={bookingDetails.qty_Conc} onChange={(evt) =>
                                handlebookingDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} disabled={debitno || inputdisable} /></td>
                            {/* <td colSpan={1} style={{ paddingLeft: '3px', paddingTop: '13px' }} disabled={debitno || inputdisable}><span>Vendor Ref #</span></td> */}
                            {/* <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Credit Days</span></td> */}

                        </tr>
                        <tr >
                            <td colSpan={1}>Shipper</td>
                            <td colSpan={2} style={{ paddingTop: '9px' }}><input className={classes.inputText} value={details.shipper_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'shipper_name',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                            <td colSpan={2} style={{ paddingTop: '9px' }}><input className={classes.inputText} value={details.forwarding_agent} onChange={(evt) => {

                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })
                            }} type="text" disabled={debitno || inputdisable} /></td>

                            
                            {/* 
                            <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                            <td colSpan={1} style={{ paddingLeft: '3px', paddingTop:'13px' }}>Vendor Ref #</td> */}
                            {/* <td colSpan={1} style={{ paddingLeft: '42px' }}><span>Credit Days</span></td> */}

                            <td colSpan={1}><span style={{ paddingLeft: '35px', paddingBottom: '16px' }}></span></td>
                            {/* <td colSpan={1} style={{ paddingBottom: '16px' }}><input className={classes.inputText} style={{ paddingBottom: '16px' }} type="text" value={vendor} onChange={(e) => setvendor(e.target.value)} disabled={debitno || inputdisable} /></td> */}
                            {/* <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={creditdays} onChange={(e) => setcreditdays(e.target.value)} /></td> */}

                            {/* <td style={{ padding: '0px 0px 17px 3%', textTransform: 'uppercase' }}>
                                <input type="file" ref={fileInputRef} id="fileInput" accept=".pdf" style={{ display: 'none' }} onChange={handleFileChange} disabled={debitno || inputdisable} />

                                <button className={classes.footerButton} style={{ textTransform: 'uppercase', paddingBottom: '8px', width: '100px' }} type="button"
                                    onClick={() => fileInputRef.current.click()} disabled={debitno || inputdisable} > Upload
                                </button>

                            </td> */}
                        </tr>
                        <tr >
                            <td colSpan={1} style={{ paddingRight: '50px' }}  >Cosingnee</td>
                            <td colSpan={2}><input className={classes.inputText} value={details.consignee_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'consignee_name',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                            <td colSpan={2}><input className={classes.inputText} value={bookingDetails.vessel} onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} type="text" disabled={debitno || inputdisable} /></td>

                            



                            {/* <td colSpan={1}><span style={{ paddingLeft: '35px' }}></span></td>
                            <td colSpan={1} style={{paddingBottom:'16px'}}><input className={classes.inputText}  type="text" value={vendor} onChange={(e) => setvendor(e.target.value)} disabled={debitno || inputdisable} /></td> */}
                            {/* <td colSpan={1}><input className={classes.inputText} style={{ marginBottom: '20px' }} type="text" value={creditdays} onChange={(e) => setcreditdays(e.target.value)} /></td> */}

                            {/* <td style={{ padding: '10px', textTransform: 'uppercase', paddingLeft: '3%',paddingBottom:'24px' }}> <input type="file" ref={fileInputRef} id="fileInput" accept=".pdf" style={{ display: 'none' }} onChange={handleFileChange} disabled={debitno || inputdisable} />

                                <button className={classes.footerButton} style={{ textTransform: 'uppercase', paddingBottom: '8px', width: '100px' }} type="button"
                                    onClick={() => fileInputRef.current.click()} disabled={debitno || inputdisable} > Upload
                                </button>

                            </td> */}


                        </tr>
                        <tr >
                            <td colSpan={1}>Notify Party</td>
                            <td colSpan={2} style={{ paddingBottom: '23px' }}><input className={classes.inputText} value={details.notify_party_name} onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} type="text" disabled={debitno || inputdisable} /></td>

                            <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                            <td colSpan={2} style={{ paddingBottom: '23px' }}><input className={classes.inputText} value={cargo} type="text" onChange={(e) => setcargo(e.target.value)} disabled={debitno || inputdisable} /></td>

                            <td colSpan={1} style={{ paddingLeft: '35px', }}>Container No.</td>
                            <td colSpan={2} rowSpan={2}><textarea style={{ height: '145px'/* , marginTop: '-20px' */ }} value={details.container_details} onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} disabled={debitno || inputdisable} /></td>
                        </tr>
                        
                        <tr>
                            <td colSpan={1}>Remarks</td>
                            <td colSpan={5}><textarea style={{ height: '90px' }} value={remarks} onChange={(e) => setRemarks(e.target.value)} disabled={debitno || inputdisable} /></td>


                        </tr>


                        <tr><td colSpan="9">
                            <hr></hr>
                        </td></tr>

                        <tr>
                            <td style={{ padding: '10px' }} colSpan={2}>Charge Description</td>
                            <td style={{ padding: '10px' }} colSpan={2}>Curr</td>
                            <td style={{ padding: '10px' }} colSpan={1}>Rate</td>
                            <td style={{ padding: '10px' }} colSpan={1}>Base</td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px' }} colSpan={1}>Exchange Rate</td>
                                : null}
                        </tr>

                        <tr>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={charges}
                                    value={charges.filter(
                                        (option) => option.value === newCharge
                                    )}
                                    onChange={(e) => saveCharge({ key: 'charge', evt: e })}
                                    isDisabled={debitno || inputdisable}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={style}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={currencies}
                                    value={currencies.filter(
                                        (option) => option.value === newCurrency
                                    )}
                                    onChange={(e) => saveCharge({ key: 'currency', evt: e })}
                                    isDisabled={debitno || inputdisable}
                                />
                            </td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={1}><input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="text" name="rate" value={formInput.rate} onChange={handleChange} disabled={debitno || inputdisable} ></input></td>
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={1}>
                                <Select
                                    components={{ IndicatorSeparator, Placeholder }}
                                    styles={baseStyle}
                                    isClearable={true}
                                    isSearchable={true}
                                    name='base'
                                    options={containers}
                                    value={containers.filter(
                                        (option) => option.value === newBase
                                    )}
                                    onChange={(e) => saveCharge({ key: 'base', evt: e })}
                                    isDisabled={debitno || inputdisable}
                                />
                            </td>
                            {newCurrency == 'EUR' ?
                                <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={2}>
                                    <input className={classes.inputText} style={{ textTransform: 'uppercase' }} type="number" name="exchangeRate" value={formInput.exchangeRate} onChange={handleChange}>
                                    </input></td> : null}
                            <td style={{ padding: '10px', textTransform: 'uppercase' }} colSpan={1}><button className={classes.footerButton} style={{ textTransform: 'uppercase' }} type="submit" onClick={handleSubmit} disabled={debitno || inputdisable} >Add</button></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                    <table style={{ width: '100%' }}>
                        <thead className={classes.theadDark}>
                            <tr>
                                <th>charges</th>
                                <th>Curr</th>
                                <th>Rate</th>
                                <th>Base</th>
                                <th>Exchange Rate</th>
                                <th>No.Of.Containers</th>
                                <th>Amount</th>
                                <th>Action</th>


                            </tr>
                        </thead>

                        <tbody>
                            {allCharges ? <>
                                {allCharges.map((data, index) => (
                                    <tr>
                                        <td>{data.charge}</td>
                                        <td style={{ textAlign: 'center' }}>{data.currency}</td>
                                        <td style={{ textAlign: 'center' }}>{data.rate ? parseFloat(data.rate).toFixed(2) : 0}</td>
                                        <td style={{ textAlign: 'center' }}>{data.base}</td>
                                        {data.currency == 'EUR' ? <td style={{ textAlign: 'center' }}>{data.exchangeRate}</td> : <td style={{ textAlign: 'center' }}>{data.currency == selectedValue ? '1' : profoInvInp}</td>}
                                        {(data.base == '20GP' || data.base == '20OT') ? <td style={{ textAlign: 'center' }}>{bookingDetails.quantity_20}</td> : <td style={{ textAlign: 'center' }}>{bookingDetails.quantity_40}</td>}
                                        {data.total > 0 ? <td style={{ textAlign: 'center' }}>{parseFloat(data.total).toFixed(2)}</td> : <td style={{ textAlign: 'center' }}>0</td>}
                                        <td style={{ textAlign: 'center' }}>
                                            <img
                                                src={EditIcon}
                                                className={classes.icon}
                                                alt='edit_icon'
                                                // style={{ borderRadius: '50%' }}
                                                onClick={() => editCharge(index, data)}
                                                style={{ pointerEvents: debitno || inputdisable ? 'none' : 'auto', opacity: debitno || inputdisable ? 0.5 : 1 }}

                                            />
                                            &nbsp;&nbsp;
                                            <img
                                                src={DeleteIcon}
                                                className={classes.icon}

                                                onClick={() => deleteCharge(index)}
                                                style={{ pointerEvents: debitno || inputdisable ? 'none' : 'auto', opacity: debitno || inputdisable ? 0.5 : 1 }}

                                            />
                                        </td>
                                    </tr>
                                )
                                )} </>
                                : null}
                        </tbody>

                    </table>

                </div>
                <div>
                    <table>
                        <tr>
                            <td colSpan={5} style={{ width: '50%' }}></td>
                            <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                            <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoInvInp ? allCharges.total.toFixed(2) : 0} onChange={(e) => { handleshippingDetails({ key: 'total', value: e.target.checked }); console.log(e.target.checked); }} required type="text">{console.log(Total)}</input></td>
                        </tr>

                        <tr>
                            <td colSpan={5} />
                            <td colSpan={4}>
                                <button id="myButton" className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={savedata} disabled={debitno ? true : false}>{ref_no ? 'Update' : 'Save'} </button>
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }}><Template
                                    buyingDetails={buyingDetails}
                                    bookingDetails={bookingDetails}
                                    bookingNo={bookingNo}
                                    details={details}
                                    allCharges={allCharges}
                                    customerdetails={customerdetails}
                                    remarks={remarks}
                                    cargo={cargo}
                                    date={date}
                                    creditdays={creditdays}
                                    vendor_ref={vendor}
                                    debitnote={debitnote}
                                    shippingDetails={shippingDetails}
                                    selectedValue={selectedValue}
                                    ref_no={ref_no}
                                    amt_in_words={amtinwords}
                                    totalInWords={totalInWords}


                                /></button>
                                <button className={classes.footerButton} style={{ float: 'right', margin: '1%' }} onClick={reload}>New</button>
                            </td>
                        </tr>

                    </table>
                </div>



            </Container>
            <br></br>
        </>
    )
}
import { Container, Row, Col } from "react-bootstrap";
import { SearchIcon, DeleteIcon, EditIcon } from "../../Images";
import React, { useEffect, useState } from "react";
import customAxios from "../../Axios";
import classes from "./Customer.module.css";
import Select from 'react-select';
import ExcelJS from 'exceljs';




function Customer() {
  const isSuperuser =
    localStorage.getItem("roll") === "Super Admin" ? true : false;
  let initValue = {
    name: "",
    type: "",
    location: "",
    address: "",
    city: "",
    pin: "",
    contactNo: "",
    mailId: "",
    department: "",
    faxNo: "",
  };
  const [customer, setCustomer] = useState(initValue);
  const [customers, setCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState(true);
  // const [noOfEmails, setNoOfEmails] = useState(1);
  const [error, setError] = useState("");
  const [customerUpdate, setCustomerUpdate] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedVal, setSelectedVal] = useState('');
  const [excelData, setExcelData] = useState([]);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      width: '200px'
    }),
    menu: base => ({
      ...base,
      zIndex: 2
  }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  const setCustomerDetail = ({ key, value }) => {
    let detail = { ...customer };
    
    detail[key] = value.toUpperCase();
  
    setCustomer(detail);

  };

  // const setEmailKey = ({key, value }) => {
  //    let detail = {...customer};
  //    detail.mailId[key] = '';
  // }

  // const setEmailValue

  const saveCustomer = () => {
    
    let temp = { ...customer };

    if (temp._id) {
      customAxios.post("/customer/update",  temp ).then((data) => {
console.log(data);
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Detail updated succesfully!");
        }
      });
    } else {
      let url = "/customer";
      customAxios
        .post(url, customer)
        .then((resp) => {

          if (resp.data.error) {

            if (
              resp.data.message ===
              "Some error occurred while creating the Customer."
            ) {
              alert("Sorry!. Unable to save the entry");
            } else {
              alert("Please fill all the required fields");
            }
            return false;
          } else {
            alert("Entry saved successfully!");
            setCustomer(initValue);

            return true;
          }
        })
        .catch((err) => {
          alert("Sorry! Unable to save the entry");
        });
    }
  };

  const searchCustomer = () => {
    let url = "/customer/search";
    customAxios.post(url, { name: searchValue }).then((customers) => {
      setCustomers(customers.data.data);

    });
  };

  const deleteCustomer = (index, id) => {
    if (window.confirm("Are you sure to delete?")) {
      customAxios.delete("/customer", { data: { userId: id } }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert("Customer deleted succesfully!");
          let temp = [...customers];
          temp.splice(index, 1);
          setCustomers(temp);
        }
      });
    }
  };

  const editCustomer = (index, id) => {
    let temp = [...customers];
    setCustomer({ ...temp[index] });
    setShowList(false);
    setCustomerUpdate(true);
  };


  useEffect(() => {
    let url = "/customer";
    customAxios.get(url).then((customers) => {
      setCustomers(customers.data.data);
      console.log(customers.data.data);
      setSearchList(customers.data.data);
      
    });

    let customerurl = "/customer";
    customAxios.get(customerurl).then((customers) => {
      let temp = [...customers.data.data];
      let custo = temp.map((data) => {
        return { label: data.name, value: data.name };
      })
      setCustomerList([...custo]);
    });
  }, [showList]);


  //extracting data for excel
  useEffect (()=>{
    const extractData = customers.map((customer, index)=>({
      id: index+1,
      name: customer.name,
      address: customer.address,
      location: customer.location,
      emailId: customer.mailId,
    }));
    setExcelData(extractData);
  },[customers]);

  const handleSearch = (option) => {
    if (option == null) {
      let url = "/customer";
      customAxios.get(url).then((customers) => {
        setCustomers(customers.data.data);
        setSearchList(customers.data.data);
        setSelectedVal(null);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.name === (option ? option.value : ''));
      setCustomers(filterdata);
    }
  }

  //converting table datas in to a excel format
  const convertToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');
  
    // Add header row
    sheet.addRow(['ID', 'Customer Name', 'Address', 'Location', 'Email ID']);
  
    // Add data rows
    excelData.forEach((row) => {
      sheet.addRow([row.id, row.name, row.address, row.location, row.emailId]);
    });
  
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'CustomerMaster.xlsx');
    });
  };
  const saveAs = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  // 
  

  return (
    <>
      <div className={classes.container}>
        <h5>Customer</h5>
        <section className={classes.title}>Dashboard / customer</section>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <h6>Customer Listing</h6>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className={classes.searchContainer}>
                {/* <div className={classes.searchBox}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={classes.searchCustomer}
                    />
                    {searchValue ? (
                      <div
                        className={classes.closeIcon}
                        onClick={() => setSearchValue("")}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    className={classes.searchIcon}
                    onClick={searchCustomer}
                  />
                </div> */}

                <div>
                  <span>Search</span>
                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={customerList}
                    value={selectedVal}
                    onChange={handleSearch}
                  />
                </div>

                <div className={classes.addButton}>
                  <button
                    className={classes.footerButton}
                    onClick={() => {
                      setShowList(false);
                      setCustomer(initValue);
                      setCustomerUpdate(false);
                    }}
                  >
                    Add
                  </button>
                  &emsp;
                  <span>
                  <button className={classes.footerButton} onClick={convertToExcel}>
                    Download
                  </button></span>
                  
                  {/* <button className={classes.footerButton}>Cancel</button> */}
                </div>
              </div>
              <table class="table">
                <thead className={classes.theadDark} style={{position: 'sticky', zIndex: 1}}>
                  <tr>
                    <th scope="col" style={theadStyle}>#</th>
                    <th scope="col" style={theadStyle}>Customer Name</th>
                    <th scope="col" style={theadStyle}>Type</th>
                    <th scope="col" style={theadStyle}>Location</th>
                    <th scope="col" style={theadStyle}>City</th>
                    <th scope="col" style={theadStyle}>Contact Number</th>
                    <th scope="col" style={theadStyle}>Email ID</th>
                    <th scope="col" style={theadStyle}>Fax Number</th>
                    <th scope="col" style={theadStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.length
                    ? customers.map((customer, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td style={{textTransform: 'uppercase'}}>{customer.name}</td>
                          <td>{customer.type}</td>
                          <td>{customer.location}</td>
                          <td>{customer.city}</td>
                          <td>{customer.contactNo}</td>
                          <td>{customer.mailId}</td>
                          <td>{customer.faxNo}</td>
                          {/* {isSuperuser && ( */}
                            <td>
                              {/* <a href='#'> */}
                              <img
                                src={EditIcon}
                                alt="edit_icon"
                                onClick={() =>
                                  editCustomer(index, customer._id)
                                }
                              />
                              {/* </a>{' '} */}
                              &nbsp;&nbsp; {/* <a href='#'> */}
                              <img
                                src={DeleteIcon}
                                alt="delete_Icon"
                                onClick={() =>
                                  deleteCustomer(index, customer._id)
                                }
                              />
                              {/* </a> */}
                            </td>
                          {/* )} */}
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
              {customers.length ? (
                ""
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>Add Customer</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>
                  Customer Name<span>*</span>
                </p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={customer.name}
                  onChange={(e) =>
                    setCustomerDetail({ key: "name", value: e.target.value })
                  } style={{textTransform: 'uppercase'}}
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>
                  Customer type<span>*</span>
                </p>
                <select
                  defaultValue="select"
                  className={classes.inputText}
                  value={customer.type}
                  onChange={(e) =>
                    setCustomerDetail({ key: "type", value: e.target.value })
                  }
                >
                  <option value="" disabled={true}>
                    select
                  </option>
                  <option value="SHIPPER/CNEE/NOTIFY">
                    SHIPPER/CNEE/NOTIFY
                  </option>
                  <option value="AGENT/PRINCIPAL">AGENT/PRINCIPAL</option>
                  <option value="CARRIER/AIRLINER/MLO/FF">
                  CARRIER/AIRLINER/MLO/FF
                  </option>
                  <option value="CFS">CFS</option>
                  <option value="WAREHOUSE">WAREHOUSE</option>
                  <option value="TRANSPORTER">TRANSPORTER</option>
                  <option value="OTHERS">OTHERS</option>
                </select>
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>
                  Location<span>*</span>
                </p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={customer.location}
                  onChange={(e) =>
                    setCustomerDetail({
                      key: "location",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>
                  Phone number<span>*</span>
                </p>
                <input
                  type="number"
                  className={classes.inputText}
                  value={customer.contactNo}
                  onChange={(e) =>
                    setCustomerDetail({
                      key: "contactNo",
                      value: e.target.value,
                    })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Fax number</p>
                <input
                  type="number"
                  className={classes.inputText}
                  value={customer.faxNo}
                  onChange={(e) =>
                    setCustomerDetail({ key: "faxNo", value: e.target.value })
                  }
                />
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>City</p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={customer.city}
                  onChange={(e) =>
                    setCustomerDetail({ key: "city", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p style={{paddingBottom:'6px'}}>
                  Address<span>*</span>
                </p>
                <textarea
                  placeholder="Maximum of 100 characters"
                  maxlength="100"
                  value={customer.address}
                  onChange={(e) =>
                    setCustomerDetail({ key: "address", value: e.target.value })
                  }
                />
              </Col>

              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>
                  Zip/Pin Code<span>*</span>
                </p>
                <input
                  type="text"
                  className={classes.inputText}
                  value={customer.pin}
                  onChange={(e) =>
                    setCustomerDetail({ key: "pin", value: e.target.value })
                  }
                />
              </Col>
            </Row>
            {/* <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Mail ID</p>
                <input
                  type='text'
                  value={customer.mailId}
                  onChange={(e) =>
                    setCustomerDetail({ key: 'mailId', value: e.target.value })
                  }
                />
              </Col>
            </Row> */}
            <h6>Email ID</h6>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Department</p>
                <select
                  defaultValue="select"
                  className={classes.inputText}
                  onChange={(e) =>
                    setCustomerDetail({ key: "department", value: e.target.value })
                  }
                >
                  <option value="select" disabled={true}>
                    select
                  </option>
                  <option value="MANAGEMENT">MANAGEMENT</option>
                  <option value="FINANCE">FINANCE</option>
                  <option value="COMMERCIAL">COMMERCIAL</option>
                  <option value="EXPORT">EXPORT</option>
                  <option value="IMPORT">IMPORT</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Email</p>
                <input
                  type="text"
                  className={classes.inputText}
                value={customer.mailId}
                onChange={(e) =>
                  setCustomerDetail({ key: 'mailId', value: e.target.value })
                }
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Department</p>
                <select defaultValue="select" className={classes.inputText} >
                  <option value="select" disabled={true}>
                    select
                  </option>
                  <option value="volvo">MANAGEMENT</option>
                  <option value="saab">FINANCE</option>
                  <option value="mercedes">COMMERCIAL</option>
                  <option value="audi">EXPORT</option>
                  <option value="audi">IMPORT</option>
                  <option value="audi">OTHER</option>
                </select>
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Email</p>
                <input type="text" className={classes.inputText} />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Department</p>
                <select defaultValue="select" className={classes.inputText}>
                  <option value="select" disabled={true}>
                    select
                  </option>
                  <option value="volvo">MANAGEMENT</option>
                  <option value="saab">FINANCE</option>
                  <option value="mercedes">COMMERCIAL</option>
                  <option value="audi">EXPORT</option>
                  <option value="audi">IMPORT</option>
                  <option value="audi">OTHER</option>
                </select>
              </Col>
              <Col sm={6} lg={4} xs={12} className={classes.input}>
                <p>Email</p>
                <input type="text" className={classes.inputText} />
              </Col>
            </Row>
            <div className={classes.hl}></div>
            <div className={classes.footer}>
              <button className={classes.footerButton} onClick={saveCustomer}>
                {customerUpdate ? "Update" : "Save"}
              </button>
              <button
                className={classes.footerButton}
                onClick={() => {
                  setShowList(true);
                  setCustomer(initValue);
                  setCustomerUpdate(false);
                }}
              >
                View List
              </button>
              <button
                className={classes.footerButton}
                onClick={() => {
                  setCustomer(initValue);
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Customer;

import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Select from 'react-select';
import classes from './Invoice/invoice.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../Axios';
import { icon_download, upload } from '../../Images';
import { Link } from "react-router-dom";


export default function ImpJobEnquiry() {

  const [bookingNumbers, setBookingNumbers] = useState([]);
  const [bookingNo, setBookingNo] = useState('');
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [incomeTotal, setIncomeTotal] = useState(null);
  const [expenseTotal, setExpenseTotal] = useState(null);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  useEffect(() => {

    customAxios.get('/import/invoice/bkgopen').then((ids) => {

      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: 'RUKIM'+data, value: data };
      });
      setBookingNumbers([...details]);

    });

  }, []);

  const fetchData = async (selectedBookingNo) => {
    console.log('Selected Booking No:', selectedBookingNo);
    try {
      const incomeResponse = await customAxios.post('/import/invoice/dn', { bkg_no: selectedBookingNo });
      console.log('Income Response:', incomeResponse);
      const incomeData = incomeResponse.data.data;
      console.log(incomeData);
      setIncomeData(incomeData);

      const incomeTotal = calculateTotal(incomeData);
      setIncomeTotal(incomeTotal.toFixed(2));

      const expenseResponse = await customAxios.post('/import/invoice/cnops/cn', { bkg_no: selectedBookingNo });
      console.log('Expense Response:', expenseResponse);
      const expenseData = expenseResponse.data.data;
      console.log(expenseData);
      setExpenseData(expenseData);

      const expenseTotal = calculateTotal(expenseData);
      setExpenseTotal(expenseTotal.toFixed(2));

      setBookingNo(selectedBookingNo);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotal = (data) => {
    return data.reduce((total, item) => total + (item.total_amt || item.totalamt), 0);
  };

  const calculateDifference = () => {
    return incomeTotal - expenseTotal;
  };

  const getProfitOrLoss = () => {
    const difference = calculateDifference();
    if (difference < 0) {
      return { label: 'Loss', value: ((Math.abs(difference)).toFixed(2)) };
    } else if (difference > 0) {
      return { label: 'Profit', value: (parseFloat(difference).toFixed(2)) };
    } else {
      return { label: 'No Profit or Loss', value: null };
    }
  };

  return (
    <>
      <div className={classes.container}>
        <h5>JOB ENQUIRY <span className={classes.title}>Import \ JobEnquiry</span></h5>
      </div>
      <Container className={classes.inputsContainer}>

        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={2}><span>Booking No</span></td>
            </tr>


            <tr>
              <td colSpan={2} style={{ width: '100px' }}>
                <section>
                  <div style={{ position: 'relative', width: '150px' }}>
                    <Select
                      components={{ IndicatorSeparator, Placeholder }}
                      styles={style}
                      isClearable={true}
                      isSearchable={true}
                      options={bookingNumbers}
                      value={bookingNumbers.find((option) => option.value === bookingNo)}
                      onChange={(selectedOption) => {
                        const selectedBookingNo = selectedOption ? selectedOption.value : '';
                        setBookingNo(selectedBookingNo);
                        fetchData(selectedBookingNo);
                        console.log(selectedBookingNo)
                      }}

                    />
                  </div>
                </section>
              </td>

              <td colSpan={2}>

              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
          <table style={{ width: '100%' }}>
            <thead className={classes.theadDark}>
              <tr>
                <th style={{ textAlign: 'center' }}>Date</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center' }}>Customer Name</th>
                <th style={{ textAlign: 'center' }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {incomeData.map((data, index) => (
                <tr key={`income-${index}`}>
                  <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                  <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                  <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                  <td style={{ textAlign: 'center' }}>{data.total_amt ? data.total_amt.toFixed(2) /* || */ : data.totalamt.toFixed(2)}</td>
                </tr>
              ))}
              {incomeTotal > 0 && (
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={3} className={classes.theadLight}>Income Total</td>
                  <td style={{ textAlign: 'center' }} className={classes.theadLight}>{incomeTotal}</td>
                </tr>
              )}
              {expenseData.map((data, index) => (
                <tr key={`expense-${index}`}>
                  <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                  <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                  <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                  <td style={{ textAlign: 'center' }}>{data.total_amt.toFixed(2) || data.totalamt.toFixed(2)}</td>
                </tr>
              ))}

              {expenseTotal > 0 && (
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={3} className={classes.theadLight}>Expense Total</td>
                  <td style={{ textAlign: 'center' }} colSpan={2} className={classes.theadLight}>{expenseTotal}</td>
                </tr>
              )}
              {getProfitOrLoss().value !== null && (
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={3} className={classes.theadDark}>{getProfitOrLoss().label}</td>
                  <td style={{ textAlign: 'center' }} className={classes.theadDark}>{getProfitOrLoss().value}</td>
                </tr>
              )}

            </tbody>
          </table>
        </div>

      </Container>
    </>
  );

}
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, ThemeProvider } from 'react-bootstrap';
import Select from 'react-select';
import { EditIcon, DeleteIcon } from '../../../Images';
import customAxios from '../../../Axios';
import classes from './Buyer.module.css';
import Template from './PdfTemplate';
import CustomTable from '../../customTable';

export default function Booking() {

  const tableStyle = {
    height: '100px',
    overflowY: 'scroll',
    margin: '20px 0',
    backgroundColor: '#eff0f1',
  };

  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  let initBuyValue = {
    carrier: '',
    source: '',
    loading_port: '',
    discharge_port: '',
    destination: '',
    cargo: '',
    freight: '',
    shipment: '',
    charges: [],
  };

  let initSellValue = {
    created_by: '',
    customer: '',
    date: '',
    valid_till: '',
    remarks: '',
    selling_rates: [],
    carrier: '',
  };

  let initScheduleValue = {
    vessel: '',
    voyage: '',
    pol: '',
    pol_eta: '',
    etd: '',
    pod: '',
    eta: '',
  };

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
      zIndex: 2,
      color: 'red'
    }),

    menu: base => ({
      ...base,
      zIndex: 2
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const haulageOptions = [
    { value: 'Carrier', label: 'Carrier' },
    { value: 'Merchant', label: 'Merchant' },
  ];

  const haulage_services = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  let initHaulage = {
    point: '',
    date: '',
    time: '',
    ref: '',
  };

  const [buyingNumber, setBuyingNumber] = useState('');
  const [sellingNo, setSellingNo] = useState('');
  const [buyingDetails, setBuyingDetails] = useState(initBuyValue);
  const [sellingDetails, setSellingDetails] = useState(initSellValue);
  const [allCharges, setAllcharges] = useState([]);
  const [schedule, setSchedule] = useState(initScheduleValue);
  const [allSchedules, setAllSchedules] = useState([]);
  const [bookingNo, setBookingNo] = useState('');
  const [error, setError] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [ports, setPorts] = useState([]);
  const [newHaulage, setNewHaulage] = useState(initHaulage);
  const [allHaulage, setAllHaulage] = useState([]);
  const [updateHaulage, isUpdateHaulage] = useState('');
  const [haulageService, setHaulageService] = useState(false);
  const [charges_inc, setCharges_inc] = useState(true);
  const [vessels, setVessels] = useState([]);
  const [Isreleased, setIsreleased] = useState(0);
  const [bkngReleaseId, setBkngReleaseId] = useState('');
  const [Base, setBase] = useState([]);
  const [poleta, setpoleta] = useState('');
  const [pol, setPol] = useState('');
  const [pod, setPod] = useState('');
  const [isOpenBkg, setIsOpenBkg] = useState(false);
  const [isOpenQuota, setIsOpenQuota] = useState(false);
  const [bkgdata, setBkgData] = useState('');
  const [quotpopdata, setquotpopdata] = useState('');
  const [bookingNumber, setBookingNumber] = useState('');
  const [sellId, setSellId] = useState('');
  const [inputdisable, setinputdisable] = useState(false);

  var preparedBY = localStorage.getItem('name');

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const handleclose = () => setShowPreview(false);
  let buttonDisabled = Object.values(schedule).includes('');
  let initBookingValue = {
    selling_id: sellingNo,
    buying_id: buyingNumber,
    liner_ref_number: '',
    yard_detail: '',

    //portnet_ref_number: '',
    remarks: '',
    sailing_schedule: allSchedules.length ? allSchedules[0] : {},
    haulage_service: '',
    haulage_option: '',
    haulage_point: allHaulage.length ? allHaulage : [],
    quantity_20: 0,
    quantity_40: 0,
    weight_in_kgs_20: 0,
    weight_in_kgs_40: 0,
    si_cutoff: '',
    vgm_cutoff: '',
    vessel_cutoff: '',
    house_bl: false,
  };

  if (initBookingValue.quantity_20 !== 0) {
    initBookingValue.weight_in_kgs_20 = 21000;
  }

  if (initBookingValue.quantity_40 !== 0) {
    initBookingValue.weight_in_kgs_40 = 28000;
  }

  const [bookingDetails, setBookingDetails] = useState(initBookingValue);

  const handleBookingDetails = ({ key, value }) => {
    let temp = { ...bookingDetails };

    if (temp.quantity_20 !== 0) {
      temp.weight_in_kgs_20 = 21000;
    }

    if (temp.quantity_40 !== 0) {
      temp.weight_in_kgs_40 = 28000;
    }
    temp[key] = value;
    setBookingDetails({ ...temp });
  };

  const handleBookingNo = (evt) => {
    setBookingNo(evt.target.value);
  };

  const getBookingDetail = (evt, bookingId, fromClick = false) => {
    setIsOpenBkg(false);

    // if ((evt.key === 'Enter' || evt.keyCode === 13) && bookingNo) {
    const newValue = evt || bookingId;
    setBookingNumber(newValue);
    if (newValue) {
      customAxios.post('/booking/getById', { id: newValue }).then((data) => {
        let result = data.data;

        if (!result.error) {
          setSellingNo(result.data.selling_id);
          setIsreleased(result.data.Isreleased ? result.data.Isreleased : 0);
          setBkngReleaseId(result.data._id);

          let {
            selling_id,
            buying_id,
            liner_ref_number,
            customer,
            preparedBY,
            //portnet_ref_number,
            yard_detail,
            sailing_schedule,
            remarks,
            haulage_service,
            haulage_option,
            haulage_point,
            quantity_20,
            quantity_40,
            weight_in_kgs_20,
            weight_in_kgs_40,
            si_cutoff,
            vgm_cutoff,
            vessel_cutoff,
            house_bl,

          } = result.data;

          let temp = {
            ...bookingDetails,
            ...buyingDetails,
            ...sellingDetails.selling_rates,
            selling_id: selling_id,
            buying_id: buying_id,
            current_date: date,
            carrier: result.data.carrier,
            customer: result.data.customer,
            preparedBY: preparedBY,
            liner_ref_number: liner_ref_number,
            //portnet_ref_number: portnet_ref_number,
            yard_detail: yard_detail,
            sailing_schedule: sailing_schedule,
            remarks: remarks,
            haulage_service: haulage_service,
            haulage_option: haulage_option,
            haulage_point: haulage_point,
            quantity_20: quantity_20,
            quantity_40: quantity_40,
            weight_in_kgs_20: weight_in_kgs_20,
            weight_in_kgs_40: weight_in_kgs_40,
            si_cutoff: si_cutoff ? si_cutoff : '',
            vgm_cutoff: vgm_cutoff ? vgm_cutoff : '',
            vessel_cutoff: vessel_cutoff ? vessel_cutoff : '',
            house_bl: house_bl,
            selling_id: result.data.selling_id
          };

          setBookingDetails({ ...temp });

          setAllSchedules([{ ...temp.sailing_schedule }]);
          setAllHaulage([...temp.haulage_point])
          setHaulageService(haulage_service === "Yes" ? true : false);
          setSellId(result.data.selling_id);
          handleSellingNo(/* { key: 'Enter', myValue: result.data.selling_id } */result.data.selling_id);

        } else {
          window.alert('No data found');
          confirmCancel();
        }
      });
    // } 
   /*  else if (evt.key === 'Enter' || evt.keyCode === 13) {
      confirmCancel();
    } */}
    else {
      confirmCancel();
    }
  };


  const handleSchedule = ({ key, value }) => {
    let temp = { ...schedule };

    temp[key] = value;
    if (allSchedules.length === 0) {
      setpoleta(temp.pol_eta);
    }
    setSchedule(temp);

    console.log(temp);

  };

  const addSchedule = () => {

    setAllSchedules([...allSchedules, schedule]);
    setBookingDetails({ ...bookingDetails, sailing_schedule: schedule });
    setSchedule({
      vessel: '',
      voyage: '',
      pol_eta: '',
      etd: '',
      eta: '',
    });
  };

  const editSchedule = () => {
    setSchedule({ ...allSchedules[0] });
    setAllSchedules([]);
  };
  // if (window.confirm('Are you sure to delete?')) {
  //   let temp = [...allHaulage];
  //   temp.splice(index, 1);
  //   setAllHaulage([...temp]);
  //   setBookingDetails({ ...bookingDetails, haulage_point: temp });
  // }
  const deleteSchedule = (index) => {
    if (window.confirm('Are you sure to delete?')) {

      let temp = [...allSchedules];
      temp.splice(index, 1);
      setAllSchedules([...temp]);
      setBookingDetails({ ...bookingDetails, sailing_schedule: {} });
    }
    // setSchedule(initScheduleValue);
    // setAllSchedules([]);
    // setBookingDetails({ ...bookingDetails, sailing_schedule: {} });
  };

  const handleSellingNo = (/* evt, */ sellId, fromClick = false) => {
    setIsOpenQuota(false);
    // if ((evt.key === 'Enter' || evt.keyCode === 13) && (sellingNo || evt.myValue)) {
    if (/* !evt.myValue */fromClick || sellId) {
      // setBookingDetails({
      //   ...bookingDetails,
      //   selling_id: sellId/* sellingNo ? sellingNo : evt.myValue, */
      // });
      console.log({ ...bookingDetails });
      console.log(sellId);
      customAxios
        .post('/selling/getById', { id: sellId/* sellingNo ? sellingNo : evt.myValue */ })
        .then((result) => {
          setSellId(sellId);
          if (!result.data.error && result.data.data) {
            let {
              source,
              loading_port,
              discharge_port,
              destination,
              cargo,
              freight,
              shipment,
              created_by,
              customer,
              date,
              valid_till,
              remarks,
              selling_rates,
              carrier,
              buying_rates,
              buying_id,
            } = result.data.data;
            setSchedule({
              vessel: '',
              voyage: '',
              pol: loading_port,
              pol_eta: '',
              etd: '',
              pod: discharge_port,
              eta: '',
            })
            setBuyingDetails({
              source,
              loading_port,
              discharge_port,
              destination,
              cargo,
              freight,
              shipment,
              charges: buying_rates,
            });
            setAllcharges(selling_rates)

            let allBase = selling_rates.map(item => item.base.replace(/[^0-9]/gi, ''));
            setBase(allBase);
            setSellingDetails({
              created_by: created_by,
              customer,
              date: date.substring(0, 10),
              valid_till: valid_till.substring(0, 10),
              remarks,
              selling_rates,
              carrier,
            });
            setBuyingNumber(buying_id);

            if (!sellId || fromClick)
              setBookingDetails({ ...bookingDetails, buying_id: buying_id });
            // setAllcharges(selling_rates);
          } else {
            window.alert('No data found');
            confirmCancel();
          }
        });
      // } 
    }
    else /* if (evt.key === 'Enter') */ {
      confirmCancel();
    }
  };

  const cancelDetails = () => {
    if (window.confirm('Are you sure to cancel?')) confirmCancel();
  };
  const confirmCancel = () => {
    setAllcharges([]);
    setBuyingDetails(initBuyValue);
    setSellingDetails(initSellValue);
    setBookingDetails(initBookingValue);
    setBuyingNumber('');
    setSellingNo('');
    setBookingNo('');
    setSchedule(initScheduleValue);
    setAllSchedules([]);
    setAllHaulage([]);
    setHaulageService(false);
  };

  const saveBooking = () => {
    let booking_count = parseInt(bookingDetails.quantity_20) + parseInt(bookingDetails.quantity_40);
    let hasError = false;

    let temp = {
      ...bookingDetails,
      ...buyingDetails,
      selling_rates: sellingDetails.selling_rates,
      current_date: date,
      selling_id: sellId,
      customer: sellingDetails.customer,
      preparedBY: preparedBY,
      carrier: sellingDetails.carrier,
      Isreleased: 0,
      buying_id: buyingNumber,
      sailing_schedule: allSchedules.length ? allSchedules[0] : { ...initScheduleValue },
      haulage_point: allHaulage.length ? allHaulage : { ...initHaulage },
    };
    const mandatoryFields = ['liner_ref_number', 'yard_detail', 'si_cutoff', 'vgm_cutoff', 'vessel_cutoff' ,'haulage_service' ];
    // const emptyFields = mandatoryFields.filter(field => field !== 'remarks' && !bookingDetails[field]);



// Exclude 'remarks' from mandatory fields
const emptyFields = mandatoryFields.filter(field => field !== 'remarks' && !temp[field]);

console.log('emptyFields:', emptyFields);
console.log('temp:', temp);
// Check if any mandatory field is empty
if (
  // Object.values(temp).includes('') ||
  // Object.values(temp.sailing_schedule).includes('') ||
  emptyFields.length > 0
) {
  setError('Please fill all mandatory fields: ' + emptyFields.join(', '));
  hasError = true;
} else if (booking_count !== allHaulage.length && haulageService) {
  setError("Please check the Load point count doesn't match");
  hasError = true;
} else {
  setError('');
}

    
    if (!allSchedules.length) {
      setError('Please add the sailing schedule');
      hasError = true;
    }
    

    if (!hasError) {
      if (!bookingNumber) {
        customAxios.post('/booking', temp).then((data) => {
          console.log(data);
          if (data.data.error) window.alert('Unable to save the details!!');
          else {
            window.alert('Detail saved successfully');
            setBkngReleaseId(data.data.id);
            setBookingNo(data.data.count);
            setBookingNumber(data.data.count);
          }
        });
      } else {
        customAxios.post('/booking/update', { id: bookingNumber, bookingDetails: temp }).then((data) => {
          if (data.data.error) window.alert('Unable to update the details!!');
          else {
            window.alert('Detail updated successfully');
          }
        });
      }
    }
  };

  const handleView = () => {
    setShowPreview(!showPreview);
  };

  let buttonEnabled = !!(
    newHaulage.point &&
    newHaulage.date &&
    newHaulage.ref
  );

  const addHaulage = () => {
    let temp = [...allHaulage];
    if (updateHaulage === '') {
      temp.splice(temp.length, 0, newHaulage);
    } else {
      temp.splice(updateHaulage, 0, newHaulage);
    }
    setAllHaulage(temp);
    setBookingDetails({ ...bookingDetails, haulage_point: allHaulage });
    //  setNewHaulage(initHaulage);
    isUpdateHaulage('');
  };

  const editHaulage = (index) => {
    let temp = [...allHaulage];
    setNewHaulage({ ...temp[index] });
    temp.splice(index, 1);
    setAllHaulage([...temp]);
    isUpdateHaulage(index);
  };

  const deleteHaulage = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      let temp = [...allHaulage];
      temp.splice(index, 1);
      setAllHaulage([...temp]);
      setBookingDetails({ ...bookingDetails, haulage_point: temp });
    }
  };

  const haulageDetails = ({ key, evt }) => {
    let temp = { ...newHaulage };
    temp[key] = evt && evt.value ? evt.value : '';
    //if(key === 'time' && evt != null){
    //  temp[key] = evt;
    //}
    setNewHaulage(temp);
  };

  const ReleaseId = () => {
    var result = window.confirm("Are you sure you want to release?");

    if (result) {
      customAxios
        .post('/booking/updaterelease', {
          "temp": {
            "_id": bkngReleaseId,
            "Isreleased": 1
          }
        })
        .then((data) => {

          if (data.data.error) window.alert('Unable to update the details!!');
          else {
            window.alert('Released successfully');
            setIsreleased(1)
          }
        });

    } else {
      // user clicked Cancel, do not proceed with the deletion
    }
  }

  useEffect(() => {
    let portUrl = '/port';
    customAxios.get(portUrl).then((ports) => {
      let temp = [...ports.data.data];
      let portDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setPorts([...portDetails]);

    });

    let vesselUrl = '/vessel';
    customAxios.get(vesselUrl).then((vessels) => {
      let temp = [...vessels.data.data];
      const dropdownstyle = { zIndex: 999 };
      let vesselDetails = temp.map((data) => {
        return { label: data.name, value: data.name, style: dropdownstyle }
      });
      setVessels([...vesselDetails]);

    });

    let bookingURL = '/booking/popup';
    customAxios.get(bookingURL).then(response => {
      console.log(response.data.data);
      setBkgData(response.data.data);
    }).catch(error => {
      console.log('Error fetching data:', error);
    });

    let quotationpopUrl = '/selling';
    customAxios.get(quotationpopUrl).then(response => {
      console.log(response);
      setquotpopdata(response.data.data);
    }).catch(error => {
      console.log('Error fetching data:', error);
    });


  }, []);

  useEffect(() => {
    console.log('bookingDetails', bookingDetails);
  }, [bookingDetails]);

  const handleClick = () => {
    window.alert('Hello');
  }

  const openbookingpop = () => {
    setIsOpenBkg(true);
    setIsOpenQuota(false);
  }

  const openquotationpop = () => {
    setIsOpenBkg(false);
    setIsOpenQuota(true);
  }

  const closePop = () => {
    setIsOpenBkg(false);
    setIsOpenQuota(false);
  }

  //booking popupDatas
  const columns = [
    {
      Header: "#",
      accessor: "id"
    },
    {
      Header: "BKG NO",
      accessor: "booking_id"
    },
    {
      Header: "Customer",
      accessor: "customer"
    },
    {
      Header: "POR",
      accessor: "source"
    },
    {
      Header: "POL",
      accessor: "pol"
    },
    {
      Header: "POD",
      accessor: "pod"
    },
    {
      Header: "DEL",
      accessor: "destination"
    },
  ]

  const bkgDATAPOPUP =
    bkgdata.length ? bkgdata.map((data, index) => {

      const bkgIdStyle = {
        color: '#D82C8D',
        cursor: 'pointer'
      }

      return {
        id: index + 1,
        booking_id: (
          <span onClick={(e) => getBookingDetail(data.booking_id, true)} style={bkgIdStyle}>{data.booking_id}</span>),
        customer: data.customer,
        source: data.source,
        pol: data.pol,
        pod: data.pod,
        destination: data.destination

      }
    }) : [];
  console.log(bkgDATAPOPUP);

  if (bkgDATAPOPUP.length === 0 || null) {
    bkgDATAPOPUP && bkgDATAPOPUP.push({
      id: '',
      booking_id: '',
      customer: '',
      source: 'No Data Available',
      pol: '',
      pod: '',
      destination: '',
    })
  }

  //quotation popup datas
  const quotationcolumns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Quotation No",
      accessor: "selling_id",
    },
    {
      Header: "customer",
      accessor: "customer",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    },
  ];

  const QuotationDATAPOPUP = quotpopdata.length ? quotpopdata.map((data, index) => {

    const SellIdStyle = {
      color: '#D82C8D',
      cursor: 'pointer'
    }
    return {
      id: index + 1,
      selling_id: (
        <span onClick={(e) => handleSellingNo(data.selling_id, true)} style={SellIdStyle}>{data.selling_id}</span>
      ),
      customer: data.customer,
      source: data.source,
      loading_port: data.loading_port,
      discharge_port: data.discharge_port,
      destination: data.destination,
    }
  }) : [];
  console.log(QuotationDATAPOPUP);
  if (QuotationDATAPOPUP.length === 0 || null) {
    QuotationDATAPOPUP && QuotationDATAPOPUP.push({
      id: '',
      selling_id: '',
      customer: '',
      source: '',
      loading_port: 'No Data Available',
      discharge_port: '',
      destination: '',
    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Booking <span className={classes.title}>Export / Sales</span></h5>
      </div>

      <Container className={classes.inputsContainer}>
        <Row className={classes.row}>

          <Col className={classes.input}>
            <span style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={openquotationpop}>Quotation#</span>
            <section>
              <div style={{ position: 'relative' }}>
                <p style={{ position: 'absolute', maxWidth: '60px', padding: '10px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>QUOT- </p>
                <input
                  type='text'
                  className={classes.inputText}
                  style={{ paddingLeft: '50px' }}
                  value={sellId}
                  onChange={(evt) => setSellId(evt.target.value)}
                  onKeyUp={(evt) => {
                    if (evt.key === 'Enter') {
                      handleSellingNo(evt.target.value, true);
                    }
                  }}
                />
              </div>
            </section>
          </Col>

          <Col className={classes.input}>
            <span style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={openbookingpop}>BKG.No#</span>
            <section>

              <div style={{ position: 'relative' }}>
                <p style={{ position: 'absolute', maxWidth: '45px', padding: '10px 4px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                <input
                  type='text'
                  className={classes.inputText}
                  style={{ paddingLeft: '45px', width: '154px' }}
                  value={bookingNumber}
                  // onChange={(evt) => handleBookingNo(evt)}
                  onChange={(evt) => setBookingNumber(evt.target.value)}
                  onKeyUp={(evt) => {
                    if (evt.key === 'Enter') {
                      getBookingDetail(evt.target.value, false);
                    }
                  }}
                />
              </div>

            </section>
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Date</p>
              <span>*</span>
            </section>
            <input

              className={classes.inputText}
              value={date}
              disabled={inputdisable ? true : false}
            // onChange={(e) => handleBookingDetails({ key: 'current_date', e: e.target })}

            />
          </Col>


          <Col className={classes.input}>
            <section>
              <p>Prepared By</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={preparedBY}
              disabled={true}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Customer</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.customer}
              disabled={true}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Cargo</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.cargo}
              disabled={true}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Freight</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.freight}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POR</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.source}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POL</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.loading_port}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>POD</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.discharge_port}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>DEL</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingDetails.destination}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Remarks</p>
              {/* <span>*</span> */}
            </section>
            <input
              type='text'
              className={classes.inputText}

              value={sellingDetails.remarks}
              disabled={true}
            />
          </Col>
          {/*<div style={tableStyle}>
          <table class='table'>
            <thead className={classes.theadDark}>
              <tr>
                <th scope='col'>#</th>
                <th scope='col' style={{ width: '40%' }}>
                  Chargess
                </th>
                <th scope='col'>Curr</th>
                <th scope='col'>Rate</th>
                <th scope='col'>Container Type</th>
                <th scope='col'>Prepaid</th>
              </tr>
            </thead>
            <tbody>
              {allCharges.map((data, index) => {
                return (
                  <tr style={{ backgroundColor: 'lightgray' }}>
                    <th scope='row'>{index + 1}</th>
                    <td>{data.charge}</td>
                    <td>{data.currency}</td>
                    <td>{data.rate}</td>
                    <td>{data.base}</td>
                    <td>Test</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
            </div>*/ }
          <Col lg='3' className={classes.input}>
            <section>
              <p>Buying#</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={buyingNumber}
              disabled={true}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Carrier</p>
              <span>*</span>
            </section>
            <input
              type='text'
              className={classes.inputText}
              value={sellingDetails.carrier}
              disabled={true}
            />
          </Col>
        </Row>
        <div style={{ width: '100%', height: '150px', overflow: 'auto', marginBottom: '18px' }}>
          <table class='table'>
            <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: '1' }}>
              <tr>
                <th scope='col' style={theadStyle}>#</th>
                <th scope='col' style={theadStyle}>
                  Charges
                </th>
                <th scope='col' style={theadStyle}>Curr</th>
                <th scope='col' style={theadStyle}>Container Type</th>
                <th scope='col' style={theadStyle}>Buying Rates</th>
                <th scope='col' style={theadStyle}>Selling Rate</th>
                <th scope='col' style={theadStyle}>Diff</th>
              </tr>
            </thead>
            <tbody>
              {allCharges.length
                ? allCharges.map((charge, index) => {
                  return (
                    <tr key={index} style={{ backgroundColor: 'lightgray' }}>
                      <th scope='row'>{index + 1}</th>
                      <td>{charge.charge}</td>
                      <td>{charge.currency}</td>
                      <td>{charge.base}</td>
                      <td>{charge.buyingRate == null ? 0 : parseFloat(charge.buyingRate).toFixed(2)}</td>
                      <td>{charge.rate ? parseFloat(charge.rate).toFixed(2) : 0}</td>
                      <td>{charge.rate - (charge.buyingRate ? charge.buyingRate : 0)}</td>
                    </tr>
                  );
                })
                : ''}
            </tbody>
          </table>
        </div>

        <Row>
          <Col className={classes.bknginput}>
            <section>
              <p>20'Cntr.Qty</p>
              <span>*</span>
            </section>
            <input
              value={bookingDetails.quantity_20}
              type='number'
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'quantity_20',
                  value: evt.target.value,
                })
              }
              disabled={!Base.includes('20')}
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>20'Cntr.wt.in Kgs</p>
              <span>*</span>
            </section>
            <input
              value={bookingDetails.weight_in_kgs_20}
              type='number'
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'weight_in_kgs_20',
                  value: evt.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>40'Cntr.Qty</p>
              <span>*</span>
            </section>
            <input
              value={bookingDetails.quantity_40}
              type='number'
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'quantity_40',
                  value: evt.target.value,
                })
              }
              disabled={!Base.includes('40') /* && !Base.includes('40HC') */}

            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>40'Cntr.wt.in.Kgs</p>
              <span>*</span>
            </section>
            <input
              value={bookingDetails.weight_in_kgs_40}
              type='number'
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'weight_in_kgs_40',
                  value: evt.target.value,
                })
              }
            />
          </Col>

          <Col className={classes.bknginput}>
            <section>
              <p>Liner Ref#</p>
              <span>*</span>
            </section>
            <input
              value={bookingDetails.liner_ref_number}
              type='text'
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'liner_ref_number',
                  value: evt.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>Remarks</p>
            </section>
            <input
              type='text'
              value={bookingDetails.remarks}
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'remarks',
                  value: evt.target.value,
                })
              }
            />
          </Col>
          <Col className={classes.bknginput} >
            <section style={{ marginBottom: '6%' }}>
              <p>Yard Details</p>
              <span>*</span>
            </section>
            <textarea
              value={bookingDetails.yard_detail}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'yard_detail',
                  value: evt.target.value,
                })
              }
            ></textarea>
          </Col>
          {/*
          <Col className={classes.input}>
            <section>
              <p>PortNet Ref#</p>
            </section>
            <input
              type='text'
              value={bookingDetails.portnet_ref_number}
              className={classes.inputText}
              onChange={(evt) =>
                handleBookingDetails({
                  key: 'portnet_ref_number',
                  value: evt.target.value,
                })
              }
            />
          </Col>
            */}

        </Row>
        <Row className={classes.row}>
          <Col lg='11'>
            <Row>
              <Col className={classes.input}>
                <section>
                  <p>Vessel</p>
                  <span>*</span>
                </section>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  className={classes.labels}
                  isClearable={true}
                  isSearchable={true}
                  options={vessels}
                  value={vessels.filter(
                    (option) => option.value === schedule.vessel
                  )}
                  onChange={(evt) =>
                    handleSchedule({ key: 'vessel', value: evt ? evt.value : "" })
                  }
                  onClick={handleClick}
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>Voyage</p>
                  <span>*</span>
                </section>
                <input
                  type='text'
                  className={classes.inputText}
                  value={schedule.voyage}
                  onChange={(evt) =>
                    handleSchedule({ key: 'voyage', value: evt.target.value })
                  }
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>POL ETA</p>
                  <span>*</span>
                </section>
                <input
                  type='date'
                  className={classes.inputText}
                  value={schedule.pol_eta}
                  // disabled={schedule.pol_eta  ? false : true}
                  max={poleta || null}
                  onChange={(evt) =>
                    handleSchedule({ key: 'pol_eta', value: evt.target.value })
                  }
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>POL</p>
                  <span>*</span>
                </section>
                <input
                  type='text'
                  className={classes.inputText}
                  value={schedule.pol}
                  disabled={true}
                  onChange={(evt) =>
                    handleSchedule({ key: 'pol', value: evt.target.value })
                  }
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>ETD</p>
                  <span>*</span>
                </section>
                <input
                  type='date'
                  className={classes.inputText}
                  value={schedule.etd}
                  onChange={(evt) =>
                    handleSchedule({ key: 'etd', value: evt.target.value })

                  }
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>POD</p>
                  <span>*</span>
                </section>
                <input
                  type='text'
                  className={classes.inputText}
                  value={schedule.pod}
                  disabled={true}
                  onChange={(evt) =>
                    handleSchedule({ key: 'pod', value: evt.target.value })
                  }
                />
              </Col>
              <Col className={classes.input}>
                <section>
                  <p>POD ETA</p>
                  <span>*</span>
                </section>
                <input
                  type='date'
                  className={classes.inputText}
                  value={schedule.eta}
                  onChange={(evt) =>
                    handleSchedule({ key: 'eta', value: evt.target.value })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg='1' style={{ display: 'flex', alignItems: 'flex-end' }}>
            <button
              className={classes.footerButton}
              disabled={buttonDisabled}
              onClick={addSchedule}
            >
              Add
            </button>
          </Col>
        </Row>

        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
          <table class='table'>
            <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: '1' }}>
              <tr>
                <th scope='col' style={theadStyle}>#</th>
                <th scope='col' style={theadStyle}>Vessel</th>
                <th scope='col' style={theadStyle}>Voyage</th>
                <th scope='col' style={theadStyle}>POL ETA</th>
                <th scope='col' style={theadStyle}>POL</th>
                <th scope='col' style={theadStyle}>ETD</th>
                <th scope='col' style={theadStyle}>POD</th>
                <th scope='col' style={theadStyle}>ETA</th>
                <th scope='col' style={theadStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allSchedules.map((sch, index) => {
                return (
                  <tr style={{ backgroundColor: 'lightgray' }}>
                    <th scope='row'>{index + 1}</th>
                    <td>{sch.vessel}</td>
                    <td>{sch.voyage}</td>
                    <td>{sch.pol_eta}</td>
                    <td>{sch.pol}</td>
                    <td>{sch.etd}</td>
                    <td>{sch.pod}</td>
                    <td>{sch.eta}</td>
                    <td>
                      <img
                        src={EditIcon}
                        className={classes.icon}
                        alt='edit_icon'
                        // style={{ borderRadius: '50%' }}
                        onClick={() => editSchedule(index)}
                      />
                      &nbsp;&nbsp;
                      <img
                        src={DeleteIcon}
                        className={classes.icon}
                        alt='delete_Icon'
                        // style={{ borderRadius: '50%' }}
                        onClick={() => deleteSchedule(index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Row>
          <Col className={classes.bknginput}>
            <section>
              <p>S/I Cutoff</p>
              <span>*</span>
            </section>
            <input
              type='datetime-local'
              className={classes.inputText}
              value={bookingDetails.si_cutoff}
              disabled={(poleta + "T00:00:00") ? false : true}
              max={(poleta + "T00:00:00") || null}
              onChange={(e) => handleBookingDetails({ key: 'si_cutoff', value: e.target.value })}
            />

          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>VGM Cutoff</p>
              <span>*</span>
            </section>
            <input
              type='datetime-local'
              className={classes.inputText}
              value={bookingDetails.vgm_cutoff}
              disabled={(poleta + "T00:00:00") ? false : true}
              max={(poleta + "T00:00:00") || null}
              onChange={(e) => handleBookingDetails({ key: 'vgm_cutoff', value: e.target.value })}
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>Vessel Cutoff</p>
              <span>*</span>
            </section>
            <input
              type='datetime-local'
              className={classes.inputText}
              value={bookingDetails.vessel_cutoff}
              disabled={(poleta + "T00:00:00") ? false : true}
              max={(poleta + "T00:00:00") || null}
              onChange={(e) => handleBookingDetails({ key: 'vessel_cutoff', value: e.target.value })}
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p>Haulage</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={haulage_services}
              value={haulage_services.filter(
                (option) => option.value === bookingDetails.haulage_service
              )}
              onChange={(e) => {
                handleBookingDetails({
                  key: 'haulage_service',
                  value: e ? e.value : '',
                });
                setHaulageService(e && e.value === "Yes" ? true : e && e.value === "" ? null : false);
              }
              }
            />
          </Col>
          <Col className={classes.bknginput}>
            <section>
              <p></p>
            </section>

            <Select className='mt-3'
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={haulageOptions}
              value={haulageOptions.filter(
                (option) => option.value === bookingDetails.haulage_option
              )}
              onChange={(e) =>
                handleBookingDetails({
                  key: 'haulage_option',
                  value: e ? e.value : '',
                })
              }
            />

          </Col>
          <Col className={classes.input}>
            <section>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="incCharges" name="incCharges" checked={charges_inc} onChange={(e) => setCharges_inc(e.target.checked)} />
                <label class="form-check-label" for="incCharges"> Include Charges </label>
              </div>
            </section>


            <section>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="house_bl" name="house_bl" checked={bookingDetails.house_bl} onChange={(e) => { handleBookingDetails({ key: 'house_bl', value: e.target.checked }) }} />
                <label class="form-check-label" for="house_bl"> House BL </label>
              </div>
            </section>

          </Col>
        </Row>
        {haulageService ? (
          <>
            <Row className={classes.row}>
              <Col className={classes.input} md='3'>
                <p>Load Point</p>
                <Select
                  components={{ IndicatorSeparator, Placeholder }}
                  styles={style}
                  isClearable={true}
                  isSearchable={true}
                  options={ports}
                  value={ports.filter(
                    (option) => option.value === newHaulage.point
                  )}
                  onChange={(e) => haulageDetails({ key: 'point', evt: e })}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Load Date & Time</p>
                <input
                  type='datetime-local'
                  className={classes.inputText}
                  value={newHaulage.date}
                  onChange={(e) => haulageDetails({ key: 'date', evt: e.target })}
                />
              </Col>
              <Col className={classes.input} md='2'>
                <p>Load Ref</p>
                <input
                  type='text'
                  className={classes.inputText}
                  value={newHaulage.ref}
                  onChange={(e) => haulageDetails({ key: 'ref', evt: e.target })}
                />
              </Col>
              <Col style={{ display: 'flex', alignItems: 'flex-end' }} md='1'>
                <button
                  className={classes.footerButton}
                  onClick={addHaulage}
                  disabled={!buttonEnabled}
                >
                  Add
                </button>
              </Col>
            </Row>

            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table class='table'>
                <thead className={classes.theadDark} style={{ position: 'sticky', zIndex: '1' }}>
                  <tr>
                    <th scope='col' style={theadStyle}>#</th>
                    <th scope='col' style={theadStyle}>Load Point</th>
                    <th scope='col' style={theadStyle}>Load Date & Time</th>
                    <th scope='col' style={theadStyle}>Load Ref</th>
                    <th scope='col' style={theadStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allHaulage.length
                    ? allHaulage.map((haul, index) => {
                      return (
                        <tr style={{ backgroundColor: 'lightgray' }}>
                          <th scope='row'>{index + 1}</th>
                          <td>{haul.point}</td>
                          <td>{haul.date}</td>
                          <td>{haul.ref}</td>
                          <td>
                            <img
                              src={EditIcon}
                              className={classes.icon}
                              alt='edit_icon'
                              // style={{ borderRadius: '50%' }}
                              onClick={() => editHaulage(index)}
                            />
                            &nbsp;&nbsp;
                            <img
                              src={DeleteIcon}
                              className={classes.icon}
                              alt='delete_Icon'
                              // style={{ borderRadius: '50%' }}
                              onClick={() => deleteHaulage(index)}
                            />
                          </td>
                        </tr>
                      );
                    })
                    : ''}
                </tbody>
              </table>
            </div>
          </>
        ) : ''}
        {/*<Modal
          size='md'
          show={showPreview}
          onHide={handleclose}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Booking Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Template
              sellingDetails={sellingDetails}
              buyingDetails={buyingDetails}
              bookingDetails={bookingDetails}
              bookingNo={bookingNo}
              charges_inc={charges_inc}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
                </Modal>*/}

        <span>{error}</span>
        <div className={classes.bookingBtns}>

          {/* {Isreleased == 0 ? <button className={classes.footerBtnRel} disabled={!bookingNo} onClick={ReleaseId}>Release
        </button>: null} */}

          {/* <button className={classes.footerButton}>ReUse</button> */}
          {Isreleased == 0 ? <button className={classes.footerButton} onClick={saveBooking}>
            {/* bookingNo */bookingNumber ? 'Update' : 'Save'}
          </button> : null}

          <button className={classes.footerButton}>
            <Template
              sellingDetails={sellingDetails}
              buyingDetails={buyingDetails}
              bookingDetails={bookingDetails}
              bookingNo={bookingNo}
              charges_inc={charges_inc}
              allHaulage={allHaulage}

            />
          </button>

          {Isreleased == 0 ? <button className={classes.footerButton} onClick={cancelDetails}>
            Cancel
          </button> : null}


        </div>
      </Container>

      {isOpenBkg ?
        <div
          className="modal show"
          style={{ display: 'block', position: 'fixed' }}
        >
          <Modal.Dialog style={{ marginRight: '0px', marginLeft: '0px', left: '10%' }}>
            <div class='modal-content' style={{ width: '215%', height: '500px', position: 'relative' }}>
              <Modal.Header onClick={closePop} closeButton>
                <Modal.Title style={{ fontSize: '15px' }}>BOOKING LIST</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ display: 'flex', justifyContent: 'center', height: '50%', overflowY: 'scroll' }}>
                <table style={{ width: '100%', border: '1px solid #C3C3C3', /* position: 'fixed' */ }}>
                  <tbody>
                    <CustomTable columns={columns} data={bkgDATAPOPUP} />


                    {/* <CustomTable columns={buyingcolumns} data={BuyDATAPOPUP}/> */}
                    {/* <tr style={{ backgroundColor: '#F2F2F2' }}>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>S.No</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Buy No</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Carrier</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POR</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POL</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POD</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>DEL</td>
                    </tr>
                    {buyingpopdata.map((data, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{index + 1}</td>
                        <td onClick={(e) => getBuyingDetails(data.rate_id, true)} style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3', cursor: 'pointer', color: '#D82C8D' }}>{data.rate_id}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.carrier}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.source}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.loading_port}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.discharge_port}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.destination}</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>Close</Button>
              </Modal.Footer>
            </div>
          </Modal.Dialog>
        </div>
        :
        isOpenQuota ?
          <div
            className="modal show"
            style={{ display: 'block', position: 'fixed' }}
          >
            <Modal.Dialog style={{ marginRight: '0px', marginLeft: '0px', left: '10%' }}>
              <div class='modal-content' style={{ width: '215%', height: '500px', position: 'relative' }}>
                <Modal.Header onClick={closePop} closeButton>
                  <Modal.Title style={{ fontSize: '15px' }}>QUOTATION LIST</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ display: 'flex', justifyContent: 'center', height: '50%', overflowY: 'scroll' }}>
                  {/* <p>Modal body text goes here.</p> */}
                  <table style={{ width: '100%', border: '1px solid #C3C3C3', /* position: 'fixed' */ }}>
                    <tbody>
                      <CustomTable columns={quotationcolumns} data={QuotationDATAPOPUP} />
                      {/*  <tr style={{ backgroundColor: '#F2F2F2' }}>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>S.No</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Quotation No</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Customer</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POR</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POL</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POD</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>DEL</td>
                      </tr>
                      {quotpopdata.map((data, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{index + 1}</td>
                          <td onClick={(e) => handleSellingNo(data.selling_id, true)} style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3', cursor: 'pointer', color: '#D82C8D' }}>{data.selling_id}</td>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.customer}</td>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.source}</td>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.loading_port}</td>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.discharge_port}</td>
                          <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.destination}</td>

                        </tr>
                      ))} */}
                    </tbody>
                  </table>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={closePop}>Close</Button>
                </Modal.Footer>
              </div>
            </Modal.Dialog>
          </div> : null}

    </>
  );
}
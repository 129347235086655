import React, { useEffect, useState } from 'react';
import customAxios from '../../../Axios';
import { Logo } from '../../../Images';
import './BLPdfTemplate.css';
import { Loader } from '../../../Images';
import Select from 'react-select';
import classes from './Shipping.module.css';

export default function Template({
  shippingDetails,
  buyingDetails,
  bookingDetails,
  //bookingNo,
  contDetail,
  shippingNo,
  details,

}) {

  const style = {
    control: (base) => ({
      ...base,
      height: '40px',
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };



  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const username = localStorage.getItem('name');

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`;

  let data = {
    customer: 'Test',
    // bookingNo: bookingNo,
    shippingNo: shippingNo,
    ...shippingDetails,
    ...buyingDetails,
    ...bookingDetails,
    details,
    today: today,
    username: username,
    contDetail: contDetail,
  };
  console.log(data);
  console.log('111', data.bookingNo);
  console.log('2222', data.booking_number);
  let count = 0;
  //if (data.container_detail.length) {
  //  let arr = bookingDetails.container_detail.split(',');
  //  for (let str of arr) {
  //    count = count + parseInt(str);
  //  }
  //}

  count = parseInt(data.quantity_20) + parseInt(data.quantity_40);

  //const [noContainers, setNoContainers] = useState(count);
  //const [containers, setContainers] = useState('');
  // const [details, setDetails] = useState(data);
  const [instructions, setInstructions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  let containers = '';
  if (count > 0) {
    if (data.quantity_20 > 0) {
      containers = containers + data.quantity_20 + ' x 20FT , ';
    }

    if (data.quantity_40 > 0) {
      containers = containers + data.quantity_40 + ' x 40FT ';
    }
  }

  function packageCount(array, key) {
    return array.reduce(function (r, a) {
      return r + parseInt(a[key]);

    }, 0);

  }

  function grossweight(array, key) {
    return array.reduce(function (r, a) {
      return r + parseInt(a[key]);
    }, 0);
  }


  function netweight(array, key) {
    return array.reduce(function (r, a) {
      return r + parseInt(a[key]);
    }, 0);
  }

  function volume(array, key) {
    return array.reduce(function (r, a) {
      return r + parseInt(a[key]);
    }, 0);
  }

  function NumInWords(number) {
    const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
    let word = '';

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
        }
      }

      tempNumber = number % (Math.pow(1000, i + 1));
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
    }
    return word;
  }
  var amt_in_words = NumInWords((packageCount(data.container_details, 'package_no'))) + "Only";

  const styles = `
  @media print{
    @page {
      table tbody tr td:before,
      table tbody tr td:after {
          content: "";
          height: 4px;
          display: block;
      }
    }
  #toPdf{
    padding: 20px;
}
.annexure{
  page-break-before: always;
}
.title{
    display:-webkit-flex;
    margin: 20px;
}
.nameAddress{
    margin-left: 20px;
}
.name{
    font-size: 1.3rem;
    font-weight: bold;
}
.address, .phone{
    font-size: 12px;
}
.header{
    font-weight: bold;
    text-transform: uppercase;
    // border-top: 2px solid #C3C3C3;
    padding: 10px;
   /*  margin-bottom: 20px; */
    display: flex !important;
    justify-content: center !important;
}
.img{
    height: 40px !important;
    width: auto;
}
.container{
    display:-webkit-flex;
    margin: 0 20px;
}
.tableContainer{
    display:flex;
    margin: 30px 20px;
}
.keyValue{
    width: 50%;
    display:-webkit-flex;
}
.remarks{
    display:flex;
    width: 100%;
}
.dateFrom{
    display:flex;
    flex-direction: column;
    width: 50%;
}
.date, .from{
    display:flex;
    width: 100%;
}
.key{
    width: 50%;
    padding: 0px;
    font-weight: bold;
    text-transform: uppercase;
}
.remarkTitle{
    padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
}
.remarks{
   display:flex;
   flex-direction: column;
}
.remark{
    padding: 0 10px;
}
.value{
    width: 50%;
    padding: 0px;
}
table{
  border-spacing: 0px;
}
th,
td {
    border: 1px solid #C3C3C3;
    border-collapse: collapse;
}
th{
    text-align: center !important;
}
td{
    padding-left: 10px;
    font-size: 0.9rem;
}
.instData{
    width: 90%;
}
.notes{
  margin-top: 130px;
}

}
  `;
  const [isLoading, setIsLoading] = useState(false);
  const print = () => {
    let html = document.getElementById('toPdf');
    let style = styles;
    let content;
    if (html.outerHTML) content = html.outerHTML;
    else if (XMLSerializer)
      content = new XMLSerializer().serializeToString(html);
    //console.log('inside', content);
    setIsLoading(true);
    customAxios
      .post('/shipping/pdf', {
        template: content,
        style: style,
      },
        {
          headers: {
            mode: 'no-cors',
          }
        })
      .then((resp) => {
        if (resp.status == 200) {
          customAxios
            .get('/shipping/getPdf', { responseType: 'blob', mode: 'no-cors' })
            .then((resp) => {
              //console.log('pdf resp', resp);
              const blob = new Blob([resp.data], { type: 'application/pdf', mode: 'no-cors' });
              //const url = URL.createObjectURL(blob);
              //console.log('blob file', blob);
              //window.open(url);

              const fileName = `Shipping RILE-${data.booking_number}.pdf`;


              // blob ready, download it
              let link = document.createElement('a');
              link.download = 'Shipping RILE-' + data.booking_number + '.pdf';
              link.href = URL.createObjectURL(blob);
              link.click();
              // delete the internal blob reference, to let the browser clear memory from it
              URL.revokeObjectURL(link.href);
              setIsLoading(false);


              customAxios
                .post('/shipping/getById', {
                  // id: bookingNo 
                  id: data.booking_number
                })
                .then((data) => {
                  console.log(data);
                  if (data.data.data.booking_number) {
                    const formData = new FormData();
                    formData.append('name', data.data.data.booking_number);
                    formData.append('images', blob, fileName);

                    customAxios
                      .post('/exp/upload', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      })
                      .then((uploadResponse) => {
                        console.log('File uploaded successfully!');
                        console.log('Response:', uploadResponse.data);
                      })
                      .catch((err) => console.log(err.message || 'Some error'));
                  } else {
                    console.log('PDF upload not allowed.');
                  }
                })
                .catch((err) => console.log(err.message || 'Some error'));

            })
            .catch((err) => console.log(err.message || 'Some error'));
        }
      });
  };

  useEffect(() => {
    // customAxios.post('/instruction/get', { key: 'default' }).then((resp) => {
    //   if (resp && resp.data && resp.data.data && resp.data.data.instructions)
    //     setInstructions([...resp.data.data.instructions]);
    //   console.log(data);
    // });
  }, []);

  //splitting string to an array and pushed datas to table 
  const desc = data.mark_description;
  const descArr = desc.split("\n");
  for (let i = 0; i < data.container_details.length; i++) {
    data.container_details[i].desc = descArr[i];
  }

  const mrkNo = details.mark_and_no;
  const markNum = mrkNo.split("\n");
  for (let i = 0; i < data.container_details.length; i++) {
    data.container_details[i].mrkno = markNum[i];
  }
  console.log(data.container_details);

  return (
    <>
      <div id='toPdf' style={{ fontSize: '7px', padding: '20px' }}>

        { /*<div id='pageHeader'>
          <div className='header' style={{fontSize: '10px', fontWeight: 'bold', textTransform: 'uppercase', borderBottom: '2px solid #C3C3C3', marginBottom: '20px', textAlign: 'center'}}>COMBINED TRANSPORT BILL OF LADING</div>
            </div> */ }

        <div id='pageContent'>
          <div className='container' style={{ display: '-webkit-flex', margin: '0 10px' }}>
            <div style={{ width: '50%', display: 'flex' }}>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ margin: '0px', color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', paddingBottom: '5px' }}>{`Shipper :`}</div>
                <div style={{ margin: '0px', width: '100%' }}>{`${data.shipper_name}`} <br></br> {`${data.shipper_address}`}</div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ margin: '0px', color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', paddingBottom: '5px' }}>{`Consignee (If “To order” so indicate) :`}</div>
                <div style={{ margin: '0px', width: '100%' }}><div style={{ paddingBottom: '0px' }}>{`${data.consignee_name}`}</div><br /><div style={{ paddingTop: '1px', paddingBottom: '5px' }}> {`${data.consignee_address}`}</div></div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ margin: '0px', color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', paddingBottom: '5px' }}>{`Notify Party (no claim shall attach for failure to notify) :`}</div>
                <div style={{ margin: '0px', width: '100%' }}><div style={{ paddingBottom: '0px' }}>{`${data.notify_party_name}`}</div><br /><div style={{ paddingTop: '1px', paddingBottom: '5px' }}>{`${data.notify_party_address}`}</div></div>
              </div>
              <div style={{ width: '100%', display: '-webkit-flex' }}>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '0px' }}>{`Pre-carriage by :`}</div>
                  <div className='value' style={{ width: '100%', padding: '0px' }}></div>
                </div>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '0px' }}>{`Place of Receipt :`}</div>
                  <div className='value' style={{ width: '100%', padding: '0px' }}>{`${data.source}`}</div>
                </div>
              </div>
              <div style={{ width: '100%', display: '-webkit-flex' }}>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '0px' }}>{`Ocean Vessel :`}</div>
                  <div className='value' style={{ width: '100%', padding: '0px' }}>{`${data.vessel}`}  {`${data.voyage}`}</div>
                </div>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '1px' }}>{`Port of Loading  :`}</div>
                  <div className='value' style={{ width: '100%', padding: '2px' }}>{`${data.loading_port}`}</div>
                </div>
              </div>
              <div style={{ width: '100%', display: '-webkit-flex' }}>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '0px' }}>{`Port of Discharge :`}</div>
                  <div className='value' style={{ width: '100%', padding: '0px' }}>{`${data.discharge_port}`}</div>
                </div>
                <div style={{ width: '50%', display: 'flex', padding: '5px' }}>
                  <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase', padding: '0px' }}>{`Place of Delivery  :`}</div>
                  <div className='value' style={{ width: '100%', padding: '0px' }}>{`${data.destination}`}</div>
                </div>
              </div>
            </div>

            <div style={{ width: '50%', display: 'flex' }}>
              <div style={{ width: '100%', display: '-webkit-flex', padding: '5px' }}>
                <div className='key' style={{ color: '#057db1', width: '50%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`B/L Number :`}</div>
                <div className='value' style={{ width: '50%' }}>RUKEX{`${data.booking_number}`}</div>
              </div>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                  <img src={Logo} alt='Logo' className='img' style={{ width: '60px', height: '60px' }} />

                  <p className='name' id='heading' style={{ fontFamily: 'Times New Roman', fontSize: '12px', fontWeight: 'bold', marginBottom: '0%' }}  >REUDAN INTERNATIONAL LTD</p>
                  <p className='name' style={{ fontSize: '6px', padding: '-2px' }}>LONDON</p>
                  <p className='name' style={{ fontSize: '10px', fontWeight: 'bold', padding: '0px' }}>Ocean Bill of Lading</p>
                  <p className='name' style={{ fontSize: '7px', padding: '0px' }}>NOT NEGOTIABLE UNLESS CONSIGNED“TO ORDER”</p>
                </div>
                <p className='name' style={{ fontSize: '6px', padding: '5px' }}>RECEIVED by the Carrier the Goods as specified below in apparent good order and condition unless
                  otherwise stated to be transported to such place as agreed, authorised or permitted herein and subject to
                  all terms and conditions appearing on the front and reverse of this Bill of Lading to which the Merchant
                  agrees by accepting this Bill of Lading, any local privileges and customs notwithstanding.
                  The Particulars given below as stated by the shipper and the weight, measure, quantity, condition,
                  contents and the value of Goods are unknown for the Carrier.
                  In WITNESS whereof one (1) original Bill of Lading has been signed if not otherwise stated hereafter,
                  the same being accomplished the other(s). if any, to be void. If required by the Carrier one (1) original
                  Bill of Lading must be surrendered duly endorsed in exchange for the Goods or Delivery
                  Order.</p>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                {/* <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`For delivery please contact :`}</div>
                <div className='value' style={{ width: '100%' }}>REUDAN INTERNATIONAL LTD<br></br>
                  OFFICE 102, 15 MAIN DRIVE, EAST LANE BUSINESS PARK, WEMBLEY - HA9
                  7NA<br></br>
                  Phone : +44 (0) 208 904 9616
                </div> */}
                <div className='key' style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>Forwarding Agent:</div>
                <div>{data.forwarding_agent}</div>
              </div>
            </div>
          </div>

          <div className='tableContainer' style={{ display: 'flex', margin: '10px 10px' }}>
            <table border="0" cellspacing='0' style={{ width: '100%', borderSpacing: '0px', fontSize: '0.9rem' }}>
              <tr>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Marks & Numbers</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>No. of Pkgs</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Description of Goods & Packages</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Gross Wt. (Kgs)</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Net Wt. (Kgs)</th>
                <th style={{ color: '#057db1', fontSize: '7px' }}>Vol. (CBM) </th>

              </tr>
              {console.log(data.container_details)}


              {data.container_details.length <= 3 ? data.container_details.map((container_detail, index) => {
                // console.log((packageCount(data.container_details, 'package_no')));

                console.log(index);
                console.log(container_detail);
                if (index == 0) {
                  return (
                    <tr>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1 ? '1px solid #C3C3C3' : 'none') }} width="25%">{container_detail.container_no || " "}<br></br> Seal No: {container_detail.seal_no || " "}{/* <br></br> Mark&No: {container_detail.mrkno || " "} */}

                      </td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1 ? '1px solid #C3C3C3' : 'none') }} width="10%">{container_detail.package_no || " "} {container_detail.package_type || " "}</td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1/* > 4 */ ? '1px solid #C3C3C3' : 'none') }} width="10%">{/* {data.mark_description || " "} */}
                        {container_detail.desc}
                      </td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center', borderBottom: (index == data.container_details.length > 4 ? '1px solid #C3C3C3' : 'none') }} rowspan="7" width="10%">  {isNaN(grossweight(data.container_details, 'gross_wt')) ? "" : grossweight(data.container_details, 'gross_wt').toFixed(2)}</td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center', borderBottom: (index == data.container_details.length > 4 ? '1px solid #C3C3C3' : 'none') }} rowspan="7" width="10%"> {isNaN(netweight(data.container_details, 'net_wt')) ? "" : netweight(data.container_details, 'net_wt').toFixed(2)}</td>
                      <td style={{ fontSize: '7px', borderTop: 'none', textAlign: 'center', borderBottom: (index == data.container_details.length > 4 ? '1px solid #C3C3C3' : 'none') }} rowspan="7" width="10%">    {isNaN(volume(data.container_details, 'measurement')) ? "" : volume(data.container_details, 'measurement').toFixed(2)}</td>
                    </tr>
                  
                  );
                 
                }
                else {
                  if (index <= 3) {
                    return (
                      <tr>
                        <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1 ? 'none' : 'none') }} width="25%">{container_detail.container_no || " "}<br></br> Seal No: {container_detail.seal_no || " "}{/* <br></br> Mark&No: {container_detail.mrkno || " "} */}

                        </td>

                        <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1 ? 'none' : 'none') }}>{container_detail.package_no || " "} {container_detail.package_type || " "}</td>
                        <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', borderBottom: (index == data.container_details.length - 1 ? 'none' : 'none') }} width="10%">{/* {data.mark_description || " "} */}
                          {container_detail.desc || " "}
                        </td>
                      </tr>
                      
                    );
                  }
                }

              }) :
                (
                  <>
                    <tr>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center', height: '200px' }} width="25%">As per Annexure</td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center' }} width="10%">{isNaN(packageCount(data.container_details, 'package_no')) ? "" : packageCount(data.container_details, 'package_no') + " " + (data.container_details[0] ? data.container_details[0].package_type : '')}</td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center' }} width="10%">As per Annexure

                      </td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center' }} rowspan="5" width="10%">{isNaN(grossweight(data.container_details, 'gross_wt')) ? "" : grossweight(data.container_details, 'gross_wt').toFixed(3)}</td>
                      <td style={{ fontSize: '7px', borderRight: 'none', borderTop: 'none', textAlign: 'center' }} rowspan="5" width="10%">{isNaN(netweight(data.container_details, 'net_wt')) ? "" : netweight(data.container_details, 'net_wt').toFixed(3)}</td>
                      <td style={{ fontSize: '7px', borderTop: 'none', textAlign: 'center' }} rowspan="5" width="10%">{isNaN(volume(data.container_details, 'measurement')) ? "" : volume(data.container_details, 'measurement').toFixed(3)}</td>
                    </tr>
                  </>
                )}

{data.container_details.length <= 3 ? [
  <tr>
    <td colspan={1} style={{borderRight: 'none', borderLeft: '1px solid #C3C3C3', borderTop: 'none', borderBottom: 'none'}}>&nbsp;</td>
    <td colspan={1} style={{borderRight: 'none', borderLeft: '1px solid #C3C3C3', borderTop: 'none', borderBottom: 'none'}}>&nbsp;</td>
    <td colspan={1} style={{borderRight: 'none', borderLeft: '1px solid #C3C3C3', borderTop: 'none', borderBottom: 'none'}}>&nbsp;</td>

  </tr>,
...data.container_details.map((container_detail, index) => (
          <tr key={`markNoRow_${index}`}>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? '1px solid #C3C3C3' : 'none') }}>
              {/* Mark&No: */} {container_detail.mrkno || ''}
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? '1px solid #C3C3C3' : 'none') }}>
              &nbsp;
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? '1px solid #C3C3C3' : 'none') }}>
              &nbsp;
            </td>
          </tr>
        ))] : null}
            </table>
          </div>

          <div className='tableContainer' style={{ display: 'flex', margin: '10px 10px' }}>
            <table cellspacing='0' style={{ width: '100%', borderSpacing: '0px', fontSize: '0.9rem' }}>
              <tr>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Description</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Rating</th>
                <th style={{ borderRight: 'none', color: '#057db1', fontSize: '7px' }}>Prepaid</th>
                <th style={{ color: '#057db1', fontSize: '7px' }}>Collect</th>
              </tr>
              { /*data.container_details.map((container_detail, index) => {
                        return (
                        <tr>
                            <td style={{fontSize: '7px'}}>{container_detail.container_no}<br></br>{container_detail.seal_no}</td>
                            <td style={{fontSize: '7px'}}>{container_detail.package_no}</td>
                            <td style={{fontSize: '7px'}}>{container_detail.mark_description}</td>
                            <td style={{fontSize: '7px'}}>{container_detail.gross_wt}</td>
                            <td style={{fontSize: '7px'}}>{container_detail.net_wt}</td>
                        </tr>
                        );
                  })*/}
            </table>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 10px' }}>
            <div style={{ width: '40%', display: 'flex', padding: '5px' }}>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`Jurisdiction and Law Clause`}</div>
                <div style={{ width: '100%' }}>{`The contract by or contained in this Bill of Lading is
                      Governed by the Law of Singapore and any claim or dispute
                      arising hereunder or in connection herewith shall be 
                      determined by the courts in Singapore and no other courts.`}</div>
              </div>
            </div>

            <div style={{ width: '30%', display: 'flex', padding: '5px' }}>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`Exchange Rate :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
            </div>

            <div style={{ width: '30%', display: 'flex' }}>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`Payable at :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`No. of original B/L(s) :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`Place and date of issue :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
            </div>
          </div>

          <div className='container' style={{ display: '-webkit-flex', margin: '0 10px' }}>
            <div style={{ width: '40%', display: 'flex', padding: '5px' }}>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '100%', fontWeight: 'bold', textTransform: 'uppercase', fontSize: '7px' }}>{`Excess Value Description : Refer to Clause 6(4)(B) + (C)
                          on reverse side`}</div>
              </div>
            </div>

            <div style={{ width: '30%', display: 'flex', padding: '5px' }}></div>

            <div style={{ width: '30%', display: 'flex' }}>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`Signed on behalf of the Carrier :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`REUDAN INTERNATIONAL LTD`}</div>
              </div>
              <div style={{ width: '100%', display: 'flex', padding: '5px' }}>
                <div style={{ color: '#057db1', width: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`By :`}</div>
                <div style={{ width: '100%' }}></div>
              </div>
            </div>
          </div>

          {data.container_details.length > 3 ? (

            <>
              <div className='annexure'>
                {/* <div className='header' style={{ fontSize: '7px', fontWeight: 'bold', textTransform: 'uppercase', padding: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}><br></br><p style={{textAlign: 'center'}}>Annexure</p><span style={{float: 'right'}}>B/L NUMBER&nbsp;:&nbsp;RUKEX-{`${data.booking_number}`}</span> */}
                  <table style={{width: '100%'}}>
                    <tr>
                      <th colspan={6} style={{ borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>&nbsp;</th>
                    </tr>
                    <tr>
                      <td  style={{ fontWeight: 'bold', width: '50%', textAlign: 'right', fontSize: '7px', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>Annexure</td>
                      <td  style={{ fontWeight: 'bold', width: '30%',textAlign: 'right', fontSize: '7px', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', textAlign: 'right' }}>B/L Number&nbsp;:</td>
                      <td  style={{ fontWeight: 'bold', width: '20%', fontSize: '7px', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', textAlign: 'left' }}>RUKEX{`${data.booking_number}`}</td>
                    </tr>
                  </table>
                {/* </div> */}
                <div className='tableContainer' style={{ display: 'flex', margin: '10px 10px' }}>
                  <table cellspacing='0' style={{ width: '100%', borderSpacing: '0px', fontSize: '0.9rem' }}>
                    <tr>
                      <th style={{ borderRight: 'none', fontSize: '7px' }}>Marks & Numbers</th>
                      <th style={{ borderRight: 'none', fontSize: '7px' }}>No. of Pkgs</th>
                      <th style={{ borderRight: 'none', fontSize: '7px' }}>Description of Goods & Packages</th>
                      <th style={{ borderRight: 'none', fontSize: '7px' }}>Gross Wt. (Kgs)</th>
                      <th style={{ borderRight: 'none', fontSize: '7px' }}>Net Wt. (Kgs)</th>
                      <th style={{ fontSize: '7px' }}>Vol. (CBM)</th>
                    </tr>
                    {data.container_details.map((container_detail, index) => {
                      if (index == 0) {
                        return (
                          <tr>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }} width="25%">{container_detail.container_no || " "}<br></br> Seal No: {container_detail.seal_no}{/* <br></br> Mark&No: {container_detail.mrkno} */}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{container_detail.package_no} {container_detail.package_type}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }} width="25%">{container_detail.desc}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{parseFloat(container_detail.gross_wt).toFixed(3)}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{container_detail.net_wt ? parseFloat(container_detail.net_wt).toFixed(3) : ' '}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none' }}>{container_detail.measurement ? parseFloat(container_detail.measurement).toFixed(3) : ' '}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }} width="25%">{container_detail.container_no}<br></br> Seal No: {container_detail.seal_no || " "}{/* <br></br> Mark&No: {container_detail.mrkno} */}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{container_detail.package_no || " "} {container_detail.package_type || " "}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }} width="25%">{container_detail.desc || " "}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{parseFloat(container_detail.gross_wt).toFixed(3) || " "}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none', borderRight: 'none', }}>{container_detail.net_wt ? parseFloat(container_detail.net_wt).toFixed(3) || " " : ' '}</td>
                            <td style={{ fontSize: '7px', borderBottom: 'none', borderTop: 'none' }}>{container_detail.measurement ? parseFloat(container_detail.measurement).toFixed(3) || " " : ' '}</td>
                          </tr>
                        );
                      }

                    })}

                    <tfoot>
                      <tr>
                        <td style={{ fontSize: '7px', borderRight: 'none', }} colspan="1">&nbsp;</td>
                        <td style={{ fontSize: '7px', borderRight: 'none', }} colspan="1">{isNaN(packageCount(data.container_details, 'package_no')) ? "" : packageCount(data.container_details, 'package_no') + " " + (data.container_details[0] ? data.container_details[0].package_type : '')} </td>
                        <td style={{ fontSize: '7px', borderRight: 'none', }} colspan="1">&nbsp;</td>
                        <td style={{ fontSize: '7px', borderRight: 'none', }} >  {/* Total Gross weight: */}   {isNaN(grossweight(data.container_details, 'gross_wt')) ? "" : grossweight(data.container_details, 'gross_wt').toFixed(3)} <br></br></td>
                        <td style={{ fontSize: '7px', borderRight: 'none', }}> {/* Total Net weight: */}  {isNaN(netweight(data.container_details, 'net_wt')) ? "" : netweight(data.container_details, 'net_wt').toFixed(3)}<br></br></td>
                        <td style={{ fontSize: '7px' }}> {/* Total Volume: */}{isNaN(volume(data.container_details, 'measurement')) ? "" : volume(data.container_details, 'measurement').toFixed(3)}<br></br></td>
                      </tr>
                    </tfoot>

                    {data.container_details && 
                    [
                      <tr>
                        <td colspan={1} style={{borderTop: 'none', borderRight: 'none', borderBottom: 'none'}}>&nbsp;</td>
                        <td colspan={1} style={{borderTop: 'none', borderRight: 'none', borderBottom: 'none'}}>&nbsp;</td>
                        <td colspan={1} style={{borderTop: 'none', borderRight: 'none', borderBottom: 'none'}}>&nbsp;</td>
                        <td colspan={1} style={{borderTop: 'none', borderRight: 'none', borderBottom: 'none'}}>&nbsp;</td>
                        <td colspan={1} style={{borderTop: 'none', borderRight: 'none', borderBottom: 'none'}}>&nbsp;</td>
                        <td colspan={1} style={{borderTop: 'none',  borderBottom: 'none'}}>&nbsp;</td>
                      </tr>,
                      ...data.container_details.map((container_detail, index) => (
          <tr key={`markNoRow_${index}`}>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              {/* Mark&No: */} {container_detail.mrkno || ''}
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              &nbsp;
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              &nbsp;
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              &nbsp;
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: 'none', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              &nbsp;
            </td>
            <td colSpan="1" style={{ fontSize: '7px',borderRight: '1px solid #C3C3C3', borderTop: 'none', borderBottom: (index === data.container_details.length - 1 ? 'none' : 'none') }}>
              &nbsp;
            </td>
          </tr>
        ))]}
                  </table>
                </div>
              </div>
            </>
          )
            : ''}
        </div>
      </div>

      <div onClick={print}>
        {isLoading ?
          <img src={Loader} width="25px" alt="" /> : 'Download PDF'
        }
      </div>
    </>
  );
}
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { icon_view } from '../../../Images';
import './acnts.module.css';
import classes from './accountsCssCls.module.css';
import CustomTable from '../../customTable';

export default function ReceiptProfoDebitNt() {

  const initProdetails = {
    bkg_no: '',
    to_customer_name: '',
    current_date: '',
    remarks: '',
    currency: '',
  }

  const inittableData = {
    _id: '',
    invoice_no: '',
    curr: '',
    date: '',
    total_amt: '',
    utr_no: '',
    balance_amt: '',
    rec_amt: '',
    remarks: '',
  }

  const [tableLists, setTableLists] = useState([]);
  const [showList, setShowList] = useState(true);
  const [DebitDetails, setDebitDetails] = useState(initProdetails);
  const [constno, setconstno] = useState('');
  const [receivedAmnt, setReceivedAmnt] = useState();
  const [balanceAmnt, setBalanceAmnt] = useState();
  const [showTable, setShowTable] = useState(false);
  const [utr_no, setutr_no] = useState();
  const [remarks, setremarks] = useState();
  const [tableData, setTableData] = useState(inittableData);
  const [showcontain, setshowcontain] = useState(false);
  const [tableview, settableview] = useState();
  const [totalamt, settotalamt] = useState(true);
  const [viewHistoryDetails, setViewHistoryDetails] = useState(false);
  const [amtCalc, setamtCalc] = useState();
  const [bal, setbal] = useState();
  const [isPaid, setIsPaid] = useState();
  const [refnumber, setRefnumber] = useState([]);
  const [searchList, setSearchList] = useState('');
  const [selectedVal, setSelectedVal] = useState('');


 

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const editCnopsList = (index, ref_no) => {
    setShowList(false);
    setShowTable(false);
    // setTableLists(ref_no);
    setshowcontain(true);

    customAxios.post('/voucher/proformadebitnote/getbyID', { ref_no: ref_no }).then((result) => {
      if (!result.data.error && result.data.data) {
        let {
          ref_no,
          total_amt,
          currency,
          bkg_no,
        } = result.data.data


        setDebitDetails({
          ref_no: ref_no,
          total_amt: total_amt,
          currency: currency,
          bkg_no:bkg_no,
        })
      }
      else {
        window.alert('No data found');
      }
    })
    customAxios
      .post("/voucher/proformadebitnote/receipt/getbyID", { invoice_no: ref_no })
      .then((result) => {
        if (!result.data.error && result.data.data) {


          let {
            _id,
            invoice_no,
            curr,
            date,
            total_amt,
            utr_no,
            balance_amt,
            rec_amt,
            remarks,

          } = result.data.data;
          setbal(balance_amt);

          setTableData(result.data.data)


          setBalanceAmnt(result.data.data && result.data.data.length > 0 ? result.data.data[result.data.data.length - 1].balance_amt.toFixed(2) : '')

          setamtCalc(result.data.data && result.data.data.length > 0 ? result.data.data[result.data.data.length - 1].balance_amt : '')

          setbal(result.data.data && result.data.data.length > 0 && result.data.data[result.data.data.length - 1].balance_amt === 0 ? true : false)
          setIsPaid(result.data.data && result.data.data.length > 0 && result.data.data[result.data.data.length - 1].balance_amt === 0 ? true : false)



        } else {
          window.alert('No data found');
        }
      });

  }

  //received nd balance amount function
  const handleamountR = (event) => {
    setReceivedAmnt(event.target.value);
    var bal = amtCalc == 0 ? DebitDetails.total_amt - event.target.value : amtCalc - event.target.value;
    setBalanceAmnt(bal.toFixed(2));

  }

  const showpayList = (index, tD) => {
    setShowTable(false);
    setShowList(false);
    setViewHistoryDetails(true);
    setshowcontain(false)
    settableview({ ...tD });

  }

  const payhistory = () => {
    setShowList(false);
    setShowTable(true);

    customAxios
      .post("/voucher/proformadebitnote/receipt/getbyID", { invoice_no: DebitDetails.ref_no })
      .then((result) => {

        setTableData(result.data.data);

      });
  }

  const payment = () => {

    if (!utr_no) {
      window.alert('UTR number is required');
      return;
    }

    customAxios
      .post("/voucher/proformadebitnote/receipt/save",
        {
          "invoice_no": DebitDetails.ref_no,
          "curr": DebitDetails.currency,
          "date": date,
          "total_amt": DebitDetails.total_amt,
          "utr_no": utr_no,
          "balance_amt": balanceAmnt,
          "rec_amt": receivedAmnt,
          "remarks": remarks,
          "bkg_no":DebitDetails.bkg_no,
        })
      .then((data) => {

        if (data.data.error) window.alert('Unable to save the details!!');
        else {
          window.alert('Paid successfully');
          setshowcontain(true);
          window.location.reload();

        }
      });
  }
  const cancel = () => {
    window.location.reload();
  }


  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "WEM/ WBY " + " ";
    setconstno(profomainvoiceAutogen)

    let url = '/voucher/proformadebitnote/getlist';
    customAxios.get(url).then((response) => {

      setTableLists(response.data.data);
      setSearchList(response.data.data);

    });

    let refNumUrl = '/voucher/proformadebitnote/getlist';
    customAxios.get(refNumUrl).then((refNos) => {
      let temp = [...refNos.data.data];
      let refno = temp.map((data) => {
        return { label: data.ref_no, value: data.ref_no };
      })
      setRefnumber([...refno]);

    })
  }, [showList]);

  const handleSearch = (option) => {
    if (option == null) {
      let url = '/voucher/proformadebitnote/getlist';
      customAxios.get(url).then((tableLists) => {

        setTableLists(tableLists.data.data);
        setSearchList(tableLists.data.data);
        setSelectedVal(null);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchList.filter(item => item.ref_no === (option ? option.value : ' '));
      setTableLists(filterdata);
    }
  }

  
  const columns = [

    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "BKG.NO",
      accessor: "bkg_no",

    },
    {
      Header: "REF#",
      accessor: "ref_no",
    },
    {
      Header: "To",
      accessor: "to_address",
    },
    {
      Header: "Curr",
      accessor: "currency",
    },
    {
      Header: "Total Amount",
      accessor: "total_amt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  { console.log(tableLists); }
  
  const DATA = tableLists.filter(item => item.is_approved === 1).map((item, index) => {
    const refNo = item.ref_no;
    // { console.log(DATA, refNo); }

    return {
      id: index + 1,
      bkg_no:'RUKEX' + item.bkg_no,
      ref_no: 'RILWEM/FE/' + item.ref_no,
      to_address: item.to_address,
      currency: item.currency,
      total_amt: item.total_amt.toFixed(2),
      action: (
        <div style={{ textAlign: 'center' }}>
          {console.log(refNo)}
          <button
            className={classes.footerButton}
            onClick={() => editCnopsList(index, item.ref_no)}
          >
            Receipt
          </button>
          {console.log(item.refNo)}
        </div>
      )
    };

  });
  return (
    <>
      <div className={classes.container}>
        <h5>Proforma DebitNote<span className={classes.title}>Export / Proforma DebitNote</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <table>
              <tr>
                <h6 style={{ width: '300px' }}>Proforma DebitNote List</h6>
                <td colSpan={1} style={{ width: '10px', paddingLeft: '557px' }}>Search</td>
                <td colSpan={4} style={{ width: '175px' }}>
                  {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={refnumber}
                    // value={cnNo.value}
                    /* value={invnumber.filter(
                        (option) =>{ 
                            return option.value === tableListNo
                        }
                    )} */
                    value={selectedVal}
                    onChange={handleSearch}
                  // selectedValue={selectedValue}
                  /* onChange={(selected) => {
                      editprofolist(selected ? selected.value:'');
                    }}  */

                  />

                </td>
              </tr>
            </table>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>

              <CustomTable columns={columns} data={DATA} />


              {/* <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Debit Ref.No</th>
                    <th scope='col'>BKG.NO</th>
                    <th scope='col'>Customer Name</th>
                    <th scope='col'>Currency</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>

                {tableLists.map((tableList, index) => (
                        <>
                          <tr key={index}>
                            <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                            <td style={{ 'text-align': 'center ' }}>{tableList.ref_no}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.bkg_no}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.to_address}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                            <td style={{ 'text-align': 'center ' }}>{tableList.total_amt.toFixed(2)}</td>
                            <td style={{ 'text-align': 'center ' }}>
                              <button
                                className={classes.footerButton}
                                onClick={() => editCnopsList(index, tableList.ref_no)}
                              >
                                Receipt
                              </button>
                            </td>
                          </tr>
                        </>
                    ))}
                </tbody>
              </table> */}


            </div>
          </>
        ) : ' '}

        {showcontain ? (
          <>
            <Container className={classes.inputsContainer}>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <td >Date</td>
                  <td colSpan={5}><input className={classes.inputText} value={date} type="text"
                    disabled={true} /></td>

                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Invoice.No#*</span></td>
                  <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                  <td colSpan={1}><input className={classes.inputText} type="text"
                    value={DebitDetails ? DebitDetails.ref_no : ''} disabled={true}

                  /></td>

                </tr>
                  <tr style={{ height: '50px' }}>
                    <td>Currency</td>
                    <td colSpan={5}><input className={classes.inputText} value={DebitDetails ? DebitDetails.currency : ''}
                      type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>UTR</td>
                    <td colSpan={2} style={{ paddingBottom: '24px' }}><input className={classes.inputText} value={utr_no} type="number" onChange={(e) => setutr_no(e.target.value)} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Total Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={DebitDetails ? parseFloat(DebitDetails.total_amt).toFixed(2) : ''} onChange={(e) => settotalamt(e.target.value)} type="number" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>Remarks</td>
                    <td colSpan={5} rowSpan={4}><textarea style={{ height: '133px', marginTop: '-30px' }} value={remarks} onChange={(e) => setremarks(e.target.value)} /></td>

                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Amount</td>
                    {isPaid ? (
                      <td colSpan={5}><input className={classes.inputText} value={receivedAmnt} type="number" disabled /></td>
                    ) : (
                      <td colSpan={5}><input className={classes.inputText} value={receivedAmnt} onChange={handleamountR} type="number" /></td>
                    )}

                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Balance</td>
                    <td colSpan={5}><input className={classes.inputText} value={balanceAmnt}
                      type="number" disabled={true} /></td>


                  </tr>
                </tbody>
              </table>
              <div className={classes.footer}>
                {isPaid ? <button className={classes.footerButton} disabled={true}>
                  paid
                </button> :
                  <button className={classes.footerButton} onClick={payment}>
                    Pay
                  </button>}

                <button className={classes.footerButton} onClick={cancel}>
                  Cancel
                </button>


                <button
                  className={classes.footerButton}
                  onClick={() => { setShowList(true); setViewHistoryDetails(false); setShowTable(false); setshowcontain(false) }}
                >
                  View List
                </button>

                <button
                  className={classes.footerButton}
                  onClick={() => { payhistory(true) }}
                >
                  View History
                </button>
              </div>

            </Container>
          </>
        ) : ''}

        {showTable ? (
          <>
            <h6>Proforma DebitNote Payment History</h6>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
              <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Invoice No</th>
                    <th scope='col'>Date</th>
                    <th scope='col'>Total</th>
                    <th scope='col'>Received </th>
                    <th scope='col'>Balance </th>
                    <th scope='col'>Receipt.NO</th>
                    <th scope='col'>View</th>
                  </tr>
                </thead>
                <tbody>

                  {tableData && showTable && tableData.length > 0
                    ? tableData.map((tD, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ 'text-align': 'center ' }}>{index + 1}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.invoice_no}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.date}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.total_amt.toFixed(2)}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.rec_amt.toFixed(2)}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.balance_amt.toFixed(2)}</td>
                          <td style={{ 'text-align': 'center ' }}>{tD.receipt_no}</td>
                          <td style={{ 'text-align': 'center ' }}>
                            <img src={icon_view} alt='icon_view' onClick={() => showpayList(index, tD)} />
                          </td>
                        </tr>
                      );
                    }
                    )
                    : ''}
                </tbody>
              </table>
            </div>
          </>
        ) : (' ')}

        {viewHistoryDetails ? (
          <>
            <Container className={classes.inputsContainer}>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <td >Date</td>
                  <td colSpan={5}><input className={classes.inputText} value={tableview.date} type="text"
                    disabled={true} /></td>

                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Invoice.No#*</span></td>
                  <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                  <td colSpan={1}><input className={classes.inputText} type="text"
                    value={tableview.invoice_no} disabled={true}

                  /></td>

                </tr>
                  <tr style={{ height: '50px' }}>
                    <td>Currency</td>
                    <td colSpan={5}><input className={classes.inputText} value={tableview.curr}
                      type="text" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>Receipt.NO</td>
                    <td colSpan={2}><input className={classes.inputText} value={tableview.receipt_no} type="number" disabled={true} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Total Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={parseFloat(tableview.total_amt).toFixed(2)} type="number" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>UTR</td>
                    <td colSpan={2} style={{ paddingBottom: '24px' }}><input className={classes.inputText} value={tableview.utr_no} type="number" disabled={true} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Amount</td>
                    <td colSpan={5}><input className={classes.inputText} value={parseFloat(tableview.rec_amt).toFixed(2)} onChange={handleamountR} type="number" disabled={true} /></td>

                    <td style={{ paddingLeft: '35px' }}>Remarks</td>
                    <td colSpan={5} rowSpan={2}><textarea style={{ height: '133px', marginTop: '-30px' }} value={tableview.remarks} disabled={true} /></td>
                  </tr>

                  <tr style={{ height: '50px' }}>
                    <td>Balance</td>
                    <td colSpan={5}><input className={classes.inputText} value={parseFloat(tableview.balance_amt).toFixed(2)}
                      type="number" disabled={true} /></td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.footer}>

                <button className={classes.footerButton} onClick={() => { setShowTable(true); setViewHistoryDetails(false); setshowcontain(true); }} >
                  Back
                </button>

              </div>
            </Container>
          </>

        ) : (' ')}

      </Container>
    </>

  );

}


import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Select from 'react-select';
import classes from './jobclose.css';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../Axios';
import { icon_download, upload } from '../../Images';
import { Link } from "react-router-dom";


export default function Jobclose() {
  const [filename, setFilename] = useState('');
  const [bkngNofl, setBkngNofl] = useState('');
  const [splitFlNam, setSplitFlNam] = useState('');
  const [bookingNumbers, setBookingNumbers] = useState([]);
  const [openbkgnos, setOpenbkgnos] = useState([]);
  const [bookingNo, setBookingNo] = useState('');
  const [selectedBookingNo, setSelectedBookingNo] = useState(null);
  const [openSelectedBkgNo, setOpenSelectedBkgNo] = useState(null);
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [incomeTotal, setIncomeTotal] = useState(null);
  const [expenseTotal, setExpenseTotal] = useState(null);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [apprvData, setapprvData] = useState([]);
  const [GbpIncomeTotal, setGBPIncomeTotal] = useState(0.0);
  const [EurIncomeTotal, setEURIncomeTotal] = useState(0.0);
  const [GbpExpenseTotal, setGBPExpenseTotal] = useState(0.0);
  const [EurExpenseTotal, setEURExpenseTotal] = useState(0.0);
  const [profitLossTotal, setProfitLossTotal] = useState({});
  const [PrLsUsd, setPrLsUsd] = useState(0.0);
  const [PrLsGbp, setPrLsGbp] = useState(0.0);
  const [PrLsEur, setPrLsEur] = useState(0.0);

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFilename(e.target.value);
  }
  const closeBookingNo = () => {
    if (bookingNo) {
      customAxios
        .post('/import/invoice/bkg/closed', {
          bkg_no: bookingNo,
        })
        .then((data) => {
          if (data.data.error) {
            window.alert('Unable to close the Booking.No');
          } else {
            if (data.data.message === 'Booking is not approved') {
              window.alert('Booking.No is open on Approval pages');
            } else if (data.data.message === 'Booking Closed Successfully') {
              window.alert('Booking.No is closed successfully');
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };


  const openBkgNo = (bookingNo) => {
    customAxios
      .post('import/invoice/bkginv/opened', {
        "bkg_no": bookingNo,
      })
      .then((data) => {

        if (data.data.error) window.alert('Unable to open the Booking.No');
        else {
          window.alert('Booking Opened Successfully');
          window.location.reload();
        }
      });
  }

  useEffect(() => {

    customAxios.get('/import/invoice/bkgclose').then((ids) => {

      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: 'RUKIM'+data, value: data };
      });
      setBookingNumbers([...details]);

    });

    customAxios.get('/import/invoice/bkgopen').then((ids) => {

      let temp = [...ids.data.data];
      let details = temp.map((data) => {
        return { label: data, value: data };
      });
      setOpenbkgnos([...details]);

    });
  }, [incomeTotal, expenseTotal, PrLsUsd, PrLsGbp, PrLsEur]);

  const fetchData = async (selectedBookingNo) => {
    console.log('Selected Booking No:', selectedBookingNo);
    try {
      const incomeResponse = await customAxios.post('/import/invoice/dn', { bkg_no: selectedBookingNo });

      const incomeData = incomeResponse.data.data;
      console.log(incomeData);
      setIncomeData(incomeData);
      setapprvData(incomeData);


      const UsincomeTotal = calculateTotal(incomeData, 'USD');
      setIncomeTotal(UsincomeTotal.toFixed(2));
      setTimeout(function () { console.log(UsincomeTotal.toFixed(2), incomeTotal, '000') }, 3000);

      const gbincomeTotal = await calculateTotal(incomeData, 'GBP');
      setGBPIncomeTotal(gbincomeTotal.toFixed(2));

      const euincomeTotal = await calculateTotal(incomeData, 'EUR');
      setEURIncomeTotal(euincomeTotal.toFixed(2));


      const expenseResponse = await customAxios.post('/import/invoice/cnops/cn', { bkg_no: selectedBookingNo });

      const expenseData = expenseResponse.data.data;
      console.log(expenseData);
      setExpenseData(expenseData);
      setapprvData(expenseData);

      const UsexpenseTotal = await calculateTotal(expenseData, 'USD');
      setExpenseTotal(UsexpenseTotal.toFixed(2));

      const GbexpenseTotal = await calculateTotal(expenseData, 'GBP');
      setGBPExpenseTotal(GbexpenseTotal.toFixed(2));

      const EuexpenseTotal = await calculateTotal(expenseData, 'EUR');
      setEURExpenseTotal(EuexpenseTotal.toFixed(2));

      setBookingNo(selectedBookingNo);

      getProfitOrLoss(UsincomeTotal, UsexpenseTotal, gbincomeTotal, GbexpenseTotal, euincomeTotal, EuexpenseTotal);
    } catch (error) {
      console.error(error);
    }
  };

  // const calculateTotal = (data) => {
  //   return data.reduce((total, item) => total + (item.total_amt || item.totalamt), 0);
  // };

  const calculateTotal = (data, currency) => {
    return data.reduce((total, item) => {
      if (item.is_approved === 1 && item.currency === currency) {
        return total + (item.total_amt || item.totalamt);
      }
      return total;
    }, 0);
  };
  /* const calculateDifference = () => {
    return incomeTotal - expenseTotal;
  }; */

  const calculateDifference = () => {
    console.log(incomeTotal);
    
    if (incomeTotal !== null && expenseTotal !== null) {
      const profitLossUsd = (incomeTotal - expenseTotal).toFixed(2);
      setPrLsUsd(profitLossUsd);

      const profitLossGbp = (GbpIncomeTotal - GbpExpenseTotal).toFixed(2);
      setPrLsGbp(profitLossGbp);

      const profitLossEur = (EurIncomeTotal - EurExpenseTotal).toFixed(2);
      setPrLsEur(profitLossEur);

      return {
        PrLsUsd: profitLossUsd,
        PrLsGbp: profitLossGbp,
        PrLsEur: profitLossEur
      }
    }
  };

  /* const getProfitOrLoss = () => {
    const difference = calculateDifference();
    if (difference < 0) {
      return { label: 'Loss', value: ((Math.abs(difference)).toFixed(2)) };
    } else if (difference > 0) {
      return { label: 'Profit', value: (parseFloat(difference).toFixed(2)) };
    } else {
      return { label: 'No Profit or Loss', value: null };
    }
  }; */

  const getProfitOrLoss = (incomeTotal, expenseTotal, GbpIncomeTotal, GbpExpenseTotal, EurIncomeTotal, EurExpenseTotal) => {
    // const data = calculateDifference()
    if (incomeTotal !== null && expenseTotal !== null && GbpIncomeTotal !== null && GbpExpenseTotal !== null && EurIncomeTotal !== null && EurExpenseTotal !== null) {
      const profitLossUsd = (incomeTotal - expenseTotal).toFixed(2);
      setPrLsUsd(profitLossUsd);

      const profitLossGbp = (GbpIncomeTotal - GbpExpenseTotal).toFixed(2);
      setPrLsGbp(profitLossGbp);

      const profitLossEur = (EurIncomeTotal - EurExpenseTotal).toFixed(2);
      setPrLsEur(profitLossEur);

      var data = {
        PrLsUsd: profitLossUsd,
        PrLsGbp: profitLossGbp,
        PrLsEur: profitLossEur
      }
    }
    
    if (data.PrLsUsd !== null && data.PrLsGbp !== null && data.PrLsEur !== null) {
      setProfitLossTotal({
        USD: { label: data.PrLsUsd < 0 ? 'Loss' : 'Profit', value: (Math.abs(data.PrLsUsd).toFixed(2)) },
        GBP: { label: data.PrLsGbp < 0 ? 'Loss' : 'Profit', value: (Math.abs(data.PrLsGbp).toFixed(2)) },
        EUR: { label: data.PrLsEur < 0 ? 'Loss' : 'Profit', value: (Math.abs(data.PrLsEur).toFixed(2)) },
      });
    }
    else {
      setProfitLossTotal({
        USD: { label: '', value: 1 },
        GBP: { label: '', value: 1 },
        EUR: { label: '', value: 1 },
      });
    }
  }

  const handleDate = (e) => {
    setSelectedDate(e.target.value);
  }
  return (
    <>
      <div className={classes.container}>
        <h5>JOB CLOSE <span style={{ fontSize: '0.85rem', color: 'darkgray', marginTop: '14px', marginBottom: '1.5rem', marginRight: '0', paddingTop: '10px', paddingLeft: '0', borderBottom: '1px dashed black' }}>Import \ Jobclose</span></h5>
      </div>
      <Container className={classes.inputsContainer}>

        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={2}><span>Booking No</span></td>
              <td colSpan={2}><span>Closing Date *</span></td>
            </tr>


            <tr>
              <td colSpan={2} style={{ width: '100px' }}>
                <section>
                  <div style={{ position: 'relative', width: '150px' }}>
                    <Select
                      components={{ IndicatorSeparator, Placeholder }}
                      styles={style}
                      isClearable={true}
                      isSearchable={true}
                      options={bookingNumbers}
                      value={bookingNumbers.find((option) => option.value === bookingNo)}
                      onChange={(selectedOption) => {
                        const selectedBookingNo = selectedOption ? selectedOption.value : '';
                        setBookingNo(selectedBookingNo);
                        fetchData(selectedBookingNo);

                      }}

                    />
                  </div>
                </section>
              </td>
              <td colSpan={2}>
                <input
                  type='date'
                  // className={classes.inputText}
                  value={selectedDate}
                  onChange={handleDate}
                  style={{ width: '150px', marginRight: '700px', border: '1px solid lightgray', outline: 'none', }}
                />
              </td>
              <td colSpan={2}>

              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <>
          <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead className={classes.theadDark}>
                <tr>
                  <th style={{ textAlign: 'center' }}>Particulars</th>
                  <th style={{ textAlign: 'center' }}>Date</th>
                  <th style={{ textAlign: 'center' }}>Status</th>
                  <th style={{ textAlign: 'center' }}>Customer Name</th>
                  <th style={{ textAlign: 'center' }}>Curr</th>
                  <th style={{ textAlign: 'center' }}>Amount</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {incomeData.filter(data => data.is_approved === 1).map((data, index) => (
                  <tr key={`income-${index}`}>
                    <td style={{ textAlign: 'center' }}>{(data.menu_name == 'Invoice' || data.menu_name == 'CN-Ops') ? data.menu_name + '-' + String(data.inv_apprv_no).padStart(4, '0') : (data.menu_name == 'CN' ? (data.menu_name + '-' + data.credit_no) : (data.menu_name + '-' + data.debit_no))}</td>
                    <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                    <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                    <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                    <td style={{ textAlign: 'center' }}>{data.currency}</td>
                    <td style={{ textAlign: 'center' }}>{data.total_amt || data.totalamt.toFixed(2)}</td>
                  </tr>
                ))}
                {incomeTotal > 0 && (
                  <tr>
                    <td style={{ textAlign: 'right', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal' }} colSpan={3} className={classes.theadLight}>Income Total</td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colspan={1} className={classes.theadLight}>USD :<span style={{ color: 'White' }}>{incomeTotal}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colspan={1} className={classes.theadLight}>GBP : <span style={{ color: 'White' }}>{GbpIncomeTotal}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colspa={1} className={classes.theadLight}>EUR : <span style={{ color: 'White' }}>{EurIncomeTotal}</span></td>
                  </tr>
                )}

                {expenseData.filter(data => data.is_approved === 1).map((data, index) => (
                  <tr key={`expense-${index}`}>
                    <td style={{ textAlign: 'center' }}>{(data.menu_name == 'Invoice' || data.menu_name == 'CN-Ops') ? data.menu_name + '-' + String(data.inv_apprv_no).padStart(4, '0') : (data.menu_name == 'CN' ? (data.menu_name + '-' + data.credit_no) : (data.menu_name + '-' + data.debit_no))}</td>
                    <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                    <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                    <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                    <td style={{ textAlign: 'center' }}>{data.currency}</td>
                    <td style={{ textAlign: 'center' }}>{data.total_amt.toFixed(2) || data.totalamt.toFixed(2)}</td>
                  </tr>
                ))}

                {expenseTotal > 0 && (
                  <tr>
                    <td style={{ textAlign: 'right', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal' }} colSpan={3} className={classes.theadLight}>Expense Total</td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colSpan={1} className={classes.theadLight}>USD : <span style={{ color: 'White' }}>{expenseTotal}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colspan={1} className={classes.theadLight}>GBP : <span style={{ color: 'White' }}>{GbpExpenseTotal}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: ' #8b9ecd', color: 'white', fontSize: '15px', fontWeight: 'normal', width: '20%' }} colSpan={1} className={classes.theadLight}>EUR : <span style={{ color: 'White' }}>{EurExpenseTotal}</span></td>
                  </tr>
                )}
                {
                  console.log(typeof expenseTotal, typeof incomeTotal)
                }

                {incomeData && expenseData ? incomeTotal && expenseTotal && (
                  <tr>
                    <td style={{ textAlign: 'right' }} colSpan={3} >PROFIT/LOSS</td>
                    <td style={{ textAlign: 'center', backgroundColor: profitLossTotal.USD.label === 'Profit' ? 'green' : 'red', color: 'white' }} colSpan={1} ><span style={{ color: 'black' }}>USD :{profitLossTotal.USD.value}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: profitLossTotal.GBP.label === 'Profit' ? 'green' : 'red', color: 'white' }} colSpan={1} ><span style={{ color: 'black' }}>GBP :{profitLossTotal.GBP.value}</span></td>
                    <td style={{ textAlign: 'center', backgroundColor: profitLossTotal.EUR.label === 'Profit' ? 'green' : 'red', color: 'white' }} colSpan={1} ><span style={{ color: 'black' }}>EUR :{profitLossTotal.EUR.value}</span></td>
                  </tr>
                ) : null}

              </tbody>

            </table>
          </div>

          <br></br>

          <button
            className={classes.bkngatt}
            style={{ width: '100px', float: 'right', backgroundColor: 'black', color: 'white' }}
            onClick={() => closeBookingNo(bookingNo)}
          >
            Close
          </button>

        </>


        <br></br>
        <br></br>

        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
          <table style={{ width: '100%' }}>
            <thead className={classes.theadDark}>
              <tr>
                <th style={{ textAlign: 'center' }}>Particulars</th>
                <th style={{ textAlign: 'center' }}>Date</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center' }}>Customer Name</th>
                <th style={{ textAlign: 'center' }}>Curr</th>
                <th style={{ textAlign: 'center' }}>Amount</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {incomeData.filter(data => data.is_approved !== 1).map((data, index) => (
                <tr key={`income-${index}`}>
                  <td style={{ textAlign: 'center' }}>{data.menu_name == 'Invoice' ? (data.menu_name + '-' + data.ref_id) : (data.menu_name + '-' + data.ref_no)}</td>
                  <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                  <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                  <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                  <td style={{ textAlign: 'center' }}>{data.currency}</td>
                  <td style={{ textAlign: 'center' }}>{data.total_amt || data.totalamt.toFixed(2)}</td>
                </tr>
              ))}
              {/* {incomeTotal > 0 && (
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={4} className={classes.theadLight}>Income Total</td>
                  <td style={{ textAlign: 'center' }} className={classes.theadLight}>{incomeTotal}</td>
                </tr>
              )} */}

              {expenseData.filter(data => data.is_approved !== 1).map((data, index) => (

                <tr key={`expense-${index}`}>
                  <td style={{ textAlign: 'center' }}>{data.menu_name == 'Invoice' ? (data.menu_name + '-' + data.ref_id) : (data.menu_name + '-' + data.ref_no)}</td>
                  <td style={{ textAlign: 'center' }}>{data.current_date || data.date}</td>
                  <td style={{ textAlign: 'center' }}>{data.is_approved === 0 ? 'not approved' : 'approved'}</td>
                  <td style={{ textAlign: 'center' }}>{data.to_customer_name || data.to_address}</td>
                  <td style={{ textAlign: 'center' }}>{data.currency}</td>
                  <td style={{ textAlign: 'center' }}>{parseFloat(data.total_amt).toFixed(2) || parseFloat(data.totalamt).toFixed(2)}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>

        <br />

      </Container>
    </>
  );

}
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { EditIcon, DeleteIcon, upload } from '../../../Images';
import customAxios from '../../../Axios';
import classes from './Buyer.module.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomTable from '../../customTable';

export default function Buyer() {

  let initValue = {
    carrier: '',
    source: '',
    loading_port: '',
    discharge_port: '',
    destination: '',
    cargo: '',
    freight: '',
    shipment: '',
    valid_from: '',
    valid_to: '',
    remarks: '',
    charges: [],
  };

  let initCharge = {
    charge: '',
    currency: '',
    rate: '',
    base: '',
  };

  const [buyingDetails, setBuyingDetails] = useState(initValue);
  const [carriers, setCarriers] = useState([]);
  const [ports, setPorts] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [containers, setContainers] = useState([]);
  const [charges, setCharges] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [newCharge, setNewCharge] = useState(initCharge);
  const [allCharges, setAllcharges] = useState([]);
  const [regId, setRegId] = useState('');
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const fileInputRef = useRef(null);
  const [popUpData, setPopUpData] = useState('');
  const [rateId, setRateId] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState(null);



  const theadStyle = {
    backgroundColor: '#3d4d76',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    // textAlign: 'center'
  };

  let buttonEnabled = !!(
    newCharge.charge &&
    newCharge.currency &&
    newCharge.rate &&
    newCharge.base
  );

  const freightOptions = [
    { value: 'Prepaid', label: 'Prepaid' },
    { value: 'Collect', label: 'Collect' },
  ];
  const shipmentOptions = [
    { value: 'FCL', label: 'FCL' },
    { value: 'LCL', label: 'LCL' },
  ];

  const saveDetails = ({ key, e }) => {
    let temp = { ...buyingDetails };
    if (e && e.value) temp[key] = e.value;
    else temp[key] = '';
    setBuyingDetails(temp);
  };

  const saveCharge = ({ key, evt }) => {
    let temp = { ...newCharge };
    temp[key] = evt && evt.value ? evt.value : '';
    setNewCharge(temp);
  };

  const addCharge = () => {

    let temp = [...allCharges, newCharge];
    setAllcharges(temp);
    setNewCharge(initCharge);
  };

  const editCharge = (index) => {
    let temp = [...allCharges];
    setNewCharge({ ...temp[index] });
    temp.splice(index, 1);
    setAllcharges([...temp]);
  };

  const deleteCharge = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      let temp = [...allCharges];
      temp.splice(index, 1);
      setAllcharges([...temp]);
    }
  };

  const saveDetail = () => {
    let temp = { ...buyingDetails };
    temp.charges = [...allCharges];
    let {
      carrier,
      source,
      loading_port,
      discharge_port,
      destination,
      cargo,
      freight,
      shipment,
      charges,
    } = { ...buyingDetails };
    
    let hasMissingField = false; // Flag to track missing fields

  if (
    !carrier ||
    !source ||
    !loading_port ||
    !discharge_port ||
    !destination ||
    !cargo ||
    !freight ||
    !shipment ||
    !charges
  ) {
    // Check if any of the required fields are missing
    setError('Please fill all the required fields');
    hasMissingField = true;
  } else {
    setError(''); // Clear the error message if all fields are filled
  }

  if (!hasMissingField) {
    if (rateId || regId) {
      customAxios.post('/buyer/update', { id: rateId, ...temp }).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert('Detail updated succesfully!');
        }
      });
      customAxios
        .post('selling/updateBuying', {
          id: rateId,
          carrier,
          source,
          loading_port,
          discharge_port,
          destination,
          cargo,
          freight,
          shipment,
          buying_rates: charges,
        })
        .then((data) => {
          // if (data.data.error) {
          //   setError(data.message);
          // } else {
          //   
          //   window.alert('Detail updated succesfully!');
          // }
        });
    } else if (
      temp.carrier &&
      temp.source &&
      temp.loading_port &&
      temp.discharge_port &&
      temp.destination &&
      temp.cargo &&
      temp.freight &&
      temp.shipment &&
      temp.valid_from &&
      temp.valid_to
    ) {
      customAxios.post('/buyer', temp).then((data) => {
        if (data.data.error) {
          setError(data.message);
        } else {

          window.alert('Entry added succesfully!');
          setRegId(data.data.count);
        }

      });
    }
  }
    /* else {

       setError((rateId || regId) ? ' ' : 'Please fill all required fields');
    } */
  };

  const confirmCancel = () => {
    setBuyingDetails(initValue);
    setAllcharges([]);
    setNewCharge(initCharge);
    setRegId('');
  };

  const handleBuyingNo = (evt) => {
    setRateId(evt.target.value);
  };

  const handleRateId = (/* evt, */ rateId, fromClick = false) => {
    setIsOpen(false);
  setRateId(rateId);
    
    if (fromClick || rateId || regId /* && (evt.key === 'Enter' || evt.keyCode === 13) */) {
      customAxios.post('/buyer/getById', { id: rateId }).then((result) => {
        if (!result.data.error && result.data.data) {

          setRateId(rateId);
          let {
            carrier,
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            valid_from,
            valid_to,
            remarks,
            charges,
          } = result.data.data;
          setBuyingDetails({
            carrier,
            source,
            loading_port,
            discharge_port,
            destination,
            cargo,
            freight,
            shipment,
            valid_from: valid_from.substring(0, 10),
            valid_to: valid_to.substring(0, 10),
            remarks,
            charges,
          });
          setSelectedCarrier({ label: carrier, value: carrier });
          console.log(buyingDetails);
          setAllcharges(charges);
        } else {
          window.alert('No data found');
          confirmCancel();
        }
      });
    } else if (!rateId) {
      confirmCancel();
    }
 
  
  };



  const cancelDetails = () => {
    if (window.confirm('Are you sure to cancel?')) {
      confirmCancel();
    }
  };

  useEffect(() => {
    let portUrl = '/port';
    customAxios.get(portUrl).then((ports) => {
      let temp = [...ports.data.data];
      let portDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setPorts([...portDetails]);

    });
    let cargoUrl = '/cargo';
    customAxios.get(cargoUrl).then((cargos) => {
      let temp = [...cargos.data.data];
      let cargoDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCargos([...cargoDetails]);

    });
    let carrierUrl = '/customer/carriers';
    customAxios.get(carrierUrl).then((carriers) => {
      let temp = [...carriers.data.data];
      let carriersDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCarriers([...carriersDetails]);

    });
    let containerUrl = '/container';
    customAxios.get(containerUrl).then((containers) => {
      let temp = [...containers.data.data];
      let containersDetails = temp.map((data) => {
        return { label: data.type, value: data.type };
      });
      setContainers([...containersDetails]);

    });
    let chargesUrl = '/charges';
    customAxios.get(chargesUrl).then((charges) => {
      let temp = [...charges.data.data];
      let chargesDetails = temp.map((data) => {
        return { label: data.name, value: data.name };
      });
      setCharges([...chargesDetails]);

    });
    let currencyUrl = '/currency';
    customAxios.get(currencyUrl).then((currencies) => {
      let temp = [...currencies.data.data];
      let currenciesDetails = temp.map((data) => {
        return { label: data.code, value: data.code };
      });
      setCurrencies([...currenciesDetails]);

    });

    let popupdataUrl = '/buyer/getall';
    customAxios.get(popupdataUrl).then(response => {
      console.log(response);
      setPopUpData(response.data.data);
    }).catch(error => {
      console.error('Error fetching data:', error);

    });

  }, []);

  // useEffect(() => {
  //   
  // }, [buyingDetails]);

  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '30px',
      // width:'100%',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '30px',
      width: '80%',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };
  const styless = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '30px',
      width: '100%',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };
  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);

  const closePop = () => {
    setIsOpen(false);
  }

  /* const Openpopup = () =>{
    setIsOpen(true);
  } */

  const columns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Buy No",
      accessor: "rate_id",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
    },
    {
      Header: "POR",
      accessor: "source",
    },
    {
      Header: "POL",
      accessor: "loading_port",
    },
    {
      Header: "POD",
      accessor: "discharge_port",
    },
    {
      Header: "DEL",
      accessor: "destination",
    }
    
  ];

  const DATAPOPUP = popUpData.length ? popUpData.map((data, index)=> {

    const rateIdStyle = {
      color: '#D82C8D',
      cursor: 'pointer'
    }
    return{
      id: index + 1,
      rate_id: (
      <span onClick={(e) => handleRateId(data.rate_id, true)} style={rateIdStyle}>{data.rate_id}</span>
      ),
      carrier: data.carrier,
      source: data.source,
      loading_port: data.loading_port,
      discharge_port: data.discharge_port,
      destination: data.destination
    }
  }) : [];

  if(DATAPOPUP.length === 0 || null){
    DATAPOPUP.push({
      id: '',
      rate_id: '',
      carrier: '',
      source: 'No Data Available',
      loading_port: '',
      discharge_port: '',
      destination: '',
    })
  }


  return (
    <>
      <div className={classes.container}>
        <h5>Buying <span className={classes.title}>Export / Sales</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p style={{ cursor: 'pointer', color: '#D82C8D' }} onClick={setIsOpen}>Buy no</p>
            </section>
            <input
              type='text'
              className={classes.inputText}
              // style={{ width: '60%',borderRadius: '5px' }}
              value={rateId || regId}
              onChange={(evt) => setRateId(evt.target.value)}
              onKeyUp={(evt) => {
                if (evt.key === 'Enter') {
                  handleRateId(evt.target.value, false);
                }}}
              contentEditable={true}
            // disabled={true}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Valid From</p>
              <span>*</span>
            </section>
            <input
              type='date'
              className={classes.inputText}
              value={buyingDetails.valid_from}
              onChange={(e) => saveDetails({ key: 'valid_from', e: e.target })}
            // onChange={(e) => 
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Valid Till</p>
              <span>*</span>
            </section>
            <input
              type='date'
              disabled={buyingDetails.valid_from ? false : true}
              min={buyingDetails.valid_from || null}
              className={classes.inputText}
              value={buyingDetails.valid_to}
              onChange={(e) => saveDetails({ key: 'valid_to', e: e.target })}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Freight</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='carrier'
              options={freightOptions}
              value={freightOptions.filter(
                (option) => option.value === buyingDetails.freight
              )}
              onChange={(e) => saveDetails({ key: 'freight', e: e })}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Shipment</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='carrier'
              options={shipmentOptions}
              value={shipmentOptions.filter(
                (option) => option.value === buyingDetails.shipment
              )}
              onChange={(e) => saveDetails({ key: 'shipment', e: e })}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POR</p>
              <span>*</span>
            </section>
            <div style={{ width: '150px' }}>
              <Select
                components={{ IndicatorSeparator, Placeholder }}
                styles={style}
                isClearable={true}
                isSearchable={true}
                // name='receipt'
                options={ports}
                value={ports.filter(
                  (option) => option.value === buyingDetails.source
                )}
                onChange={(e) => saveDetails({ key: 'source', e: e })}
              />
            </div>
          </Col>
          <Col className={classes.input}>
            <section>
              <p>POL</p>
              <span>*</span>
            </section>
            <div style={{ width: '150px' }}>
              <Select
                components={{ IndicatorSeparator, Placeholder }}
                styles={style}
                isClearable={true}
                isSearchable={true}
                // name='loading'
                options={ports}
                value={ports.filter(
                  (option) => option.value === buyingDetails.loading_port
                )}
                onChange={(e) => saveDetails({ key: 'loading_port', e: e })}
              />
            </div>
          </Col>

        </Row>
        <Row className={classes.row}>
          <Col className={classes.input}>
            <section>
              <p>POD</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='discharge'
              options={ports}
              value={ports.filter(
                (option) => option.value === buyingDetails.discharge_port
              )}
              onChange={(e) => saveDetails({ key: 'discharge_port', e: e })}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>DEL</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='destination'
              options={ports}
              value={ports.filter(
                (option) => option.value === buyingDetails.destination
              )}
              onChange={(e) => saveDetails({ key: 'destination', e: e })}
            />
          </Col>
          <Col className={classes.input}>
            <section>
              <p>Cargo</p>
              <span>*</span>
            </section>
            {/*<input
              type='text'
              className={classes.inputText}
              value={buyingDetails.cargo}
              onChange={(e) =>
                saveDetails({ key: 'cargo', e: e.target })
              }
            />*/}
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='carrier'
              options={cargos}
              value={cargos.filter(
                (option) => option.value === buyingDetails.cargo
              )}
              onChange={(e) => saveDetails({ key: 'cargo', e: e })}
            />
          </Col>

          <Col className={classes.input}>
            <section>
              <p>Carrier</p>
              <span>*</span>
            </section>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              // name='carrier'
              options={carriers}
              /* value={carriers.filter(
                (option) => option.value === buyingDetails.carrier
              )} */
              value={selectedCarrier}
              onChange={(e) => {
                setSelectedCarrier(e);
                saveDetails({ key: 'carrier', e: e });
              }}
            />
          </Col>
          {/* space */}
          <Col className={classes.input}>
            <p>Remarks</p>
            <input

              type='text'
              className={classes.inputText}
              value={buyingDetails.remarks}
              onChange={(e) => saveDetails({ key: 'remarks', e: e.target })}
            />
          </Col>

        </Row>
        <div >
          <span>{error}</span>
        </div>
        <div className={classes.footer}>
          <button className={classes.footerButton} onClick={saveDetail}>
            {(rateId || regId) ? 'Update' : 'Save'}
          </button>
          {/* <button
            className={classes.footerButton}
          >
            View
          </button> */}
          <button className={classes.footerButton} onClick={cancelDetails}>
            Cancel
          </button>
        </div>
        {/* <div className={classes.hl} style={{ marginBottom: '20px' }}></div> */}
        <Row className={classes.row}>
          <Col className={classes.input} md='5'>
            <p>Charges</p>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={charges}
              value={charges.filter(
                (option) => option.value === newCharge.charge
              )}
              onChange={(e) => saveCharge({ key: 'charge', evt: e })}
            />
          </Col>
          <Col className={classes.input} md='2'>
            <p>Curr</p>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={currencies}
              value={currencies.filter(
                (option) => option.value === newCharge.currency
              )}
              onChange={(e) => saveCharge({ key: 'currency', evt: e })}
            />
          </Col>
          <Col className={classes.input} md='2'>
            <p>Rate</p>
            <input
              type='number'
              className={classes.inputText}
              value={newCharge.rate}
              onChange={(e) => saveCharge({ key: 'rate', evt: e.target })}
            />
          </Col>
          <Col className={classes.input} md='2'>
            <p>Base</p>
            <Select
              components={{ IndicatorSeparator, Placeholder }}
              styles={style}
              isClearable={true}
              isSearchable={true}
              options={containers}
              value={containers.filter(
                (option) => option.value === newCharge.base
              )}
              onChange={(e) => saveCharge({ key: 'base', evt: e })}
            />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'flex-end' }} md='1'>
            <button
              className={classes.footerButton}
              onClick={addCharge}
              disabled={!buttonEnabled}
            >
              Add
            </button>
          </Col>
        </Row>
        <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
          <table class='table'>
            <thead className={classes.theadDark}>
              <tr>
                <th scope='col' style={theadStyle}>
                  Charges
                </th>
                <th scope='col' style={theadStyle}>Curr</th>
                <th scope='col' style={theadStyle}>Rate</th>
                <th scope='col' style={theadStyle}>Base</th>
                <th scope='col' style={theadStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allCharges.length
                ? allCharges.map((charge, index) => {
                  return (
                    <tr key={index}>
                      {/* <th scope='row'>{index + 1}</th> */}
                      <td>{charge.charge}</td>
                      <td>{charge.currency}</td>
                      <td>{parseFloat(charge.rate).toFixed(2)}</td>
                      <td>{charge.base}</td>
                      <td>
                        <img
                          src={EditIcon}
                          className={classes.icon}
                          alt='edit_icon'
                          // style={{ borderRadius: '50%' }}
                          onClick={() => editCharge(index)}
                        />
                        &nbsp;&nbsp;
                        <img
                          src={DeleteIcon}
                          className={classes.icon}
                          alt='delete_Icon'
                          // style={{ borderRadius: '50%' }}
                          onClick={() => deleteCharge(index)}
                        />
                      </td>
                    </tr>
                  );
                })
                : ''}
            </tbody>
          </table>
        </div>
      </Container>


       {/* popup */}
      {isOpen ?
        <div
          className="modal show"
          style={{ display: 'block', position: 'fixed' }}
        >
          <Modal.Dialog style={{ marginRight: '0px', marginLeft: '0px', left: '10%' }}>
            <div class='modal-content' style={{ width: '215%', height: '500px', position: 'relative' }}>
              <Modal.Header onClick={closePop} closeButton>
                <Modal.Title style={{ fontSize: '15px' }}>BUYING LIST</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ display: 'flex', justifyContent: 'center', height: '50%', overflowY: 'scroll' }}>
                {/* <p>Modal body text goes here.</p> */}
                <table style={{ width: '100%', border: '1px solid #C3C3C3', /* position: 'fixed' */ }}>
                  <tbody>
                    <CustomTable columns={columns} data={DATAPOPUP} />
                    {/* <tr style={{ backgroundColor: '#F2F2F2' }}>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>S.No</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Buy No</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POR</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POL</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>POD</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>DEL</td>
                      <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>Carrier</td>
                    </tr>
                    {popUpData.map((data, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{index + 1}</td>
                        <td onClick={(e) => handleRateId(data.rate_id, true)} style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3', cursor: 'pointer', color: '#D82C8D' }}>{data.rate_id}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.source}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.loading_port}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.discharge_port}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.destination}</td>
                        <td style={{ textAlign: 'center', borderRight: '1px solid #C3C3C3', borderBottom: '1px solid #C3C3C3' }}>{data.carrier}</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>Close</Button>
              </Modal.Footer>
            </div>
          </Modal.Dialog>
        </div>
        : null}
    </>
  );
}
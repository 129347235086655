import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import customAxios from '../../../../Axios';
import { icon_view } from '../../../../Images';
import classes from './Style.module.css';
import CustomTable from '../../../customTable';
import ExcelJS from 'exceljs';

export default function JobCloseReport() {

    const [JobcloseDetails, setJobcloseDetails] = useState('');
    const [allCharges, setAllcharges] = useState([]);
    const [contDetails, setcontDetils] = useState([]);
    const [showList, setShowList] = useState(true);
    const [jobcloselist, setJobcloselist] = useState([]);
    const [fromdate, setFromDate] = useState('');
    const [todate, setToDate] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [shippinginfo, setshippinginfo] = useState([]);

    const theadStyle = {
        backgroundColor: '#3d4d76',
        color: 'white',
        fontWeight: 'normal',
        fontSize: '12px',
        // textAlign: 'center'
    };


    const showJobcloseReport = (index, booking_id) => {
        setShowList(false);

        customAxios.post('/import/booking/reportgetbyID', { bkg_no: booking_id }).then((result) => {
            if (!result.data.error && result.data.data) {
                // console.log(result.data.data);

                const JobcloseData = result.data.data.find(item => item.booking_id === booking_id);

                if (JobcloseData) {
                    let {
                        buying_id,
                        carrier,
                        source,
                        loading_port,
                        discharge_port,
                        destination,
                        customer,
                        cargo,
                        freight,
                        shipment,
                        current_date,
                        remarks,
                        selling_rates,
                        bookingShipping,
                    } = JobcloseData;

                    setshippinginfo(bookingShipping.length === 0 ? 'unused' : 'used');

                    if (bookingShipping && bookingShipping.length > 0) {
                        const containerDetails = bookingShipping[0].container_details;

                        if (containerDetails && containerDetails.length > 0) {
                            const {
                                container_no,
                                seal_no,
                                package_no,
                                package_type,
                                gross_wt,
                                net_wt,
                                measurement,
                                desc,
                                mrkno,
                            } = containerDetails[0];
                            setcontDetils(containerDetails);

                        }
                    }

                    setJobcloseDetails({
                        buying_id,
                        booking_id,
                        carrier,
                        source,
                        customer,
                        loading_port,
                        discharge_port,
                        destination,
                        cargo,
                        freight,
                        shipment,
                        current_date/* .substring(0, 10) */,
                        remarks,
                        selling_rates,
                    });

                    //   console.log(JobcloseDetails);
                    setAllcharges(selling_rates);
                } else {
                    window.alert('No data found for the specified booking_id');
                }
            }
            else {
                window.alert('No data found');
            }
        })
    }

    const handleClick = () => {
        if (fromdate && todate) {
            const payload = {
                fromdate: fromdate,
                todate: todate
            };

            customAxios.post("/plreport/gebydate", payload)
                .then((response) => {
                    const responseData = response.data.data;

                    if (responseData) {
                        const files = responseData;
                        setJobcloselist(files);
                        console.log(jobcloselist);
                    } else {
                        setJobcloselist([]);
                        alert('No files found for the selected date range.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    alert('An error occurred while fetching files.');
                });

        } else {
            alert('Please choose both From Date and To Date');
        }
    };


    // Extracting Data For Excel 
    useEffect(() => {

        const extractData =  jobcloselist && jobcloselist.flatMap((jobcloseData, index) => {
            console.log(jobcloseData.income);

            const usdValue = jobcloseData.income && jobcloseData.income.USD !== undefined ? jobcloseData.income.USD : '';
            const gbpValue = jobcloseData.income && jobcloseData.income.GBP !== undefined ? jobcloseData.income.GBP : '';
            const exusdValue = jobcloseData.expense && jobcloseData.expense.USD !== undefined ? jobcloseData.expense.USD : '';
            const exgbpValue = jobcloseData.expense && jobcloseData.expense.GBP !== undefined ? jobcloseData.expense.GBP : '';
            const netusdValue = jobcloseData.profitLoss && jobcloseData.profitLoss.USD !== undefined ? jobcloseData.profitLoss.USD : '';
            const netgbpValue = jobcloseData.profitLoss && jobcloseData.profitLoss.GBP !== undefined ? jobcloseData.profitLoss.GBP : '';

            const profitLossUSD = netusdValue >= 0 ? "Profit" : "Loss";
            const profitLossGBP = netgbpValue >= 0 ? "Profit" : "Loss";

            const rowData = {
                ID: index + 1,
                BookingNo: jobcloseData.bkg_no,
                Customer: jobcloseData.customer,
                POR: jobcloseData.source,
                POD: jobcloseData.loading_port,
                DischargePort: jobcloseData.discharge_port,
                DEL: jobcloseData.destination,
                incomeTotal: `USD: ${usdValue} GBP: ${gbpValue}`,
                expenseTotal: `USD: ${exusdValue} GBP: ${exgbpValue}`,
                netIncome: `USD: ${netusdValue} GBP: ${netgbpValue}`,
                Profitloss: `USD: ${profitLossUSD} GBP: ${profitLossGBP}`,
            };

            return rowData;
        });

        setExcelData(extractData);
    }, [jobcloselist]);

    const convertToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Sheet1');

        const mainHeaderRow = sheet.addRow([
            '#', 'BookingNo',  /* 'Date ', */ 'Customer', 'POR', 'POD', 'Discharge Port', 'DEL',
            'incomeTotal', 'ExpenseTotal', 'NetIncome', 'Profit/Loss'
        ]);

        //Main Row 
        mainHeaderRow.font = { bold: true };

        excelData.forEach((row) => {
            sheet.addRow([
                row.ID, row.BookingNo,  /* row.date,  */row.Customer, row.POR, row.POD, row.DischargePort, row.DEL,
                row.incomeTotal, row.expenseTotal, row.netIncome, row.Profitloss
            ]);

            // Add blank row as separator
            sheet.addRow([]);

        });

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'Export JobClose Report.xlsx');
        });
    };

    const saveAs = (blob, fileName) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    useEffect(() => {
        let url = 'plreport';
        customAxios.get(url).then((Jobclose) => {
            console.log(Jobclose);
            setJobcloselist(Jobclose.data.data);
            console.log(Jobclose.data.data);
            console.log(jobcloselist);

        });
    }, [showList]);


    const JobClosecolumns = [
        {
            Header: "#",
            accessor: "id",
        },
        {
            Header: "Booking No",
            accessor: "booking_id",
        },
        {
            Header: "Customer",
            accessor: "customer",
        },
        {
            Header: "POR",
            accessor: "source",
        },
        {
            Header: "POL",
            accessor: "loading_port",
        },
        {
            Header: "POD",
            accessor: "discharge_port",
        },
        {
            Header: "DEL",
            accessor: "destination",
        },
        {
            Header: "IncomeTotal",
            accessor: "incomeTotal",
        },
        ,
        {
            Header: "ExpenseTotal",
            accessor: "expenseTotal",
        },
        {
            Header: "NetIncome",
            accessor: "netIncome",
        },

    ];

    // const JoblistDatas = jobcloselist ? [
    //     {
    //         id: 1,
    //         booking_id: jobcloselist.booking_id,
    //         customer: jobcloselist.to_customer_name,
    //         source: jobcloselist.source,
    //         loading_port: jobcloselist.loading_port,
    //         discharge_port: jobcloselist.discharge_port,
    //         destination: jobcloselist.destination,
    //         incomeTotal: jobcloselist.income,
    //         expenseTotal: jobcloselist.expense,
    //         netIncome: jobcloselist.profitLoss,
    //         action: (
    //             <div style={{ textAlign: 'center' }}>
    //                 <img src={icon_view} alt='icon_view' onClick={() => showJobcloseReport(0, jobcloselist.booking_id)} />
    //             </div>
    //         ),
    //     }
    // ] : [];

    const JobCloseDatas =  jobcloselist && jobcloselist.length ? jobcloselist.map((item, index) => {
        console.log(item);

        const usdValue = item.income && item.income.USD !== undefined ? item.income.USD : '';
        const gbpValue = item.income && item.income.GBP !== undefined ? item.income.GBP : '';
        const exusdValue = item.expense && item.expense.USD !== undefined ? item.expense.USD : '';
        const exgbpValue = item.expense && item.expense.GBP !== undefined ? item.expense.GBP : '';
        const netusdValue = item.profitLoss && item.profitLoss.USD !== undefined ? item.profitLoss.USD : '';
        const netgbpValue = item.profitLoss && item.profitLoss.GBP !== undefined ? item.profitLoss.GBP : '';

        return {
            id: index + 1,
            booking_id: item.bkg_no,
            customer: item.customer,
            source: item.source,
            loading_port: item.loading_port,
            discharge_port: item.discharge_port,
            destination: item.destination,
            incomeTotal: `USD: ${usdValue} GBP: ${gbpValue}`,
            expenseTotal: `USD: ${exusdValue} GBP: ${exgbpValue}`,
            netIncome: `USD: ${netusdValue} GBP: ${netgbpValue}`,
            pl: item.source,
            action: (
                <div style={{ textAlign: 'center' }}>
                    <img src={icon_view} alt='icon_view' onClick={() => showJobcloseReport(index, item.ref_no)} />
                </div>
            )
        };

    }) : [];


    if (JobCloseDatas && JobCloseDatas.length === 0 || null) {
        JobCloseDatas.push({
            id: '',
            booking_id: '',
            customer: '',
            valid_till: 'No Data Available',
            loading_port: '',
            discharge_port: '',
            destination: '',
            action: ''
        })
    }

    return (
        <>
            <div className={classes.container}>
                <h5>Jobclose Report <span className={classes.title}>Export / JobcloseReport</span></h5>
            </div>
            <Container className={classes.inputsContainer}>
                {showList ? (
                    <>
                        <h6>Jobclose Report Listing</h6>
                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div style={{ display: 'inline-flex', padding: '1%', width: '100%' }}>
                                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                                    <section>
                                        <p>From Date</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='date'
                                        className={classes.inputText}
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </Col>

                                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                                    <section>
                                        <p>To Date</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='date'
                                        className={classes.inputText}
                                        value={todate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </Col>

                                <Col className={classes.input} style={{ margin: '0% 1%', width: '100%' }}>
                                    <button className={classes.bkngatt} style={{ float: 'left', margin: '1%', width: '100px' }} onClick={handleClick}>Get Files</button>
                                </Col>

                                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                                    &nbsp;
                                </Col>

                                <Col className={classes.input} style={{ margin: '0% 1%' }}>
                                    <button className={classes.footerButton} style={{ float: 'right' }} onClick={convertToExcel}>
                                        Download
                                    </button>
                                </Col>

                            </div>
                            <CustomTable columns={JobClosecolumns} data={JobCloseDatas} />
                        </div>
                    </>
                ) : (
                    <>
                        <Container className={classes.inputsContainer}>
                            <Row className={classes.row}>
                                <Col className={classes.input}>
                                    <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Quotation#</span>
                                    <section>
                                        <input
                                            type='text'
                                            className={classes.inputText}
                                            value={JobcloseDetails ? JobcloseDetails.buying_id : ''}
                                            disabled={true}
                                        />
                                    </section>
                                </Col>

                                <Col className={classes.input}>
                                    <span style={{ cursor: 'pointer', color: '#D82C8D' }}>Booking#</span>
                                    <section>
                                        <input
                                            type='text'
                                            className={classes.inputText}
                                            value={JobcloseDetails ? JobcloseDetails.booking_id : ''}
                                            disabled={true}
                                        />
                                    </section>
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>Date</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.current_date : ''}
                                        disabled={true}
                                    />
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>Customer</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.customer : ''}
                                        disabled={true}
                                    />
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>Carrier</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.carrier : ''}
                                        disabled={true} />
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>Cargo</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.cargo : ''}
                                        disabled={true} />
                                </Col>
                            </Row>

                            <Row className={classes.row}>
                                <Col className={classes.input}>
                                    <section>
                                        <p>Freight</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.freight : ''}
                                        disabled={true} />
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>POR</p>
                                        <span>*</span>
                                    </section>
                                    <div style={{ width: '150px' }}>
                                        <input
                                            type='text'
                                            className={classes.inputText}
                                            value={JobcloseDetails ? JobcloseDetails.source : ''}
                                            disabled={true} />
                                    </div>
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>POL</p>
                                        <span>*</span>
                                    </section>
                                    <div style={{ width: '150px' }}>
                                        <input
                                            type='text'
                                            className={classes.inputText}
                                            value={JobcloseDetails ? JobcloseDetails.loading_port : ''}
                                            disabled={true} />
                                    </div>
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>POD</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.discharge_port : ''}
                                        disabled={true} />
                                </Col>

                                <Col className={classes.input}>
                                    <section>
                                        <p>DEL</p>
                                        <span>*</span>
                                    </section>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={JobcloseDetails ? JobcloseDetails.destination : ''}
                                        disabled={true} />
                                </Col>

                                <Col className={classes.input}>
                                    <p>Remarks</p>
                                    <input
                                        type='text'
                                        className={classes.inputText}
                                        value={shippinginfo} disabled={true}
                                    />
                                </Col>
                            </Row>

                            &nbsp;&nbsp;

                            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                                <table style={{ width: '100%' }}>
                                    <thead className={classes.theadDark}>
                                        <tr>
                                            <th scope='col' style={theadStyle}>
                                                Charges
                                            </th>
                                            <th scope='col' style={theadStyle}>Curr</th>
                                            <th scope='col' style={theadStyle}>BuyingRate</th>
                                            <th scope='col' style={theadStyle}>SellingRate</th>
                                            <th scope='col' style={theadStyle}>Base</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCharges.length
                                            ? allCharges.map((charge, index) => {
                                                return (
                                                    <tr key={index} style={{ borderBottomWidth: 'none' }}>
                                                        <td>{charge.charge}</td>
                                                        <td>{charge.currency}</td>
                                                        <td>{parseFloat(charge.rate).toFixed(2)}</td>
                                                        <td>{parseFloat(charge.buyingRate).toFixed(2)}</td>
                                                        <td>{charge.base}</td>

                                                    </tr>
                                                );
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
                                <table style={{ width: '100%' }}>
                                    <thead className={classes.theadDark}>
                                        <tr>
                                            <th scope='col' style={theadStyle}>container_no</th>
                                            <th scope='col' style={theadStyle}>gross_wt</th>
                                            <th scope='col' style={theadStyle}>measurement</th>
                                            <th scope='col' style={theadStyle}>net_wt</th>
                                            <th scope='col' style={theadStyle}>package_no</th>
                                            <th scope='col' style={theadStyle}>package_type</th>
                                            <th scope='col' style={theadStyle}>seal_no</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contDetails.length
                                            ? contDetails.map((contDetail, index) => {
                                                return (
                                                    <tr key={index} style={{ borderBottomWidth: 'none' }}>
                                                        <td>{contDetail.container_no}</td>
                                                        <td>{contDetail.gross_wt}</td>
                                                        <td>{contDetail.measurement}</td>
                                                        <td>{contDetail.net_wt}</td>
                                                        <td>{contDetail.package_no}</td>
                                                        <td>{contDetail.package_type}</td>
                                                        <td>{contDetail.seal_no}</td>

                                                    </tr>
                                                );
                                            })
                                            : ''}
                                    </tbody>
                                </table>
                            </div>

                        </Container>

                        <div className={classes.footer} style={{ display: 'flex', justifyContent: 'end', gridGap: '20px', padding: '10px', gap: '20px' }}>
                            <button
                                className={classes.footerButton} style={{ margin: '1%' }}
                                onClick={() => { setShowList(true) }}
                            >
                                View List
                            </button>
                        </div>

                    </>)}
            </Container>
        </>
    );
}

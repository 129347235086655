import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import customAxios from '../../../Axios';
import { EditIcon, DeleteIcon } from '../../../Images';
import classes from './profoapproval.module.css';
import './invoice.module.css';
import CustomTable from '../../customTable';
import Template from './ApprvInvPdf';



export default function ApprvdInvoice() {

  const initProdetails = {
    booking_number: '',
    carrier_booking_no: '',
    bill_type: '',
    to_customer_name: '',
    current_date: '',
    Address: '',
    currency: '',
    shipper: '',
    agent: '',
    mlo: '',
    notify_party: '',
    cnf: '',
    remarks: '',
    invoice_charges: '',
  }

  const [tableLists, setTableLists] = useState([]);
  const [showList, setShowList] = useState(true);
  const [tableListNo, setTableListNo] = useState('');
  const [profoDetails, setProfoDetails] = useState(initProdetails);
  const [profoList, setProfoList] = useState('');
  const [isApproved, setIsApproved] = useState(0);
  const [isApprovedId, setIsApprovedId] = useState('');
  const [constno, setconstno] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [autogenInvNo, setAutogenInvNo] = useState("");
  const [invnumber, setInvnumber] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [searchtable, setSearchTable] = useState('');
  const [selectedVal, setSelectedVal] = useState('');
  const [pdfDatas, setPdfDatas] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [bkgDetail, setBkgDetail] = useState('');
  const [bkgnumber, setBkgnumber] = useState('');
  const [bkgSailSched, setBkgSailSched] = useState('');


  



  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


  const IndicatorSeparator = () => null;
  const Placeholder = () => null;
  const style = {
    control: (base) => ({
      ...base,
      minHeight: '35px',
      height: '35px',
      padding: '0',
      outline: 'none',
      // This line disable the blue border
      boxShadow: 'none',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  };



  const editprofolist = (index, invNum, val) => {
    setShowList(false);
    // setTableListNo(invNum);

    customAxios.post('/invoice/approve/getbyId', { inv_apprv_no: invNum }).then((result) => {
      if (!result.data.error && result.data.data) {

        let {
          _id,
          booking_number,
          carrier_booking_no,
          bill_type,
          to_customer_name,
          current_date,
          Address,
          currency,
          shipper,
          agent,
          mlo,
          notify_party,
          cnf,
          remarks,
          invoice_charges,
          destination,
          consignee,
          ref_id,
          currency_value,
          container_no,
          container_count,
          totalamt,
          inv_apprv_no
        } = result.data.data

        setInvoiceNumber(inv_apprv_no);
        setBkgnumber(result.data.data);
        console.log(bkgnumber);
        console.log(result.data.data);


        setPdfDatas(result.data.data);

        setProfoDetails({
          _id: _id,
          booking_number: booking_number,
          carrier_booking_no: carrier_booking_no,
          bill_type: bill_type,
          to_customer_name: to_customer_name,
          current_date: current_date,
          Address: Address,
          currency: currency,
          shipper: shipper,
          agent: agent,
          mlo: mlo,
          notify_party: notify_party,
          cnf: cnf,
          remarks: remarks,
          invoice_charges: invoice_charges,
          isApproved: 0,
          destination: destination,
          consignee: consignee,
          ref_id: ref_id,
          currency_value: currency_value,
          container_no: container_no,
          container_count: container_count,
          totalamt: totalamt,
          inv_apprv_no: inv_apprv_no,
        })


        setProfoList(invoice_charges);
        console.log(invoice_charges);
        setIsApproved(result.data.isApproved ? result.data.isApproved : 0);
        setIsApprovedId(result.data.data._id);

        console.log(profoDetails);
        customAxios.post('/booking/getById', { id: booking_number }).then((resp) => {
          let res = resp.data.data;
          setBkgDetail(resp.data.data);
          setBkgSailSched(resp.data.data.sailing_schedule);
          console.log(resp.data.data.sailing_schedule);


        });


      }
      else {
        window.alert('No data found');
      }
    })


  }



  useEffect(() => {
    let year = new Date().getFullYear();
    const profomainvoiceAutogen = "RILWEM/ FE/" + " " + year;
    setconstno(profomainvoiceAutogen)


    let url = '/profomainvoice';
    customAxios.get(url).then((tableLists) => {

      setTableLists(tableLists.data.data);

      setSearchTable(tableLists.data.data);


      console.log(tableLists.data.data);

    });

    let invNumUrl = '/invoice/approve/ids';
    customAxios.get(invNumUrl).then((invNos) => {
      let temp = [...invNos.data.data];
      let invno = temp.map((data) => {
        return { label: String(data.inv_apprv_no).padStart(4, '0'), value: String(data.inv_apprv_no).padStart(4, '0') };
      })
      setInvnumber([...invno]);

    })
  }, [showList]);

  const handleSearch = (option) => {

    if (option == null) {
      let url = '/profomainvoice';
      customAxios.get(url).then((tableLists) => {

        setTableLists(tableLists.data.data);

        setSearchTable(tableLists.data.data);


        console.log(tableLists.data.data);
        setSelectedVal(null);
      });
    }
    else {
      setSelectedVal(option);
      const filterdata = searchtable.filter(item => String(item.inv_apprv_no).padStart(4, '0') === (option ? option.value : ' '));
      setTableLists(filterdata);
    }

  }
  const columns = [

    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "BKG.NO",
      accessor: "booking_number",

    },
    {
      Header: "REF#",
      accessor: "ref_id",
    },
    {
      Header: "ApprovedNo#",
      accessor: "inv_apprv_no",
    },
    {
      Header: "To",
      accessor: "to_customer_name",
    },
    {
      Header: "Curr",
      accessor: "currency",
    },
    {
      Header: "Total Amount",
      accessor: "totalamt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  { console.log(tableLists); }
  const DATA = tableLists.filter(item => item.is_approved === 1).map((item, index) => {
    const refNo = item.ref_no;
    // { console.log(DATA, refNo); }

    return {
      id: index + 1,
      booking_number: 'RUKEX' + item.booking_number,
      ref_id: 'RILWEM/FE/' + item.ref_id,
      inv_apprv_no: String(item.inv_apprv_no).padStart(4, '0'),
      to_customer_name: item.to_customer_name,
      currency: item.currency,
      totalamt: item.totalamt.toFixed(2),
      action: (
        <div style={{ textAlign: 'center' }}>
          {console.log(refNo)}
          <div style={{ textAlign: 'center' }}>

            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, item.inv_apprv_no)} />
            &nbsp;&nbsp;
          </div>
          {console.log(item.refNo)}
        </div>
      )
    };

  });

  if(DATA.length == 0 || null){
    DATA.push({
      id: '',
      booking_number: '',
      ref_id: '',
      inv_apprv_no: 'No Data Available',
      to_customer_name: '',
      currency: '',
      totalamt: '',
      action: ''

    })
  }

  return (
    <>
      <div className={classes.container}>
        <h5>Approved Invoice <span className={classes.title}>Export / Approved Invoice</span></h5>
      </div>
      <Container className={classes.inputsContainer}>
        {showList ? (
          <>
            <table>
              <tr>
                <h6>Approved REF# Listing</h6>
                <td colSpan={1} style={{ width: '10px', paddingLeft: '642px' }}>Search</td>
                <td colSpan={4} style={{ width: '175px' }}>
                  {/* <input className={classes.inputText} value={bookingDetails.carrier_booking_no} onChange={(e) => { handlebookingDetails({ key: 'carrier_no', value: e.target.checked });  }} type="text" /> */}

                  <Select
                    components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    options={invnumber}
                    /* value={invnumber.filter(
                        (option) =>{ 
                            return option.value === tableListNo
                        }
                    )} */
                    value={selectedVal}
                    onChange={handleSearch}
                  />

                </td>
              </tr>
            </table>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12' style={{ marginTop: '5px' }}>

              <CustomTable columns={columns} data={DATA} />

              {/* <table class='table'>
                <thead className={classes.theadDark}>
                  <tr>
                    <th scope='col' style={{ 'text-align': 'center ' }}>#</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>BKG.NO</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>REF#</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>ApprovedNo#</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>To</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Curr</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Total Amount</th>
                    <th scope='col'style={{ 'text-align': 'center ' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                 
                  {tableLists.length
                    ? tableLists.map((tableList, index) => {
                     
                      return (
                        <>
                        {tableList.is_approved == 1 ? 
                        <tr key={index}>
                          <th scope='row' style={{ 'text-align': 'center ' }}>{index + 1}</th>
                          <td style={{ 'text-align': 'center ' }}>RUKEX{tableList.booking_number}</td>
                          <td style={{ 'text-align': 'center ' }}>RILWEM/ FE/{tableList.ref_id}</td>
                          <td style={{ 'text-align': 'center ' }}>{String(tableList.inv_apprv_no).padStart(4, '0')}</td>
                          <td style={{ 'text-align': 'center ' }}>{tableList.to_customer_name}</td>
                          <td style={{ 'text-align': 'center ' }}>{tableList.currency}</td>
                          <td style={{ 'text-align': 'center ' }}>{(tableList.totalamt).toFixed(2)}</td>
                          <td style={{ 'text-align': 'center ' }}>
                            <img src={EditIcon} alt='edit_icon' onClick={() => editprofolist(index, tableList.inv_apprv_no)} />
                           
                          </td>
                        </tr>
                        : null}
                        </>
                      );
                    
                    })
                    : ''}
                </tbody>
              </table> */}
              {tableLists.length ? (
                ''
              ) : (
                <div className={classes.noData}>No data available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <table style={{ width: '100%' }}>
              <tbody><tr>
                <td colSpan={1}><span>BKG.No#*</span></td>
                <td colSpan={2} style={{ width: '100px' }}>
                  <section>
                    <div style={{ position: 'relative' }}>
                      <p style={{ position: 'absolute', maxWidth: '45px', padding: '6px 7px', fontWeight: 'normal', color: '#b6bbc6' }}>RUKEX</p>
                      <input
                        type='text'
                        className={classes.inputText}
                        style={{ paddingLeft: '58px' }}
                        value={profoDetails ? profoDetails.booking_number : ''}
                        // onChange={(e) => setBookingNo(e.target.value)}
                        // onKeyUp={(e) => { handleShippingNo(e ? e : 'edfc') }}
                        disabled={true} />
                    </div>
                  </section>
                </td>

                <td colSpan={1} style={{ width: '10px' }}><span>ApprovedNo#*</span></td>
                <td colSpan={2}><input className={classes.inputText} value={profoDetails ? String(profoDetails.inv_apprv_no).padStart(4, '0') : ''} disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'carrier_no', value: e.target.checked }); }} */ type="text" /></td>
                <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Ref#*</span></td>
                <td colSpan={1}><input className={classes.inputText} type="text" value={constno} disabled={true} /></td>
                <td colSpan={1}><input className={classes.inputText} type="text"
                  value={profoDetails ? profoDetails.ref_id : ''}
                  // value={refNo}
                  // onChange={(evt) => handlerefNo(evt)}
                  // onChange={(e) => { handleshippingDetails({ key: 'refNo', value: e.target.checked }); 
                  // onKeyUp={(evt) => getinvoiceDetail(evt)} 
                  disabled={true} />
                </td>

              </tr>
                <tr>
                  <td>To<span>*</span></td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.to_customer_name : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_name',
                                    value: evt.target.value,
                                })} */
                    type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '35px' }}>Date</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.current_date : ''} type="text" disabled={true} /></td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.Address : ''} /* onChange={(evt) =>
                                handleBookingDetails({
                                    key: 'cus_address',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>
                  <td colSpan={1}><span style={{ paddingLeft: '35px' }}>Curr*</span></td>
                  <td colSpan={1} ><input className={classes.inputText} components={{ IndicatorSeparator, Placeholder }}
                    styles={style}
                    isClearable={true}
                    isSearchable={true}
                    // options={currencies}
                    type="text"
                    value={profoDetails ? profoDetails.currency : ''}
                    // value={currencies.filter(
                    //     (option) => option.value === selectedValue
                    // )}
                    // selectedValue={selectedValue}
                    // onChange={(e) => {
                    //     if (e) handleSelectChange(e);
                    // }}
                    disabled={true} >
                  </input>
                  </td>
                  <td colSpan={1}><input className={classes.inputText} value={profoDetails ? profoDetails.currency_value : ''} /* onChange={(e) => { handleProfoInvInp(e) }} */ type="text" disabled={true} /></td>
                </tr>
                <tr>

                  <td style={{ paddingLeft: '2px' }} colSpan={1} >Destination</td>
                  <td colSpan={5}><input className={classes.inputText} value={profoDetails ? profoDetails.destination : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'place_of_delivery',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>

                </tr>
                <tr>
                  <td colSpan={1}>Shipper</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.shipper : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'shipper_name',
                                    value: evt.target.value,
                                })} */  type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>Agent</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.agent : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'forwarding_agent',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container</td>
                  <td colSpan={2} rowSpan={2}><textarea style={{ height: '100px', marginTop: '-18px' }} value={profoDetails ? profoDetails.container_count : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'containerlength',
                                    value: evt.target.value,
                                })} */ disabled={true} /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Cosingnee</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.consignee : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'consignee_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1} >MLO</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.mlo : ''} /* onChange={(evt) => handlebookingDetails({ key: 'vessel', value: evt.target.value, })} */ type="text" disabled={true} /></td>

                </tr>
                <tr>
                  <td colSpan={1}>Notify Party</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.notify_party : ''} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'notify_party_name',
                                    value: evt.target.value,
                                })} */ type="text" disabled={true} /></td>

                  <td style={{ paddingLeft: '100px' }} colSpan={1}>CNF</td>
                  <td colSpan={2}><input className={classes.inputText} value={profoDetails ? profoDetails.cnf : ''} type="text" disabled={true} /* onChange={(e) => { handleBookingDetails({ key: 'CNF', value: e.target.checked });  */ /></td>
                </tr>
                <tr>
                  <td colSpan={1}>Remarks</td>
                  <td colSpan={5}><textarea style={{ height: '100px' }} value={profoDetails ? profoDetails.remarks : ''} disabled={true} /* onChange={(e) => { handleshippingDetails({ key: 'remarks', value: e.target.checked });  }} */ /></td>

                  <td colSpan={1} style={{ paddingLeft: '35px' }}>Container No</td>
                  <td colSpan={2}><textarea style={{ height: '118px', marginTop: '-20px' }} value={profoDetails ? profoDetails.container_no : ''} disabled={true} /* onChange={(evt) =>
                                handleDetails({
                                    key: 'container_details',
                                    value: evt.target.value,
                                })} */  /></td>
                  <tr><td colSpan="9">
                    <hr></hr>
                  </td></tr>

                </tr>
              </tbody>
            </table>

            <Row className={classes.row}>
              <Col className={classes.input} md='5'>
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    padding: '10px 0px',
                  }}
                >
                  Invoices
                </p>
              </Col>
            </Row>

            <div style={{ width: '100%', height: '150px', overflow: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className={classes.theadDark}>
                  <tr>
                    <th>charges</th>
                    <th>Curr</th>
                    <th>Rate</th>
                    <th>Base</th>
                    <th>Exchange Rate</th>
                    <th>No.Of.Containers</th>
                    <th>Amount</th>
                    {/* <th>Action</th> */}


                  </tr>
                </thead>

                <tbody>
                  {profoList ? <>
                    {profoList.map((data, index) => (
                      <tr>
                        <td>{data.charge}</td>
                        <td>{data.currency}</td>
                        <td>{parseFloat(data.rate).toFixed(2)}</td>
                        <td>{data.base}</td>
                        <td>{data.exchangeRate}</td>
                        <td>{data.quantity}</td>
                        <td>{data.total == null || data.total == 0 ? '0' : parseFloat(data.total).toFixed(2)}</td>
                        {/* {data.total > 0 ? <td>{data.total}</td> : '0'}  */}

                        {/*                                         {
                                            data.currency == 'USD' ? (
                                            <td>{data.rate * profoInvInp}</td>
                                            ): <td>{data.rate * details.containerlength }</td>
                                            } */}
                      </tr>
                    )
                    )} </>
                    : null}
                </tbody>

              </table>

            </div>
            <div>
              <table>
                <tr>
                  <td colSpan={5} style={{ width: '50%' }}></td>
                  <td colSpan={2} style={{ width: '20%', textAlign: 'right' }}>Total</td>
                  <td colSpan={1} style={{ width: '15%' }}><input className={classes.inputText} style={{ marginTop: '10%' }} value={profoDetails ? (parseFloat(profoDetails.totalamt).toFixed(2)) : ''} type="text" disabled={true} ></input></td>

                </tr>
              </table>
              <div className={classes.footer}>
                {/* {isApprovedId == 1 ? <button className={classes.footerButton} onClick={ApprovedId} disabled={true}>
                Approved
              </button> : <button className={classes.footerButton} onClick={ApprovedId}>
                Approve
              </button>} */}
                <button className={classes.footerButton} style={{ float: 'right', margin: '1%', marginRight: '-20px' }} ><Template
                  //   sellingDetails={sellingDetails}
                  pdfDatas={pdfDatas}
                  profoList={profoList}
                  profoDetails={profoDetails}
                  invoiceNumber={invoiceNumber}
                  bkgDetail={bkgDetail}
                  bkgSailSched={bkgSailSched}

                /></button>
                <button
                  className={classes.footerButton} style={{ float: 'right', margin: '1%' }}
                  onClick={() => { setShowList(true) }}
                >
                  View List
                </button>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );

}
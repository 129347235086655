import React, { useEffect, useRef, useState } from 'react';
import customAxios from '../../../Axios';
import { Logo, HeaderLogo } from '../../../Images';
import { Loader } from '../../../Images';
import Select from 'react-select';

export default function Template({
  pdfDatas,
  prodebitlist,
  prodebitDetails,
  bkgDetail,
  bkgSailSched


  // formInput
}) {

  const style = {
    control: (base) => ({
      ...base,
      padding: '0',
      outline: 'none',
      minWidth: '100px',
      // This line disable the blue border
      boxShadow: 'none',
    }),
  };

  const IndicatorSeparator = () => null;
  const Placeholder = () => null;

  const username = localStorage.getItem('name');



   const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth();
  // const day = date.getDate();
  // const today = `${year}-${month < 10 ? '0' : ''}${month + 1}-${day}`; 
  // const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  // ;
  let data = {
    customer: 'Test',
    ...pdfDatas,
    ...prodebitlist,
    ...prodebitDetails,
    ...bkgDetail,
    ...bkgSailSched,
  };

  if (data === 'undefined') {
    data = ''; 
  }
  
  console.log(data); 
  
  let count = 0;

  count = parseInt(data.quantity_20) + parseInt(data.quantity_40);

  const [instructions, setInstructions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  let containers = '';
  if (count > 0) {
    if (data.quantity_20 > 0) {
      containers = containers + data.quantity_20 + ' x 20FT ';
    }

    if (data.quantity_40 > 0) {
      containers = containers + data.quantity_40 + ' x 40FT ';
    }
  }

  const styles = `
  @media print{
  
    
    
  
    @page { 
      // margin: 0 !important;     
      margin: 96px 120px 96px 120px;
      background-color: #FAFAFA; 
     }
     #pageHeader {
      /* Styles for the header section */
      font-family: Arial, sans-serif;
      font-size: 11px;
      // text-align: center;
      padding: 10px;
      // background-color: #f2f2f2;
      // border-bottom: 1px solid #ccc;
      // height:50px;
      letter-spacing: 0.5px;
      
      
    }
    body{
      padding: 2px;
      border-spacing: 0px;
      
      position:relative;
    
   }
  
   .tablepdf {
    padding: 0px;
    margin: 0px;
    line-height: 0;
  }
 

.img{
  height: 50px;
  width:auto;
}
    th .headerLeft{
      text-align: left !important;
   }
  
   .tableContainer {
      // display: flex;
    //   margin: 20px 10px;
    //   border-width: 5px;
      // margin-top:10px; 
      bordercolor: 'white';
   }

   .tablepdf tr {
    //   border: 2px solid !important ;

   }

    .tablepdf th {
      //   border: 2px solid !important ;
    }

    .tablepdf td {
      //   border: 2px solid !important ;
         padding: 5px !important      
    }

    .container {
      padding: 3px; 
    }

    .bankdetails{
    //  padding:100px !important;
      text-decoration:underline !important ;
      text-decroration-thickness:5px !important;
    }

    .nextpage{
    page-break-before:always;
    }
  }

 
  }
  @media print {
    .footer {
      position: fixed;
      bottom: 0;
      width: 97%;
     
      visibility: hidden;
     
    }
  
    .footer:last-child {
      visibility: visible;
    }
  
    .page-break {
      page-break-after: always;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 97%;
    visibility: hidden;
   
   }
   
   .footer:last-child {
    visibility: visible;
   }

   #toPdf{
    font-family : Arial;
    letter-spacing: 0.5px;
    
   }

   #footerhtml{
    font-family : Arial;
    font-size: 11px;
    letter-spacing: 0.5px;

   }

   #parent > td, #child > td{
    border : 1px solid #C3C3C3;
   }

   .tab {
    display: inline-block;
    margin-left: 4em;
}


   /* th, td{
    padding: 1%;
   
   } */
  
  `;

  const [IsLoading, setIsLoading] = useState(false);
  const [autogenInvNo, setAutogenInvNo] = useState(false);
  const [crdtNo,setCrdtNo] = useState('');
 
  const print = () => {
    
    let html = document.getElementById('toPdf');
   
    let style = styles;
    let content;
    if (html.outerHTML) content = html.outerHTML;
    else if (XMLSerializer)
      content = new XMLSerializer().serializeToString(html);

      let headerhtml = document.getElementById('pageHeader');
    // let style = styles;
    let headercontent;
    if (headerhtml.outerHTML) headercontent = headerhtml.outerHTML;

    else if (XMLSerializer)
      headercontent = new XMLSerializer().serializeToString(headerhtml);
    
    setIsLoading(true);
    
    customAxios
    .post('/booking/pdf', {
      header: headercontent,
        template: content,
      style: style,
      bookingRefNo: data.bookingNo,
      refno: data.ref_no,
      logo: Logo,
    })
    .then((resp) => {
      if (resp.status == 200) {
        customAxios
          .get('/booking/getPdf', { responseType: 'blob' })
          .then((resp) => {
            const blob = new Blob([resp.data], {
              type: 'application/pdf',
              mode: 'no-cors',
            });
          
            const fileName = `CreditNote-${data.credit_no}.pdf`;

            let link = document.createElement('a');
            link.download = `CreditNote-${data.credit_no}.pdf`;
            link.href = URL.createObjectURL(blob);
            link.click();
            URL.revokeObjectURL(link.href);
            setIsLoading(false);

            customAxios
              .post('import/voucher/proformacreditnote/getbyID', {
                ref_no: data.ref_no,
              })
              .then((data) => {
                console.log(data);
                console.log(data.data.data.credit_no);
                console.log(data.data.data.is_approved);
                // const creditNumber = data.data.data.credit_no;
                
                if (data.data.data.is_approved === 1) { 
                  const formData = new FormData();
                  formData.append('name', data.data.data.bkg_no);
                  formData.append('documents', blob, fileName);

                  customAxios
                    .post('/imp/upload', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })
                    .then((uploadResponse) => {
                      console.log('File uploaded successfully!');
                      console.log('Response:', uploadResponse.data);
                    })
                    .catch((err) => console.log(err.message || 'Some error'));
                } else {
                  console.log('PDF upload not allowed.');
                }
              })
              .catch((err) => console.log(err.message || 'Some error'));
          })
          .catch((err) => console.log(err.message || 'Some error'));
      }
    });
  };



  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
    customAxios.post('/customer/byName', { name: data.customer }).then((resp) => {
      if (resp && resp.data && resp.data.data)
        setCustomerDetails(resp.data.data);
      

    });
  }, [data.customer]);

  
  useEffect(() => {
    if (data.ref_no) {
      customAxios
        .post('import/voucher/proformacreditnote/getbyID', {
          ref_no: data.ref_no,
        })
        .then((responseData) => {
          console.log(responseData);
          const creditNumber = responseData.data.data.credit_no;
          if (responseData.data.data.is_approved === 1) {
            setAutogenInvNo(true);
            setCrdtNo(creditNumber);
          }
          
        })
        .catch((err) => console.log(err.message || 'Some error'));
    }
  }, [data.ref_no]);

  //converted logo image as a base64
  const [base64Image, setBase64Image] = useState('');
  useEffect(() => {
    // Load the image and convert it to base64
    const loadImageAsBase64 = async () => {
      const response = await fetch(HeaderLogo); // Replace with your image URL
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(blob);
    };

    loadImageAsBase64();
  }, []);

  //for amount in words value
  var amount = data.amt_in_words;
  var amtinwords = amount && amount.replace('-', ' ');

  return (
    <>
    {/* <div id="toPdf" style={{ padding: "5px 20px 5px 20px" }}> */}
    <div id="pageHeader" style={{ padding: "5px 20px 0px 20px", display: 'none' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <td colspan="4" style={{ textAlign: 'left' }}><img src={base64Image} alt="Image" style={{ height: '15%', width: '55%', verticalAlign: 'top' }} /></td>
            <td colspan="3" style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center', textAlign: 'justify', fontFamily: 'Arimo' }}>CREDIT NOTE</td>
          </tr>
        </thead>
      </table>
    </div>
    <div id="toPdf" style={{ padding: "5px 20px 5px 20px", display: 'none' }}>

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>

        <tbody>
          <tr>

            <td colspan="5" style={{ fontSize: '7px'/* , width: '50%' */, fontFamily: 'Arimo' }}>CREDIT TO:</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>{/* autogenInvNo ? 'INVOICE NO.' : 'INVOICE NO.' */}JOB#</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>{`${"RUKIM"  + data.bkg_no}`}</td>
          </tr>
          <tr >
            {/* <td colspan="5" rowspan="3" style={{ fontSize: '7px', fontFamily: 'Arimo' }}>{data.cus_name},<br></br>{data.cus_address}</td> */}
            <td colspan="5" rowspan="3" style={{ fontSize: '7px', fontFamily: 'Arimo' }}>
              {data && data.to_address}
              <br />
              {data && data.Address && data.Address.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>CN#</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${"RILWEM/FI/"+ year + " " + data.credit_no}`}</td>
          </tr>

          <tr>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px'/* , width: '26%' */, fontFamily: 'Arimo' }}>VESSEL</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px'/* , width: '24%' */, fontFamily: 'Arimo' }}>{`${data.vessel}`}</td>
          </tr>
          <tr>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '26%' */, fontFamily: 'Arimo' }}>DATE</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', borderBottom: '1px solid #C3C3C3'/* , width: '24%'  */, fontFamily: 'Arimo' }}>{`${date}`}</td>
          </tr>


          <tr>
            <td colspan="1" style={{ fontSize: '7px'/* , width: '30%' */, borderBottom: 'none' }}>&nbsp;</td>
            <td colspan="4" style={{ fontSize: '7px'/* , width: '20%' */, borderBottom: 'none' }}>&nbsp;</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', fontSize: '7px', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}>&nbsp;</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', borderBottom: 'none', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}>&nbsp;</td>
          </tr>


          <tr>
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>SHIPPER{/* &emsp;&emsp;&emsp;&emsp;&ensp;&ensp;&nbsp;: */}</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.shipper}`}</td>
            <td colspan="1" style={{ border: 'none', fontSize: '7px',  width: '26%', fontFamily: 'Arimo' }}>&nbsp;</td>
            <td colspan="1" style={{ border: 'none',  fontSize: '7px', borderTop: 'none', width: '24%', fontFamily: 'Arimo' }}>&nbsp;</td>
          </tr>
          <tr >
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>CONSIGNEE{/* &emsp;&emsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;: */}</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.consignee}`}</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: '1px solid #C3C3C3', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>B/L#</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: '1px solid #C3C3C3', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${"RUKIM"  + data.bkg_no}`}</td>
          </tr>
          <tr>
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>NOTIFY PARTY{/* &emsp;&nbsp;&nbsp;&nbsp;&ensp;: */}</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%',/* , width: '40%', */ fontFamily: 'Arimo' }}>{`${data.notify_party}`}</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', width: '20%', fontFamily: 'Arimo' }}>POD</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%'/* , width: '20%' */, fontFamily: 'Arimo' }}>{`${data.pod /* + ' ' + '&' + ' ' + data.eta */}`}</td>
          </tr>
    
          <tr>
            <td colspan="2" style={{ fontSize: '7px', width: '12%', fontFamily: 'Arimo' }}>REMARKS</td>
            <td colspan="1" style={{ fontSize: '7px', width: '2%', fontFamily: 'Arimo' }}>:</td>
            <td colspan="2" style={{ fontSize: '7px', width: '36%', fontFamily: 'Arimo' }}>{`${data.remarks}`}</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px', width: '26%', fontFamily: 'Arimo' }}>VENDOR REF#</td>
            <td colspan="1" style={{ border: '1px solid #C3C3C3', borderTop: 'none', borderLeft: 'none', fontSize: '7px', width: '24%', fontFamily: 'Arimo' }}>{`${data.vendor_ref ? data.vendor_ref : ' '}`}</td>
          </tr>
        



          <tr>
            <td colSpan={7}>&nbsp;</td>
          </tr>
        </tbody>

      </table>

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>

        <tbody>

          <tr style={{ border: '1px solid #C3C3C3', backgroundColor: '#F2F2F2', fontSize: '7px' }} id="parent">
            <td style={{ width: '50%', textAlign: 'center', fontFamily: 'Arimo' }}>CHARGES</td>
            <td style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>CURR</td>
            <td style={{ width: '5%', textAlign: 'center', fontFamily: 'Arimo' }}>RATE</td>
            <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>BASE</td>
            <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>EX RATE</td>
            <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>QUANTITY</td>
            <td style={{ width: '10%', textAlign: 'center', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>AMOUNT({`${data.currency}`})</td>
          </tr>

          {prodebitlist ? <>

            {prodebitlist.map((data, index) => (
              <tr style={{ border: '1px solid #C3C3C3', borderTop: 'none', fontSize: '7px' }} id="child">
                <td style={{ width: '20%', textAlign: 'Left', fontFamily: 'Arimo' }}>{`${data.charge}`}</td>
                <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.currency}`}</td>
                <td style={{ width: '20%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.rate ? parseFloat(data.rate).toFixed(2) : 0.00}`}</td>
                <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.base}`}</td>
                <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.exchangeRate == null ? ' ' : data.exchangeRate}`}</td>
                <td style={{ width: '10%', textAlign: 'center', fontFamily: 'Arimo' }}>{`${data.quantity == null ? ' ' : data.quantity}`}</td>
                <td style={{ width: '20%', textAlign: 'center', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>{`${data.total == null ? '0.00' : parseFloat(data.total).toFixed(2)}`}</td>

              </tr>

            )
            )} </>
            : null}
          <tr style={{ fontSize: '7px' }}>
            <td colspan={6} style={{ width: '20%', textAlign: 'left', borderRight: '1px solid #C3C3C3', borderLeft: '1px solid #C3C3C3'/* borderTop: 'none', borderBottom: 'none' */, fontFamily: 'Arimo' }}>TOTAL</td>
            <td colspan={1} style={{ width: '80%', textAlign: 'center', fontWeight: 'bold', borderRight: '1px solid #C3C3C3', fontFamily: 'Arimo' }}>{`${prodebitDetails ? parseFloat(prodebitDetails.total_amt).toFixed(2) : 0}`}</td>

          </tr>
          <tr style={{ fontSize: '7px', borderBottom: 'none' }} id="child">
            <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '10%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
            <td style={{ width: '20%', textAlign: 'center', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>&nbsp;</td>
          </tr>
        </tbody>

      </table>
      <div style={{ minHeight: '170px', position: 'relative' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>

          <tbody>
            <tr style={{ fontSize: '7px' }} >
              <td colspan={4} style={{ textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borederBottom: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ width: '20%', textAlign: 'left', borderRight: '1px solid #C3C3C3', fontWeight: 'bold', textAlign: 'center', borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: 'none', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>

            

            <tr style={{ fontSize: '7px' }}>
              <td colspan={2} style={{ width: '20%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>Amount In Words{/* &emsp;&emsp;&emsp;&ensp;&nbsp;: */}</td>
              <td colspan={5} style={{ width: '60%', textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo', textTransform: 'uppercase' }}>{`${data.currency}`}  {`${amtinwords}`}{/* {`${data.amtinwords.toUpperCase()}`} */}</td>

            </tr>
            <tr style={{ fontSize: '7px' }}>
              <td colspan={2} style={{ width: '20%', textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>&nbsp;</td>
              <td colspan={5} style={{ width: '60%', textAlign: 'left', fontWeight: 'bold' }}>&nbsp;</td>

            </tr>

            <tr style={{ fontSize: '7px' }}>
              <td colspan={4} style={{ textAlign: 'left', borderRight: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', fontFamily: 'Arimo' }}>CONTAINER DETAILS</td>
              <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ textAlign: 'left', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>
            <tr style={{ fontSize: '7px' }}>
              <td colspan={4} style={{ textAlign: 'left', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: 'none', fontFamily: 'Arimo' }}>{`${data.container_count}`}</td>
              <td colspan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
              <td style={{ textAlign: 'left', fontWeight: 'bold', fontFamily: 'Arimo' }}>&nbsp;</td>
            </tr>

          </tbody>

        </table>
        <div style={{ width: '93%', position: 'fixed', bottom: 0 }}>
          

          <table style={{ width: '100%', borderCollapse: 'collapse', bottom: '0px' }}>

            <tbody>
              <tr>
                <td colspan={7} style={{ borderTop: '1px solid #C3C3C3' }}></td>
              </tr>

              <tr>
                <td colspan={7} style={{ fontSize: '5px' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '5px', fontFamily: 'Arimo' }}>E & O E</span><br></br>

                  <p style={{ textAlign: 'Left', fontFamily: 'Arimo' }}>&#10070;&nbsp;IF THERE IS ANY DISCREPANCY, KINDLY CONTACT OUR ACCOUNTS DEPARTMENT WITHIN 3 DAYS IN WORKING FROM THIS INVOICE DATE OTHERWISE ALL CHARGES ARE DEEMED TO BE CORRECT.<br></br>
                    <span>&nbsp;</span><br></br>
                    &#10070;&nbsp;PENALITIES FOR LATE PAYMENT AS APPLICABLE.<br></br>
                    <span>&nbsp;</span><br></br>
                    &#10070;&nbsp;ALL CARGOES MUST BE INSURED UNTIL ITS FINAL DESTINATION, WE AS CARRIER WILL NOT BE RESPONSIBLE FOR ANY DAMAGE/CLAIM DURING CARGO IN TRANSIT.<br></br>
                    <span>&nbsp;</span><br></br>
                    &#10070;&nbsp;THIS INVOICE IS COMPUTER GENERATED, NO SIGNATURE IS REQUIRED.<br></br>
                    <span>&nbsp;</span><br></br>
                    &#10070;&nbsp;ALL BUSINESS ARE UNDERTAKEN SUBJECT TO THE STANDARD TRADING CONDITIONS OF BRITISH INTERNATIONAL FREIGHT ASSOCIATION. A COPY OF WHICH CAN BE OBTAINED
                    FROM HTTP://WWW.BIFA.ORG/CONTENT/TRADING.ASPx.</p>

                </td>
              </tr>

            </tbody>

          </table>
        </div>
      </div>
    </div>

    

    <div onClick={print} > {/* ref={loaderRef}  disabled={IsLoading} */}
      {/* Download PDF */}
      {IsLoading ?

        <img src={Loader} width="25px" alt="" /> : 'Download PDF'
      }
    </div>
  </>
  );
}